import React, { useRef, useState} from "react";
import Parser from "html-react-parser";
import { withStyles, withTheme } from "@material-ui/core/styles";

import * as serviceBrowser from "lib/service-browser";
import * as configApp from "config/config-app";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import Zoom from "@material-ui/core/Zoom";

const TAG = "[templates-ui/material/alerts/NoticeDialog.js]";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Zoom timeout={200} ref={ref} {...props} />;
// });

const styles = (theme) => ({
  root: {
    // border: "1px solid red",
    margin: 0,
    padding: theme.spacing(2),
  },
  modalTitle: {
    //border: "1px solid red",
    height: "50px",
    //background: "#404149",
    background: "#fff",
    "& h3": {
      //color: "#fff",
      margin: "0",
      width: "95%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  modalTail: {
    //border: "1px solid red",
    // textAlign: "center",
    // justifyContent: "center",
    "& .MuiButton-containedPrimary": {
      background: "#424242",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
  selectableLabel: {
    userSelect: "none",
  },
});

const noop = function () {};

/**
 * Material-UI의 Dialog를 랩퍼 한 Functional Component 입니다.<p/>
 *
 * @component
 * @name NoticeDialog
 * @property {sring} title - 알림 팝업의 제목
 * @property {sring} description - 알림 팝업의 내용
 * @property {array} actions - DialogActions에 주입 되는 React.Component 집합체.
 * @see module:lib/service-redux-alerts/redux
 * @see Alerts
 * @author Taesung Park <pts@pineone.com>
 */
const NoticeDialog = (props) => {
  const {
    classes,
    who,
    open,
    setHandleOnRequestClose,
    onOpened,
    onClosed,
    title,
    description,
    actions,
    onDataWatcher,
  } = props;
  const closeBtnRef = useRef(null);
  const [isOpen, setIsOpen] = useState(open);
  const [state, setState] = useState({
    isHideToday: false,
    isHideNotice: false,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const other = name === "isHideToday" ? "isHideNotice" : "isHideToday";
    if (!!state[other]) {
      setState({
        ...state,
        [name]: checked,
        [other]: !checked,
      });
      return;
    }
    setState({
      ...state,
      [name]: checked,
    });
  };

  const handleOnRequestClose = () => {
    serviceBrowser.enableScroll();
    serviceBrowser.enableBackNavigation();
    setIsOpen(false);
  };

  setHandleOnRequestClose && setHandleOnRequestClose(who, handleOnRequestClose);

  const isMobile = serviceBrowser.isMobile();

  const { isHideToday, isHideNotice } = state;

  return (
    <Dialog
      open={isOpen}
      // TransitionComponent={Transition}
      keepMounted
      onClose={handleOnRequestClose}
      onKeyDown={(evt, ) => {
        if( evt.key === 'Escape') {
          // ESC 버튼으로 모달 닫을 경우, 오늘 그만보기, 다시보지 않기 설정 저장
          closeBtnRef.current && closeBtnRef.current.click();
        }
      }}
      disableBackdropClick={true}
      onEntered={onOpened}
      onExited={onClosed}
      PaperProps={{
        style: {
          minWidth: 600,
        },
      }}
      className={classes.root}
    >
      {configApp.SERVICE_TYPE === "pc" && (
        <React.Fragment>
          <DialogTitle
            disableTypography
            // className={classes.root}
            className={classes.modalTitle}
          >
            <h3>{title}</h3>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleOnRequestClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Box px={3}>
            <Divider />
          </Box>
        </React.Fragment>
      )}

      <DialogContent dividers>{Parser(description)}</DialogContent>
      <DialogActions className={classes.modalTail}>
        <Box my={1} mx={2}>
          <FormControlLabel
            classes={{ label: classes.selectableLabel }}
            control={
              <Checkbox
                name="isHideToday"
                color="primary"
                checked={isHideToday}
                onChange={handleChange}
              />
            }
            label="오늘 그만 보기"
          />
        </Box>
        <Box my={1} mx={2}>
          <FormControlLabel
            classes={{ label: classes.selectableLabel }}
            control={
              <Checkbox
                name="isHideNotice"
                color="primary"
                checked={isHideNotice}
                onChange={handleChange}
              />
            }
            label="다시보지 않기"
          />
        </Box>
        <div style={{ flex: "1 0 0" }} />
        {actions && 0 < actions.length && (
          <>
            {actions.map((action, index) => {
              let label = "";
              let callback = noop;
              let dataType = "";
              let { props } = action;
              if (!!props) {
                let { children, onClick } = props;
                label = children;
                if (!!onClick) callback = onClick;
                dataType = props["data-type"] || props.dataType || "";
              } else {
                if (action.hasOwnProperty("label") && !!action.label)
                  label = action.label;
                if (action.hasOwnProperty("onClick") && !!action.onClick)
                  callback = action.onClick;
                if (action.hasOwnProperty("dataType") && !!action.dataType)
                  dataType = action.dataType;
              }
              props = null;

              if (!label) {
                return null;
              }

              return (
                <Box my={1} mx={2} key={index}>
                  <Button
                      ref={closeBtnRef}
                    data-type={dataType}
                    onClick={(evt) => {
                      onDataWatcher && onDataWatcher({ ...state });
                      callback(evt);
                    }}
                    color="primary"
                    type="button"
                    className={
                      "MuiButton-contained MuiButton-containedPrimary custom-modal-button"
                    }
                    style={isMobile ? { width: "80px" } : { width: "100px" }}
                  >
                    <strong>{label}</strong>
                  </Button>
                </Box>
              );
            })}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

NoticeDialog.defaultProps = {
  /**
   * Material-UI Dialog의 open 속성 입니다.<p/>
   *
   * AlertDialog에서 'useState' Hook 함수로 Material-UI Dialog의 종료를 자체 처리 합니다.
   */
  open: true,
  /**
   * Dialog 종료 핸들러를 설정하는 메소드 입니다.<p/>
   *
   * lib/service-redux-alerts/redux 모듈에서 주입 합니다.
   */

  setHandleOnRequestClose: function () {
    console.log(TAG, "setHandleOnRequestClose 주입 되지 않았습니다");
  },
  /**
   * Material-UI Dialog의 onEntered 콜백 메소드 실행 시 호출 되는 핸들러 입니다.<p/>
   *
   * <a href="https://material-ui.com/api/dialog/" target="_blank">참고) onEntered</a>
   *
   * lib/service-redux-alerts/redux 모듈에서 주입 합니다.
   */

  onOpened: function () {
    console.log(TAG, "onOpened가 주입 되지 않았습니다");
  },
  /**
   * Material-UI Dialog의 onExited 콜백 메소드 실행 시 호출 되는 핸들러 입니다.<p/>
   *
   * <a href="https://material-ui.com/api/dialog/" target="_blank">참고) onExited</a>
   *
   * lib/service-redux-alerts/redux 모듈에서 주입 합니다.
   */

  onClosed: function () {
    console.log(TAG, "onClosed가 주입 되지 않았습니다");
  },
  onDataWatcher: function () {
    console.log(TAG, "onDataWatcher가 주입 되지 않았습니다");
  },
  // @TODO 검증배포 테스트
};

export default withTheme(withStyles(styles)(NoticeDialog));
