import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import commaFormater from "utils/commaFormater";
import { Box, IconButton, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import * as serviceBrowser from "../lib/service-browser";


const isMobile = serviceBrowser.isMobile();
/*
 * [변경사항]
 * 선착순 쿠폰용 버튼 수정..
 */

const styles = (theme) => ({
  root: {
    border: "1px solid #ddd",
    "& input": {
      textAlign: "center",
      border: "none",
    },
    "& fieldset": {
      display: "none",
    },
    height: "40px",
    "& button": {
      height: "40px",
      marginTop: "0 !important",
    },
  },
  button: {
    // border: "1px solid red",
    background: "#dedede",
    "& button": {
      width: '100%',
      height: "36px",
      marginTop: "2px !important",
      padding: "6px 12px",
    },
  },
});

class PlusMinusCouponControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      interval: 1,
    };
  }

  componentDidMount() {
    if (typeof this.props.initialVal === "string") {
      this.setState({ initialVal: 0 });
    } else {
      this.setState({ initialVal: this.props.initialVal });
    }
  }

  handleDown = () => {
    const { initialVal, interval, max, min, viewName, textOption } = this.props;

    let _returnVal =
      typeof initialVal === "string" ? min - interval : initialVal - interval;
    if (max && _returnVal > max) {
      _returnVal = max;
    } else if ((min || min === 0 )&& _returnVal < min) {
      if (viewName === "mileageAdd") {
        _returnVal = "쿠폰 없음";
      }else if (viewName === "unlimited") {
        _returnVal = "제한 없음";
      } else {
        if (textOption?.isText) {
          _returnVal = 0;
        } else {
          _returnVal = this.props.min;
        }
      }
    } else if (_returnVal < 0) {
      if (textOption?.isText) {
        _returnVal = 0;
      }
    }

    this.setState({ initialVal: _returnVal });

    if (this.props.callback) {
      if (this.props.name) {
        this.props.callback(_returnVal, this.props.name, "down");
      } else {
        this.props.callback(_returnVal);
      }
    }
  };

  handleUp = () => {
    const { initialVal, interval, max, min } = this.props;
    let _returnVal =
      typeof initialVal === "string" ? min : initialVal + interval;

    if (max && _returnVal > max) {
      _returnVal = max;
    }

    if(min && _returnVal < min){
      _returnVal = min;
    }

    this.setState({ initialVal: _returnVal });

    if (this.props.callback) {
      if (this.props.name) {
        this.props.callback(_returnVal, this.props.name, "up");
      } else {
        this.props.callback(_returnVal);
      }
    }
  };

  render() {
    const { classes, inputWidth = 70, disabled, textOption = { isText: false }, unitType = "" } = this.props;

    if (disabled) {
      return (
        <Box display="flex" className={classes.root}>
          <Box className={classes.button} style={{width: isMobile ? '30px' : ''}}>
            <IconButton>
              <RemoveIcon />
            </IconButton>
          </Box>
          <Box width={inputWidth} align="center">
            <TextField
              disabled
              value={ (textOption.isText && this.props.initialVal === textOption.value ) ? textOption.text : `${commaFormater(this.props.initialVal)}${unitType}`}
              variant="outlined"
              margin="normal"
              size="small"
              style={{
                width: "100%",
                margin: "0",
                border: "none",
                backgroundColor: "#efefef",
                //양태욱 추가
                height: "100%",
              }}
              // 양태욱 추가
              inputProps={{
                style: {
                  fontSize: "12px",
                },
              }}
            />
          </Box>
          <Box className={classes.button} style={{width: isMobile ? '30px' : ''}}>
            <IconButton>
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box display="flex" className={classes.root}>
          <Box className={classes.button} onClick={this.handleDown} style={{width: isMobile ? '30px' : ''}}>
            <IconButton>
              <RemoveIcon />
            </IconButton>
          </Box>
          <Box width={inputWidth} align="center">
            <TextField
              disabled
              value={ (textOption.isText && this.props.initialVal === textOption.value ) ? textOption.text : `${commaFormater(this.props.initialVal)}${unitType}`}
              variant="outlined"
              margin="normal"
              size="small"
              style={{
                width: "100%",
                margin: "0",
                border: "none",
              }}
              inputProps={{
                style: {
                  fontSize: "12px",
                },
              }}
            />
          </Box>
          <Box className={classes.button} onClick={this.handleUp} style={{width: isMobile ? '30px' : ''}}>
            <IconButton>
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      );
    }
  }
}

const styledPlusMinusCouponControl = withStyles(styles)(PlusMinusCouponControl);

export { styledPlusMinusCouponControl as PlusMinusCouponControl };
