/**
 * 입력 필드의 입력 길이를 정의 한 모듈 입니다
 *
 * @version 0.0.1
 * @author Taesung Park <pts@pineone.com>
 * @module constants/InputLength
 */

const InputLength = {
  // 이름
  userName: {
    max: 20,
  },
  // 아이디
  userId: {
    max: 100,
  },
  // 비밀번호
  password: {
    max: 20,
  },
  // 이메일
  email: {
    // 로컬 파트 + @ + 도메인 파트
    max: 64 + 1 + 255,
    // 로컬 파트
    LOCAL: {
      max: 64,
    },
    // 도메인 파트
    DOMAIN: {
      max: 255,
    },
  },
  // 휴대폰 번호
  phoneNumber: {
    max: 11,
  },
  // 인증번호
  smsAuthCode: {
    max: 6,
  },
  // 제휴점명
  stayName: {
    max: 25,
  },
  // 대표자명
  agentName: {
    max: 25,
  },
  // 사업자등록번호
  businessNum: {
    max: 3 + 2 + 5,
    // 사업자등록번호-첫번째
    FIRST: {
      max: 3,
    },
    // 사업자등록번호-첫번째
    MIDDLE: {
      max: 2,
    },
    // 사업자등록번호-첫번째
    LAST: {
      max: 5,
    },
  },
  // 주소
  address: {
    // 상세 주소
    DETAIL: {
      max: 50,
    },
  },
  // 제휴점 대표 번호
  stayTel: {
    max: 3 + 4 + 4,
    // 제휴점 대표 번호-첫번째
    FIRST: {
      max: 3,
    },
    // 제휴점 대표 번호-첫번째
    MIDDLE: {
      max: 4,
    },
    // 제휴점 대표 번호-첫번째
    LAST: {
      max: 4,
    },
  },
  // 정산계좌번호 – 예금주
  accountName: {
    max: 50,
  },
  // 정산계좌번호 – 계좌번호
  accountNum: {
    max: 20,
  },
  // 자동이체계좌번호  – 예금주
  accountNameAuto: {
    max: 50,
  },
  // 자동이체계좌번호 – 계좌번호
  accountSSNAuto: {
    max: 6,
  },
  // 자동이체계좌번호 – 계좌번호
  accountNumAuto: {
    max: 20,
  },
  // 제휴점 관리
  businessInfo: {
    // 기본 정보
    BASE: {
      // 제휴점관리[찾아오시는 길 제목] 	최대 15자
      description: {
        max: 15,
      },
      // 제휴점관리[찾아오시는 길 내용] 	최대 500자
      locationDescription: {
        max: 500,
      },
      // 제휴점관리[주차장정보 상세내용] 	최대 1,000자
      parkingDescription: {
        max: 1000,
      },
      // 혜택정보[객실내부 시설] 	최대 500자
      installationInside: {
        max: 500,
      },
      // 혜택정보[프론트 외 그 외 시설] 	최대 1,000자
      installationFront: {
        max: 1000,
      },
      // 사장님 인사말 	최대 1,000자
      greetingMsg: {
        max: 1000,
      },
    },
    ROOM: {
      // 숙소기본정보[객실명] 	최대 45자
      name: {
        max: 45,
      },
    },
  },

  // 후기 답변 - 1000
  // 특정일(특정일명) - 25
  // 특정일[추가안내] 	최대 1,000자
  // 기준시간[추가안내] 	최대 500자
  // 우리모텔이벤트[이벤트명] 	최대 25자
  // 우리모텔이벤트[이벤트내용] 	최대 1,000자
  // 1:1문의[문의하기 제목] 	최대 25자
  // 1:1문의[문의하기 내용] 	최대 1,000자
  // 매물거래[제목] 	최대 25자
  // 매물거래[매물소개] 	최대 500자
  // 매물거래[댓글] 	최대 100자
  // 매물거래[은행권] 	최대 15자
  // 매물거래[난방방식] 	최대 20자[콤마(,), 대쉬(/), 하이픈(-) 포함]
};

export default InputLength;
