/**
 * 본 모듈은 개행 문자를 BR 문자로 변환하여 반환하는 모듈입니다.
 *
 * @module utils/newlineToBr
 */

/**
 * 개행 문자를 BR 문자로 변환하는 메소드 입니다.
 *
 * @param {string} str 개행 문자가 포함된 문자열
 * @returns {string} 개행 문자를 BR 문자로 변환 한 문자열
 */
export default function newlineToBr(str) {
  if (str && typeof str === "string") {
    return str.replace(/(\n|\r\n|'\\n'|'\\r\\n')/g, "<br>");
  }
  return str;
}
