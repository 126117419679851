/**
 * Multipart 기반으로 파일 업로드 시<br/>
 * 전송 할 정보를 HTML5 FormData 객체로 변환 처리 하는 모듈 입니다<p/>
 *
 * @module lib/service-form-data-transform
 * @see module:lib/service-api
 */

/**
 * 전송 할 정보를 HTML5 FormData 객체로 변환 처리 하는 메소드 입니다
 *
 * @param {object} data - 전송 할 정보
 * @returns {FormData} HTML5 FormData 객체
 */
export function formDataTransform(data) {
  var formData = new FormData();
  forEach(data, function (value, key) {
    if (isElement(value)) {
      var files = [];
      forEach(value, function (el) {
        forEach(el.files, function (file) {
          files.push(file);
        });
        el.value = "";
      });
      if (files.length !== 0) {
        if (files.length > 1) {
          forEach(files, function (file, index) {
            formData.append(key + "[" + index + "]", file);
          });
        } else {
          formData.append(key, files[0]);
        }
      }
    } else if (isArrayFile(value)) {
      var files = [].concat(value);
      if (files.length !== 0) {
        // if (files.length > 1) {
        forEach(files, function (file, index) {
          // formData.append(key + "[" + index + "]", file);
          formData.append(key, file);
        });
        // } else {
        //   formData.append(key, files[0]);
        // }
      }
    } else {
      formData.append(key, value);
    }
  });
  return formData;
}

function isString(value) {
  return typeof value === "string";
}

function isWindow(obj) {
  return obj && obj.window === obj;
}

function isNumber(value) {
  return typeof value === "number";
}

function isFunction(value) {
  return typeof value === "function";
}

function isArray(arr) {
  return Array.isArray(arr) || arr instanceof Array;
}

function isArrayFile(arr) {
  return isArray(arr) && arr[0] instanceof File;
}

function isArrayLike(obj) {
  if (obj == null || isWindow(obj)) return false;
  // if (isArray(obj) || isString(obj) || (jqLite && obj instanceof jqLite)) return true;
  if (isArray(obj) || isString(obj)) return true;
  var length = "length" in Object(obj) && obj.length;
  return (
    isNumber(length) &&
    ((length >= 0 && length - 1 in obj) || typeof obj.item === "function")
  );
}

function isBlankObject(value) {
  return (
    value !== null && typeof value === "object" && !Object.getPrototypeOf(value)
  );
}

function isElement(node) {
  return !!(
    node &&
    (node.nodeName || // We are a direct element.
      (node.prop && node.attr && node.find))
  ); // We have an on and find method part of jQuery API.
}

function forEach(obj, iterator, context) {
  var key, length;
  if (obj) {
    if (isFunction(obj)) {
      for (key in obj) {
        if (
          key !== "prototype" &&
          key !== "length" &&
          key !== "name" &&
          obj.hasOwnProperty(key)
        ) {
          iterator.call(context, obj[key], key, obj);
        }
      }
    } else if (isArray(obj) || isArrayLike(obj)) {
      var isPrimitive = typeof obj !== "object";
      for (key = 0, length = obj.length; key < length; key++) {
        if (isPrimitive || key in obj) {
          iterator.call(context, obj[key], key, obj);
        }
      }
    } else if (obj.forEach && obj.forEach !== forEach) {
      obj.forEach(iterator, context, obj);
    } else if (isBlankObject(obj)) {
      // createMap() fast path --- Safe to avoid hasOwnProperty check because prototype chain is empty
      for (key in obj) {
        iterator.call(context, obj[key], key, obj);
      }
    } else if (typeof obj.hasOwnProperty === "function") {
      // Slow path for objects inheriting Object.prototype, hasOwnProperty check needed
      for (key in obj) {
        if (obj.hasOwnProperty(key)) {
          iterator.call(context, obj[key], key, obj);
        }
      }
    } else {
      // Slow path for objects which do not have a method `hasOwnProperty`
      for (key in obj) {
        if (hasOwnProperty.call(obj, key)) {
          iterator.call(context, obj[key], key, obj);
        }
      }
    }
  }
  return obj;
}
