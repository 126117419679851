/**
 * 본 모듈은 문자열이 전화번호 형식 인지 검사하는 모듈 입니다
 *
 * @module utils/isPhoneNumberFormat
 */

/**
 * 문자열을 검사하여 전화번호 형식 여부를 반환 합니다
 *
 * @param {string} str - 문자열
 * @return {boolean} 전화번호 형식 여부
 */
export default function isPhoneNumberFormat(str) {
  if (/^\d{2,3}-\d{3,4}-\d{4}$/.test("" + str) === false) {
    return false;
  }
  return true;
}
