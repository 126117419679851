import React, { useRef } from "react";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";

const TextFieldLengthRegShowLimit = ({
  autoComplete,
  autoFocus,
  classes,
  className,
  color,
  defaultValue,
  disabled,
  error,
  FormHelperTextProps,
  fullWidth,
  helperText,
  id,
  InputLabelProps,
  inputProps,
  InputProps,
  inputRef,
  label,
  margin,
  multiline,
  name,
  onChange,
  placeholder,
  required,
  rows,
  rowsMax,
  select,
  SelectProps,
  size,
  type,
  value,
  variant,
  style,
  maxLength,
  filterRegExp,
  showLength = false,
  onFocus,
  onBlur,
  countMargin = "5px",
  bottom = "-5px",
}) => {
  const textRef = useRef();
  const length = defaultValue ? defaultValue.length : 0;
  const inChangeFunc = (event) => {

    // if(event.nativeEvent.isComposing) return; <- 한글 카운팅 즉각적으로 반영되지 않아 주석처리

    if (filterRegExp && event.currentTarget.value) {
      event.currentTarget.value = event.currentTarget.value.match(filterRegExp)
        ? event.currentTarget.value.match(filterRegExp).join("")
        : "";
    }
    //길이제한
    if (maxLength && event.currentTarget.value.length >= maxLength) {
      event.currentTarget.value = event.currentTarget.value.substring(
        0,
        maxLength
      );
    }

    onChange && onChange(event);
  };


  return (
    <div style={{ position: "relative" }}>
      <TextField
        inputRef={inputRef ? inputRef : textRef}
        onChange={inChangeFunc}
        autoComplete={autoComplete || "off"}
        autoFocus={autoFocus && autoFocus}
        classes={classes && classes}
        color={color && color}
        defaultValue={defaultValue && defaultValue}
        disabled={disabled && disabled}
        error={error && error}
        FormHelperTextProps={FormHelperTextProps && FormHelperTextProps}
        fullWidth={fullWidth && fullWidth}
        helperText={helperText && helperText}
        id={id && id}
        InputLabelProps={InputLabelProps && InputLabelProps}
        inputProps={inputProps && inputProps}
        InputProps={InputProps && InputProps}
        label={label && label}
        margin={margin || "normal"}
        multiline={multiline && multiline}
        name={name && name}
        placeholder={placeholder && placeholder}
        required={required && required}
        rows={rows && rows}
        rowsMax={rowsMax && rowsMax}
        select={select && select}
        SelectProps={SelectProps && SelectProps}
        size={size && size}
        type={type && type}
        value={value && value}
        variant={variant || "outlined"}
        style={style && style}
        className={className && className}
        onFocus={onFocus && onFocus}
        onBlur={onBlur && onBlur}
      />
      {showLength && (
        <div
          style={{
            position: "absolute",
            bottom,
            right: countMargin,
            fontSize: "14px",
            opacity: "0.5",
          }}
        >
          ({length}/{maxLength})
        </div>
      )}
    </div>
  );
};

TextFieldLengthRegShowLimit.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  classes: PropTypes.object,
  color: PropTypes.oneOf(["primary", "secondary"]),
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  FormHelperTextProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  label: PropTypes.node,
  margin: PropTypes.oneOf(["dense", "none", "normal"]),
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowsMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  select: PropTypes.bool,
  SelectProps: PropTypes.object,
  size: PropTypes.oneOf(["medium", "small"]),
  type: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  style: PropTypes.object,
  maxLength: PropTypes.number,
  filterRegExp: PropTypes.object,
  className: PropTypes.string,
  showLength: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  bottom: PropTypes.string,
};
export { TextFieldLengthRegShowLimit };
