/**
 * react-metismenu-router-link 에서 업데이트 되어 PC 전용으로 사용되는 컴포넌트 입니다.<p/>
 *
 * 원본 소스는 다음 경로에 위치해 있습니다.<p/>
 * <a href="https://github.com/jhillhouse92/react-metismenu-router-link" target="_blank">참고) jhillhouse92/react-metismenu-router-link</a>
 *
 * react, react-router가 업데이트 되면서<br/>
 * 기존 react-metismenu-router-link에서 history를 가져오는 코드에 문제가 발생하여 업데이트 된 버전입니다.<p/>
 *
 * <a href="https://stackoverflow.com/questions/57798307/is-there-a-problem-in-react-metismenu-router-link-with-react16" target="_blank">참고) 이슈 내용</a>
 *
 * 기본 props는 react-metismenu 정책을 따르며, react-router의 location, history가 추가 되었습니다.<p/>
 *
 * <a href="https://www.npmjs.com/package/react-metismenu" target="_blank">참고) react-metismenu</a>
 *
 * <a href="https://reacttraining.com/react-router/web/guides/quick-start" target="_blank">참고) react-router-dom</a>
 *
 * @class
 * @component
 * @name RouterLinkOnPC
 * @see DefaultLayoutContainer
 * @see RouterLinkOnMobile
 * @property {string} className - Passes built-in class name and classNameLink prop of top component
 * @property {string} classNameActive - Passes built-in class name and classNameLinkActive prop of top component
 * @property {string} classNameHasActiveChild - Passes built-in class name and classNameLinkHasActiveChild prop of top component
 * @property {boolean} active - Active link or not state
 * @property {boolean} hasActiveChild - Has active child or grand child state
 * @property {boolean} hasSubMenu - Has sub menu or not state
 * @property {function} toggleSubMenu - If item has submenu, toggle sub menu callback. Otherwise dead function.
 * @property {function} activateMe - If it is normal link, callback that activates link (to assign active class name) and makes all parents beware they have active link. Also triggers onSelected and given parameters are passed to listener.
 * @property {string} to - Contains to info of the item comes from menu content object
 * @property {boolean} externalLink - Destination is external or not
 * @property {React.Component} children - Ready to render content of link - contains icon, label and other stuff
 * @property {object} location - [react-router] Locations represent where the app is now, where you want it to go, or even where it was
 * @property {object} history - [react-router] The term “history” and "history object" in this documentation refers to the history package, which is one of only 2 major dependencies of React Router (besides React itself), and which provides several different implementations for managing session history in JavaScript in various environments.
 * @author Taesung Park <pts@pineone.com>
 * @example
 * return (
 *    <MetisMenu content={routes} LinkComponent={RouterLinkOnPC} activeLinkFromLocation/>
 * )
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import classnames from "classnames";
import Box from "@material-ui/core/Box";

class RouterLinkOnPC extends React.Component {
  componentWillMount() {
    this.to = this.props.to;
    if (this.to[0] !== "/") this.to = `/${this.to}`;
    this.unlisten = this.props.history.listen(this.onLocationChange.bind(this));
    this.onLocationChange(this.props.location);
  }
  onLocationChange(e) {
    if ((e.pathname || "/") === this.to) {
      this.props.activateMe();
    }
  }
  componentWillUnmount() {
    !!this.unlisten && this.unlisten();
    this.unlisten = null;
  }
  render() {
    const {
      className,
      classNameActive,
      classNameHasActiveChild,
      active,
      hasActiveChild,
      to,
      externalLink,
      hasSubMenu,
      toggleSubMenu,
      children,
      id,
    } = this.props;

    /*
    20200325 박태성C : menu가 없는 화면으로 Routing 시 좌측 메뉴 비활성 처리

    1. 처리 플로우
      1) Routing 설정 파일(ex : routeOnPC, routesOnMobile)
          - id 가 1 인 menu 정의 
      
      2) DefaultLayoutContainer
          - 이벤트 리스닝 중 menu가 없는 화면으로 Routing 시 id 1 인 메뉴 활성화 처리
          - this.menuRef.changeActiveLinkId(1);

      3) /lib/service-react-metismenu-router-link/RouterLinkOnPC.jsx
          - 'JS-HIDE'가 전달 되면 해당 메뉴를 그리지 않음
    */

    if (id === "JS-HIDE") return null;

    return hasSubMenu || externalLink ? (
      <a
        className={classnames(
          className,
          hasActiveChild && classNameHasActiveChild
        )}
        target={externalLink ? "_blank" : undefined}
        href={to}
        onClick={toggleSubMenu}
      >
        <Box
          style={{
            // border: '1px solid red',
            position: "relative",
            display: "flex",
            lineHeight: "53px",
            height: "55px",
          }}
          title={children[1]}
        >
          {children}
        </Box>
      </a>
    ) : (
      <Link
        className={classnames(className, active && classNameActive)}
        to={to}
      >
        <Box
          style={{
            // border: '1px solid red',
            position: "relative",
            display: "flex",
            lineHeight: "53px",
            height: "55px",
          }}
          title={children[1]}
        >
          {children}
        </Box>
      </Link>
    );
  }
}

RouterLinkOnPC.propTypes = {
  className: PropTypes.string.isRequired,
  classNameActive: PropTypes.string.isRequired,
  classNameHasActiveChild: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  hasActiveChild: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  externalLink: PropTypes.bool,
  hasSubMenu: PropTypes.bool.isRequired,
  toggleSubMenu: PropTypes.func,
  activateMe: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(RouterLinkOnPC);
