import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import grey from "@material-ui/core/colors/grey";

import * as configApp from "config/config-app";
let SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";

const WhiteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
}))(Button);

const styles = (theme) => {
  return {
    displayBlock: {
      display: "block !important",
    },
    textCenter: {
      textAlign: "center",
    },
    submitOnPC: {
      width: "200px",
    },
    submitOnMobile: {
      width: "60%",
    },
    submitFull: {
      width: "100%",
    },
    boxStyle: {
      display: "block !important",
      textAlign: "center",
    },
  };
};

/**
 * 확인/취소 Functional Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name ButtonsConfirm
 * @component
 */
function ButtonSubmit(props) {
  const {
    classes,
    fullWidth,
    lblSubmit,
    noop,
    onSubmitByClick,
    styleWhite,
    mt,
    mb,
  } = props;
  const isSubmitByClick = !!onSubmitByClick;
  const isFullWidth = !!fullWidth;
  const CompButton = !!styleWhite ? WhiteButton : Button;
  return (
    <Box
      mt={!isNaN(mt) ? mt : 6}
      mb={!isNaN(mb) ? mb : 3}
      className={classes.boxStyle}
    >
      <CompButton
        type={!isSubmitByClick ? "submit" : "button"}
        onClick={!isSubmitByClick ? noop : onSubmitByClick}
        variant="contained"
        color="primary"
        className={
          !isFullWidth
            ? SERVICE_TYPE_PC
              ? classes.submitOnPC
              : classes.submitOnMobile
            : classes.submitFull
        }
      >
        <strong style={{ fontSize: "15px" }}>{lblSubmit}</strong>
        {/* 양태욱 버튼 텍스트 스타일 추가  */}
      </CompButton>
    </Box>
  );
}

// 초기 props 설정
ButtonSubmit.defaultProps = {
  noop: function () {},
  onSubmitByClick: null,
  lblSubmit: "확인",
};

export default withTheme(withStyles(styles)(ButtonSubmit));
