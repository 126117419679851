import {
  addDays,
  format,
  isFriday,
  isSaturday,
  isSunday,
  subDays,
} from "date-fns";
import { motelTodayFormat, getThisMonthStartDay } from "utils";
/**
 * @author TaeWook Yang <didxodnr0769@pineonc.com>
 *
 * 공통적으로 사용하는 이전 15일, 이전 30일 등의 검색을 편리하게 하기 위하여 제작
 */

const MOTEL_TODAY = motelDateFormat();
/** 3시간 1초를 뺀 Date객체를 반환 */
function motelDateFormat(date) {
  const _date = date === undefined ? new Date() : date;
  const today = new Date(_date.getTime() - 3600 * 3 * 1000 - 1);
  return today;
}

/** 이번달의 마지막 날짜 Date객체 반환
 * 한달을 더한후 하루를 빼서 이번달의 마지막 날짜를 구함.
 */
function getThisMonthLastDay() {
  return new Date(
    MOTEL_TODAY.getFullYear(),
    MOTEL_TODAY.getMonth() + 1,
    0,
    1,
    1,
    1,
    1
  );
}

/**Date객체를 받아 마지막 날짜 Date객체 반환 */
function getMonthLastDay(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0, 1, 1, 1, 1);
}

/** yyyyMMdd 형식의 문자열을 받아 데이트 객체로 반환 */
function getDateByStr(dateStr) {
  if (!dateStr) return;
  return new Date(
    parseInt(dateStr.substr(0, 4)),
    parseInt(dateStr.substr(4, 2)) - 1,
    parseInt(dateStr.substr(6, 2))
  );
}

/** yyyy-MM-dd 형식의 문자열을 받아 yyyyMMdd 형식 반환 */
function dateFormater(date) {
  if (!date) return "";
  return format(new Date(date), "yyyyMMdd");
}


/**
 *
 * */
/**
 * today, yesterday, last7등 정해진 타입별 startDate,endDate 반환
 * @call MileageListPageLaytout, CouponListPageLayout, ReservationPageLayout, SettlePageLayout
 */
function getDatesByType(type) {
  const nowDate = motelTodayFormat();
  switch (type) {
    case "today":
      return { startDate: nowDate, endDate: nowDate };
    case "yesterday":
      return getLastDay();
    case "last7":
      return { startDate: subDays(nowDate, 6), endDate: nowDate };
    case "last15":
      return { startDate: subDays(nowDate, 14), endDate: nowDate };
    case "last30":
      return { startDate: subDays(nowDate, 29), endDate: nowDate };
    case "last60":
      return { startDate: subDays(nowDate, 59), endDate: nowDate };
    case "next7":
      return { startDate: nowDate, endDate: addDays(nowDate, 6) };
    case "next15":
      return { startDate: nowDate, endDate: addDays(nowDate, 14) };
    case "thisMonth":
      return getThisMonthDay();
    case "all":
      return { startDate: null, endDate: null };
    default:
      return { startDate: nowDate, endDate: nowDate };
  }
}

/** date객체를 받아 yyyyMMdd 형식 반환 */
function getStrByDate(date) {
  if (!date) return;
  return format(date, "yyyyMMdd");
}

/** date객체를 받아 yyyy.MM.dd 형식 반환 */
function dateCommaFormater(timestamp) {
  if (!timestamp) return "";
  return format(new Date(timestamp), "yyyy.MM.dd");
}

/** date객체를 받아 yyyy-MM-dd 형식 반환 */
function dateHyphenFormater(date) {
  if (!date) return "";
  return format(new Date(date), "yyyy-MM-dd");
}

/** 어제 날짜 Date객체 반환*/
function getLastDay() {
  const startDate = subDays(MOTEL_TODAY, 1);
  const endDate = subDays(MOTEL_TODAY, 1);
  return { startDate, endDate };
}

/** 이번달 시작일, 종료일 Date객체 반환 */
function getThisMonthDay() {
  const startDate = getThisMonthStartDay(MOTEL_TODAY);
  const endDate = getThisMonthLastDay();
  return { startDate, endDate };
}

/** 퇴실일 정보를 받아 노쇼선택이 가능한 시간 판별 */
function getPossibleNoShow(date) {
  const nextDay = addDays(motelDateFormat(new Date(date)), 1);
  const nextDay3Hour = new Date(
    nextDay.getFullYear(),
    nextDay.getMonth(),
    nextDay.getDate(),
    3
  );
  return nextDay3Hour.valueOf() > new Date().valueOf();
}

/** 오늘 날짜의 자정을 가져옵니다. */
function getTodayMidnight() {
  const todayMidnight = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  return todayMidnight;
}

/** date객체를 받아 해당 일의 자정을 가져옵니다. */
function getMidnight(date) {
  const dateObj = new Date(date);
  const todayMidnight = new Date(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate(),
    0,
    0,
    0
  );
  return todayMidnight;
}

/** Date객체를 받아 해당 날짜의 꿀스테이 기준 종료시간(익일 03시)을 반환해줍니다 */
function getCoolstayMidnight(date) {
  const coolstayMidnight = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 1,
    3
  );
  return coolstayMidnight;
}

/** 금, 토 여부 체크 */
function getIsFridayAndSaturday(date) {
  return isFriday(date) || isSaturday(date) ;
}

/** 주말(금, 토, 일) 여부 체크 */
function getIsWeekendDay(date) {
  return isFriday(date) || isSaturday(date) || isSunday(date);
}
/** 주말(금, 토, 일) 여부 체크 */
function getIsCouponWeekendDay(date) {
  return isFriday(date) || isSaturday(date);
}
/** 주말(토,일) 여부 체크 */
function getIsWeekendHour(date) {
  return isSaturday(date) || isSunday(date);
}
/**
 * 숙박 :  공전 여부 체크
 * 대실  : 공휴 여부 체크
 */
function getIsHoliday(data, isDay) {
  const { _is_holiday, _is_before_holiday } = data;
  if (isDay) return  _is_before_holiday;
  else return _is_holiday;
}

function getIsCouponHoliday(data, isDay) {
  const { _is_holiday, _is_before_holiday } = data;
  if (isDay) return _is_before_holiday;
  else return _is_holiday;
}


function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  const formattedDateTime = `${year}${month}${day}${hours}${minutes}${seconds}`;
  return formattedDateTime;
}

function getDateFromServerTime  (date) {
  if (typeof date !== 'string') return;
  const indexT = date.indexOf('T');
  if (indexT === -1) return date;
  return date.substring(0, indexT);
};

export {
  getLastDay,
  getThisMonthDay,
  getMidnight,
  getPossibleNoShow,
  getDateByStr,
  getDatesByType,
  getStrByDate,
  getMonthLastDay,
  getTodayMidnight,
  getCoolstayMidnight,
  dateCommaFormater,
  dateHyphenFormater,
  dateFormater,
  getIsFridayAndSaturday,
  getIsWeekendDay,
  getIsWeekendHour,
  getIsHoliday,
  getIsCouponHoliday,
  getCurrentDateTime,
  getDateFromServerTime,
  getIsCouponWeekendDay
};
