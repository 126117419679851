import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import { BoxWrap } from "components";

const font = {
  fontSize: "17px",
  fontWeight: "bold",
  letterSpacing: "normal",
};

const badge = {
  margin: "0 0 0 16px",
  borderRadius: "6px",
};

const styles = (theme) => ({
  badgeUse: {
    ...font,
    ...badge,
    padding: "2px 17px 4px",
    color: "#372ff9",
    border: "solid 2px #372ff9",
    backgroundColor: "#ffffff",
  },
  badgeNoUse: {
    ...font,
    ...badge,
    padding: "2px 7px 4px",
    color: "#ffffff",
    border: "solid 2px #e84f4f",
    backgroundColor: "#e84f4f",
  },
});

class SitePaymentTemplate extends React.PureComponent {
  render() {
    const { classes, model } = this.props;
    const { isSitePaymentYN } = model;
    return (
      <BoxWrap>
        <div>
          <div style={{ float: "left", fontSize: "17px", fontWeight: "bold" }}>
            현장결제(시범서비스)
            {isSitePaymentYN ? (
              <span className={classes.badgeUse}>사용 중</span>
            ) : (
              <span className={classes.badgeNoUse}>적용 안 됨</span>
            )}
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
        <div style={{ marginTop: "10px" }}>
          {/* <Box mt={2}> */}
          <h5 style={{ margin: "0", color: "#008aff" }}>
            ※ 현장결제 시범서비스 기간입니다.
          </h5>
          <h5 style={{ margin: "0", color: "#008aff" }}>
            ※ 현장결제 시범서비스가 종료되면 현장결제 기능이 제공되지 않을 수
            있습니다.
          </h5>
        </div>
      </BoxWrap>
    );
  }
}

// 초기 props 설정
SitePaymentTemplate.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default withTheme(withStyles(styles)(SitePaymentTemplate));
