import React from "react";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  arrowButtonWrap: {
    flexDirection: "column",
  },
});

function CustomArrowComponent(props) {
  const handleClickUpButton = () => {
    !!props.onClickUpButton && props.onClickUpButton(props.index);
  };
  const handleClickDownButton = () => {
    !!props.onClickDownButton && props.onClickDownButton(props.index);
  };
  const classes = useStyles();
  return (
    <div id="SelectTinyBtn" className={classes.arrowButtonWrap}>
      <Button focusRipple={false} onClick={handleClickUpButton}>
        <ArrowDropUp />
      </Button>
      <Button focusRipple={false} onClick={handleClickDownButton}>
        <ArrowDropDown />
      </Button>
    </div>
  );
}

export default CustomArrowComponent;
