import React from "react";

import { Container, Box } from "@material-ui/core";

import { ModalWrap } from "components";

import CouponAddTemplate from "./components/templates/CouponAddTemplate";
// import CouponEasyTemplate from "./components/templates/CouponEasyTemplate";
// import CouponEasyTemplate from "./components/templates/CouponEasyTemplateV2";
import CouponEasyTemplateV3 from "./components/templates/CouponEasyTemplateV3";
import FirstComeCouponTemplate from "./components/templates/FirstComeCouponTemplate";
import CouponStatusTemplate from "./components/templates/CouponStatusTemplate";
import MileageTemplate from "./components/templates/MileageTemplate";
import NoticeTemplate from "./components/templates/NoticeTemplate";
import QuickSettingTemplate from "./components/templates/QuickSettingTemplate";
import SitePaymentTemplate from "./components/templates/SitePaymentTemplate";
import ChannelOutTemplate from "./components/templates/ChannelOutTemplate";
import CheapestTemplate from "./components/templates/CheapestTemplate";
import ReservationTemplate from "./components/templates/ReservationTemplate";
import ReviewTemplate from "./components/templates/ReviewTemplate";
// import RoomStatusTemplate from "./components/templates/RoomStatusTemplate";
import RoomSalesTemplate from "./components/templates/RoomSalesTemplateV3";
import ServiceTemplate from "./components/templates/ServiceTemplate";

import * as configApp from "config/config-app";
import * as serviceScheduler from "lib/service-scheduler";
import * as serviceNotice from "lib/service-notice";
import * as serviceStorage from "lib/service-storage";

import { history } from "lib/history";

import { openHttpRejectionPopupWithPromise } from "lib/helper-popup";
import ErrorBoundary from "components/ErrorBoundary";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {logoutRequest} from "../../system/redux-ducks/authentication";
import {openAlert, openSnackbar} from "../../lib/service-redux-alerts";
import withStyles from "@material-ui/core/styles/withStyles";

const SCHEDULE_TIME_CLOSING = "SCHEDULE_TIME_CLOSING"; //마감 시간

/**
 * 메인페이지의 Layout Component 입니다.<p/>
 *
 * @component
 * @name MainPageLayout
 * @author Taesung Park <pts@pineone.com>
 */
class MainPageLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    /* 
      -----------------------------------------------------------------------------------------------
      `공지 팝업` 비활성화 처리 
      -----------------------------------------------------------------------------------------------
      1. 작성자 : 박태성 책임
      2. 작성일 : 2020년 9월 11일
      3. 내용
        사업주 회원 가입 후 최초 로그인 일 경우 
        대시보드 화면에서 `회원 가입 축하 팝업`이 출력 되어야 합니다.

        일시적으로 `공지 팝업`의 실행을 비활성화 합니다.
      */
    const isShowGreeting = serviceStorage.isFirstLogin();
    !!isShowGreeting && serviceNotice.disableNoticePopup();

    this.state = {
      modelCoupon: null,
      modelNotice: null,
      modelQuickSetting: null,
      modelDetail: null,
      modelReservation: null,
      modelReview: null,
      modelRoomStatus: null,
      modelService: null,
      isShowGreeting,
      modelChannelOut: null,
    };

    this.isActive = true;
  }

  handleCloseGreeting = () => {
    /* 
    -----------------------------------------------------------------------------------------------
    `공지 팝업` 활성화 처리 
    -----------------------------------------------------------------------------------------------
    1. 작성자 : 박태성 책임
    2. 작성일 : 2020년 9월 11일
    3. 내용
      `회원 가입 축하 팝업` 실행 시 `공지 팝업`의 상태를 비활성화 시킵니다.
      팝업이 종료 되면 `공지 팝업`의 상태를 다시 활성화 시킵니다.
    */
    serviceNotice.enableNoticePopup();
    this.setState({ ...this.state, isShowGreeting: false }, () => {
      history.push({ pathname: "/businessInfo" });
    });
  };

  handleChangeState = (res) => {
    if (!this.isActive) return;
    let models = { ...this.state };
    res.forEach((item) => {
      Object.keys(item).forEach((key) => {
        models[key] = item[key];
        item[key] = null;
      });
    });
    this.setState(models);
    models = null;
  };

  // 20201202 박태성C - isHideLoading가 undefined이면, false로 처리 되기 때문에 로딩바가 출력 됨
  handleOnRefreshCoupons = (isHideLoading) => {
    const { onRequestAjax } = this.props;
    return Promise.all([
      onRequestAjax("REQUEST_modelCoupon", null, isHideLoading), // 오늘 선착순 쿠폰 현황 & 쿠폰 발행 간편 설정
    ]).then(this.handleChangeState);
  };

  handleOnRefreshQuickSetting = (isHideLoading) => {
    const { onRequestAjax } = this.props;
    return Promise.all([
      onRequestAjax("REQUEST_modelRoomStatus", null, isHideLoading), //오늘 객실 현황
      onRequestAjax("REQUEST_modelQuickSetting", null, isHideLoading), // 빠른 설정
    ]).then(this.handleChangeState);
  };


  getBackendData = (isHideLoading) => {
    const { onRequestAjax } = this.props;
    return Promise.all([
      onRequestAjax("REQUEST_modelReservation", null, isHideLoading), //12시간 내 입실예정 내역
      onRequestAjax("REQUEST_modelRoomStatus", null, isHideLoading), //오늘 객실 현황
      onRequestAjax("REQUEST_modelReview", null, isHideLoading), // 최근 후기
      onRequestAjax("REQUEST_modelNotice", null, isHideLoading), // 공지 사항
      onRequestAjax("REQUEST_modelService", null, isHideLoading), // 서비스 이용 현황
      onRequestAjax("REQUEST_modelCoupon", null, isHideLoading), // 오늘 선착순 쿠폰 현황 & 쿠폰 발행 간편 설정
      onRequestAjax("REQUEST_modelQuickSetting", null, isHideLoading), // 빠른 설정
      onRequestAjax("REQUEST_modelDetail", null, isHideLoading), // 현장결제
      onRequestAjax("REQUEST_modelChannelOut", null, isHideLoading), //채널 아웃
    ]);
  };

  componentDidMount() {
    /* 양태욱[C2103-156]
     * 1. 해당 화면에서 metisMenu의 해당 페이지 링크를 클릭하였을 경우 새로 마운트 되지 않음
     * 2. unListen이 해제 되지 않아 계속 listen이 중첩되는 문제 발생
     * => 상위컴포넌트 에서 history 변경시 listen 해제 및 언마운트 후 마운트 발생 유도
     */

    // this.unlisten = history.listen((location) => {
    //   if (location.pathname === "/dashboard") {
    //     this.getData();
    //   }
    // });
    this.getData();
  }

  componentWillUnmount() {
    // this.unlisten();
    this.isActive = false;
    serviceScheduler.disableScheduleByName(SCHEDULE_TIME_CLOSING);
  }

  getData = () => {
    serviceScheduler.enableScheduleByName(SCHEDULE_TIME_CLOSING);
    this.getBackendData()
      .then(this.handleChangeState)
      .catch(openHttpRejectionPopupWithPromise);
  };

  render() {
    const { onRequestAjax } = this.props;

    const {
      modelCoupon,
      modelNotice,
      modelQuickSetting,
      modelDetail,
      modelReservation,
      modelReview,
      modelRoomStatus,
      modelService,
      isShowGreeting,
      modelChannelOut,
    } = this.state;

    return (
      <Container id="Main-PC">
        <Box mt={-2} display="flex">
          <Box width={"50%"}>
            {!!modelQuickSetting && (
              <QuickSettingTemplate
                model={modelQuickSetting}
                modelDetail={modelDetail}
                onRefresh={this.handleOnRefreshQuickSetting}
                onRequestAjax={onRequestAjax}
              />
            )}
            {/** 채널 아웃 */}
            {!!modelChannelOut && (
              <ChannelOutTemplate
                model={modelChannelOut}
                onRequestAjax={onRequestAjax}
              />
            )}
            {/* 판매캘린더 - 숙박 */}
            {!!modelRoomStatus && (
              <RoomSalesTemplate
                model={modelRoomStatus}
                typeSale="SALE_DAY"
                onRefresh={this.handleOnRefreshQuickSetting}
                onRequestAjax={onRequestAjax}
              />
            )}
            {/* 12시간 내 입실 예정 내역 */}
            {!!modelReservation && (
              <ReservationTemplate
                model={modelReservation}
                onRequestAjax={onRequestAjax}
              />
            )}
            {/* 최근 후기 */}
            {!!modelReview && (
              <ReviewTemplate
                model={modelReview}
                onRequestAjax={onRequestAjax}
              />
            )}
            {/* 공지 사항 */}
            {!!modelNotice && (
              <NoticeTemplate
                model={modelNotice}
                onRequestAjax={onRequestAjax}
              />
            )}
          </Box>
          <Box ml={2} width={"50%"}>
            {/* 꿀스테이 2.0 - 선착순 쿠폰 설정 현황 추가 */}
            {!!modelCoupon && (
              <ErrorBoundary>
                <FirstComeCouponTemplate
                  model={modelCoupon.firstComeCouponInfo ?? {}}
                />
              </ErrorBoundary>
            )}
            {!!modelQuickSetting && (
              <MileageTemplate
                model={modelQuickSetting}
                onRefresh={this.handleOnRefreshQuickSetting}
                onRequestAjax={onRequestAjax}
              />
            )}
          </Box>
        </Box>
        <GreetingModal
          open={isShowGreeting}
          onClose={this.handleCloseGreeting}
        />
      </Container>
    );
  }
}

export default MainPageLayout;



function GreetingModal({ open, onClose }) {
  const BASE_NAME = configApp.getPathRoot();
  const ownerName = serviceStorage.getCurrentMotel().motel_extra.owner_name;
  return (
    <ModalWrap
      onClose={onClose}
      onButtons={[
        {
          title: "시작하기",
          callback: onClose,
        },
      ]}
      open={open}
      PaperProps={{
        style: {
          width: "740px",
          height: "430px",
        },
      }}
      titleHide={true}
      // link="/businessInfo"
    >
      <Box>
        <Box mt={5} align="center">
          <img
            style={{ height: "54px" }}
            src={`${BASE_NAME}/icons/cm_img_logo_1@3x.png`}
            alt=""
          />
        </Box>
        <Box mt={2} align="center">
          <Box>
            <h1>환영합니다!</h1>
          </Box>
          <Box style={{ fontSize: "1.2em" }}>
            <Box>
              <strong>{ownerName}</strong> 사장님, 회원가입을 축하합니다.
            </Box>
            <Box mt={1}>
              꿀스테이 플랫폼을 이용하시려면{" "}
              <strong style={{ color: "#2199ff" }}>숙소 필수 정보</strong>를
              등록해주셔야 합니다.
            </Box>
            <Box mt={1}>
              소중한 사장님의 숙소를 홍보할 수 있도록 꿀스테이가 함께
              하겠습니다.
            </Box>
          </Box>
        </Box>
      </Box>
    </ModalWrap>
  );
}
