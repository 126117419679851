/**
 * 본 모듈은 배열 여부를 검사하는 모듈 입니다
 *
 * @module utils/isArray
 */

/**
 * 전달 된 값을 검사하고, 배열 여부를 true/false로 반환 합니다
 *
 * @param {any} arr - 검사 할 값
 * @return {boolean} 배열 여부
 */
export default function isArray(arr) {
  return Array.isArray(arr) || arr instanceof Array;
}
