import React, { useState, useEffect } from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import DTRoomSales from "./DTRoomSalesOrganismV2";

const styles = (theme) => ({
  noDataComponent: {
    margin: "50px",
    fontSize: "0.825rem",
  },
});

/**
 * [업주PC] 오늘 판매 설정 카드의 Tab 관련 Organism Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name TabPanelSalesRoomOrganismV2
 * @component
 */
function TabPanelSalesRoomOrganismV2({
  classes,
  uniqueTable,
  tag,
  data,
  isActive,
  onChangeCheckBox,
  onCallbackSaleYn,
  onCallbackUpDown,
  onCallbackOpen,
  onCallbackClose,
}) {
  const [state, setState] = useState({
    list: data,
  });

  useEffect(() => {
    setState({ ...state, list: data.slice() });
    return () => {
      onChangeCheckBox({ selectedRows: [] });
    };
  }, [data]); // 실행 중에 판매/마감을 변경 할 경우 data가 새로 유입 됨

  return (
    <>
      <Box mt={2}>
        <DTRoomSales
          tag={tag}
          uniqueTable={uniqueTable}
          data={state.list}
          noDataComponent={
            !!isActive ? (
              ""
            ) : (
              <span
                className={classes.noDataComponent}
              >{`${tag} 판매 내역이 없습니다.`}</span>
            )
          }
          onCallbackSaleYn={onCallbackSaleYn}
          onCallbackUpDown={onCallbackUpDown}
          onChangeCheckBox={onChangeCheckBox}
          onCallbackOpen={onCallbackOpen}
          onCallbackClose={onCallbackClose}
        />
      </Box>
    </>
  );
}

export default withTheme(withStyles(styles)(TabPanelSalesRoomOrganismV2));
