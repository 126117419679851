export const TableStyle = {
  styleNormal,
  getHomeStyles, // PC 대시보드 테이블 스타일
  getMobileHomeStyles, // Mobile 대시보드 테이블 스타일
};

function styleNormal() {
  return {
    table: {
      style: {
        border: "1px solid #ddd",
      },
    },
    head: {
      style: { height: "46px" },
    },
    headRow: {
      style: {
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
      },
    },
    headCells: {
      style: {
        height: "46px",
        fontSize: "0.92em",
        fontWeight: "bold",
        backgroundColor: "#eaeaea",
        color: "#000",
        textAlign: "center",
        paddingLeft: "8px",
        paddingRight: "8px",
        // minWidth: 'auto !important',
        "& div": {
          lineHeight: "1.1",
          // width: '100%',
        },
      },
      activeSortStyle: {
        color: "#000",
        "&:focus": {
          outline: "none",
        },
        "&:hover:not(:focus)": {
          color: "#000",
        },
      },
      inactiveSortStyle: {
        "&:focus": {
          outline: "none",
          color: "#000",
        },
        "&:hover": {
          color: "#000",
        },
      },
    },
    rows: {
      style: {
        fontSize: "12px",
        minHeight: "48px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "#ddd",
        },
      },
      selectedHighlighStyle: {
        "&:not(:last-of-type)": {
          borderBottomColor: "#ddd",
        },
      },
      highlightOnHoverStyle: {
        transitionDuration: "0.15s",
        backgroundColor: "#f5f6f8",
        "&:not(:last-of-type)": {
          outlineWidth: "0px",
          borderBottomColor: "#ddd",
        },
      },
      stripedStyle: {
        "&:nth-child(odd)": {
          backgroundColor: "#f5f6f8",
        },
      },
    },
    cells: {
      style: {
        justifyContent:'center',
        paddingLeft: "8px",
        paddingRight: "8px",
        // wordBreak: 'break-word',
        wordBreak: "break-all",
      },
    },
    pagination: {
      style: {
        "-webkitBoxPack": "center",
        justifyContent: "center",
      },
      pageButtonsStyle: {},
    },
    progress: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "primary",
        backgroundColor: "#fff",
      },
    },
  };
}

function getHomeStyles() {
  return {
    table: {
      style: {
        border: "1px solid #ddd",
        borderCollapse: "collapse",
        tableLayout: "fixed",
        width: "100%",
        borderSpacing: "0",
      },
    },
    tableWrapper: {
      style: {
        display: "table",
      },
    },
    header: {
      style: {
        padding: "0",
      },
    },

    headRow: {
      style: {
        background: "#f8f8f9",
        minHeight: "43px",
      },
    },
    headCells: {
      style: {
        margin: "0 auto",
        fontSize: "0.875rem",
        color: "#000",
        fontWeight: 700,
        "& div": {
          margin: "0 auto",
        },
      },
      activeSortStyle: {
        color: "#000",
        "&:focus": {
          outline: "none",
        },
        "&:hover:not(:focus)": {
          color: "#000",
        },
      },
      inactiveSortStyle: {
        "&:focus": {
          outline: "none",
          color: "#000",
        },
        "&:hover": {
          color: "#000",
        },
      },
    },
    cells: {
      style: {
        padding: "8px",
        height: "50px",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#ddd",
        },
      },
    },
    pagination: {
      style: {
        "-webkitBoxPack": "center",
        justifyContent: "center",
      },
      pageButtonsStyle: {},
    },
  };
}

function getMobileHomeStyles() {
  return {
    table: {
      style: {
        border: "none",
        borderCollapse: "collapse",
        tableLayout: "fixed",
        width: "100%",
        borderSpacing: "0",
      },
    },
    tableWrapper: {
      style: {
        display: "table",
      },
    },
    header: {
      style: {
        padding: "0",
      },
    },

    headRow: {
      style: {
        minHeight: "43px",
      },
    },
    headCells: {
      style: {
        margin: "0 auto",
        fontSize: "0.875rem",
        color: "#000",
        fontWeight: 700,
        "& div": {
          margin: "0 auto",
        },
      },
      activeSortStyle: {
        color: "#000",
        "&:focus": {
          outline: "none",
        },
        "&:hover:not(:focus)": {
          color: "#000",
        },
      },
      inactiveSortStyle: {
        "&:focus": {
          outline: "none",
          color: "#000",
        },
        "&:hover": {
          color: "#000",
        },
      },
    },
    cells: {
      style: {
        paddingTop: "15px",
        paddingBottom: "10px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
    pagination: {
      style: {
        "-webkitBoxPack": "center",
        justifyContent: "center",
      },
      pageButtonsStyle: {},
    },
  };
}
