import React, { useMemo, useEffect, useState, useCallback } from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import {
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  Grid,
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import {
  UpDownControlUC,
  // CustomMaterialPagination,
  TableStyle,
} from "components";
import { SalesSwitch } from "../SalesSwitch";
import commaFormater from "utils/commaFormater";

const styles = (theme) => ({
  root: {
    "& .remainNum-1": {
      color: "red",
      "& input[type=text]": {
        color: "red",
      },
    },
    "& .updownCtrl input[type=text]": {
      maxWidth: "27px",
    },
    "& > div": {
      overflowX: "hidden !important",
    },
    // 상단 전체 선택/해제 감추기
    "& .rdt_TableHeadRow .rdt_TableCol span": {
      display: "none",
    },
  },
  buttonGroup: {
    "& button": {
      width: "120px",
      marginLeft: "0.5rem",
    },
  },
});

const RoomOpenButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    borderRadius: "3px",
    border: "solid 2px #36981f",
    backgroundColor: "#36981f",
    "&:hover": {
      backgroundColor: "#36981f",
    },
    boxShadow: "none",
  },
}))(Button);

const RoomCloseButton = withStyles((theme) => ({
  root: {
    color: "#ff4a4a",
    borderRadius: "3px",
    border: "solid 2px #ff4a4a",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    boxShadow: "none",
    // color: "#FFF",
    // backgroundColor: "#ff4a4a",
    // "&:hover": {
    //   backgroundColor: "#ff4a4a",
    // },
  },
}))(Button);

/**
 * [업주PC] 오늘 판매 설정 카드의 DataTable 관련 Organism Component 입니다.<p/>
 *
 * 커스텀으로 [전체 선택/해제] 기능이 구현되어 있습니다.<br/>
 * IE11에서 렌더링이 느린 현상이 있으나, JIRA C202101-36 요청이 있어 반영 합니다
 *
 * @author Taesung Park <pts@pineone.com>
 * @name DTRoomSalesOrganismV2
 * @component
 */
function DTRoomSalesOrganismV2({
  classes,
  tag,
  uniqueTable,
  onCallbackSaleYn,
  onCallbackUpDown,
  onChangeCheckBox,
  onCallbackOpen,
  onCallbackClose,
  data,
  noDataComponent,
}) {
  const [state, setState] = useState({
    isAllSelect: false,
    list: data,
  });
  const { list, isAllSelect } = state;

  const customStyles = useMemo(() => TableStyle.getHomeStyles());
  const selectProps = useMemo(() => ({
    color: "primary",
    indeterminate: (isIndeterminate) => isIndeterminate,
  }));
  const columns = useMemo(
    () => [
      {
        cell: (row) => (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              boxSizing: "border-box",
              display: "block",
              width: "100%",
              paddingTop: "15px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <SalesSwitch
                saleYn={row.sale_yn}
                callback={onCallbackSaleYn}
                type={`${uniqueTable}`}
                name={`${row.room_key}`}
              ></SalesSwitch>
              <div
                style={{
                  boxSizing: "border-box",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "calc(100% - 70px)",
                  // width: "calc(100% - 100px)",
                  float: "left",
                  marginLeft: "8px",
                }}
              >
                {row.room_name}
              </div>
            </div>
          </div>
        ),
        name: `${tag} 객실명`,
        left: true,
      },
      {
        name: "판매",
        selector: "sales_amount",
        center: true,
        width: "80px",
      },
      {
        cell: (row) => {
          const remainNum = row.total_amount - row.sales_amount;
          return (
            <div className={`tHead updownCtrl remainNum-${remainNum}`}>
              {row.sale_yn === "Y" && (
                <UpDownControlUC
                  callback={onCallbackUpDown}
                  initialVal={remainNum}
                  interval={1}
                  min={0}
                  max={50 - parseInt(row.sales_amount)}
                  type={`${uniqueTable}`}
                  name={`${row.room_key}`}
                />
              )}
              {row.sale_yn !== "Y" && remainNum}
            </div>
          );
        },
        name: "잔여",
        center: true,
        width: "80px",
      },
      {
        // cell: (row) => commaFormater(row.sales_amount * row.sales_price),
        // cell: (row) => commaFormater(row.discount_sum_price || 0), // 20210304 김주호 - [C2103-17] 매출을 판매금액으로 변경
        cell: (row) => commaFormater(row.sales_price || 0), // 2023.05.15 Yang - [CHOUT2303-175] 이슈로 인한 수정
        name: "판매금액",
        center: true,
        width: "97px",
      },
    ],
    [list]
  );

  useEffect(() => {
    setState({ ...state, list: data, isAllSelect: false });
    return () => {};
  }, [data]);

  // 데이타가 갱신 되어 체크 상태(전체 체크)를 초기화 하기 위함
  const handleSelectableRowSelected = (row) => {
    return isAllSelect;
  };

  const handleAllCheckBox = useCallback((evt) => {
    const isAllSelect = !!evt.target.checked;
    setState((state) => ({
      ...state,
      list: state.list.slice(),
      isAllSelect,
    }));
  });

  const handleOnChangeCheckBox = useCallback((stateDT) => {
    const { allSelected } = stateDT;
    !!onChangeCheckBox && onChangeCheckBox(stateDT);
    setState((state) => ({
      ...state,
      isAllSelect: allSelected,
    }));
  });

  const dataCount = data.length;
  return (
    <div className={classes.root}>
      <DataTable
        noHeader={true}
        highlightOnHover={false}
        data={list}
        customStyles={customStyles}
        noDataComponent={noDataComponent}
        columns={columns}
        // 체크 박스 관련
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={selectProps}
        onSelectedRowsChange={handleOnChangeCheckBox}
        selectableRowSelected={handleSelectableRowSelected}
        // 페이징 관련
        // pagination={10 < dataCount}
        // paginationComponent={CustomMaterialPagination}
      />
      {0 < dataCount && (
        <Box mt={2}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAllSelect}
                    color="primary"
                    onClick={handleAllCheckBox}
                  />
                }
                label="전체선택/해제"
                style={{
                  marginLeft: "0.25rem",
                }}
              />
            </Grid>
            <Grid item xs={6} align={"right"} className={classes.buttonGroup}>
              <RoomOpenButton variant="contained" onClick={onCallbackOpen}>
                <strong>{`${tag} 판매`}</strong>
              </RoomOpenButton>
              <RoomCloseButton variant="contained" onClick={onCallbackClose}>
                <strong>{`${tag} 마감`}</strong>
              </RoomCloseButton>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default withTheme(withStyles(styles)(React.memo(DTRoomSalesOrganismV2)));

function getTextStatus(row) {
  const { sale_yn, total_amount, sales_amount } = row;
  if (sale_yn !== "Y") {
    return "마감";
  }
  if (total_amount > sales_amount) {
    return "판매";
  }
  return "완료";
}

function getStyleStatus(row) {
  const { sale_yn, total_amount, sales_amount } = row;
  if (sale_yn !== "Y") {
    return "bg-room-close";
  }
  if (total_amount > sales_amount) {
    return "bg-room-open";
  }
  return "bg-room-soldout";
}
