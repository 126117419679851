/**
 * 공지를 처리 하는 모듈 입니다<p/>
 *
 * @module lib/service-notice
 * @see module:lib/service-api
 */

import * as configApp from "config/config-app";
import * as serviceAPI from "lib/service-api";
import * as serviceStorage from "lib/service-storage";
import {
  getServiceCodeBoardCode,
  getServiceCodeSearchConditionType,
  getServiceCodeSortCondition,
} from "constant/ServiceCode";
import {
  POPUP_ACTION_OK,
  openNoticePopupWithPromise,
  closeNoticePopup,
} from "lib/helper-popup";
import { newlineToBr, setCookie, getCookie, deleteCookie } from "utils";

const TAG = "[lib/service-notice.js]";

// const { BASE_NAME } = configApp;
const SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";
const BOARD_TYPE = getServiceCodeBoardCode("공지사항");
// const SEARCH_TYPE = getServiceCodeSearchConditionType("게시판 - 전체");
const SEARCH_TYPE = getServiceCodeSearchConditionType(
  "게시판 - 게시중인 게시물 조회"
);
const SORT = getServiceCodeSortCondition("최신순");
const CURSOR = 0;
const COUNT = 3;

const COOKIE_NAME_HIDE_ONLY_TODAY = "hide_only_today";
const COOKIE_NAME_HIDE_NOTICE = "hide_notice";
const COOKIE_DELIMITER = String.fromCharCode(29); // #
const COOKIE_EXPIRE = 1000 * 60 * 60 * 24 * 365; // 1년

// const UPDATE_EXPIRE = 1000 * 60 * 5; // 5분
const UPDATE_EXPIRE = 1000 * 60 * 60; // 1시간

let __isRuningService = false;
let __isRuningUpdate = false;
let __noticeList = [];
let __timeLatest = 0;
let __isOpeningNoticePopup = false;
let __isDisableNoticePopup = false;

let __popupKeyNoticeList = [];

let __listHideToday = getCookie(COOKIE_NAME_HIDE_ONLY_TODAY);
let __listHideNotice = getCookie(COOKIE_NAME_HIDE_NOTICE);

// 쿠키는 String으로 저장하기 때문에 읽어 들일 때 Integer로 변환 한다.
__listHideToday = !__listHideToday
  ? []
  : __listHideToday.split(COOKIE_DELIMITER).map(convertInt);

__listHideNotice = !__listHideNotice
  ? []
  : __listHideNotice.split(COOKIE_DELIMITER).map(convertInt);

// console.log(TAG, "Create", __listHideToday, __listHideNotice);

function convertInt(key) {
  return parseInt(key);
}

// `공지팝업`은 PC 버전에서 로그인(계정 + 모텔키)이 되어 있을 경우에만 노출을 허용 한다.
function isAllowedShowNotice() {
  // PC 버전 체크
  if (!SERVICE_TYPE_PC) return false;

  // 로그인(계정 + 모텔키) 상태 체크
  return (
    !!serviceStorage.getIdentity() && !!serviceStorage.getConnectedMotelKey()
  );
}

/**
 * 공지 팝업을 실행 시키는 메소드 입니다.<p/>
 *
 * 해당 메소드는 로그인 된 상태에서만 실행 됩니다.
 */
export function requestShowNotice() {
  if (!__isRuningService) return;

  isAllowedShowNotice() && doRungingShowNotice();
}

function doRungingShowNotice() {
  // 업데이트 주기를 체크하여 분기 처리 한다.
  const now = Date.now();
  if (UPDATE_EXPIRE < now - __timeLatest) {
    __timeLatest = now;
    // 업데이트(서버 연동) 후 팝업을 출력 한다.
    updateNoticeData();
  } else {
    // 업데이트 할 시점이 아니면 `공지팝업` 출력을 요청 한다.
    doRequestNoticePopup();
  }
}

/**
 * 공지 팝업의 상태를 비활성 시키는 메소드 입니다.<p/>
 */
export function disableNoticePopup() {
  console.log(TAG, "Called disableNoticePopup(). 공지 팝업 비활성");
  __isDisableNoticePopup = true;
}

/**
 * 공지 팝업의 상태를 활성 시키는 메소드 입니다.<p/>
 */
export function enableNoticePopup() {
  console.log(TAG, "Called enableNoticePopup(). 공지 팝업 활성");
  __isDisableNoticePopup = false;
}

/**
 * 공지 팝업의 서비스를 실행 시키는 메소드 입니다.<p/>
 */
export function startService() {
  console.log(TAG, "Called startService(). 공지 팝업 서비스 시작");
  __isRuningService = true;
  requestShowNotice();
}

/**
 * 공지 팝업의 서비스를 정지 하는 메소드 입니다.<p/>
 */
export function stopService() {
  if (!__isRuningService) return;

  console.log(TAG, "Called stopService(). 공지 팝업 서비스 정지");

  if (0 < __popupKeyNoticeList.length) {
    __popupKeyNoticeList.map(doCloseNoticePopup);
    __popupKeyNoticeList.splice(0, __popupKeyNoticeList.length);
  }
  __isDisableNoticePopup = false;
  __isOpeningNoticePopup = false;
  __noticeList.splice(0, __noticeList.length);
  __timeLatest = 0;
}

function doCloseNoticePopup(popupKey) {
  !!popupKey && closeNoticePopup(popupKey);
}

/**
 * 공지 데이타를 업데이트 시키는 메소드 입니다.<p/>
 *
 * 해당 메소드는 로그인 된 상태에서만 실행 됩니다.
 */
export function updateNoticeData() {
  if (!__isRuningService) return;

  // 계정 정보가 없을 경우 서버통신 자체가 안됌.
  if (isAllowedShowNotice() && !__isRuningUpdate) {
    console.log(TAG, "Called updateNoticeData(). 공지 데이타 업데이트 시작");

    __isRuningUpdate = true;
    serviceAPI
      .getItemsBoards(
        {
          board_type: BOARD_TYPE,
          count: COUNT,
          cursor: CURSOR,
          search_type: SEARCH_TYPE,
          sort: SORT,
        },
        true /* isHideLoading */
      )
      .then(doParsingNoticeData)
      .catch(noop)
      .finally(onUpdatedNoticeData);
  }
}

function onUpdatedNoticeData() {
  __isRuningUpdate = false;
  console.log(TAG, "Called onUpdatedNoticeData(). 공지 데이타를 업데이트 완료");
}

function doParsingNoticeData(response) {
  // 공지 데이타가 유효 할 경우 내부 캐싱 된 데이타를 업데이트 한다.
  if (!!response.data.result && !!response.data.result.board_items) {
    __noticeList = response.data.result.board_items.slice();

    // 업데이트 후 검사
    let noticeList = __noticeList.filter(filteringNoticeData);
    if (3 === noticeList.length) {
      // 신규 업데이트 된 공지 데이타의 갯수가 3개 일 경우 저장 된 쿠키 데이타를 삭제 한다
      if (0 < __listHideToday.length) {
        deleteCookie(COOKIE_NAME_HIDE_ONLY_TODAY);
      }
      if (0 < __listHideNotice.length) {
        deleteCookie(COOKIE_NAME_HIDE_NOTICE);
      }
    }
  }
  // 공지 팝업을 출력 한다.
  isAllowedShowNotice() && doRequestNoticePopup();
}

function doRequestNoticePopup() {
  if (__isDisableNoticePopup === true) {
    console.log(
      TAG,
      "Called doRequestNoticePopup(). 현재 비활성 상태라 공지팝업 실행 불가"
    );
    return;
  }

  // 캐싱 된 공지 데이타 있을 경우 팝업을 출력 한다
  if (0 < __noticeList.length) {
    let noticeList = __noticeList.filter(filteringNoticeData);

    0 < noticeList.length &&
    openNoticesSequentially(noticeList)
        .then(() => {
          console.log("All notices displayed.");
        })
        .catch(err => {
          console.error("Error displaying notices:", err);
        });
  }
}

function openNoticesSequentially(noticeList) {
  return noticeList.reduce((prevPromise, notice) => {
    return prevPromise.then(() => {
      return new Promise(resolve => {
        doOpenNoticePopup(notice);
        const checkInterval = setInterval(() => {
          if (!__isOpeningNoticePopup) {
            clearInterval(checkInterval);
            resolve();
          }
        }, 500); // 각 공지를 확인하는 간격(ms)
      });
    });
  }, Promise.resolve());
}

function filteringNoticeData(notice) {
  // 공지 데이타가 `오늘 그만 보기`나 `다시 보지 않기`에 등록 되어 있는지 검사한다.
  const key = parseInt(notice.key);
  if (-1 < __listHideToday.indexOf(key)) return false;
  if (-1 < __listHideNotice.indexOf(key)) return false;
  // 게시기간이 끝난 공지 데이타는 출력하지 않는다.
  // if (notice.end_dt < Date.now()) return false;
  return true;
}

function doOpenNoticePopup(modelNotice) {
  // 서버에서 조회 된 공지 데이타를 팝업으로 출력 한다.

  let key = -1; // 게시물 유니크 키
  if (modelNotice.hasOwnProperty("key") && !!modelNotice.key) {
    key = modelNotice.key;
  }

  // 이미 팝업이 열린 상태이거나 유니크 키가 존재 하지 않으면 팝업을 출력 시키지 않는다.
  if (!!__isOpeningNoticePopup || 0 > key) {
    return;
  }

  console.log(TAG, "Called doOpenNoticePopup(). 공지 팝업 오픈");

  __isOpeningNoticePopup = true;
  openNoticePopupWithPromise(
    {
      title: `${modelNotice.title || "공지사항"}`,
      description: `${makeDescription(modelNotice)}`,
    },
    onOpendNoticePopup
  )
    .then((modelPopup) => {
      let idx = __popupKeyNoticeList.indexOf(modelPopup.popupKey);
      if (-1 < idx) {
        __popupKeyNoticeList.splice(idx, 1);
      }

      // 사용자가 버튼을 클릭하여 닫았을 경우
      if (modelPopup.actionType === POPUP_ACTION_OK) {
        if (modelPopup.data.isHideToday === true) {
          // `오늘 그만 보기`가 체크 되어 있을 경우

          // 하루 동안 해당 공지는 노출 시키지 않는다.
          let today = new Date();
          today.setHours(23);
          today.setMinutes(59);
          today.setSeconds(59);
          today.setMilliseconds(0);

          __listHideToday.push(parseInt(key));

          setCookie(
            COOKIE_NAME_HIDE_ONLY_TODAY,
            __listHideToday.join(COOKIE_DELIMITER),
            today,
            "/"
          );
          today = null;
        } else if (modelPopup.data.isHideNotice === true) {
          // `다시 보지 않기`가 체크 되어 있을 경우

          // 오늘 부터 1년 뒤까지 노출 시키지 않도록 쿠키에 저장 한다.
          __listHideNotice.push(parseInt(key));

          setCookie(
            COOKIE_NAME_HIDE_NOTICE,
            __listHideNotice.join(COOKIE_DELIMITER),
            new Date(Date.now() + COOKIE_EXPIRE),
            "/"
          );
        }
      }
    })
    .finally(onClosedNoticePopup);
}

function onOpendNoticePopup(popupKey) {
  __popupKeyNoticeList.push(popupKey);
}

function onClosedNoticePopup() {
  __isOpeningNoticePopup = false;
}

function makeDescription(modelNotice) {
  let images = "";
  if (!!modelNotice.images && 0 < modelNotice.images.length) {
    images +=
      `<div style="margin:0px; padding: 0px; margin-top:${
        !!modelNotice.description ? "4rem" : "0px"
      };">` +
      modelNotice.images
        .map((item) => {
          return `
          <img src="${item.url}" style="max-width: 540px; min-width: 480px; height: auto; display: block; vertical-align: top; margin: 5px auto; text-align: center; background-repeat: no-repeat; padding: 0px; border-style: none; box-sizing: inherit;" />
          `;
        })
        .join("<br>") +
      "</div>";
  }

  return `
    ${!!modelNotice.description ? newlineToBr(modelNotice.description) : ""}
    ${images}`;
}

function noop() {}
