import React, { PureComponent, createRef } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/locale";
import { DateRange } from "@material-ui/icons";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
// 2020.08.25
//   - 양태욱 전임
//
//
/*
 * [변경사항]
 * 2020.08.24 양태욱
 * DatePicker를 커스터마이징하여 변경 사항이 있을 경우 일괄적으로 변경하는 DatePicker입니다.
 *
 * 2021.01.19 양태욱
 * 아이콘에 hover시 DatePicker에 노란 테두리가 안생기는 문제 수정
 */
const styles = {
  calendarIcon: {
    width: "32px",
    height: "32px",
    position: "absolute",
    top: "8px",
    right: "2px",
    cursor: "pointer",
  },
};
/**
 * @author TaeWook Yang <didxodnr0769@pineone.com>
 * @description PC DatePicker 공통 컴포넌트입니다.
 *
 */
class CustomDatePicker extends PureComponent {
  constructor(props) {
    super(props);
    this.dateRef = createRef();
  }
  /** 해당 날짜의 00시 00분 00초로 포맷 */
  formatZeroClock = (date) => {
    const _date = new Date(date);
    const year = _date.getFullYear();
    const month = _date.getMonth();
    const day = _date.getDate();
    return new Date(year, month, day);
  };
  handleDayClassName = (date) => {
    const dateStr = format(date, "iii");
    const CONST_CLASS_SATURDAY = "react-datepicker__day--saturday";
    const CONST_CLASS_SUNDAY = "react-datepicker__day--sunday";
    if (dateStr === "Sat") return CONST_CLASS_SATURDAY;
    else if (dateStr === "Sun") return CONST_CLASS_SUNDAY;
  };
  /** 아이콘에 마우스가 위치할 경우 이벤트 */
  handleMouseOver = () => {
    if (this.dateRef.current.input.className !== "over")
      this.dateRef.current.input.className = "over";
  };
  /** 아이콘에 마우스가 빠졌을 경우 이벤트 */
  handleMouseOut = () => {
    if (this.dateRef.current.input.className === "over")
      this.dateRef.current.input.className = "";
  };
  /** 아이콘 클릭 이벤트  */
  handleClickIcon = () => {
    this.dateRef.current.onInputClick();
  };

  render() {
    const { classes } = this.props;
    const {
      selected,
      startDate,
      endDate,
      minDate,
      maxDate,
      dateFormat = "yyyy-MM-dd",
      id = "",
      selectsStart = false,
      selectsEnd = false,
      disabled = false,
      showMonthYearPicker = false,
      calendarClassName,
      className,
    } = this.props;

    return (
      <Box
        position="relative"
        style={{
          border: "none",
          padding: "0",
        }}
      >
        <DatePicker
          selected={selected}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          // customInputRef={this.dateRef}
          ref={this.dateRef}
          onChange={this.props.onChange}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          showPopperArrow={false}
          dateFormat={dateFormat}
          locale={ko}
          popperPlacement="end"
          onFocus={(e) => e.target.blur()}
          id={id}
          disabled={disabled}
          showMonthYearPicker={showMonthYearPicker}
          calendarClassName={calendarClassName}
          calssName={className}
          dayClassName={this.handleDayClassName}
        />
        <DateRange
          className={classes.calendarIcon}
          color={disabled ? "disabled" : "inherit"}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          onClick={this.handleClickIcon}
        />
      </Box>
    );
  }
}
const styledDatePicker = withStyles(styles)(CustomDatePicker);
export { styledDatePicker as CustomDatePicker };
