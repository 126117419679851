import {
  Box,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { commaFormater, isNumberFormat } from "utils";

/**
 * @author TaeWook Yang <didxodnr0769@pineone.com>
 * @description 판매캘린더, 기준요금/시간 페이지에서 사용할 Custom TextField입니다.
 *                    1. InitValue 값과 다를시 배경색상이 변경됩니다.
 *                    2. X버튼 추가.(X버튼 : InitValue값으로 변경)
 * @param isPound boolean / ##으로 표시할지 여부
 */

/*
* 개발 기준
1. 입력 범위 제한 Validate( 예 : 0원 입력 불가 , 10000원 이하 입력불가 등)은 해당 컴포넌트를 호출하는 곳에서 처리 해주어야함.
2. 이 컴포넌트를 이용하여 기준요금/시간 및 판매캘린더의 요금 입력 컴포넌트를 문제가 없게, 이식이 쉽도록 제작하여야 함
3. 기준요금/시간 & 판매캘린더에서 공통적으로 사용할 수 있게끔 제작하여야 함.


*개발 사항
1. 주입받은 값을 표출
3. Focus,Blur 이벤트 발생시 콤마를 추가,제거.
4. 6자리까지만 숫자 입력 가능.
5. X버튼 클릭시 기존값(props.initValue)으로 원복
6. 기존값(props.initValue)과 다른값일 경우 배경색 변경 


* 2021.04.16 양태욱
  - ref를 통하여 제어하던 데이터를 상위 컴포넌트에서 주입받아 사용
*/

const useStyles = makeStyles({
  root: {
    "& input::placeholder": {
      color: "black",
      opacity: 1,
    },
    "& input:disabled": {
      opacity: 0.4,
    },
    padding: 0,
    display: "inline",
  },
  defaultInput: {
    fontSize: "16px",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
    textAlign: "center", // IE 정렬
    textAlignLast: "center", // Chrome에서 필요함
  },
  customInput: {
    fontSize: "16px",
    width: "88%",
    paddingLeft: "0px",
    paddingRight: "0px",
    textAlign: "right", // IE 정렬
    textAlignLast: "end", // Chrome 정렬
  },
  notchedOutline: {
    backgroundColor: "blue",
    opacity: "0.1",
  },
});

function TextFieldForCharge(props) {
  const regExp = useMemo(() => /[^0-9]/g, []);
  const valueRef = props.inputRef || createRef();
  const { initValue, index } = props;
  const [diffValue, setDiffValue] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  useEffect(() => {
    judgeForDiff(props.value);
  }, [props.value]);
  /** 포커스 이벤트(콤마 제거) */
  const onFocusAction = (e) => {
    setIsFocus(true);
  };
  /** 포커스 아웃 이벤트(콤마 추가) */
  const onBlurAction = (e) => {
    setIsFocus(false);
  };

  /** Value Change 이벤트 */
  const handleChangeValue = (e) => {
    const { value } = e.currentTarget;
    let returnValue = 0;
    if (!value) {
      // 값이 없을 경우 0 처리
      returnValue = isPound ? "" : 0;
      if (isPound) {
        setDiffValue(false);
      }
    } else if (!isNumberFormat(value)) {
      // 문자를 넣었을 경우 기존 props에서 받은 value값으로 대체
      returnValue = isPound ? "" : props.value;
    } else {
      returnValue = parseInt(value.replace(regExp, 0));
    }
    /* 현재값과 이전값 변경사항 판별 */
    judgeForDiff(returnValue);
    !!props.onChange && props.onChange(returnValue, index);
  };

  /** X버튼 클릭 이벤트 */
  const handleClickCancel = () => {
    // valueRef.current.value = isPound ? "" : commaFormater(initValue);
    setDiffValue(false);
    setIsFocus(false);
    !!props.onChange && props.onChange(initValue, index);
  };

  /* 현재값과 초기값 변경사항 판별 */
  const judgeForDiff = (value) => {
    if (isNaN(parseInt(value)) && isNaN(parseInt(initValue))) {
      setDiffValue(false);
    } else if (parseInt(initValue) !== parseInt(value) && diffValue === false) {
      setDiffValue(true);
    } else if (parseInt(initValue) === parseInt(value) && diffValue === true) {
      setDiffValue(false);
    }
  };

  const classes = useStyles();
  const { disabled, isPound, middleLine } = props;

  const value = !isFocus ? commaFormater(props.value) : props.value;
  return (
    <Box>
      <TextField
        size="small"
        variant="outlined"
        disabled={disabled}
        value={value}
        inputRef={valueRef}
        placeholder="##"
        onChange={handleChangeValue}
        className={classes.root}
        onFocus={onFocusAction}
        onBlur={onBlurAction}
        inputProps={{
          className: diffValue ? classes.customInput : classes.defaultInput,
          maxLength: 7,
          style: {
            textDecorationLine: middleLine ? "line-through" : "none",
          },
        }}
        InputProps={{
          classes: {
            notchedOutline: diffValue && classes.notchedOutline,
          },
          endAdornment: diffValue && (
            <InputAdornment position="end" style={{ width: "12%" }}>
              <IconButton
                size="small"
                onClick={handleClickCancel}
                style={{
                  padding: 0,
                  display: diffValue ? "block" : "none",
                }}
              >
                <CancelIcon size="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export default React.memo(TextFieldForCharge);
