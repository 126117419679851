/**
 * 쿠키 저장, 조회, 삭제
 *
 * @module utils/cookie
 */

// https://ko.javascript.info/cookie

export function setCookie(
  cookieName,
  cookieValue,
  cookieExpire,
  cookiePath,
  cookieDomain,
  cookieSecure
) {
  let cookieText = escape(cookieName) + "=" + escape(cookieValue);
  cookieText += cookieExpire ? "; EXPIRES=" + cookieExpire.toGMTString() : "";
  // cookieText += cookieExpire ? "; EXPIRES=" + cookieExpire.toUTCString() : "";
  cookieText += cookiePath ? "; PATH=" + cookiePath : "";
  cookieText += cookieDomain ? "; DOMAIN=" + cookieDomain : "";
  cookieText += cookieSecure ? "; SECURE" : "";
  document.cookie = cookieText;
}

export function getCookie(cookieName) {
  let cookieValue = null;
  if (document.cookie) {
    let array = document.cookie.split(escape(cookieName) + "=");
    if (array.length >= 2) {
      let arraySub = array[1].split(";");
      cookieValue = unescape(arraySub[0]);
      arraySub.splice(0, arraySub.length);
      arraySub = null;
    }
    array.splice(0, array.length);
    array = null;
  }
  return cookieValue;
}

export function deleteCookie(cookieName) {
  let temp = getCookie(cookieName);
  if (!!temp) {
    setCookie(cookieName, temp, new Date(1));
  }
}
