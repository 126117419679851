import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  // ExpansionPanel,
  // ExpansionPanelSummary,
  // ExpansionPanelDetails,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Typography,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// import * as configApp from "config/config-app";

/**
 * Expansion component 입니다.<p/>
 *
 * @author Drst3in <drst3in9196@gmail.com>
 * @name ExpansionWrap
 * @class
 * @component
 */

const styles = (theme) => ({
  root: {
    boxShadow: "none",
  },
  content: {
    margin: "0 !important",
  },
  expandIcon: {
    padding: "0",
    marginRight: "0",
  },
  detailRoot: {
    padding: "8px 0",
  },
  expansionTitleText: {
    fontSize: "14px",
    fontstyle: "normal",
    color: "#000000",
    margin: 0,
  },
});

class ExpansionWrap extends Component {
  render() {
    const {
      classes,
      title,
      style,
      titleFontSize,
      defaultExpanded,
    } = this.props;

    return (
      <Accordion
        className={classes.root}
        style={style}
        defaultExpanded={defaultExpanded}
      >
        <AccordionSummary
          style={{ minHeight: "auto", padding: "0" }}
          classes={{ content: classes.content, expandIcon: classes.expandIcon }}
          expandIcon={<ExpandMoreIcon />}
        >
          {/* <h4 style={{ margin: "0" }}>{title}</h4> */}
          <b
            className={classes.expansionTitleText}
            style={{
              fontSize: titleFontSize,
            }}
          >
            {title}
          </b>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.detailRoot }}>
          {this.props.children}
        </AccordionDetails>
      </Accordion>
      // <ExpansionPanel className={classes.root} style={style}>
      //   <ExpansionPanelSummary
      //     style={{ minHeight: "auto", padding: "0" }}
      //     classes={{ content: classes.content, expandIcon: classes.expandIcon }}
      //     expandIcon={<ExpandMoreIcon />}
      //   >
      //     <h4 style={{ margin: "0" }}>{title}</h4>
      //   </ExpansionPanelSummary>
      //   <ExpansionPanelDetails classes={{ root: classes.detailRoot }}>
      //     {this.props.children}
      //   </ExpansionPanelDetails>
      // </ExpansionPanel>
    );
  }
}

//export default BoxWrap;
const styledExpansionWrap = withStyles(styles)(ExpansionWrap);

export { styledExpansionWrap as ExpansionWrap };
