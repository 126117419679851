import React, { Component } from "react";
import { history } from "lib/history";
import { withStyles } from "@material-ui/core/styles";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import * as configApp from "config/config-app";
import * as serviceBrowser from "../lib/service-browser";

/**
 * 페이지 내의 박스 블럭 Component 입니다.<p/>
 */

const styles = (theme) => ({
  root: {
    borderRadius: "5px",
    background: "#fff",
    position: "relative",
    "& h3": {
      marginTop: "0",
      fontSize: "17px",
    },
    "& h4": {
      marginTop: "0",
      fontSize: "14px",
    },
  },
  boxWrapHeaderWraper: {
    borderBottom: "1px solid #ddd",
    marginBottom: "20px",
  },
  boxWrapIcons: {
    position: "absolute",
    top: "17px",
  },
  subTitle: {
    "& h4": {
      margin: "4px 0 0 0",
      color: "#949494",
    },
    "& h6": {
      margin: "2px 0 0 0",
      color: "#949494",
    },
  },
});

class BoxWrap extends Component {
  handleClick = () => {
    const { link, linkState, historyPush, settingModal } = this.props;
    if (!link) return;

    if(!!settingModal){
      settingModal(true);
      return;
    }

    //현재 화면 히스토리 대체
    if (historyPush) {
      history.replace(historyPush);
    }
    //목표 화면으로 리다이렉트
    if (linkState) {
      history.push({ pathname: link, state: linkState });
    } else {
      history.push({ pathname: link });
    }
  };

  render() {
    const {
      classes,
      title,
      link,
      subTitle,
      style,
      titleBorder,
      titleStyle,
      titleBoxStyle,
      badgeVal,
      titleLink,
      channelName,
      channelOutYn,
    } = this.props;

    const commonTitleElement = title && (
      <Box
        display="flex"
        className={titleBorder && classes.boxWrapHeaderWraper}
      >
        <Box style={titleBoxStyle}>
          <p
            style={
              titleStyle || {
                fontSize: "12px",
                fontWeight: "bold",
                margin: "0",
              }
            }
          >
            {title}
            {channelName && channelOutYn === "Y" ? "(" + channelName + ")" : ""}
          </p>
        </Box>

        {subTitle && (
          <Box ml={1} className={classes.subTitle}>
            <h6 style={{ margin: "0" }}>{subTitle}</h6>
          </Box>
        )}

        {badgeVal && (
          <Box ml={1} className={"item-badge"}>
            {badgeVal}
          </Box>
        )}
      </Box>
    );

    const linkElement = titleLink && (
      <span
        style={{
          fontSize: "0.65em",
          fontWeight: "bold",
          opacity: "0.7",
        }}
      >
        {titleLink}
      </span>
    );

    return (
      <Box
        onClick={this.handleClick}
        my={2}
        p={2}
        className={classes.root}
        style={style}
      >
        {commonTitleElement}

        <Box position="absolute" top={2} right={2}>
          {linkElement}

          {link && (
            <IconButton>
              <ChevronRightIcon />
            </IconButton>
          )}
        </Box>
        {this.props.children}
      </Box>
    );
  }
}

const styledBoxWrap = withStyles(styles)(BoxWrap);
export { styledBoxWrap as BoxWrapTouchCard };
