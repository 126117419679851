import React, { useState, useEffect } from "react";
import { OutlinedInput, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import { openSnackbar } from "lib/service-redux-alerts";
import { bindActionCreators } from "redux";

const MobileSaleTitleInputRaw = (props) => {
  const [title, setTitle] = useState("");
  const handleChangeStr = ({ currentTarget: { value } }) => {
    if (value.length > 25) {
      // alert('test')
      props.inputRef.current.blur();
      props.openSnackbar({
        message: "최대 25자까지 입력 가능합니다.",
      });
    }
    const targetStr = value.substring(0, 25);
    setTitle(targetStr);
    props.inputRef.current.value = targetStr;
  };

  useEffect(() => {
    if (props.defaultValue) {
      setTitle(props.defaultValue);
    }
  }, []);

  return (
    <OutlinedInput
      fullWidth
      placeholder="제목을 입력해 주세요."
      size="small"
      variant="outlined"
      name="title"
      style={{ fontSize: "1.1em" }}
      onChange={handleChangeStr}
      inputRef={props.inputRef}
      defaultValue={props.defaultValue && props.defaultValue}
      endAdornment={
        <InputAdornment position="end">{title.length}/25</InputAdornment>
      }
      inputProps={{
        name: "title",
        autoComplete: "off",
        style: {
          padding: "14px 8px",
        },
      }}
    />
  );
};

// export { MobileSaleTitleInput }
const MobileSaleTitleInput = connect(null, (dispatch) =>
  bindActionCreators({ openSnackbar }, dispatch)
)(MobileSaleTitleInputRaw);

export { MobileSaleTitleInput };
