import React, { PureComponent } from "react";
import commaFormater from "utils/commaFormater";

import { withStyles } from "@material-ui/core/styles";
import {Box, IconButton, OutlinedInput, FormControl } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelIcon from "@material-ui/icons/Cancel";

// import * as configApp from "config/config-app";

/*
 * [변경사항]
 * 2020.12.15 양태욱
 * unitType 추가 : 장, 원, % 등의 단위를 표시하기 위하여 추가
 */

/**
 * 업 다운 버튼이 있는 컨트롤러 Component 입니다.<p/>
 *
 * @author Drst3in <drst3in9196@gmail.com>
 * @name UpDownControl
 * @class
 * @component
 */

const styles = (theme) => ({
  root: {
    "& .MuiOutlinedInput-input" : {
      padding: '8px'
    }
  },
  button: {
    background: "#dedede",
    "& button": {
      marginTop: "4px",
    },
  },
  changeValue: {
    "& .MuiOutlinedInput-notchedOutline": {
      backgroundColor: "blue",
      opacity: '0.1'
    }
  },

});

class UpDownControl extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      initialVal: 0,
      originVal :0,
    };
  }


  componentDidMount() {
    this.setState({ initialVal: this.props.initialVal });
    this.setState({ originVal: this.props.originVal });
  }

  handleDown = (event) => {
    const { initialVal, interval, max, min, viewName, textOption } = this.props;

    let _returnVal =
      typeof initialVal === "string" ? min - interval : initialVal - interval;

    if (max && _returnVal > max) {
      _returnVal = max;
    } else if ((min || min === 0 ) && _returnVal < min) {
      if (viewName === "mileageAdd") {
        _returnVal = "쿠폰 없음";
      } else if (viewName === "autoCoupon") {
        _returnVal = 0;
      } else {
        if (textOption?.isText) {
          _returnVal = 0;
        } else {
          _returnVal = this.props.min;
        }
      }
    } else if (_returnVal < 0) {
      if (textOption?.isText) {
        _returnVal = 0;
      }
    }

    this.setState({ initialVal: _returnVal });

    if (this.props.callback) {
      if (this.props.name) {
        this.props.callback(_returnVal, this.props.name, "down");
      } else {
        this.props.callback(_returnVal);
      }
    }
  };

  handleUp = () => {
    const { initialVal, interval, max, min } = this.props;
    let _returnVal =
      typeof initialVal === "string" ? min : initialVal + interval;

    if (max && _returnVal > max) {
      _returnVal = max;
    }

    if(min && _returnVal < min){
      _returnVal = min;
    }

    this.setState({ initialVal: _returnVal });

    if (this.props.callback) {
      if (this.props.name) {
        this.props.callback(_returnVal, this.props.name, "up");
      } else {
        this.props.callback(_returnVal);
      }
    }
  };

  returnOrigin = () => {
    const { originVal , initialVal , max, min , interval } = this.props;
    let _returnVal =
        typeof originVal === "string" ? min : originVal + interval;

    if (max && _returnVal > max) {
      _returnVal = max;
    }

    if(min && _returnVal < min){
      _returnVal = min;
    }

    this.setState({ originVal: _returnVal });

    if (this.props.callback) {
        this.props.callback(originVal);
    }
  }

  render() {
    const {
      classes,
      // initialVal = 0, //입력값
      min = 0, //최소값
      // interval = 1, //증감값
      unitType = "",
      disabled,
      textAlign,
      isChangeValue = false,
      viewName,
      initialVal,
      textOption = { isText: false },
      hasCancelIcon = false
    } = this.props;

    let val = null;

   if(textOption.isText && this.props.initialVal === textOption.value){
      val = textOption.text;
    } else {
      val = commaFormater(this.props.initialVal)+unitType
    }


    return (
      <Box className={classes.root} display="flex" justifyContent={'center'} alignItems={'center'}>
        <Box>
          <FormControl variant="outlined" style={{ display: "block" }} >
            <OutlinedInput className={isChangeValue ? classes.changeValue : ''}
              disabled
              value={val}
              autoComplete="off"
              style={{
                display: 'flex',
                padding: "0 3px",
                color: !disabled ? "#000000DE" : "#00000061",
              }}
             inputProps={{
                style: {
                  textAlign: textAlign || "right"},
              }}
             endAdornment={
                <IconButton
                    size="small"
                    onClick={() => this.returnOrigin()}
                   style={{
                      padding: '0',
                     margin:0,
                      display: hasCancelIcon && isChangeValue ? "inline-block" : "none",
                        }}
                >
                    <CancelIcon size="small" />
                </IconButton>
             }
            />
          </FormControl>
        </Box>
        <Box
          ml={0.5}
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft:'5px',
            // marginLeft:'6px'
          }}
        >
          <Box
            style={{
              border: "1px solid #ddd",
              borderRadius: "5px 5px 0 0",
              height: "19px",
            }}
          >
            <IconButton
              style={{ padding: "0 0 6px 0" }}
              onClick={this.handleUp}
              disabled={disabled}
            >
              <ExpandLessIcon style={{ fontSize: 14 }} />
            </IconButton>
          </Box>
          <Box
            style={{
              border: "1px solid #ddd",
              borderRadius: "0 0 5px 5px",
              height: "19px",
            }}
          >
            <IconButton
              style={{ padding: "0 0 6px 0" }}
              onClick={this.handleDown}
              disabled={disabled}
            >
              <ExpandMoreIcon style={{ fontSize: 14 }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  }
}

const styledUpDownControl = withStyles(styles)(UpDownControl);

export { styledUpDownControl as UpDownControl };
