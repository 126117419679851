import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import Root from "./system/client/Root";

//firebase 시작
// import { fireBaseInit, getToken } from "./lib/service-firebase";
// fireBaseInit();

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
