import {
    GET_RSA_REQUEST, GET_RSA_SUCCESS,GET_RSA_FAILURE, SET_RSA_INITIAL

} from './actions/rsaAction';


const initialState = {
    rsaKey: null,
    publicKey: null,
    isLoading: false,
    error: null
};

const rsa = (state = initialState, action) => {
    switch (action.type) {
        case GET_RSA_REQUEST:
            return { ...state, isLoading: true };
        case GET_RSA_SUCCESS:
            return { ...state, isLoading: false, rsaKey: action.key, publicKey : action.publicKey };
        case GET_RSA_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case SET_RSA_INITIAL:
            return { ...initialState }

        default:
            return state;
    }
};

export default rsa;
