/**
 * WAS와 연동을 처리하는 서비스의 설정을 처리하는 서비스의 Provider 모듈 입니다<p/>
 *
 * @module lib/provider-api
 * @see module:lib/service-api
 * @see module:lib/service-storage
 * @see module:utils/event-dom
 * @see system/redux-ducks/loading
 */

import axios from "axios";
import qs from "qs";
import * as configApp from "config/config-app";
import * as serviceStorage from "lib/service-storage";
import * as serviceHttpCanceler from "lib/service-http-canceler";
import * as utils from "utils";

import {
  openAlertPopupWithPromise,
  openHttpRejectionPopupWithPromise,
} from "lib/helper-popup";
import { logoutRequest,  } from "system/redux-ducks/authentication";
import { openRequest, closeRequest } from "system/redux-ducks/loading";
// import { openLoading, closeLoading } from "system/redux-ducks/loading";
import { PopupConsts } from "constant";
import { history } from "./service-history-browser";
import {postRefreshToken} from "../system/redux-ducks/actions/refreshTokenAction";
import {Queue} from "@material-ui/icons";
import ApiQueue from "./ApiQueue";

const TAG = "[lib/provider-api.js]";
// console.log(TAG, "Create");

const SYSTEM_CHECK_TITLE = "서비스 안정화를 위한 시스템 점검 안내";
const SYSTEM_CHECK_GUIDE =
  "파트너센터의 원활한 서비스 이용을 위하여\n시스템 점검을 진행 할 예정입니다.\n\n점검기간 동안 파트너센터 이용이 제한되며,\n빠른시간 내 더욱 안정적인 서비스를\n제공할 수 있도록 노력하겠습니다.";

const DEFAULT_POPUP_TITLE = "파트너센터 안내";

const URL_WAS = configApp.URL_WAS;
const ALIAS_SVR = configApp.ALIAS_SVR;

let __isProvided = false;
let __reqsTotal = 0;
let __reqsCompleted = 0;


/**
 * WAS와 연동을 처리하는 서비스의 설정을 처리하는 서비스 모듈의 초기 설정을 처리하는 메소드 입니다
 *
 * @param {Redux.Store} store - Redux Store 객체
 */
export function provider(store ) {
  if (__isProvided) return;
  __isProvided = false;
  // console.log(TAG, "Provider");
  let lock= false;
  let queue = new ApiQueue();


  // 현재 요청 갯수(__reqsTotal)가 완료 갯수(__reqsCompleted) 보다 크면,
  // Click, Tab key Event를 Blocking 할 수 있도록 Listener를 등록 한다
  // utils.addEventListener(window, "click", blockEvent, true);
  utils.addEventListener(
    window,
    "click",
    (evt) => {
      // 서버 연동 중에 'click' 이벤트가 발생 합니다
      if (isBlocking()) {
        // 이벤트를 발생한 주체(ex : button, a)의 속성을 검사합니다
        if (evt.target) {
          const passblocking = evt.target.getAttribute("data-passblocking");
          // data-passblocking 속성이 있다면, Blocking 처리를 수행하지 않습니다
          if (passblocking) return;
          // data-passblocking 속성이 없다면, Event를 Blocking 처리 합니다
          blockEvent(evt);
        }
      }
    },
    true
  );

  utils.addEventListener(document, "keydown", (evt) => {
    // 서버 연동 중에 'keydown' 이벤트가 발생 합니다
    if (isBlocking()) {
      let keycode = evt.keyCode ? evt.keyCode : evt.which;
      // Tab Key가 눌린 경우
      if (keycode === 0 || keycode === 9) {
        // Event를 Blocking 처리 합니다
        blockEvent(evt);
      }
    }
  });

  // Axios의 Request Interceptor
  axios.interceptors.request.use(
    async (config) => {
      config.params  = new URLSearchParams(config.params);
      if (config.data === undefined) {
        config.data = null;
      }

      if (0 === config.url.indexOf(ALIAS_SVR)) {
        if (config.hideLoading !== true) {
          // 로딩 바 실행
          // !isBlocking() && store.dispatch(openLoading());
          !isBlocking() && !!store && openRequest()(store.dispatch);
        }

        if (
          document &&
          document.activeElement &&
          document.activeElement.tagName !== "BODY"
        ) {
          // 서버 연동 시작 시 현재 포커싱 된 element가 존재할 경우
          // blur()를 호출하여 키보드 [ENTER] 키 연속 입력에 대한 처리를 함
          document.activeElement.blur();
        }

        // 예외 처리
        // Ajax는 비동기로 동작하기 때문에 reset()이 실행 되더라도
        // 외부 요인(ex : axios)에 의해 상태가 동기 안될 수 있음
        if (__reqsTotal === 0) __reqsCompleted = 0;

        __reqsTotal++;

        // GET params : { foo: [5, 2] }  요청시 foo[]=5&foo[]=2 => foo=5&foo=2 변환
        // 20200718 박태성C - 객체 직렬화 비용이 높아 params 내에 배열이 존재 할 경우에만 paramsSerializer 주입 되도록 처리
        if (
          !!config.params &&
          !config.paramsSerializer &&
          -1 < JSON.stringify(config.params).indexOf("]")
        ) {
          config.paramsSerializer = (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          };
        }

        // 별칭(Alias)이 적용 된 URL를 환경(local/dev/prod)에 맞게 실제 WAS URL로 치환
        config.url = config.url.replace(ALIAS_SVR, URL_WAS);

        if (!config.hasOwnProperty("cancelToken")) {
          // 화면 라우팅 시 완료가 안된 WAS API에 대해 취소 처리를 할 수 있도록
          // axios.CancelToken.source를 주입 한다
          config.cancelToken = serviceHttpCanceler.getCancelToken();
        }

        if (config.hasOwnProperty("headers")) {
          // 사용자 로그인이 정상 처리 되면, 사용자 식별 정보(identity)가 브라우저에 저장 됨
          // 식별 정보에서 인증 관련 정보를 가져와 서버 통신 시 헤더에 포함 시킴
          let value = serviceStorage.getAccessToken(
            serviceStorage.getIdentity()
          );

          // 제휴 화면에서 서버 연동을 하기 위해 임시 토큰 적용
          if (!value) {
            value = serviceStorage.getTempData().tmpAccessToken;
          }

          if (!!value && config.noToken !== true) {
            // config.headers["Authorization"] = "Bearer " + value || "";
            // 20200618 박용성 선임의 요청으로 Bearer 삭제하고, Authorization을 AUTHORIZATION으로 변경 함.
            config.headers["APP-TOKEN"] = value || "";
          }
          value = null;

          if (!config.headers.hasOwnProperty("Content-Type")) {
            config.headers["Content-Type"] = "application/json; charset=utf-8";
          }
        }
      }

      return config;
    },
    (error) => {
      if (0 === error.config.url.indexOf(URL_WAS)) {
        const isPrevBlocking = isBlocking();
        __reqsCompleted++;
        doCheckAndCloseLoading(isBlocking(), isPrevBlocking);
      }
      return Promise.reject(error);
    }
  );

  // Axios의 Response Interceptor 추가
  axios.interceptors.response.use(
      async (response) => {

      if (0 === response.config.url.indexOf(URL_WAS)) {
        const isPrevBlocking = isBlocking();
        __reqsCompleted++;
        doCheckAndCloseLoading(isBlocking(), isPrevBlocking);
      }

      // 쿠키에 저장 된 자동로그인 여부에 따라 로그아웃 처리가 수행 됨. (PC/모바일에 따라 다르게 처리 될 수 있음)
      if (serviceStorage.isForceLogoutByCookie()) {
        serviceHttpCanceler.cancelAll();
        !!store && logoutRequest()(store.dispatch).then(() => {});
      }

      return response;
    },
    async (error) => {
      console.log(
        TAG,
        "Error axios.interceptors.response",
        "\n",
        JSON.stringify(error, null, 4)
      );

      if (axios.isCancel(error)) {
        if (serviceHttpCanceler.isCancelReason(error.message)) {
          reset();
          !!store && closeRequest()(store.dispatch);
        }
      } else {
        if (0 === error.config.url.indexOf(URL_WAS)) {
          const isPrevBlocking = isBlocking();
          __reqsCompleted++;
          doCheckAndCloseLoading(isBlocking(), isPrevBlocking);

          if (error.response) {
            if(error.response.status === 401) {

                error.isDisableAlert = true;
                serviceHttpCanceler.cancelAll();
                !!store &&
                logoutRequest()(store.dispatch).then(() => {
                  // 팝업 지연 출력
                  setTimeout(() => {
                    error.isDisableAlert = false;

                    if (
                        !!error &&
                        !!error.response &&
                        !!error.response.data &&
                        !!error.response.data.code
                    ) {
                      switch (error.response.data.code) {
                        case "40199001": // 40199001 사용자 권한 없음(gw서버) - 모텔 관련 요청에 대하여 권한이 없을 경우 발생
                          error.isDisableAlert = true;
                          openAlertPopupWithPromise(
                              null,
                              "제휴점 접근 권한이 없습니다.\n\n파트너센터로 문의해 주시기 바랍니다."
                          );
                          break;
                      }
                    }
                    openHttpRejectionPopupWithPromise(error);
                  }, 100);
                });
                return Promise.reject(error);
            } else if(error.response.status === 400) {
                console.log("error : ", error.response)
              if (!!error && !!error.response) {
                const _config = error.config;
                if(error.response.data.code === "40000004") {
                  if(lock && !_config._retry) {
                    return new Promise((resolve, reject) => {
                      queue.add({resolve, reject});
                    }).then(() => {
                      __reqsTotal++;
                      _config.headers["APP-TOKEN"] = serviceStorage.getAccessToken(serviceStorage.getIdentity());
                      return axios.request(_config);
                    })
                  }
                  lock = true;
                  _config._retry = true;
                  error.isDisableAlert = true;

                  let identity = serviceStorage.getIdentity();
                  if(Object.keys(identity).length === 0) {
                    identity = serviceStorage.getAutoLoginData()
                  }
                  if (Object.keys(identity).length !== 0) {
                    const refreshToken = serviceStorage.getRefreshToken(identity);

                    try {
                      let res = await postRefreshToken(refreshToken)(store.dispatch);
                      if(res !== undefined) {

                        let identity = serviceStorage.getIdentity();
                        const accessToken = serviceStorage.getAccessToken(identity);

                        error.config.headers["APP-TOKEN"] = accessToken;
                        __reqsTotal++;
                        queue.get().forEach((q) => {
                          q.resolve();
                        });
                        queue.clear();
                        const retryResponse = await axios.request(_config);
                        if (retryResponse !== undefined) {
                          return retryResponse;
                        }
                      }
                    } catch (e) {
                      serviceStorage.removeIdentity();
                      console.error(e);
                    } finally {
                        lock = false;
                    }
                  }
                } else if (error.response.data.code === "40000005") {
                  error.isDisableAlert = true;
                  serviceHttpCanceler.cancelAll();
                  !!store &&
                  logoutRequest()(store.dispatch).then(() => {
                    setTimeout(() => {
                      // 팝업 지연 출력
                      openAlertPopupWithPromise(
                          DEFAULT_POPUP_TITLE,
                          "세션이 만료 되었습니다."
                      );
                    }, 100);
                  }).finally(() => {
                    queue.clear();
                  });
                } else if (error.response.data.code === "40000999") {
                  interruptPopup(store, error);
                  return Promise.reject(error);
                } else if (error.response.data.code === "40100003") {
                  logoutRequest()(store.dispatch).then(() => {
                    setTimeout(() => {
                      // 팝업 지연 출력
                      openAlertPopupWithPromise(
                          DEFAULT_POPUP_TITLE,
                          "토큰 정보가 없습니다.\n다시 로그인 해주세요."
                      );
                    }, 100);
                  })
                } else {
                  return Promise.reject(error);
                }
              }
            }else if (error.response.status === 500) {
              if (!!error && !!error.response) {
                switch (error.response.data.code) {
                  case "50000001": // 상태코드 50000001 인 경우 점검중 팝업 출력
                  case "50000999":
                    interruptPopup(store, error);
                    break;
                }
              }
              return Promise.reject(error);
            }else if(error.response.status === 503) {
              error.isDisableAlert = true;
              serviceHttpCanceler.cancelAll();
              !!store &&
              logoutRequest()(store.dispatch).then(() => {
                setTimeout(() => {
                  // 팝업 지연 출력
                  openAlertPopupWithPromise(
                      SYSTEM_CHECK_TITLE,
                      SYSTEM_CHECK_GUIDE
                  );
                }, 100);
              });
              return Promise.reject(error);
            }
          }
        }
      }
    }
  );

  function doCheckAndCloseLoading(isCurrentBlocking, isPrevBlocking) {
    // !isCurrentBlocking && isPrevBlocking && store.dispatch(closeLoading());
    !isCurrentBlocking &&
      isPrevBlocking &&
      !!store &&
      closeRequest()(store.dispatch);
  }
}

// Browser Event를 Blocking 처리 합니다
function blockEvent(evt) {
  console.log(TAG, `Blocking ${evt.type}. Not yet completed ajax.`);
  utils.preventDefault(evt);
  utils.stopPropagation(evt);
}

// Blocking 여부를 반환하는 메소드 입니다
function isBlocking() {
  return __reqsCompleted < __reqsTotal;
}

// Blocking 상태를 초기화 합니다
function reset() {
  __reqsCompleted = 0;
  __reqsTotal = 0;
}

// 특정 에러코드 반환시 로그아웃 요청과 함께 안내 문구를 출력하는 popup
function interruptPopup(store, error) {
  error.isDisableAlert = true;
  serviceHttpCanceler.cancelAll();
  const { status } = error.response;
  const goToLogin = status === 500;

  !!store &&
    logoutRequest()(store.dispatch).then(() => {
      setTimeout(() => {
        // 팝업 지연 출력
        openAlertPopupWithPromise(
          goToLogin ? SYSTEM_CHECK_TITLE : DEFAULT_POPUP_TITLE,
          !!error?.response?.data?.desc
            ? error.response.data.desc
            : SYSTEM_CHECK_GUIDE
        ).then((res) => {
          if (goToLogin) {
            history.push("/login");
          }
        });
      }, 100);
    });
}
