import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

/**
 * TabPanel Component 입니다.<p/>
 *
 * @author Dr.st3in <drst3in9196@pineone.com>
 * @name TabPanel
 * @class
 * @component
 */

const styles = (theme) => ({});

class TabPanel extends Component {
  render() {
    const { children, value, index, ...other } = this.props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        <Box p={0}>{children}</Box>
      </Typography>
    );
  }
}

//export default TabPanel;
const styledTabPanel = withStyles(styles)(TabPanel);

export { styledTabPanel as TabPanel };
