/**
 * Breadcrumb Redux 기능을 제공하는 모듈 입니다
 *
 * @author Taesung Park <pts@pineone.com>
 * @module system/redux-ducks/breadcrumb
 */

const TAG = "[system/redux-ducks/breadcrumb.js]";
// console.log(TAG, "Create");

/*#############################################################
 *  Actions
 *###########################################################*/

/** Breadcrumb 상태 업데이트 시 발생 되는 Action Type 입니다 */
export const BREADCRUMB_CHANGED = "@breadcrumb/BREADCRUMB_CHANGED";

/*#############################################################
 *  Action Creator
 *###########################################################*/

/**
 * Breadcrumb 상태 업데이트 요청을 수행하는 Action 함수 입니다
 *
 * @param {object} params - Breadcrumb 상태 정보
 * @returns {Promise} Promise 객체
 */
export function changeRequest(params) {
  console.log(TAG, `Called changeRequest()`);

  return (dispatch) =>
    Promise.resolve().then(() => dispatch(changeBreadcrumb(params)));
}

/**
 * BREADCRUMB_CHANGED 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @param {object} params - Breadcrumb 상태 정보
 * @returns {object} BREADCRUMB_CHANGED 타입을 정의한 객체
 */
export function changeBreadcrumb(params) {
  console.log(TAG, `Called changeBreadcrumb()`);

  return {
    type: BREADCRUMB_CHANGED,
    history: params.history,
  };
}

/*#############################################################
 *  Reducer
 *###########################################################*/

const initialState = {
  status: {
    history: "",
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case BREADCRUMB_CHANGED:
      return {
        ...state,
        status: {
          ...state.staus,
          history: action.history,
        },
      };
    default:
      return state;
  }
}
