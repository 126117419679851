import React, { useState, useRef } from "react";

import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Typography from "@material-ui/core/Typography";

import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import * as configApp from "config/config-app";
import * as serviceBrowser from "lib/service-browser";
import * as serviceStorage from "lib/service-storage";

import InputFieldGuide from "constant/InputFieldGuide";

const SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";
const IS_IOS = serviceBrowser.isIOS();

const styles = (theme) => ({
  link: {
    margin: 0,
    padding: 0,
    "& *": {
      letterSpacing: "-1px",
    },
    "& a": {
      textDecoration: "none",
    },
    "& a:link": {
      color: "#000",
    },
    "& a:visited": {
      color: "#000",
    },
    "& a:active": {
      color: "#000",
    },
    "& a:hover": {
      textDecoration: "underline",
    },
  },
  formControlBox: {
    display: "flex",
    width: "100vw",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControlLabel: {
    fontSize: "0.83em",
    fontWeight: "bold",
  },
  formControlLabelV2: {
    //양태욱추가
    fontSize: "12px",
    fontWeight: "bold",
  },
  loginButtonText: {
    //양태욱추가
    fontSize: "12px",
  },
  linkText: {
    //양태욱추가
    fontSize: "12px",
  },
});

/**
 * 로그인 Template Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name LoginTemplate
 * @component
 */
function LoginTemplate(props) {
  ////////////////////////////////////////////////////
  // 1. 선언
  ////////////////////////////////////////////////////
  const { classes, onSubmit } = props;

  const { register, handleSubmit, errors } = useForm();

  const refForm = useRef();

  const defaultUserId = serviceStorage.getTempUserId() || "";
  const [state, setState] = useState({
    isSaveID: !!defaultUserId,
  });

  ////////////////////////////////////////////////////
  // 2. 핸들러
  ////////////////////////////////////////////////////

  const handleOnChange = (event) => {
    // !!event.preventDefault && event.preventDefault();
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleBeforeOnSubmit = (params) => {
    let isSaveID = false;
    if (!!refForm.current.elements["isSaveID"]) {
      isSaveID = !!refForm.current.elements["isSaveID"].checked;
    }

    !!onSubmit &&
      onSubmit(
        Object.assign(
          {
            isSaveID,
          },
          params
        )
      );
  };

  ////////////////////////////////////////////////////
  // 3. 에러 상태 체크
  ////////////////////////////////////////////////////

  const isErrorUserId = !!errors.userId;
  const isErrUserPassword = !!errors.userPassword;

  return (
    <>
      <form ref={refForm} onSubmit={handleSubmit(handleBeforeOnSubmit)}>
        <Box className="boxField">
          <TextField
            error={isErrorUserId}
            inputRef={register({
              required: {
                value: true,
              },
            })}
            fullWidth
            defaultValue={defaultUserId}
            id="userId"
            name="userId"
            inputProps={
              IS_IOS
                ? {
                    autoCapitalize: "off",
                    autoCorrect: "off",
                  }
                : {}
            }
            placeholder={InputFieldGuide.userId.LOGIN.placeholder}
            variant="outlined"
            size="small"
            className="boxFieldText"
            //양태욱추가
            inputProps={{
              style: {
                fontSize: "12px",
              },
            }}
            autoFocus
          />
        </Box>
        <Box className="boxField">
          <TextField
            error={isErrUserPassword}
            inputRef={register({
              required: {
                value: true,
              },
            })}
            fullWidth
            type="password"
            id="userPassword"
            name="userPassword"
            inputProps={
              IS_IOS
                ? {
                    autoCapitalize: "off",
                    autoCorrect: "off",
                  }
                : {}
            }
            placeholder={InputFieldGuide.password.LOGIN.placeholder}
            variant="outlined"
            size="small"
            className="boxFieldText"
            //양태욱추가
            inputProps={{
              style: {
                fontSize: "12px",
              },
            }}
          />
        </Box>

        <Box mt={SERVICE_TYPE_PC ? 2 : 0} className={classes.formControlBox}>
          <FormGroup className={"formGroup"} row>
            {/* 20200922 박태성 - JIRA PLATFORM-41
            `로그인 유지`는 사용자의 선택에 의해서 진행되어야 
            추후 고객과 분쟁을 줄일 수 있다는 파인스테이 요청이 있어 
            모바일 버전도 노출 되도록 수정 함 */}
            {/* {SERVICE_TYPE_PC && ( */}
            <FormControlLabel
              label={
                <p className={classes.formControlLabelV2}>로그인 유지</p>
                // <Typography className={classes.formControlLabel}>
                //   로그인 유지
                // </Typography>
              }
              control={
                <Checkbox
                  inputRef={register}
                  name="isAutoLogin"
                  color="primary"
                />
              }
            />
            {/* )} */}
            <FormControlLabel
              label={
                <p className={classes.formControlLabelV2}>아이디 기억하기</p>
                // <Typography className={classes.formControlLabel}>
                //   아이디 기억하기
                // </Typography>
              }
              control={
                <Checkbox
                  // inputRef={register}
                  name="isSaveID"
                  onChange={handleOnChange}
                  checked={state.isSaveID}
                  color="primary"
                />
              }
            />
          </FormGroup>
        </Box>

        <Box className="boxField">
          <Button focusRipple={false} type="submit" variant="contained" color="primary" fullWidth>
            {/* <strong>로그인</strong> */}
            <strong className={classes.loginButtonText}>로그인</strong>
          </Button>
        </Box>
        <Box display="flex" justifyContent="center" className="boxField">
          <h5 className={classes.link}>
            {/* 20201117 박태성 - PLATFORM-456에 의거 해당 버튼 히든 처리 */}
            {/* {!!SERVICE_TYPE_PC && (
              <>
                <Link
                  to={{
                    pathname: "/signup",
                  }}
                >
                  회원가입
                </Link>
                &nbsp;&nbsp;&nbsp;
              </>
            )} */}
            <Link
                to={{
                  pathname: "/signup",
                }}
            >
              회원가입
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link
              to={{
                pathname: "/findid",
              }}
            >
              아이디 찾기
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link
              to={{
                pathname: "/findpass",
              }}
            >
              비밀번호 찾기
            </Link>
          </h5>
        </Box>
      </form>
    </>
  );
}

// 초기 props 설정
LoginTemplate.defaultProps = {
  onSubmit: function () {},
};

export default withStyles(styles)(LoginTemplate);
