import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Box, Backdrop, CircularProgress } from "@material-ui/core";

/**
 * 로딩 애니메이션 Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name Loading
 * @class
 * @component
 */

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#940000",
  },
});

class Loading extends Component {
  constructor(props) {
    super(props);
    console.log("Loading props ; ", props);
  }

  render() {
    const { classes, open } = this.props;

    return (
      <Box display={open ? "block" : "none"}>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    );
  }
}

// props의 초깃값을 정의합니다.
Loading.defaultProps = {
  open: false,
};

const styledLoading = withStyles(styles)(Loading);

export { styledLoading as Loading };
