/**
 * 본 모듈은 휴대폰 번호 형식이 유효한지 체크 하는 모듈 입니다<p/>
 *
 * @module utils/checkHandPhoneNumber
 */

import InputFieldGuide from "constant/InputFieldGuide";
import isEmpty from "./isEmpty";
import isNumberFormat from "./isNumberFormat";
import isHandPhoneNumberFormat from "./isHandPhoneNumberFormat";
import phoneFormatter from "./phoneFormatter";

/**
 * 휴대폰 번호를 검사하여, 유효하지 않을 경우 에러 메세지를 반환 합니다
 *
 * @param {string} phoneNumber - 휴대폰 번호
 * @return {string} 에러 메세지. 유효 할 경우 빈문자열을 반환 함
 */

export default function checkHandPhoneNumber(phoneNumber) {
  let errorMessage = "";
  let strNumber = phoneNumber?.trim();

  // 공백 검사
  if (!errorMessage && isEmpty(strNumber) === true) {
    errorMessage = InputFieldGuide.phoneNumber.required;
  }

  // 숫자 형식 검사
  if (!errorMessage && isNumberFormat(strNumber) === false) {
    errorMessage = InputFieldGuide.phoneNumber.pattern;
  }

  // 길이 및 번호 형식 검사
  if (
    !errorMessage &&
    isHandPhoneNumberFormat(phoneFormatter(strNumber)) === false
  ) {
    errorMessage = InputFieldGuide.phoneNumber.invalid;
  }

  return errorMessage;
}
