import React, { useRef, useState } from "react";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";

const TextFieldLengthReg = ({
  autoComplete,
  autoFocus,
  classes,
  className,
  color,
  defaultValue,
  disabled,
  error,
  FormHelperTextProps,
  fullWidth,
  helperText,
  id,
  InputLabelProps,
  inputProps,
  InputProps,
  inputRef,
  label,
  margin,
  multiline,
  name,
  onChange,
  placeholder,
  required,
  rows,
  rowsMax,
  select,
  SelectProps,
  size,
  type,
  value,
  variant,
  style,
  maxLength,
  filterRegExp,
  onFocus,
  onBlur,
}) => {
  const textRef = useRef();
  const [legnth, setLength] = useState(defaultValue ? defaultValue.length : 0);
  const inChangeFunc = (event) => {
    if (filterRegExp && event.currentTarget.value) {
      event.currentTarget.value = event.currentTarget.value.match(filterRegExp)
        ? event.currentTarget.value.match(filterRegExp).join("")
        : "";
    }

    //길이제한
    if (maxLength && event.currentTarget.value.length >= maxLength) {
      event.currentTarget.value = event.currentTarget.value.substring(
        0,
        maxLength
      );
    }
    setLength(event.currentTarget.value.length);
    onChange && onChange(event);
  };

  return (
    <TextField
      inputRef={inputRef ? inputRef : textRef}
      onChange={inChangeFunc}
      autoComplete={autoComplete && autoComplete}
      autoFocus={autoFocus && autoFocus}
      classes={classes && classes}
      color={color && color}
      defaultValue={defaultValue && defaultValue}
      disabled={disabled && disabled}
      error={error && error}
      FormHelperTextProps={FormHelperTextProps && FormHelperTextProps}
      fullWidth={fullWidth && fullWidth}
      helperText={helperText && helperText}
      id={id && id}
      InputLabelProps={InputLabelProps && InputLabelProps}
      inputProps={inputProps && inputProps}
      InputProps={InputProps && InputProps}
      label={label && label}
      margin={margin && margin}
      multiline={multiline && multiline}
      name={name && name}
      placeholder={placeholder && placeholder}
      required={required && required}
      rows={rows && rows}
      rowsMax={rowsMax && rowsMax}
      select={select && select}
      SelectProps={SelectProps && SelectProps}
      size={size && size}
      type={type && type}
      value={value && value}
      variant={variant && variant}
      style={style && style}
      className={className && className}
      onFocus={onFocus && onFocus}
      onBlur={onBlur && onBlur}
    />
  );
};

TextFieldLengthReg.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  classes: PropTypes.object,
  color: PropTypes.oneOf(["primary", "secondary"]),
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  FormHelperTextProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  label: PropTypes.node,
  margin: PropTypes.oneOf(["dense", "none", "normal"]),
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowsMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  select: PropTypes.bool,
  SelectProps: PropTypes.object,
  size: PropTypes.oneOf(["medium", "small"]),
  type: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  style: PropTypes.object,
  maxLength: PropTypes.number,
  filterRegExp: PropTypes.object,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
export { TextFieldLengthReg };
