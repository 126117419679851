/**
 * 본 모듈은 전화번호 형식으로 변환하는 모듈 입니다
 *
 * @module utils/phoneFormatter
 */

/**
 * 숫자 형식의 문자열을 전화번호 형식으로 변환하여 반환 합니다
 *
 * @param {string} num - 숫자 형식의 문자열
 * @param {number} type - 전화번호 형식 타입
 * @return {string} 전화번호 형식의 문자열
 */
export default function phoneFormatter(num, type) {
  if (num !== 0 && !num) return "";
  num = "" + num;

  // 참고) https://sesok808.tistory.com/551
  let formatNum = "";
  try {
    if (num.length === 11) {
      if (type === 0) {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-****-$3");
      } else {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      }
    } else if (num.length === 8) {
      formatNum = num.replace(/(\d{4})(\d{4})/, "$1-$2");
    } else {
      if (num.indexOf("02") === 0) {
        if (num.length === 9) {
          if (type === 0) {
            formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, "$1-****-$3");
          } else {
            formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          }
        } else {
          if (type === 0) {
            formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-****-$3");
          } else {
            formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        }
      } else {
        if (type === 0) {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-***-$3");
        } else {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
    }
  } catch (e) {
    formatNum = num;
  }
  return formatNum;
}
