import React, { createRef } from "react";
import { BoxWrap, ModalWrap } from "components";
import { TableStyle } from "components/TableStyle";
import {
  Box,
  FormControlLabel,
  Button,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DataTable from "react-data-table-component";
import {
  getAdminsMotels,
  createAdminsMotels,
  createDeleteAdminsMotels,
} from "lib/service-api";
import CommonConsts from "constant/CommonConsts";
import { getServiceCodeUserType } from "constant/ServiceCode";
import {
  POPUP_ACTION_OK,
  openConfirmPopupWithPromise,
  openAlertPopupWithPromise,
} from "lib/helper-popup";
import dateFormat from "date-fns/format";
import phoneFormater from "utils/phoneFormatter";
import { TextFieldLengthReg } from "./TextFieldLengthReg";
import { getUserType, getIdentity } from "lib/service-storage";

class AdminSetting extends React.Component {
  constructor(props) {
    super(props);

    this.adminIdRef = createRef();
    this.state = {
      modalOpen2: false,
      motelKey: props.motelKey,
      adminList: props.adminList,
      userType: getUserType(getIdentity()),
    };
  }

  adminColumns = [
    {
      cell: ({ type }) => {
        return CommonConsts.USER_TYPE[type]
      },
      name: "권한",
      center: true,
    },
    {
      name: "아이디",
      selector: "id",
      center: true,
    },
    {
      name: "성명",
      selector: "name",
      center: true,
    },
    {
      name: "휴대폰번호",
      // selector: "phone_number",
      cell: ({ phone_number }) => phoneFormater(phone_number),
      center: true,
    },
    {
      name: "등록일",
      center: true,
      cell: ({ staff_reg_dt, reg_dt }) => {
        return staff_reg_dt
          ? dateFormat(new Date().setTime(staff_reg_dt), "yyyy.MM.dd")
          : dateFormat(new Date().setTime(reg_dt), "yyyy.MM.dd");
      },
    },
    {
      cell: (row) => {
        // console.log("row.sel6 : ", row.sel6);
        return row.type !== getServiceCodeUserType("사장님 Web - 사장님") ? (
          <IconButton
            onClick={() => {
              this.removeAdmin(row.key);
            }}
          >
            <DeleteForeverIcon style={{ fontSize: 24 }} />
          </IconButton>
        ) : null;
      },
      name: "계정 삭제",
      center: true,
    },
  ];

  componentDidMount() {
    // this.getAdminList()
  }

  getAdminList = () => {
    getAdminsMotels({
      motel_key: this.state.motelKey,
    })
      .then((res) => {
        if (res.data.code !== CommonConsts.SERVER_API_CODE.SUCCESS) {
          return openAlertPopupWithPromise(null, res.data.desc);
        }
        this.setState({
          adminList: res.data.result.admin_users,
        });
      })
      .catch(this.commonCatch);
  };

  commonCatch = (err) => {
    if (err.response) {
      if (err.response.data.code === "40099001") {
        return openAlertPopupWithPromise(
          null,
          "사장님 아이디로 등록하실 수 없습니다."
        );
      }
      openAlertPopupWithPromise(null, err.response.data.desc);
    } else {
      openAlertPopupWithPromise(null, err);
    }
  };

  removeAdmin = (key) => {
    if (
      !this.state.userType ||
      this.state.userType === getServiceCodeUserType("사장님 Web - 직원")
    ) {
      return openAlertPopupWithPromise(null, "권한이 없습니다.");
    }
    openConfirmPopupWithPromise(null, "선택된 사용자를 삭제하시겠습니까?")
      .then((res) => {
        if (res === POPUP_ACTION_OK) {
          return createDeleteAdminsMotels({
            admin_key: key,
            motel_key: this.state.motelKey,
          });
        }
      })
      .then((res) => {
        if (res) {
          if (res.data.code !== CommonConsts.SERVER_API_CODE.SUCCESS) {
            return openAlertPopupWithPromise(null, res.data.desc);
          }
          this.setState({
            adminList: [
              ...this.state.adminList.filter((data) => data.key !== key),
            ],
          });
        }
      })
      .catch(this.commonCatch);
  };

  handleClickOpen = (modalNum) => {
    this.setState({ modalOpen2: !this.state.modalOpen2 });
  };

  handleModalClose = (modalNum) => {
    this.setState({ modalOpen2: !this.state.modalOpen2 });
  };

  addAdminList = () => {
    const targetId = this.adminIdRef.current.value;
    if (this.state.adminList.length >= 10) {
      return openAlertPopupWithPromise(
        null,
        "최대 10명까지 등록하실 수 있습니다."
      );
    }
    if (!targetId) {
      return openAlertPopupWithPromise(
        null,
        "검색한 아이디의 직원 정보가 없습니다."
      );
    }
    if (
      !this.state.userType ||
      this.state.userType === getServiceCodeUserType("사장님 Web - 직원")
    ) {
      return openAlertPopupWithPromise(null, "권한이 없습니다.");
    }
    createAdminsMotels({
      motel_key: this.state.motelKey,
      user_id: targetId,
    })
      .then((res) => {
        if (res.data.code !== CommonConsts.SERVER_API_CODE.SUCCESS) {
          if (res.data.code === "20000001") {
            return openAlertPopupWithPromise(
              null,
              "검색한 아이디의 직원 정보가 없습니다."
            );
          }
          //40099001
          return openAlertPopupWithPromise(null, res.data.desc);
        }
        this.handleModalClose(2);
        this.getAdminList();
      })
      .catch(this.commonCatch);
  };

  render() {
    return (
      <>
        <BoxWrap title={"직원 등록"}>
          <Box
            position="absolute"
            style={{ color: "#008aff", top: "20px", left: "120px" }}
          >
            사업장을 담당하고 있는 직원을 추가, 삭제할 수 있습니다.
          </Box>

          <Box>
            <DataTable
              noHeader={true}
              highlightOnHover={true}
              columns={this.adminColumns}
              data={this.state.adminList}
              customStyles={TableStyle.styleNormal()}
            />
          </Box>

          <Box mt={2} align="right">
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={"submit"}
              onClick={() => {
                this.handleClickOpen();
              }}
              size="small"
            >
              <strong>직원 등록</strong>
            </Button>
          </Box>
        </BoxWrap>

        <ModalWrap
          title={"직원 등록"}
          onClose={() => {
            this.handleModalClose();
          }}
          // onButtons={[
          //   {
          //     title: "검색",
          //     callback: this.handleModalSubmit,
          //   }
          // ]}
          open={this.state.modalOpen2}
          PaperProps={{
            style: {
              width: "1000px",
              height: "200px",
            },
          }}
        >
          <Box id="CommonFormList-PC">
            <Box>
              <div className={"title"}>아이디</div>
              <div className={"contentBody"}>
                <TextFieldLengthReg
                  variant="outlined"
                  margin="normal"
                  required
                  //fullWidth
                  id="userId"
                  name="userId"
                  autoComplete="off"
                  style={{ width: "550px" }}
                  size="small"
                  inputRef={this.adminIdRef}
                  maxLength={100}
                  inputProps={{
                    placeholder: "아이디를 입력하세요",
                  }}
                />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={this.addAdminList}
                  size="small"
                  style={{ marginLeft: "10px", width: "112px" }}
                >
                  <strong>등록</strong>
                </Button>
              </div>
            </Box>

            <Box style={{ borderBottom: "1px solid #ddd" }}>
              <div className={"title"}>권한</div>
              <div className={"contentBody"}>
                <RadioGroup value="직원" style={{ flexWrap: "nowrap" }}>
                  <div>
                    <FormControlLabel
                      value="직원"
                      control={<Radio color="primary" />}
                      label="직원"
                    />
                    {/* <FormControlLabel
                      value="2"
                      control={<Radio color="primary" />}
                      label="매니저"
                      style={{ marginLeft: "20%" }}
                    /> */}
                  </div>
                  {/* <div>
                    <FormControlLabel
                      value="3"
                      control={<Radio color="primary" />}
                      label="프론트"
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio color="primary" />}
                      label="기타"
                      style={{ marginLeft: "20%" }}
                    />
                  </div> */}
                </RadioGroup>
              </div>
            </Box>
          </Box>
        </ModalWrap>
      </>
    );
  }
}

export { AdminSetting };
