import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/locale";
import { getMonthLastDay } from "lib/helper-date";
import { Box } from "@material-ui/core";

/**
 * 예약내역, 정산내역, 쿠폰발행 등 월별로 일자를 간편하게 선택할 수 있는 컴포넌트입니다.
 */
class MonthSelectCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectMonth: new Date(),
    };
  }
  /** 강제 포커스 아웃 */
  handleFocus = (e) => {
    e.target.blur();
  };
  /** 월 선택 이벤트 */
  handleMonthChange = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const startDate = new Date(year, month, 1);
    const endDate = getMonthLastDay(date);
    this.setState({
      selectMonth: date,
    });
    this.props.onChange({ startDate, endDate });
  };
  render() {
    const { selectMonth } = this.state;
    return (
      <Box id="MonthSelectorWrap">
        <DatePicker
          showMonthYearPicker
          selected={selectMonth}
          minDate={new Date('2020-01-01')}
          dateFormat="월 선택"
          onFocus={this.handleFocus}
          locale={ko}
          onChange={this.handleMonthChange}
        />
      </Box>
    );
  }
}

export { MonthSelectCalendar };
