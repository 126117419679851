/**
 * url에 관련된 처리 함수의 집합체
 *
 * @module utils/urlParser
 */

// 20200708 박태성C 추가
(function (w) {
  // https://stackoverflow.com/questions/45758837/script5009-urlsearchparams-is-undefined-in-ie-11
  w.URLSearchParams =
    w.URLSearchParams ||
    function (searchString) {
      var self = this;
      self.searchString = searchString;
      self.get = function (name) {
        var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
          self.searchString
        );
        if (results == null) {
          return null;
        } else {
          return decodeURIComponent(results[1]) || 0;
        }
      };
    };
})(window);

export const urlParser = {
  getQueryString,
};

/**
 * url로 부터 쿼리 스트링을 추출 합니다.
 *
 * @param {object} location - history 객체의 location객체
 */
function getQueryString(location) {
  const search = location.search;

  return new URLSearchParams(search);
}
