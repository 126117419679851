/**
 * 입력 필드의 placeholder 및 guidetext를 정의 한 모듈 입니다
 *
 * @version 0.0.1
 * @author Taesung Park <pts@pineone.com>
 * @module constants/InputFieldGuide
 */

// 20201128 박태성C 특수문자 종류
// const SPECIAL_CHARACTERS = "!$%^@";

// 20201130 박태성C @는 포함이 결정 되지 않아 원복 함
// const SPECIAL_CHARACTERS = "!$%^";

const InputFieldGuide = {
  USER_MOBILE_SIGN_UP : {
    ID : {
      placeholder:"가입하실 아이디를 입력해 주세요.",
      guidetext : "*영문&숫자 이외 특수문자는 입력하실 수 없습니다."
    },
    NAME : {
      placeholder : "이름을 입력해 주세요.",
      guidetext: "*최소 2글자 이상 문구(한글, 영어)를 입력해 주세요.(띄어쓰기 가능)",
    },
    PASSWORD : {
      placeholder : "비밀번호를 입력해 주세요.",
      guideText: "* 영문자, 숫자, 특수문자 중 2가지 조합하여 10~20자리 또는 3가지 조합하여 8~20자리로 사용하셔야 합니다." +
          "\n* 아이디와 같은 비밀번호는 사용하실 수 없습니다."
    },
    PASSWORD_CONFIRM : {
      placeholder: "비밀번호를 입력해 주세요.",
      guidetext:""
    },
    EMAIL : {
      placeholder : "제휴점 이메일",
      guidetext:""
    },
    AUTH : {
      placeholder:"휴대폰 번호를 입력해 주세요.",
      guidetext: "※ 인증 받을 휴대폰 번호를 입력해 주세요."
    },
    AUTH_CONFIRM : {
      placeholder :"인증번호를 입력해 주세요."
    }
  },
  USER_MOBILE_ALLIANCE : {
    STORE_NAME : {
      placeholder:"제휴점명을 입력해 주세요.",
      guidetext : "*제휴점명에 이모지는 등록불가입니다."
    },
    OWNER_NAME : {
      placeholder : "대표자명을 입력해 주세요.",
      guidetext: "*사업자등록증 기준입니다.",
    },
    STORE_ADDRESS : {
      placeholder : "나머지 주소를 직접 입력해 주세요."
    },
    ACCOUNT_OWNER : {
      placeholder : "예금주를 입력해 주세요.",
      guidetext: "*예금주가 2명 이상일 때는 콤마(,)를 입력하여 예금주를 구분해 주세요."
    },
    ACCOUNT_NUMBER : {
      placeholder : "계좌번호를 입력해 주세요.(-없이 입력해 주세요.)",
      guidetext : "*첨부하시는 통장 사본과 동일한 계좌 정보를 입력해 주세요.\n*정산 계좌의 예금주는 반드시 대표자 명의의 계좌이어야 합니다."
    },
    ACCOUNT_SSNAUTO : {
      placeholder : "주민등록 앞 6자리",
      guidetext : ""
    },
    ACCOUNT_NUMBER_AUTO : {
      placeholder : "계좌번호를 입력해 주세요.(-없이 입력해 주세요.)",
      guidetext : "*사장님의 은행 계좌에서 매달 월정액을 자동이체 입금 신청하는 항목입니다.\n*전자금융법에 따라 예금주가 일치해야 합니다."
    },
    ADD_FILE: {
      BUSI_LICENSE : "사업자등록증을 첨부해 주세요.",
      ACCOUNT_NUM_COPY : "정산계좌 통장사본을 첨부해 주세요.",
      ACCOUNT_AUTO_NUM_COPY : "자동이체계좌 통장사본을 첨부해 주세요.",
      AGREE_AUTO_FILE :"자동이체 동의서를 첨부해 주세요.",
      guideText : "*사업자등록증과 정산계좌 통장사본은 필수 사항입니다.\n*첨부하시는 파일은 실제 파일을 첨부해 주세요.\n*이미지는 jpg,jpeg,png의 확장자만 등록 가능합니다.\n*개별 파일 용량은 5MB 이하로 등록해 주세요."
    },
  },
  userId: {
    LOGIN: {
      placeholder: "아이디를 입력해 주세요.",
      guidetext: "",
    },
    REGISTER: {
      placeholder: "가입하실 아이디를 입력해 주세요.",
      guidetext: "※ 영문&숫자 이외 특수문자는 입력하실 수 없습니다.",
    },
    FIND_ID: {
      placeholder: "아이디를 입력해 주세요.",
      guidetext: "",
    },
    FIND_PASSWORD: {
      placeholder: "아이디를 입력해 주세요.",
      guidetext: "",
    },
    SIGN_IN: {
      placeholder: "가입하실 아이디를 입력해 주세요.",
      guidetext: "영문&숫자 이외 특수문자는 입력하실 수 없습니다.",
    },
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
    pattern: "영문&숫자만 입력하여 주세요.",
    check: "중복 확인 버튼을 클릭해 주세요.",
    invalid: "영문&숫자만 입력하여 주세요.",
    /*
    2020년07월27일 박태성 C - 7월 15일 사업주ID는 다음의 정책으로 가는 것으로 정리 됨
    1. 대소문자 구분여부: 구분되지 않도록 변경(클라이언트에서 대소문자 일괄적으로 변경해 서버로 전송하는 방식으로)
    2. 글자 최대수~최소수 범위: 최소수 6자(영문+숫자 조합) ~ 최대수는 영문+숫자 조합 100자까지
    */
    minLength: "최소 6글자 이상 입력만 유효 합니다.",
    maxLength: "최대 100글자 이하 입력만 유효 합니다.",
  },
  userName: {
    FIND_ID: {
      placeholder: "이름을 입력해 주세요.",
      guidetext: "※ 최소 2글자 이상 문구(한글, 영어)를 입력해 주세요.",
    },
    FIND_PASSWORD: {
      placeholder: "이름을 입력해 주세요.",
      guidetext: "※ 최소 2글자 이상 문구(한글, 영어)를 입력해 주세요.",
    },
    SIGN_IN: {
      placeholder: "이름을 입력해 주세요.",
      guidetext: "※ 최소 2글자 이상 문구(한글, 영어)를 입력해 주세요.",
    },
    REGISTER: {
      placeholder: "이름을 입력해 주세요.",
      guidetext:
        "※ 최소 2글자 이상 문구(한글, 영어)를 입력해 주세요.(띄어쓰기 가능)",
    },
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
    pattern: "영문, 한글, 공백 입력만 유효합니다.",
    minLength: "최소 2글자 이상 입력만 유효합니다.",
    invalid: "영문, 한글, 공백 입력만 유효합니다.",
  },
  phoneNumber: {
    FIND_ID: {
      placeholder: "휴대폰번호를 입력해 주세요.",
      guidetext:
        "※ 등록된 계정의 휴대폰번호를 입력해 주세요.\n※ 휴대폰번호 입력 시 하이픈(-)을 제외한 숫자만 입력해 주세요.",
      guidetextMobile:
        "※ 하이픈(-)을 제외한 숫자만 입력해 주세요.\n※ 등록된 계정의 휴대폰번호를 입력해 주세요.",
    },
    FIND_PASSWORD: {
      placeholder: "휴대폰번호를 입력해 주세요.",
      guidetext:
        "※ 등록된 계정의 휴대폰번호를 입력해 주세요.\n※ 휴대폰번호 입력 시 하이픈(-)을 제외한 숫자만 입력해 주세요.",
      guidetextMobile:
        "※ 하이픈(-)을 제외한 숫자만 입력해 주세요.\n※ 등록된 계정의 휴대폰번호를 입력해 주세요.",
    },
    MY_PAGE: {
      placeholder: "휴대폰 번호 입력",
      guidetext: "1분 후에 다시 시도할 수 있습니다.",
      guidetextOverwritePC: "휴대폰번호 인증을 재설정해 주세요.",
      guidetextOverwriteMobile: "휴대폰번호 인증을 재설정해 주세요.",
      errorDuplicate: "현재 등록된 휴대폰 번호와 동일합니다.",
    },
    SIGN_IN: {
      placeholder: "",
      guidetext: "※ 알림톡 또는 문자메세지로 전송된 인증번호를 입력해 주세요.",
    },
    REGISTER: {
      placeholder: "",
      guidetext: "※ 인증 받을 휴대폰 번호를 입력해 주세요.",
    },
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
    pattern: "숫자만 입력해 주세요.",
    invalid: "휴대폰 번호 형식이 잘못 되었습니다. 다시 입력해 주세요.",
    invalid2: "대표 번호 형식이 잘못 되었습니다. 다시 입력해 주세요.",
  },
  smsAuthCode: {
    FIND_ID: {
      placeholder: "인증번호 6자리 입력해 주세요.",
      guidetext: "※ 알림톡 또는 문자메세지로 전송된 인증번호를 입력해 주세요.",
    },
    FIND_PASSWORD: {
      placeholder: "인증번호 6자리 입력해 주세요.",
      guidetext: "※ 알림톡 또는 문자메세지로 전송된 인증번호를 입력해 주세요.",
    },
    REGISTER: {
      placeholder: "",
      guidetext: "",
    },
    MY_PAGE: {
      placeholder: "인증번호 입력",
      guidetext: "3분 이내로 인증번호(6자리)를 입력해 주세요.",
    },
    guide: "※ 인증번호를 요청하였습니다. 1분 후에 다시 시도해 주세요.",
    required: "인증번호를 입력해 주세요.",
    request: "인증 요청을 진행해 주세요.",
    check: "인증 확인을 진행해 주세요.",
    minLength: "인증번호 6자리를 입력해 주세요.",
    invalid: "인증번호가 틀립니다. 다시 입력해 주세요.",
    timeout: "입력 시간이 초과되었습니다. 인증번호를 다시 요청 해주세요.",
  },
  email: {
    MY_PAGE: {
      placeholder: "이메일 입력",
      guidetext: "※ 세금계산서 발행을 위해 정확한 이메일 주소를 입력해 주세요.",
    },
    FIND_PASSWORD: {
      placeholder: "유효한 이메일주소를 입력해 주세요.",
      guidetext: "※ 등록된 계정의 이메일주소를 입력해 주세요.",
    },
    FIND_ID: {
      placeholder: "유효한 이메일주소를 입력해 주세요.",
      guidetext: "※ 등록된 계정의 이메일주소를 입력해 주세요.",
    },
    REGISTER: {
      placeholder: "",
      // 20210315 김주호, [PLATFORM-293] 이메일 중복 허용
      // guidetextOwner:
      //   "※ 세금계산서 발행을 위해 정확한 이메일 주소를 입력해 주세요.\n이미 등록된 이메일 주소는 가입하실 수 없습니다.",
      // guidetext: "※ 이미 등록된 이메일 주소는 가입하실 수 없습니다.",
      guidetextOwner:
        "※ 세금계산서 발행을 위해 정확한 이메일 주소를 입력해 주세요.",
      guidetext: "",
    },
    required: "필수 입력란은 꼭 입력해주셔야 합니다.",
    // invalid: "잘못된 형식의 이메일주소입니다. 다시 입력해 주세요.",
    invalid: "적합하지 않은 이메일 형식입니다. 다시 입력해 주세요",
  },

  password: {
    MY_PAGE: {
      placeholder: "현재 비밀번호를 입력해 주세요.",
      guidetext: "등록된 비밀번호를 입력해 주세요.",
    },
    LOGIN: {
      placeholder: "비밀번호를 입력해 주세요.",
      guidetext: "",
    },
    SIGN_IN: {
      placeholder: "비밀번호를 입력해 주세요.",
      // guidetext: `※ 영문자, 숫자, 특수문자(${SPECIAL_CHARACTERS}) 중 2가지 조합하여 10~20자리 또는 3가지 조합하여 8~20자리로 사용하셔야 합니다.\n연속적인 숫자, 아이디와 같은 비밀번호는 사용하실 수 없습니다.`,
      guidetext: `※ 영문자, 숫자, 특수문자 중 2가지 조합하여 10~20자리 또는 3가지 조합하여 8~20자리로 사용하셔야 합니다.\n아이디와 같은 비밀번호는 사용하실 수 없습니다.`,
    },
    REGISTER: {
      placeholder: "비밀번호를 입력해 주세요.",
      // guidetext: `※ 영문자, 숫자, 특수문자(${SPECIAL_CHARACTERS}) 중 2가지 조합하여 10~20자리 또는 3가지 조합하여 8~20자리로 사용하셔야 합니다.\n※ 연속적인 숫자, 아이디와 같은 비밀번호는 사용하실 수 없습니다.`,
      guidetext: `※ 영문자, 숫자, 특수문자 중 2가지 조합하여 10~20자리 또는 3가지 조합하여 8~20자리로 사용하셔야 합니다.\n※ 아이디와 같은 비밀번호는 사용하실 수 없습니다.`,
    },
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
    // pattern: `영문자, 숫자, 특수문자(${SPECIAL_CHARACTERS}) 중 2가지 조합하여 10~20자리 또는 3가지 조합하여 8~20자리로 사용하셔야 합니다.`,
    pattern: `영문자, 숫자, 특수문자 중 2가지 조합하여 10~20자리 또는 3가지 조합하여 8~20자리로 사용하셔야 합니다.`,
    // sequenceNumber: "연속적인 숫자는 사용하실 수 없습니다.",
    sequenceNumber: "",
    equalsUserId: "아이디와 같은 비밀번호는 사용하실 수 없습니다.",
    // min10AndMax20: `영문자, 숫자, 특수문자(${SPECIAL_CHARACTERS}) 중 2가지 조합 시 10~20자리로 사용하셔야 합니다.`,
    // min8AndMax20: `영문자, 숫자, 특수문자(${SPECIAL_CHARACTERS}) 중 3가지 조합 시 8~20자리로 사용하셔야 합니다.`,
    min10AndMax20: `영문자, 숫자, 특수문자 중 2가지 조합 시 10~20자리로 사용하셔야 합니다.`,
    min8AndMax20: `영문자, 숫자, 특수문자 중 3가지 조합 시 8~20자리로 사용하셔야 합니다.`,
  },
  passwordNew: {
    MY_PAGE: {
      placeholder: "새로운 비밀번호를 입력해 주세요.",
      guidetext: `※ 영문자, 숫자, 특수문자 중 2가지 조합하여 10~20자리 또는 3가지 조합하여 8~20자리로 사용하셔야 합니다.\n※ 연속적인 숫자, 아이디와 같은 비밀번호는 사용하실 수 없습니다.`,
    },
  },
  passwordConfirm: {
    MY_PAGE: {
      placeholder: "새로운 비밀번호를 입력해 주세요.",
      guidetext: "",
    },
    SIGN_IN: {
      placeholder: "새로운 비밀번호를 입력해 주세요.",
      guidetext: "",
    },
    REGISTER: {
      placeholder: "비밀번호를 입력해 주세요.",
      guidetext: "",
    },
    required: "비밀번호 확인란을 입력해 주세요.",
    validate: "새로운 비밀번호와 일치하지 않습니다.",
  },
  stayName: {
    ALLIANCE: {
      placeholder: "제휴점명을 입력해 주세요.",
      guidetext: "※ 제휴점명에 이모지는 등록 불가입니다.",
    },
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
    hasEmoji: "제휴점명에 이모지는 등록 불가입니다.",
  },
  businessNum: {
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
    pattern: "사업자 등록번호를 확인해 주세요.",
  },
  agentName: {
    ALLIANCE: {
      placeholder: "대표자명을 입력해 주세요.",
      guidetext: "",
    },
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
  },
  address: {
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
  },
  addressDetail: {
    ALLIANCE: {
      placeholder: "나머지 주소를 직접 입력해 주세요.",
      guidetext: "",
    },
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
  },
  bankName: {
    ALLIANCE: {
      placeholder: "은행명을 입력해 주세요.",
      guidetext: "",
    },
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
    minLength: "은행명은 2자 이상 입력해 주세요.",
  },
  accountBankCode: {
    required: "필수 입력란은 꼭 선택해 주셔야 합니다.",
  },
  accountName: {
    ALLIANCE: {
      placeholder: "예금주를 입력해 주세요.",
      guidetext: "",
    },
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
    pattern: "최소 2글자 이상, 영문/한글/콤마/빈칸 입력만 유효합니다.",
    minLength: "최소 2글자 이상 입력만 유효합니다.",
    // invalid: "예금주명을 확인해주세요.",
    invalid:
      "예금주명은 최소 2글자 이상 입력해야 하며,  영문/한글/일부특수문자를 제외한 입력은 사용이 제한될 수 있습니다.",
  },
  accountNum: {
    ALLIANCE: {
      placeholder: "계좌번호를 입력해 주세요( - 없이 입력해 주세요)",
      guidetext:
        "※ 첨부하시는 통장 사본과 동일한 계좌 정보를 입력해 주세요.\n※ 정산 계좌의 예금주는 반드시 대표자 명의의 계좌이어야 합니다.",
      guidetextAuto:
        "※ 사장님의 은행계좌에서 매달 월정액을 자동이체 입금신청하는 항목입니다.\n※ 전자금융법에 따라 예금주가 일치해야 합니다.",
    },
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
    pattern: "계좌번호 형식이 잘못 되었습니다. - 없이 입력해 주세요.",
  },
  SSN: {
    ALLIANCE: {
      placeholder: "주민등록 앞 6자리",
      guidetext: "",
      minLength: "주민등록번호 앞 6자리를 입력해 주세요.",
    },
    required: "필수 입력란은 꼭 입력해 주셔야 합니다.",
  },
  nameEID: {
    ALLIANCE: {
      placeholder: "사업자등록증을 첨부해 주세요.",
      guidetext:
        "※ 사업자등록증과 정산계좌 통장 사본은 필수 사항입니다.\n※ 첨부하시는 파일은 실제 파일을 첨부해 주세요.\n※ 이미지는 jpg, jpeg, png 의 확장자만 등록 가능합니다.\n※ 개별 파일 용량은 5MB 이하로 등록해 주세요.",
    },
    // required: "필수 입력란은 꼭 입력해주셔야 합니다.",
    required: "사업자등록증은 필수 사항입니다.",
  },
  nameBank: {
    ALLIANCE: {
      placeholder: "정산계좌 통장사본을 첨부해 주세요.",
      guidetext:
        "※ 예금주가 2명 이상일 때는 콤마(,)를 입력하여 예금주를 구분하여 주세요.",
    },
    // required: "필수 입력란은 꼭 입력해주셔야 합니다.",
    required: "정산계좌통장사본은 필수사항입니다.",
  },
  nameBankAuto: {
    ALLIANCE: {
      placeholder: "자동이체계좌 통장사본을 첨부해 주세요.",
      guidetext:
        "※ 예금주가 2명 이상일 때는 콤마(,)를 입력하여 예금주를 구분하여 주세요.",
    },
    // required: "필수 입력란은 꼭 입력해주셔야 합니다.",
    required: "자동이체계좌 통장사본은 필수 사항입니다.",
  },
  nameAgreementAuto: {
    ALLIANCE: {
      placeholder: "자동이체 동의서를 첨부해 주세요.",
    },
    required: "자동이체 동의서는 필수사항입니다.",
  },
  rateType: {
    ALLIANCE: {
      guidetext:
        "※ 꿀스테이에서 사용하실 요금제를 선택해 주세요.\n※ 요금제는 월 기준으로 요금제 변경을 원하실 경우 월 단위로 요금제 변경이 가능합니다.",
    },
  },
};

export default InputFieldGuide;
