/**
 * 본 모듈은 인증번호 형식이 유효한지 검사하는 모듈 입니다
 *
 * @module utils/isSMSCodeFormat
 */

import isNumberFormat from "./isNumberFormat";

/**
 * 인증번호를 검사하여 형식의 유효 여부를 반환 합니다
 *
 * @param {string} smsAuthCode - 인증번호
 * @return {boolean} 형식 유효 여부
 */
export default function isSMSCodeFormat(smsAuthCode) {
  const isValid = isNumberFormat(smsAuthCode);
  if (!isValid || smsAuthCode.length !== 6) {
    return false;
  }
  return true;
}
