import React, { useState, useRef, useEffect, useCallback } from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import TextField from "@material-ui/core/TextField";
// import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";

import ButtonSubmit from "components/ButtonSubmit";
import ButtonGroupConfirm from "components/ButtonGroupConfirm";

import * as utils from "utils";
import * as configApp from "config/config-app";
import * as serviceStorage from "lib/service-storage";

import { getRegions, getMotelsRegions } from "lib/service-api";
import InputLength from "constant/InputLength";
import AppVisibleBadge from "components/AppVisibleBadge";

const GUIDE_NONE_MOTELS = "찾으시는 검색 결과가 없습니다.";
const TIME_INPUT = 500;
let SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";

const styles = (theme) => {
  if (!SERVICE_TYPE_PC) {
    // 모바일
    return {
      root: {
        "& .mobile-motels": {
          overflow: "hidden",
          overflowY: "auto",
          display: "block",
          height: `calc(100vh - 25rem)`,
          overflowScrolling: "touch",
          WebkitOverflowScrolling: "touch",
          padding: "0.25rem",
        },
        "& .mobile-motels.none": {
          overflowY: "hidden",
          display: "table",
          width: "100%",
          border: "none",
        },
        "& .mobile-motels .mobile-motels-item": {
          position: "relative",
          width: "100%",
          marginTop: "0.25rem",
          marginBottom: "0.25rem",
        },
        "& .mobile-motels .formControl": {
          width: "100%",
          marginTop: "0px !important",
        },
        "& .mobile-motels .formControlLabel": {
          borderBottom: "1px solid #f5f6f8",
          marginRight: "0px",
        },
      },
      adornedEndCss: {
        padding: "0",
      },
    };
  }

  return {
    root: {
      "& .master-motels": {
        overflow: "hidden",
        overflowY: "auto",
        display: "block",
        height: `calc(100vh - 30rem)`,
        padding: "0.25rem",
      },
      "& .master-motels.none": {
        overflowY: "hidden",
        display: "table",
        width: "100%",
        border: "none",
      },
      "& .master-motels .master-motels-item": {
        position: "relative",
        display: "block",
        marginTop: "0.25rem",
        marginBottom: "0.25rem",
        width: "488px",
      },
      "& .master-motels .formControl": {
        marginTop: "0px !important",
      },
      "& .master-motels .formControlLabel": {
        minWidth: "50%",
        marginLeft: "0px !important",
        marginRight: "0px !important",
      },
    },
    adornedEndCss: {
      padding: "0",
    },
  };
};

const INIT_FIELD_STATE = {
  currentRegions: "ALL",
  currentMotelsRegions: "NONE",
  motelsRegions: [],
  selectedKey: "",
  isShowSearchCancel: false,
  compMotels: [],
};

/**
 * 마스터 권한 전용 다중 숙소 선택 Template Component 입니다.<p/>
 *
 * @component
 * @name MotelSelectMasterTemplate
 * @author Taesung Park <pts@pineone.com>
 */
function MotelSelectMasterTemplate(props) {
  ////////////////////////////////////////////////////
  // 1. 선언
  ////////////////////////////////////////////////////
  const {
    classes,
    onSubmit,
    onOpenPopup,
    onCancel,
    onGotoAlliancePage,
    motels,
  } = props;

  const [state, setState] = useState({
    motels: motels,
    regions: [],
    // isFocus: false,
    isHiddenGuideText: true,
    ...INIT_FIELD_STATE,
  });

  const {
    compMotels,
    selectedKey,
    currentRegions,
    regions,
    currentMotelsRegions,
    motelsRegions,
    isShowSearchCancel,
    isHiddenGuideText,
  } = state;

  const isDisableRegions = 1 > regions.length;
  const isDisableMotelsRegions = 1 > motelsRegions.length;

  // 비동기 코드가 많아 Functional 함수의 생성, 소멸에 대한 체크가 필요함
  const stateActive = useRef(true);
  const refScrollTarget = useRef();
  const refTextField = useRef();
  const timerInput = useRef();
  // const validInput = useRef(false);

  ////////////////////////////////////////////////////
  // 2. 핸들러
  ////////////////////////////////////////////////////

  const handleBeforeOnSubmit = (event) => {
    !!event.preventDefault && event.preventDefault();
    if (!!state.selectedKey) {
      onSubmit({ selectedKey: state.selectedKey });
    } else {
      onOpenPopup("로그인하실 제휴점을 선택해주세요.");
    }
  };

  const handleOnChange = (event) => {
    !!event.preventDefault && event.preventDefault();
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleOnChangeRegions = useCallback((event) => {
    !!event.preventDefault && event.preventDefault();
    const { name, value } = event.target;

    if (value === "ALL") {
      setState((state) => {
        return {
          ...state,
          ...INIT_FIELD_STATE,
        };
      });
      return;
    }

    setState((state) => {
      let findRegions = state.regions.filter((region) => {
        return region.code === value;
      });

      if (0 < findRegions.length) {
        return {
          ...state,
          motelsRegions: findRegions[0].sub_regions,
          currentMotelsRegions: findRegions[0].sub_regions[0].code,
          [name]: value,
        };
      }

      return state;
    });
  }, []);

  const doClearTimerInput = useCallback(() => {
    !!timerInput.current && clearTimeout(timerInput.current);
    timerInput.current = null;
  }, []);

  const isActiveTimerInput = useCallback(() => {
    return !!timerInput.current;
  }, []);

  const handleOnChangeMotelsRegions = useCallback((event) => {
    !!event.preventDefault && event.preventDefault();
    const { value } = event.target;
    setState((state) => {
      return {
        ...state,
        currentMotelsRegions: value,
      };
    });
  }, []);

  const doRenderMotelsRegions = useCallback((value) => {
    getMotelsRegions({
      region_code: value,
    })
      .then((response) => {
        if (!stateActive.current) return;
        let motels = (response.data.result.motels || []).map((motel) => {
          return {
            key: motel.key,
            name: motel.motel_extra.motel_name,
            isAppVisible: motel.isAppVisible,
          };
        });
        return Promise.resolve(motels);
      })
      .catch(() => {
        if (!stateActive.current) return;
        return Promise.resolve([]);
      })
      .then((motels) => {
        if (!!refTextField.current) refTextField.current.value = "";
        if (!!refScrollTarget.current) refScrollTarget.current.scrollTop = 0;
        setState((state) => {
          return {
            ...state,
            selectedKey: "",
            isShowSearchCancel: false,
            isHiddenGuideText: false,
            compMotels: makeCompMotels(
              motels,
              SERVICE_TYPE_PC ? "190px" : "100%"
            ),
          };
        });
      });
  }, []);

  useEffect(() => {
    if (currentMotelsRegions !== "NONE") {
      doRenderMotelsRegions(currentMotelsRegions);
    } else {
      setState((state) => {
        return {
          ...state,
          isHiddenGuideText: 1 > state.compMotels.length,
        };
      });
    }
  }, [currentMotelsRegions]);

  const getDisabled = useCallback((val) => {
    if (val) return { disabled: true };
    return {};
  }, []);

  // const handleOnKeyDownKeyword = useCallback((event) => {
  //   validInput.current = true;
  // }, []);

  // const handleOnKeyUpKeyword = useCallback((event) => {
  //   validInput.current = false;
  // }, []);

  const handleOnChangeKeyword = useCallback((event) => {
    !!event.preventDefault && event.preventDefault();
    doClearTimerInput();

    // 20210226 박태성C
    // 한글 입력 중 [삭제] 버튼을 누르면 handleOnChangeKeyword 이벤트가 중복 호출 됨
    // onKeyDown, onKeyUp 사이에 발생 되는 onChange 이벤트만 유효하다고 판단하여 처리함

    /**
     * finn ( 20240522 )
     * 일단 [삭제] 버튼을 누를 때 handleOnChangeKeyword 이벤트가 호출 되지 않음
     * 그래서 keyDown, keyUp 이벤트를 사용하여 validInput.current = true, false 처리를 하지 않아도 됨
     * 주석 처리
     * */
    // if (!validInput.current) return;

    const value = event.target.value || "";

    if (!!value) {
      timerInput.current = setTimeout(() => {
        doClearTimerInput();

        if (!stateActive.current) return;

        setState((state) => {
          // if (state.isFocus === true) {
          return {
            ...state,
            ...INIT_FIELD_STATE,
            isShowSearchCancel: 0 < value.length,
            compMotels: makeCompMotels(
              state.motels.filter((motel) => {
                const name = motel.name || "";
                const lowerName = name.toLowerCase();
                const lowerValue = value.toLowerCase();
                return lowerName.includes(lowerValue);
              }),
              SERVICE_TYPE_PC ? "190px" : "100%"
            ),
            isHiddenGuideText: false,
          };
          // }
          // return state;
        });
      }, TIME_INPUT);
    } else {
      setState((state) => {
        // if (state.isFocus === true) {
        if (0 < state.compMotels.length || !!state.isShowSearchCancel) {
          return {
            ...state,
            ...INIT_FIELD_STATE,
            isHiddenGuideText: true,
          };
        }
        // }
        return state;
      });
    }
  }, []);

  const handleOnChangeInputKeyword = useCallback((event) => {
    !!event.preventDefault && event.preventDefault();
    doClearTimerInput();
    const value = event.target.value || "";
    setState((state) => {
      return {
        ...state,
        isShowSearchCancel: 0 < value.length,
      };
    });
  }, []);

  const handleOnClickSearchCancel = (event) => {
    !!event.preventDefault && event.preventDefault();
    doClearTimerInput();

    if (!!refTextField.current && !!refTextField.current.value.trim()) {
      refTextField.current.value = "";

      setState({
        ...state,
        ...INIT_FIELD_STATE,
        isHiddenGuideText: true,
      });
    }

    if (
      document &&
      document.activeElement &&
      document.activeElement.tagName !== "BODY"
    ) {
      document.activeElement.blur();
    }
  };

  const handleOnClickSearch = (event) => {
    !!event && !!event.preventDefault && event.preventDefault();
    const value = refTextField.current.value || "";
    setState({
      ...state,
      ...INIT_FIELD_STATE,
      isShowSearchCancel: 0 < value.length,
      isHiddenGuideText: 1 > value.length,
      compMotels: !!value.trim()
        ? makeCompMotels(
            state.motels.filter((motel) => {
              const lowerName = motel.name.toLowerCase();
              const lowerValue = value.toLowerCase();
              return lowerName.includes(lowerValue);
            }),
            SERVICE_TYPE_PC ? "190px" : "100%"
          )
        : [],
    });

    if (
      document &&
      document.activeElement &&
      document.activeElement.tagName !== "BODY"
    ) {
      document.activeElement.blur();
    }
  };

  const handleOnFocusKeyword = (event) => {
    doClearTimerInput();
    // setState({ ...state, isFocus: true });
  };

  const handleOnBlurKeyword = (event) => {
    const isActvieTimer = isActiveTimerInput();
    doClearTimerInput();
    // setState({ ...state, isFocus: false });
    !!isActvieTimer && handleOnClickSearch(null);
  };

  ////////////////////////////////////////////////////
  // 3. 구동 및 업데이트 처리
  ////////////////////////////////////////////////////

  useEffect(() => {
    let identity = serviceStorage.getIdentity();
    const isOwner = serviceStorage.isOwner(identity);
    identity = null;
    let regions = [];
    let timer;
    getRegions()
      .then((response) => {
        if (!stateActive.current) return;
        regions = response.data.result.regions || [];
      })
      .finally(() => {
        if (!stateActive.current) return;

        timer = setTimeout(() => {
          clearTimeout(timer);
          timer = null;

          if (!stateActive.current) return;

          setState({
            ...state,
            regions,
            compMotels: [],
            isOwner,
          });

          regions = null;
        }, 0);
      });

    return () => {
      stateActive.current = false;

      !!timer && clearTimeout(timer);
      timer = null;

      doClearTimerInput();

      Object.keys(state).map((key) => {
        let item = state[key];
        if (!!item && utils.isArray(item) && 0 < item.length) {
          item.splice(0, item.length);
        }
        state[key] = null;
        item = null;
      });
    };
  }, []);

  return (
    <div className={classes.root}>
      {SERVICE_TYPE_PC && (
        <>
          <Box>
            <OutlinedInput
              placeholder="숙소명으로 찾아보세요."
              fullWidth
              variant="outlined"
              size="small"
              autoComplete="off"
              inputProps={{
                style: { padding: "14px" },
                maxLength: InputLength.stayName.max,
                autoCapitalize: "off",
                autoCorrect: "off",
              }}
              endAdornment={
                !!isShowSearchCancel ? (
                  <InputAdornment>
                    <IconButton onClick={handleOnClickSearchCancel}>
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <></>
                )
              }
              classes={{ adornedEnd: classes.adornedEndCss }}
              inputRef={refTextField}
              onFocus={handleOnFocusKeyword}
              onBlur={handleOnBlurKeyword}
              // onKeyDown={handleOnKeyDownKeyword}
              // onKeyUp={handleOnKeyUpKeyword}
              onChange={handleOnChangeKeyword}
            />
          </Box>
          <Box mt={1}>
            <FormControl
              variant="outlined"
              size="small"
              style={{ minWidth: "49%" }}
            >
              <Select
                value={currentRegions}
                onChange={handleOnChangeRegions}
                name="currentRegions"
                {...getDisabled(isDisableRegions)}
              >
                <MenuItem value={"ALL"}>선택</MenuItem>
                {!!regions &&
                  regions
                    .filter((region) => {
                      return (
                        region.has_motel_yn === "Y" &&
                        !!region.sub_regions &&
                        0 < region.sub_regions.length
                      );
                    })
                    .map((region, index) => (
                      <MenuItem key={index} value={region.code}>
                        {region.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              style={{
                marginLeft: "2%",
                minWidth: "49%",
                maxWidth: "239px",
              }}
            >
              <Select
                value={currentMotelsRegions}
                onChange={handleOnChangeMotelsRegions}
                name="currentMotelsRegions"
                {...getDisabled(isDisableMotelsRegions)}
              >
                {isDisableMotelsRegions && <MenuItem value={"NONE"}></MenuItem>}
                {!isDisableMotelsRegions &&
                  motelsRegions.map((motel, index) => (
                    <MenuItem key={index} value={motel.code}>
                      {motel.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            mt={1}
            className={`master-motels ${1 > compMotels.length ? "none" : ""}`}
            ref={refScrollTarget}
          >
            {0 < compMotels.length && (
              <FormControl component="fieldset" className="formControl">
                <RadioGroup
                  name="selectedKey"
                  value={selectedKey}
                  onChange={handleOnChange}
                  className="master-motels-item"
                >
                  {compMotels}
                </RadioGroup>
              </FormControl>
            )}
            {!isHiddenGuideText && 1 > compMotels.length && (
              <GuideText
                isMobile={!SERVICE_TYPE_PC}
                description={GUIDE_NONE_MOTELS}
              />
            )}
          </Box>
          <ButtonGroupConfirm
            mt={1}
            mb={0}
            fullWidth={true}
            onSubmitByClick={handleBeforeOnSubmit}
            onCancel={onCancel}
          />
        </>
      )}

      {!SERVICE_TYPE_PC && (
        <>
          <Box>
            <OutlinedInput
              placeholder="숙소명으로 찾아보세요."
              fullWidth
              variant="outlined"
              size="small"
              autoComplete="off"
              inputProps={{
                style: { padding: "14px" },
                maxLength: InputLength.stayName.max,
                autoCapitalize: "off",
                autoCorrect: "off",
              }}
              endAdornment={
                <InputAdornment>
                  {!!isShowSearchCancel && (
                    <IconButton onClick={handleOnClickSearchCancel}>
                      <CancelIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleOnClickSearch} position="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              classes={{ adornedEnd: classes.adornedEndCss }}
              inputRef={refTextField}
              onChange={handleOnChangeInputKeyword}
            />
          </Box>
          <Box mt={1}>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select
                value={currentRegions}
                onChange={handleOnChangeRegions}
                name="currentRegions"
                {...getDisabled(isDisableRegions)}
              >
                <MenuItem value={"ALL"}>선택</MenuItem>
                {!!regions &&
                  regions
                    .filter((region) => {
                      return (
                        region.has_motel_yn === "Y" &&
                        !!region.sub_regions &&
                        0 < region.sub_regions.length
                      );
                    })
                    .map((region, index) => (
                      <MenuItem key={index} value={region.code}>
                        {region.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Box>
          <Box mt={1}>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select
                value={currentMotelsRegions}
                onChange={handleOnChangeMotelsRegions}
                name="currentMotelsRegions"
                {...getDisabled(isDisableMotelsRegions)}
              >
                {isDisableMotelsRegions && <MenuItem value={"NONE"}></MenuItem>}
                {!isDisableMotelsRegions &&
                  motelsRegions.map((motel, index) => (
                    <MenuItem key={index} value={motel.code}>
                      {motel.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            mt={1}
            className={`mobile-motels ${1 > compMotels.length ? "none" : ""}`}
            ref={refScrollTarget}
          >
            {0 < compMotels.length && (
              <FormControl component="fieldset" className="formControl">
                <RadioGroup
                  name="selectedKey"
                  value={selectedKey}
                  onChange={handleOnChange}
                  className="mobile-motels-item"
                >
                  {compMotels}
                </RadioGroup>
              </FormControl>
            )}
            {!isHiddenGuideText && 1 > compMotels.length && (
              <GuideText
                isMobile={!SERVICE_TYPE_PC}
                description={GUIDE_NONE_MOTELS}
              />
            )}
          </Box>
          <Box
            mt={0}
            mb={0}
            style={{
              bottom: 0,
              marginBottom: "0.5rem",
            }}
          >
            <ButtonSubmit
              mt={1}
              mb={0}
              lblSubmit={"확인"}
              onSubmitByClick={handleBeforeOnSubmit}
              fullWidth={true}
            />
          </Box>
        </>
      )}
    </div>
  );
}

// 초기 props 설정
MotelSelectMasterTemplate.defaultProps = {
  onSubmit: function () {},
  onOpenPopup: function () {},
  onCancel: function () {},
  onGotoAlliancePage: function () {},
  motels: [],
};

export default withTheme(withStyles(styles)(MotelSelectMasterTemplate));

/**
 * [업주PC/M-웹마스터 > 제휴점선택/변경] 제휴점 아이템 컴포넌트
 * @see MotelSelectMasterTemplate 로그인 후 제휴점 선택 화면
 * @see PopupMotelSelectMaster 제휴점 변경 팝업
 * @param {Array} motels 제휴점 정보 배열
 * @param {Number} motels[].key 제휴점 키
 * @param {String} motels[].name 제휴점명
 * @param {String} motels[].app_visible_yn 제휴점 노출/비노출 여부
 * @param {Number} labelWidth 라벨 너비
 * @param {Boolean} useEllipsis 라벨 텍스트 줄임 처리 여부
 */
export function makeCompMotels(motels, labelWidth, useEllipsis = false) {
  const badgeWidth = 70; // 뱃지 너비
  return motels.map((motel, index) => {
    const { key, name = "", isAppVisible = false } = motel;
    return (
      <FormControlLabel
        key={index}
        control={<Radio color="primary" />}
        value={"" + key}
        label={
          <div
            style={{
              width: labelWidth,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* 제휴점명 */}
            <p
              style={{
                fontSize: "14px",
                margin: 0,
                alignSelf: "center",
                // Ellipsis 처리
                width: useEllipsis ? `calc(100% - 70px)` : "unset",
                overflow: useEllipsis ? "hidden" : "unset",
                textOverflow: useEllipsis ? "ellipsis" : "unset",
                whiteSpace: useEllipsis ? "nowrap" : "unset",
              }}
            >
              {name}
            </p>

            {/* 제휴점 노출/비노출 뱃지 */}
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <AppVisibleBadge
                visibleYn={isAppVisible}
                style={{ width: `${badgeWidth}px`, fontSize: "12px" }}
              />
            </span>
          </div>
        }
        className="formControlLabel"
      />
    );
  });
}

function GuideText({ isMobile = false, description = "" }) {
  return (
    <div
      style={{
        whiteSpace: "pre-line",
        verticalAlign: "middle",
        display: "table-cell",
        textAlign: "center",
      }}
    >
      {isMobile ? (
        <p style={{ fontSize: "13px", fontWeight: "bold" }}>{description}</p>
      ) : (
        <p style={{ fontWeight: "bold" }}>{description}</p>
      )}
    </div>
  );
}
