/**
 * 앱에서 기본으로 사용 되는 Reducer를 정의 한 모듈 입니다
 *
 * @author Taesung Park <pts@pineone.com>
 * @module system/reducers
 * @see module:system/redux-ducks/authentication
 * @see module:system/redux-ducks/breadcrumb
 * @see module:system/redux-ducks/loading
 * @see module:lib/service-redux-alerts
 */

import { combineReducers } from "redux";

import scheduler from "../redux-ducks/scheduler";
import authentication from "../redux-ducks/authentication";
import breadcrumb from "../redux-ducks/breadcrumb";
import loading from "../redux-ducks/loading";
import rsa from "../redux-ducks/rsa";
import sales from "../redux-ducks/sales";
import headerBadge from "../redux-ducks/headerBadge";
import rooms from "../redux-ducks/rooms";
import { reducer as alerts } from "lib/service-redux-alerts";

export default combineReducers({
  scheduler,
  authentication,
  breadcrumb,
  loading,
  alerts,
  rsa,
  sales,
  headerBadge,
  rooms
});
