import React from "react";

import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";

import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";


const styles = (theme) => ({
  adornedEndCss: {
    padding: "0",
    width: "100%",
    "& input::placeholder": {
      fontSize: "13px",
    },
  },
  iconWrap: {
    width: "50px",
  },
});
/**
 * @author TaeWook Yang <didxodnr0769@pineone.com>
 *
 * 2020.12.11 변경사항
 *  1. 파일명 변경
 *   - BoardOutlinedInput => SearchOutlinedInput
 *  2. X버튼 표출 여부와 X버튼 클릭 이벤트는 공통 기능이므로
 *     현재 컴포넌트에서 공통으로 처리
 *     => inputRef를 props로 받아줘야함
 *  3. 엔터키 작동을 위하여 <form>태그 추가
 */

class SearchOutlinedInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelBtn: false,
    };
  }

  /** 알림톡, 검색을 통하여 들어왔을 때 x버튼 활성화,비활성화 처리 */
  componentDidUpdate() {
    if (!this.props.inputRef) return false;
    const valueLength = this.props.inputRef.current.value.length;
    const { cancelBtn } = this.state;
    if (valueLength !== 0 && !cancelBtn) this.trueCanecelBtn();
    else if (valueLength === 0 && cancelBtn) this.falseCanecelBtn();
  }
  /** 입력된 검색어 길이를 판단하여 X버튼 Active판단 */
  handleValueChange = (evt) => {
    let searchLength = evt.target.value.length;
    if (searchLength !== 0 && this.state.cancelBtn === false)
      this.trueCanecelBtn();
    else if (searchLength === 0) this.falseCanecelBtn();
  };

  /** 검색어 초기화 버튼 클릭 이벤트 */
  handleSearchReset = () => {
    if (!!this.props.inputRef) this.props.inputRef.current.value = null;
    this.trueCanecelBtn();
  };
  /** X버튼 활성 */
  trueCanecelBtn = () => {
    this.setState({
      cancelBtn: true,
    });
  };
  /** X버튼 비활성 */
  falseCanecelBtn = () => {
    this.setState({
      cancelBtn: false,
    });
  };

  /** 검색 버튼 클릭 이벤트  */
  handelSubmitClick = (e) => {
    !!this.props.onSearch && this.props.onSearch(e);
    e.preventDefault();
  };
  render() {
    const { inputRef, classes, placeholder, inputProps } = this.props;
    const { cancelBtn } = this.state;
    return (
      <form>
        <OutlinedInput
          onChange={this.handleValueChange}
          fullWidth
          inputRef={inputRef}
          size="small"
          variant="outlined"
          placeholder={placeholder || "검색어 입력"}
          classes={{ adornedEnd: classes.adornedEndCss }}
          inputProps={
            inputProps || {
              style: {
                height: "52px",
                padding: "0 14px",
                maxWidth: "200px",
              },
            }
          }
          endAdornment={
            <InputAdornment>
              <Box className={classes.iconWrap}>
                {cancelBtn && (
                  <IconButton onClick={this.handleSearchReset}>
                    <CancelIcon />
                  </IconButton>
                )}
              </Box>
              <Box className={classes.iconWrap}>
                <IconButton
                  type="submit"
                  onClick={this.handelSubmitClick}
                  value="submit"
                >
                  <SearchIcon />
                </IconButton>
              </Box>
            </InputAdornment>
          }
        />
      </form>
    );
  }
}

const styledBoardOutlinedInput = withTheme(
  withStyles(styles)(SearchOutlinedInput)
);

export { styledBoardOutlinedInput as SearchOutlinedInput };
