import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import * as configApp from "config/config-app";
let SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";

const styles = (theme) => {
  return {
    displayBlock: {
      display: "block !important",
    },
    textCenter: {
      textAlign: "center",
    },
    submitOnPC: {
      width: "30%",
    },
    cancelOnPC: {
      width: "30%",
      marginLeft: "5%",
    },
    submitOnMobile: {
      width: "40%",
    },
    cancelOnMobile: {
      width: "40%",
      marginLeft: "5%",
    },
    submitFull: {
      width: "47%",
    },
    cancelFull: {
      width: "47%",
      marginLeft: "6%",
    },
    boxStyle: {
      display: "block !important",
      textAlign: "center",
    },
  };
};

/**
 * 확인/취소 Functional Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name ButtonsConfirm
 * @component
 */
function ButtonGroupConfirm(props) {
  const {
    classes,
    fullWidth,
    lblSubmit,
    lblCancel,
    noop,
    onSubmitByClick,
    onCancel,
    mt,
    mb,
  } = props;
  const isSubmitByClick = !!onSubmitByClick;
  const isCancelByClick = !!onCancel;
  const isFullWidth = !!fullWidth;
  return (
    <Box
      mt={!isNaN(mt) ? mt : 6}
      mb={!isNaN(mb) ? mb : 3}
      className={classes.boxStyle}
    >
      <Button
        type={!isSubmitByClick ? "submit" : "button"}
        variant="contained"
        color="primary"
        onClick={!isSubmitByClick ? noop : onSubmitByClick}
        className={
          !isFullWidth
            ? SERVICE_TYPE_PC
              ? classes.submitOnPC
              : classes.submitOnMobile
            : classes.submitFull
        }
      >
        <strong>{lblSubmit}</strong>
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={!isCancelByClick ? noop : onCancel}
        className={
          !isFullWidth
            ? SERVICE_TYPE_PC
              ? classes.cancelOnPC
              : classes.cancelOnMobile
            : classes.cancelFull
        }
      >
        <strong>{lblCancel}</strong>
      </Button>
    </Box>
  );
}

// 초기 props 설정
ButtonGroupConfirm.defaultProps = {
  noop: function () {},
  onSubmitByClick: null,
  onCancel: null,
  lblSubmit: "확인",
  lblCancel: "취소",
};

export default withTheme(withStyles(styles)(ButtonGroupConfirm));
