import { Typography } from "@material-ui/core";
import React from "react";

/**
 *
 * @param {String} content
 * @author TaeWook Yang <didxodnr0769@pineone.com>
 * @description  react-data-table의 데이터가 없는경우 표출할 컴포넌트입니다.
 * @see CouponListPageLayout.js
 *
 */
export default function NoDataComponent(props) {
  const { content } = props;
  return (
    <Typography style={{ margin: "50px" }}>
      <strong>{content}</strong>
    </Typography>
  );
}
