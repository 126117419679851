/**
 * 본 모듈은 해당 월의 첫날을 구하는 모듈입니다.
 *
 * @module utils/isEmpty
 */

/**
 * Date객체를 받아 해당 객체 월의 첫날을 구하여 반환합니다. - 양태욱
 *
 * @param {Date} date - 검사 할 값
 * @return {Date} Empty 여부
 */
export default function getThisMonthStartDay(date) {
  return new Date(`${date.getFullYear()}`, date.getMonth(), 1, 1, 1, 1, 1);
}
