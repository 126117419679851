/**
 * 팝업 String
 *
 * @version 0.0.1
 * @author <max5500@pineone.com>
 * @module constants/PopupConsts
 */

const POPUP_CONSTS_MOBILE = {
  NO_SEARCH_DATA: {
    title: null,
    desc: "찾으시는 자료가 없습니다.",
    button: null,
  },
  IMAGE_KIND: {
    title: null,
    desc: "이미지는 jpg, jpeg, png의 확장자만\n등록 가능합니다.",
    button: null,
  },
  FILE_KIND: {
    title: null,
    desc: "파일은 doc, xls, xlsx, zip, pdf의 확장자만\n등록 가능합니다.",
    button: null,
  },
  FILE_COUNT: {
    title: null,
    desc: "파일은 3개까지 업로드 가능합니다.",
    button: null,
  },
  FILE_SIZE: {
    title: null,
    desc: "파일 용량이 초과되었습니다.\n5MB이하로 업로드 해주세요",
    button: null,
  },
  LOGOUT: {
    title: null,
    desc: "로그아웃 하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  DIFFERENT_MOTEL_KEY: {
    title: null,
    desc: "잘못된 접근입니다.",
    button: null,
  },
  A1: {
    title: null,
    desc: "필수 사항은 기재해주셔야 합니다.",
    button: null,
  },
  A2: {
    title: null,
    desc: "비밀번호 5회 오류로 사용이 제한\n되었습니다. 비밀번호 재설정 후\n이용하세요.",
    button: null,
  },
  A3: {
    title: null,
    desc: "숫자만 입력하실 수 있습니다.",
    button: null,
  },
  A4: {
    title: null,
    desc: "이벤트명을 입력해 주세요.",
    button: null,
  },
  A5: {
    title: null,
    desc: "요일을 선택해주세요",
    button: null,
  },
  A6: {
    title: null,
    desc: "쿠폰을 선물할 고객을 선택해 주세요.",
    button: null,
  },
  A7: {
    title: null,
    desc: "베스트 후기로 선정되었습니다.",
    button: null,
  },
  A8: {
    title: null,
    desc: "최대 5명까지 등록하실 수 있습니다.",
    button: null,
  },
  A9: {
    title: null,
    desc: "사진 용량이 초과되었습니다.\n5MB 이하로 업로드 해주세요.",
    button: null,
  },
  A10: {
    title: null,
    desc: "파일 업로드 가능 수량은 10개까지 입니다.\n그 이상은 등록하실 수 없습니다.",
    button: null,
  },
  A21: {
    title: null,
    desc: "만실 설정되었습니다.",
    button: null,
  },
  A22: {
    title: null,
    desc: "설정 해제되었습니다.",
    button: null,
  },
  A23: {
    title: null,
    desc: "만차 설정되었습니다.",
    button: null,
  },
  A52: {
    title: null,
    desc: `오늘 숙박/대실 만실 시 당일 판매가 마감됩니다.
    오늘 주차장 만차 시 고객이 차량 방문으로 예약 불가하며, 도보로만 예약 가능합니다.
    
      ※ 만실/만차 설정은 새벽 3시를 기준으로 초기화됩니다.`,
    button: "닫기",
  },
  A53: {
    title: null,
    desc: "현장결제 시범서비스 기간입니다.\n현장결제 시범서비스가 종료되면 현장결제 기능이 제공되지 않을 수 있습니다.",
    button: "닫기",
  },
  A54: {
    title: null,
    desc: "•빠른 설정을 위해 선택한 일자의 판매 정보를 \n불러와 자동완성이 가능합니다. \n•선택한 일자에 판매 정보가 없는 상품은\n 자동완성 되지 않습니다. \n•자동완성 후 변경할 판매 정보는 없는지\n 반드시 확인 후 저장해 주세요.",
    button: "닫기",
  },
  A55: {
    title: null,
    desc: "쉼표(,) 앰퍼샌드(&) 를 제외한 검색어를 입력해 주세요.",
    button: null,
  },
  A56: {
    title: null,
    desc: "불러오기 시 해당 날짜의 <span style='color: #FF0000;'>숙박 정보</span>만\n이용자앱에 바로 적용됩니다.\n그래도 불러오기 하시겠습니까?",
    button: null,
  },
  A57: {
    title: null,
    desc: "불러오기 시 해당 날짜의 <span style='color: #FF0000;'>대실 정보</span>만\n이용자앱에 바로 적용됩니다.\n그래도 불러오기 하시겠습니까?",
    button: null,
  },
  C1: {
    title: null,
    desc: "전 객실을 숙박 마감하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C2: {
    title: null,
    desc: "주차장 만차로 설정하시겠습니까?\n고객이 차량 방문으로 예약할 수 없으며, 도보로만 예약 가능합니다. 설정하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C3: {
    title: null,
    desc: "판매 마감 상태로 적용하시겠습니까?\n선택된 날짜의 상품을 모두 판매 마감 상태로 적용하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C4: {
    title: null,
    desc: "판매 상태로 적용하시겠습니까?\n선택된 날짜의 상품을 모두 판매 상태로 적용하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C5: {
    title: null,
    desc: "설정을 취소하시겠습니까?\n설정 내용은 적용되지 않습니다.",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C6: {
    title: null,
    desc: "정말 삭제하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C7: {
    title: null,
    desc: "설정한 값으로 적용하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C8: {
    title: null,
    desc: "위 내용으로 저장하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C9: {
    title: null,
    desc: "주차장 만차를 해제하시겠습니까?\n고객은 차량 방문으로 예약할 수 있습니다.",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C10: {
    title: null,
    desc: "전 객실을 숙박 판매하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C30: {
    title: null,
    desc: "전 객실을 대실 마감하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C31: {
    title: null,
    desc: "전 객실을 대실 판매하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  }
};

export default POPUP_CONSTS_MOBILE;
