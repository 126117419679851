import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { BoxWrap } from "components";
import { COUPON_SUMMARY_ITEM_TYPE_LIST } from "lib/helper-coupon";
import HelpIcon from "@material-ui/icons/Help";
// import commaFormater from "utils/commaFormater"; //11.23 양태욱추가

const styles = (theme) => ({
  box: {
    marginTop: "0",
  },
  table: {
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    "& th": {
      background: "#f8f8f9",
      fontWeight: "bold",
    },
    "& td": {
      border: "1px solid #ddd",
    },
  },
});

/**
 * 오늘 선착순 쿠폰 현황 Template Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name CouponStatusTemplate
 * @component
 */
class CouponStatusTemplate extends React.PureComponent {
  render() {
    const HELP_TEXT = `템플릿 변경 전 미리예약 시 사용된 쿠폰이 포함될 수 있으므로,\n발급 수보다 사용 수가 많을 수 있습니다.`;
    const { classes, model } = this.props;

    return (
      <BoxWrap title={"오늘 선착순 쿠폰 현황"}>
        <Box mt={2} className={classes.box}>
          <TableContainer component={Paper} style={{ boxShadow: "none" }}>
            <Table style={{ tableLayout: "fixed" }} className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" width={160}>
                    <strong>구분</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>발급</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>사용 </strong>
                    {/* <IconTooltip tooltipText={HELP_TEXT} /> */}
                    <span
                      coupon-tooltip-text={HELP_TEXT}
                      style={{
                        color: "#9F9F9F",
                        position: "absolute",
                        top: "69px",
                      }}
                    >
                      <HelpIcon style={{ fontSize: "16px" }} />
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <strong>잔여</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                <TableRow>
                  <TableCell align="center">숙박 쿠폰</TableCell>
                  <TableCell align="center">
                    {model?.stayTotalCount || 0}
                  </TableCell>
                  <TableCell align="center">
                    {model?.stayUseCount || 0}
                  </TableCell>
                  <TableCell align="center">
                    {model?.stayRemainCount || 0}
                  </TableCell>
                </TableRow>

                  <TableRow>
                      <TableCell align="center">대실 쿠폰</TableCell>
                      <TableCell align="center">
                          {model?.timeTotalCount || 0}
                      </TableCell>
                      <TableCell align="center">
                          {model?.timeUseCount || 0}
                      </TableCell>
                      <TableCell align="center">
                          {model?.timeRemainCount || 0}
                      </TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </BoxWrap>
    );
  }
}

// 초기 props 설정
CouponStatusTemplate.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default withTheme(withStyles(styles)(CouponStatusTemplate));
