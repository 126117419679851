/**
 * react-router-dom Lib.의 Browser history를 제공하는 모듈 입니다<p/>
 *
 * @module lib/service-history-browser
 * @see module:lib/provider-history
 */

import { createBrowserHistory } from "history";

import * as providerHistory from "lib/provider-history";
import * as configApp from "config/config-app";

// const TAG = "[lib/service-history-browser.js]";
// console.log(TAG, "Create");

export const history = createBrowserHistory({
  basename: configApp.BASE_NAME,
  getUserConfirmation: providerHistory.getUserConfirmation,
});
