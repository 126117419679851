import {Workbook} from "exceljs";
import { saveAs } from 'file-saver';
import {flattenObject} from "./ObjectHelper";
const excelSurtaxlDownload = async (headers, list, motelName, filename)=> {
    const workbook = new Workbook();

    list.forEach((data, index) => {
        const date = data.date;

        const worksheet = workbook.addWorksheet(`${date.substring(0,4)}.${date.substring(4,6)}`);

        let currentCol = 1;
        let rowIndex = 1;
        let isChildHeaders = false;

        let headerCell = worksheet.getCell(rowIndex, currentCol);
        headerCell.value = `${date.substring(0,4)}년 ${date.substring(4,6)}월 ${motelName} 부가세 신고`
        Object.assign(headerCell.style, convertToXlsxStyle({bold: true, border: true, alignment: { vertical: 'middle', horizontal: 'center' },  font: {'size': 30 }}));
        worksheet.mergeCells(rowIndex, currentCol, rowIndex, currentCol += 5);

        currentCol+=6;

        headers.mainHeader.slice(0).forEach((header) => {
            const cell = worksheet.getCell(rowIndex, currentCol);
            cell.value = header.text;

            Object.assign(cell.style, convertToXlsxStyle(header.style));
            worksheet.mergeCells(rowIndex, currentCol, rowIndex + header.mergeDown -1, currentCol + header.mergeAcross - 1);
            worksheet.getColumn(currentCol).width = header.width;

            if (header.childHeaders) {
                isChildHeaders = true;
                header.childHeaders.forEach((childHeader, index) => {
                    const cell = worksheet.getCell(rowIndex + 1, currentCol + index);
                    cell.value = childHeader.text;
                    Object.assign(cell.style, convertToXlsxStyle(header.style));
                    worksheet.getColumn(currentCol + index).width = childHeader.width;
                });
            }

            currentCol += header.mergeAcross;
        });

        rowIndex+=1;
        currentCol = 1;
        //
        headerCell = worksheet.getCell(rowIndex, currentCol);
        headerCell.value = '매출액 신고'
        Object.assign(headerCell.style, convertToXlsxStyle({bold: true, border: true, alignment: { vertical: 'middle', horizontal: 'center' }}));
        worksheet.mergeCells('A2:C2');

        rowIndex+=1;
        headerCell = worksheet.getCell(rowIndex, currentCol);
        headerCell.value = '매입액 신고'
        Object.assign(headerCell.style, convertToXlsxStyle({bold: true, border: true, alignment: { vertical: 'middle', horizontal: 'center' }}));
        worksheet.mergeCells('A3:C3');
        rowIndex+=1;


        headers.subHeader.slice(0).forEach((header) => {
            const cell = worksheet.getCell(rowIndex, currentCol);
            cell.value = header.text;

            Object.assign(cell.style, convertToXlsxStyle(header.style));
            worksheet.mergeCells(rowIndex, currentCol, rowIndex + header.mergeDown -1, currentCol + header.mergeAcross - 1);
            worksheet.getColumn(currentCol).width = header.width;

            if (header.childHeaders) {
                isChildHeaders = true;
                header.childHeaders.forEach((childHeader, index) => {
                    const cell = worksheet.getCell(rowIndex + 1, currentCol + index);
                    cell.value = childHeader.text;
                    Object.assign(cell.style, convertToXlsxStyle(header.style));
                    worksheet.getColumn(currentCol + index).width = childHeader.width;
                });
            }

            currentCol += header.mergeAcross;

        });


        data.excelDetailInfos.forEach((rowData, index) => {
            const newRow = flattenObject(rowData);
            const excelRowIndex = index + (isChildHeaders ? rowIndex + 2 : rowIndex + 1);
            const row = worksheet.getRow(excelRowIndex);
            let cellIndex = 0;
            headers.subHeader.forEach((header) => {
                if (header.childHeaders) {
                    header.childHeaders.forEach((childHeader) => {
                        cellIndex += 1;
                        const cell = row.getCell(cellIndex);
                        cell.value = newRow[childHeader.id];
                        Object.assign(cell.style, convertToXlsxStyle({ border: true }));
                    });
                } else {
                    cellIndex += 1;
                    const headerKey = header.key;
                    if (header.key === 'index') {
                        row.getCell(cellIndex).value = index + 1;
                    } else {
                        row.getCell(cellIndex).value = newRow[headerKey];
                    }

                    Object.assign(row.getCell(cellIndex), convertToXlsxStyle({ border: true }));
                }
            });

            row.commit();
        });



        rowIndex += (isChildHeaders ?  2 :  1) + data.excelDetailInfos.length;
        const endDataRowIndex = rowIndex -1;
        currentCol = 1;

        // 합계
        let sumCell = worksheet.getCell(rowIndex, currentCol);
        sumCell.value = `${date.substring(4,6)}월 합계`
        Object.assign(sumCell.style, convertToXlsxStyle({bold: true, border: true, bgColor: '#228B22', alignment: { vertical: 'middle', horizontal: 'center' },  font: {'size': 30 }}));
        worksheet.mergeCells(rowIndex, currentCol, rowIndex, currentCol += 5);

        addSumFormula(worksheet, 7, 17, 6, endDataRowIndex);

        // mainHeader 합계
        sumCell = worksheet.getCell('L2');
        sumCell.value = data.coolstayUsePrice;
        Object.assign(sumCell.style, convertToXlsxStyle({bold: true, border: true, alignment: { vertical: 'middle', horizontal: 'center' },  font: {'size': 30 }}));

        sumCell = worksheet.getCell('M2');
        sumCell.value = data.residuePrice;
        Object.assign(sumCell.style, convertToXlsxStyle({bold: true, border: true, alignment: { vertical: 'middle', horizontal: 'center' },  font: {'size': 30 }}));

        sumCell = worksheet.getCell('N2');
        sumCell.value = data.promotionPrice;
        Object.assign(sumCell.style, convertToXlsxStyle({bold: true, border: true, alignment: { vertical: 'middle', horizontal: 'center' },  font: {'size': 30 }}));

        sumCell = worksheet.getCell('O2');
        sumCell.value = data.salesPrice;
        Object.assign(sumCell.style, convertToXlsxStyle({bold: true, border: true, alignment: { vertical: 'middle', horizontal: 'center' },  font: {'size': 30 }}));

        sumCell = worksheet.getCell('P2');
        sumCell.value = data.purchasePrice;
        Object.assign(sumCell.style, convertToXlsxStyle({bold: true, border: true, alignment: { vertical: 'middle', horizontal: 'center' },  font: {'size': 30 }}));

        sumCell = worksheet.getCell('D2');
        sumCell.value = { formula: `O2`  };
        Object.assign(sumCell.style, convertToXlsxStyle({bold: true, border: true, alignment: { vertical: 'middle', horizontal: 'center' },  font: {'size': 30 }}));
        worksheet.mergeCells('D2:F2');

        sumCell = worksheet.getCell('D3');
        sumCell.value = { formula: `P2`  };
        Object.assign(sumCell.style, convertToXlsxStyle({bold: true, border: true, alignment: { vertical: 'middle', horizontal: 'center' },  font: {'size': 30 }}));
        worksheet.mergeCells('D3:F3');
    })

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename ? `${filename}.xlsx` : 'download.xlsx');
};

function getColumnLetter(colIndex) {
    const charCodeA = 'A'.charCodeAt(0);
    const alphabetSize = 26;

    let dividend = colIndex;
    let columnName = '';

    while (dividend > 0) {
        const modulo = (dividend - 1) % alphabetSize;
        columnName = String.fromCharCode(charCodeA + modulo) + columnName;
        dividend = Math.floor((dividend - modulo) / alphabetSize);
    }

    return columnName;
}

// SUM 공식을 추가하는 함수
function addSumFormula(worksheet, startCol, endCol, startRow, endRow) {
    for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
        if(colIndex === 7 || colIndex === 14) {
            Object.assign(worksheet.getCell(endRow+1, colIndex).style, convertToXlsxStyle({bold: true, border: true, bgColor: '#228B22', alignment: { vertical: 'middle', horizontal: 'center' },  font: {'size': 30 }}));
            continue;
        }
        const colLetter = getColumnLetter(colIndex);
        const sumFormula = `IF(SUM(${colLetter}${startRow}:${colLetter}${endRow})=0,"-",SUM(${colLetter}${startRow}:${colLetter}${endRow}))`;
        const cell = worksheet.getCell(endRow+1, colIndex);
        cell.value = { formula: sumFormula };
        Object.assign(cell.style, convertToXlsxStyle({bold: true, border: true, bgColor: '#228B22', alignment: { vertical: 'middle', horizontal: 'center' },  font: {'size': 30 }}));
    }
}



const convertToXlsxStyle = (style) => {
    const xlsxStyle = {};

    if (style.bold) {
        xlsxStyle.font = { bold: true };
    }

    if (style.bgColor) {
        const color = style.bgColor.replace('#', '');
        xlsxStyle.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF' + color }
        };
    }

    if (style.border) {
        xlsxStyle.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    }

    xlsxStyle.alignment = { vertical: 'middle', horizontal: 'center' };

    return xlsxStyle;
};

export default excelSurtaxlDownload;