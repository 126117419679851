import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

/**
 * 물음표 모양의 툴팁 Component 입니다.<p/>
 *
 * @author Drst3in <drst3in9196@gmail.com>
 * @name QuestionTooltip
 * @class
 * @component
 */

const styles = (theme) => ({
  Tooltip: {
    fontSize: "0.92em",
  },
});

class QuestionTooltip extends Component {
  constructor(props) {
    super(props);
    console.log("QuestionTooltip component ; ", props);

    this.state = {
      error: false,
    };
  }

  render() {
    const { classes, title = "" } = this.props;

    return (
      <Tooltip title={title} arrow classes={{ tooltip: classes.Tooltip }}>
        <IconButton style={{ color: "#f34788" }}>
          <HelpIcon />
        </IconButton>
      </Tooltip>
    );
  }
}

const styledQuestionTooltip = withStyles(styles)(QuestionTooltip);

export { styledQuestionTooltip as QuestionTooltip };
