import React, { Component } from "react";
import { history } from "lib/history";

import { withStyles } from "@material-ui/core/styles";
import { Box, Divider, Tabs, Tab } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

/**
 * ContainerWrap Component를 변경하여 상단 Tab을 Fixed한 Component 입니다.<p/>
 *
 * @author TaeWook Yang <didxodnr0769@pineone.com>
 * @name TabFixedContainerWrap
 * @class
 * @component
 * @see MileagePageMobileLayout
 * @see QuestionPageMobileLayout
 */

const styles = (theme) => ({
  container: {
    background: "#fff",
  },
  headerContainer: {
    top: 0,
    left: "auto",
    right: 0,
    position: "sticky",
    background: "#fff",
    zIndex: 2,
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "0% 5% 3%",
  },
  title: {
    "& h3": {
      padding: "0",
      margin: "0",
    },
  },
});

class TabFixedContainerWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }
  defaultGotoBack = () => {
    const { pathname } = this.props;
    if (!!pathname) {
      history.push(pathname);
    } else {
      history.goBack(-1);
    }
  };
  handleChange = (event, newValue) => {
    //탭 변경 메서드
    this.setState({ tabIndex: newValue });
  };
  render() {
    const {
      classes,
      title,
      onBack,
      children,
      tabChange,
      tabIndex,
      tab1,
      tab2,
    } = this.props;

    return (
      <Box
        px={0}
        className={classes.container}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box className={classes.headerContainer}>
          <Box display="flex" mt={2} mb={1}>
            <Box
              onClick={!!onBack ? onBack : this.defaultGotoBack}
              style={{ paddingTop: "1px" }}
              ml={1}
            >
              <strong>
                <ArrowBackIcon />
              </strong>
            </Box>
            <Box ml={2} className={classes.title}>
              <strong style={{ fontSize: "17px", margin: 0 }}>{title}</strong>
            </Box>
          </Box>
          <Divider />
          <Box className={classes.tabsContainer}>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              centered
              variant="fullWidth"
              onChange={tabChange}
              style={{ width: "100%" }}
            >
              <Tab value={0} className={"tab"} label={tab1} />
              <Tab value={1} className={"tab"} label={tab2} />
            </Tabs>
          </Box>
        </Box>
        {!!children && (
          <Box
            id="container-wrap-contents"
            flex={1}
            style={{ margin: "0% 5% 3% 5%" }}
          >
            {children}
          </Box>
        )}
      </Box>
    );
  }
}
const styledContainerWrap = withStyles(styles)(TabFixedContainerWrap);

export { styledContainerWrap as TabFixedContainerWrap };
