/**
 * 본 모듈은 String형의 콤마가 포함된 숫자를 받아 숫자를 제거 후 Number형식 반환
 *1
 * @module utils/commaRemover
 */

/**
 * 문자열을 검사하여 한국어 여부를 반환 합니다
 *
 * @param {String}} num - 문자열
 * @return {Number} 숫자
 */
export default function commaRemover(numStr) {
  if (!numStr) return;
  return parseInt(String(numStr).replace(/,/g, ""));
}
