import { differenceInDays, isBefore } from "date-fns";
import format from "date-fns/format";
import { AMOUNT_CODE, RATE_CODE } from "lib/helper-fee";
import phoneFormatter from "utils/phoneFormatter";

/**
 * @see ServiceTemplate,ServiceMobileTemplate
 * @description 업주 PC + MOBILE 홈 화면 > 서비스 이용현황에서 사용하는 Util입니다.
 */

const RATE_HELPER = "※ 정률 요금제는 판매가에 따라 요금이 결정됩니다.";
const AMOUNT_HELPER =
  "※ 정액 요금제는 월 누적 판매수에 따라 추가 요금이 결정됩니다.";

/* PC + MOBILE Start*/

/** 담당 컨설턴트 정보 계산 */
const getConsultantInfo = (serviceData) => {
  const { consultant_name, consultant_phone_number } = serviceData;
  if (!serviceData || !consultant_name || !consultant_phone_number) return "";

  return `${consultant_name} ${phoneFormatter(consultant_phone_number)}`;
};

/** 도움말 문구 */
const getServiceHelperText = (code) => {
  return code === RATE_CODE
    ? RATE_HELPER
    : code === AMOUNT_CODE
    ? AMOUNT_HELPER
    : "";
};

export { getConsultantInfo, getServiceHelperText };
