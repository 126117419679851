import React, { useState, useRef } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  makeStyles,
  Popover,
  TextField,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import { openAlertPopupWithPromise } from "lib/helper-popup";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 0,
    display: "inline",
  },
  selectDiv: {
    display: "inline-block",
    backgroundColor: "#fff",
  },
  selectWrap: {
    height: "154px",
    padding: "2px",
  },
  selectContent: {
    width: "85px",
    height: "150px",
    outlineColor: palette.primary.main,
  },
  selectTitle: {
    minHeight: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
  },
  defaultInput: {
    fontSize: "16px",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
    textAlign: "center", // IE 정렬
    textAlignLast: "center", // Chrome에서 필요함
  },
  customInput: {
    fontSize: "16px",
    width: "88%",
    paddingLeft: "0px",
    paddingRight: "3px",
    textAlign: "right", // IE 정렬
    textAlignLast: "end", // Chrome 정렬
  },
  notchedOutline: {
    backgroundColor: "blue",
    opacity: "0.1",
  },
  optionItem: {
    textAlign: "center",
    textAlignLast: "center",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // minHeight: "30px",
    // height: "30px",
    fontSize: "16px",
    msTextAlignLast: "center",
  },
}));

function CustomTimePicker(props) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [isStartSelected, setStartSelected] = useState(false);
  const [isEndSelected, setEndSelected] = useState(false);
  const {
    startName = "입실", //(필수)첫번째 옵션 이름
    endName = "퇴실", //(필수)두번째 옵션 이름
    optionName = "이용시간", //세번째 옵션 이름
    startHourList = [], //(필수)첫번째 옵션 리스트
    endHourList = [], //(필수)두번째 옵션 리스트
    stayHourList = [], // 세번째 옵션 리스트
    startTime = 0, //(필수)첫번째 옵션 선택값
    endTime = 0, //(필수)두번째 옵션 선택값
    stayTime = 0, //세번째 옵션 선택값
    value = "", // (필수)TextField에 실제로 보여줄 값
    targetKey = 0, //(필수) callback으로 호출한 곳에 값을 보내줄 때 구분할 키
    initStartTime = 0, // (필수)초기화 할 첫번째 값
    initEndTime = 0, //(필수)초기화 할 때 두번째 값
    initStayTime = 0,
    onChangeStartTime, //(필수) 시작 시간 변경 이벤트
    onChangeEndTime, //(필수) 종료 시간 변경 이벤트
    onChangeStayTime, // 이용시간 변경 이벤트
    onClickCancel, // (필수)X버튼 클릭 이벤트
    useDisabled, // <option> disabled 사용 여부 ( 사용시 start,endTime이 서로 영향을 준다.)
    useStayTime, // 세번째 옵션 사용 여부
    enableSelectAll = false, // 시작, 종료, 사용시간 중 한 가자를 변경하면 나머지도 모두 변경해야하는 경우 사용
  } = props;
  const classes = useStyles();
  const openTimeTable = (e) => {
    setStartSelected(false);
    setEndSelected(false);
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const closeTimeTable = () => {
    if (enableSelectAll && isStartSelected) {
      openAlertPopupWithPromise(null, `시간을 모두 선택해주세요.`);
    } else {
      setOpen(false);
    }
  };

  const handleClickOfTextField = (e) => {
    openTimeTable(e);
  };

  const handleKeyDownOfTextField = (e) => {
    switch (e.key) {
      case "Enter":
        openTimeTable(e);
        break;
      default:
        break;
    }
  };

  const handleKeyDownOfTimeTible = (e) => {
    switch (e.key) {
      case "Escape":
      case "Enter":
        closeTimeTable();
        break;
      default:
        break;
    }
  };

  const handleCloseOfTimeTable = () => {
    closeTimeTable();
  };

  const handleFocusOfTimeTable = (e) => {
    // Time Table 진입하면 focus는 Popover에 위치한다.
    // Popover에 focus가 있으면 start time으로 focus 이동한다.
    if (e.target.classList.contains("MuiPopover-paper")) {
      !!startTimeRef.current && startTimeRef.current.focus();
    }
  };

  /** 시작날짜 변경 이벤트 */
  const handleChangeOfStartTime = (e) => {
    setStartSelected(true);
    const { value } = e.target;
    !!onChangeStartTime && onChangeStartTime(targetKey, parseInt(value));
  };
  /** 종료날짜 변경 이벤트 */
  const handleChangeOfEndTime = (e) => {
    setEndSelected(true);
    const { value } = e.target;
    !!onChangeEndTime && onChangeEndTime(targetKey, parseInt(value));
  };

  /** 이용시간(세번째 옵션) 변경 이벤트 */
  const handleChangeOfStayTime = (e) => {
    const { value } = e.target;
    !!onChangeStayTime && onChangeStayTime(targetKey, parseInt(value));
  };

  /** X버튼 클릭 이벤트 */
  const handleClickCancel = (e) => {
    !!onClickCancel &&
      onClickCancel(targetKey, initStartTime, initEndTime, initStayTime);
    e.stopPropagation();
  };

  const startHour = startHourList.map((e, idx) => (
    <option
      key={idx}
      value={e}
      disabled={useDisabled && e > parseInt(endTime) - 1}
      style={{
        backgroundColor: e === parseInt(startTime) ? "#1E90FF" : "white",
      }}
      className={classes.optionItem}
    >
      {e}
    </option>
  ));
  const endHour = endHourList.map((e, idx) => (
    <option
      key={idx}
      value={e}
      disabled={
        (useDisabled && e < parseInt(startTime) + 1) ||
        (enableSelectAll && !isStartSelected)
      }
      style={{ backgroundColor: e === parseInt(endTime) ? "#1E90FF" : "white" }}
      className={classes.optionItem}
    >
      {e}
    </option>
  ));
  const stayHour = stayHourList.map((e, idx) => (
    <option
      key={idx}
      value={e}
      disabled={e > endTime - startTime || (enableSelectAll && !isEndSelected)}
      style={{
        backgroundColor: e === parseInt(stayTime) ? "#1E90FF" : "white",
      }}
      className={classes.optionItem}
    >
      {e}
    </option>
  ));
  const diffValue =
    initStartTime !== startTime ||
    initEndTime !== endTime ||
    (useStayTime && initStayTime !== stayTime);

  const startTimeRef = useRef(null); // DOM 요소를 startTimeElement에 할당
  return (
    <Box>
      <TextField
        autoComplete="off" //입력했던 문구 드롭박스 제거
        size="small"
        variant="outlined"
        name="startEndTime"
        onClick={handleClickOfTextField}
        onKeyDown={handleKeyDownOfTextField}
        style={{ width: "100%" }}
        className={classes.root}
        inputProps={{
          className: diffValue ? classes.customInput : classes.defaultInput,
        }}
        InputProps={{
          classes: {
            notchedOutline: diffValue && classes.notchedOutline,
          },
          endAdornment: diffValue && (
            <InputAdornment position="end" style={{ width: "12%" }}>
              <IconButton
                size="small"
                onClick={handleClickCancel}
                style={{
                  padding: 0,
                  display: diffValue ? "block" : "none",
                }}
              >
                <CancelIcon size="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={value}
      />
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          marginTop: "10px",
          vertical: "top",
          horizontal: "left",
        }}
        style={{
          marginTop: "10px",
        }}
        open={open}
        anchorEl={anchorEl}
        onFocus={handleFocusOfTimeTable}
        onClose={handleCloseOfTimeTable}
        onKeyDown={handleKeyDownOfTimeTible}
      >
        <div>
          <div className={classes.selectDiv}>
            <div className={classes.selectTitle}>{startName}</div>
            <div className={classes.selectWrap}>
              <select
                ref={startTimeRef}
                multiple
                onChange={handleChangeOfStartTime}
                className={classes.selectContent}
              >
                {startHour}
              </select>
            </div>
          </div>
          <div className={classes.selectDiv}>
            <div className={classes.selectTitle}>{endName}</div>
            <div className={classes.selectWrap}>
              <select
                multiple
                onChange={handleChangeOfEndTime}
                className={classes.selectContent}
              >
                {endHour}
              </select>
            </div>
          </div>
          {useStayTime && (
            <div className={classes.selectDiv}>
              <div className={classes.selectTitle}>{optionName}</div>
              <div className={classes.selectWrap}>
                <select
                  multiple
                  onChange={handleChangeOfStayTime}
                  className={classes.selectContent}
                >
                  {stayHour}
                </select>
              </div>
            </div>
          )}
        </div>
      </Popover>

      {/* <div className={classes.selectDiv}>
          <div className={classes.selectTitle}>{startName}</div>
          <div className={classes.selectWrap}>
            <select
              ref={startTimeRef}
              multiple
              onChange={handleChangeOfStartTime}
              className={classes.selectContent}
            >
              {startHour}
            </select>
          </div>
        </div>
        <div className={classes.selectDiv}>
          <div className={classes.selectTitle}>{endName}</div>
          <div className={classes.selectWrap}>
            <select
              multiple
              onChange={handleChangeOfEndTime}
              className={classes.selectContent}
            >
              {endHour}
            </select>
          </div>
        </div>
        {useStayTime && (
          <div className={classes.selectDiv}>
            <div className={classes.selectTitle}>{optionName}</div>
            <div className={classes.selectWrap}>
              <select
                multiple
                onChange={handleChangeOfStayTime}
                className={classes.selectContent}
              >
                {stayHour}
              </select>
            </div>
          </div>
        )} */}
      {/* 
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        anchorEl={anchorEl}
        onFocus={handleFocusOfTimeTable}
        onClose={handleCloseOfTimeTable}
        onKeyDown={handleKeyDownOfTimeTible}
      >
        <div className={classes.selectDiv}>
          <div className={classes.selectTitle}>{startName}</div>
          <div className={classes.selectWrap}>
            <select
              ref={startTimeRef}
              multiple
              onChange={handleChangeOfStartTime}
              className={classes.selectContent}
            >
              {startHour}
            </select>
          </div>
        </div>
        <div className={classes.selectDiv}>
          <div className={classes.selectTitle}>{endName}</div>
          <div className={classes.selectWrap}>
            <select
              multiple
              onChange={handleChangeOfEndTime}
              className={classes.selectContent}
            >
              {endHour}
            </select>
          </div>
        </div>
        {useStayTime && (
          <div className={classes.selectDiv}>
            <div className={classes.selectTitle}>{optionName}</div>
            <div className={classes.selectWrap}>
              <select
                multiple
                onChange={handleChangeOfStayTime}
                className={classes.selectContent}
              >
                {stayHour}
              </select>
            </div>
          </div>
        )}
      </Popover>*/}
    </Box>
  );
}
/** 불필요 렌더링 방지 */
function propsCompare(preProps, nextProps) {
  return JSON.stringify(preProps) === JSON.stringify(nextProps);
}

export default React.memo(CustomTimePicker, propsCompare);
