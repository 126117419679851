import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import * as configApp from "config/config-app";
import * as serviceBrowser from "lib/service-browser";
let SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";

/*
Material-ui Media Query 관련 참고
https://material.io/resources/devices/
https://material-ui.com/customization/breakpoints/
https://developer.mozilla.org/ko/docs/Web/Guide/CSS/Media_queries
https://stackoverflow.com/questions/52150419/material-ui-breakpoints-to-set-orientation-portrait-and-landscape-view
*/
const styles = () => {
  if (!SERVICE_TYPE_PC) {
    // 모바일
    return {
      root: {
        position: "absolute",
        // AppOnMobile에서 body에 landscape를 등록 할 경우 하단 스타일이 적용 되도록 수정
        // "body.landscape &": {},
        height: `100vh !important`,
        minHeight: `${serviceBrowser.getWindowHeight()}px !important`,
        pointerEvents: "none",
        // backgroundColor: "#fff",
        top: "0px",
        left: "0px",
        width: "100%",
        margin: "0px",
        padding: "0px",
        "& .footerWrap": {
          position: "absolute",
          bottom: "0px",
        },
      },
    };
  }

  return {
    root: {
      position: "absolute",
      pointerEvents: "none",
      top: "0px",
      left: "50%",
      margin: "0px",
      marginLeft: "-640px",
      width: "1280px",
      height: "800px",
      padding: "0px",
      "& .footerWrap": {
        position: "absolute",
        bottom: "0px",
        width:'100%'
      },
    },
  };
};

/**
 * 공용 FooterContainer Component 입니다.<p/>
 *
 * PC/Mobile 로그인 하단, PC 서비스 페이지 하단에 출력 됩니다.
 *
 * @author Taesung Park <pts@pineone.com>
 * @name FooterContainer
 * @class
 * @component
 */
class FooterContainer extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <div className={classes.root}>
        <Box px={1} mb={1} className="footerWrap">
          {children}
        </Box>
      </div>
    );
  }
}

const styledFooterContainer = withStyles(styles)(FooterContainer);
export { styledFooterContainer as FooterContainer };
