/**
 * 판매캘린더 판매 정보 불러오기 기능 제공
 *
 * */
import {openLoading} from "./loading";

const TAG = "[selling/redux-ducks/selling.js]";

// Actions

/* 판매 설정 날짜 유무 체크 */
export const SELLING_DATE_CHECK = "@selling/SELLING_DATE_CHECK";

/* 수정된 판매 설정 데이터  */
export const SELLING_DATA_CHANGED = "@selling/SELLING_DATA_CHANGED";

/* 판매 설정 날짜, 데이터 초기값 설정 */
const initialState = {
    isSalesDate: false,
    changedSalesData : []
}

// action creators
export function checkSalesDate(isSalesDate) {
    console.log(TAG, `Called checkSalesDate()`);
    return (dispatch) => Promise.resolve().then(() => dispatch({
        type: SELLING_DATE_CHECK,
        isSalesDate
    }));
}

export function changeSalesData(changedSalesData) {
    console.log(TAG, `Called changeSalesData()`);
    return (dispatch) => Promise.resolve().then(() => dispatch({
        type: SELLING_DATA_CHANGED,
        changedSalesData
    }));
}


// reducer
export default function reducer(state = initialState, action = {}) {

    switch (action.type) {
        case SELLING_DATE_CHECK:
            return {
                ...state,
                isSalesDate: action.isSalesDate
            }
        case SELLING_DATA_CHANGED:
            return {
                ...state,
                changedSalesData: action.changedSalesData
            }
        default:
            return state;
    }
}


