import React from "react";
import { Box } from "@material-ui/core";
import CustomArrowComponent from "./CustomArrowComponent";
import CustomSelectComponent from "./CustomSelectComponent";

function CustomSelectArrow(props) {
  return (
    <Box style={{ display: "flex" }}>
      <Box style={{ width: "80%" }}>
        <CustomSelectComponent {...props} />
      </Box>
      <Box style={{ width: "20%", marginLeft: "5px" }}>
        <CustomArrowComponent
          index={props.index}
          onClickUpButton={props.onClickUpButton}
          onClickDownButton={props.onClickDownButton}
        />
      </Box>
    </Box>
  );
}

/** 불필요 렌더링 방지 */
function propsCompare(preProps, nextProps) {
  return JSON.stringify(preProps) === JSON.stringify(nextProps);
}

export default React.memo(CustomSelectArrow, propsCompare);
