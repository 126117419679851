/**
 * Alert Redux 기능을 제공하는 모듈 입니다<p/>
 *
 * Material-UI의 Dialog, Snackbar 컴포넌트를 랩퍼 한 커스텀 컴포넌트로 처리 합니다.<p/>
 *
 * ```
 * - 알림 팝업 : Material-UI의 Dialog를 랩퍼 한 AlertDialog 컴포넌트로 처리
 * - 토스트 메세지 : Material-UI의 Snackbar를 랩퍼 한 MessageSnackbars 컴포넌트로 처리
 * ```
 *
 * mui-redux-alerts 3rd Party Lib.를 기반으로 커스텀 마이징 하였습니다.<p/>
 *
 * <a href="https://material-ui.com/components/dialogs/#dialog" target="_blank">참고) Material-UI Dialog</a>
 *
 * <a href="https://material-ui.com/components/snackbars/#snackbar" target="_blank">참고) Material-UI Snackbar</a>
 *
 * <a href="https://www.npmjs.com/package/mui-redux-alerts" target="_blank">참고) mui-redux-alerts</a>
 *
 * @author Taesung Park <pts@pineone.com>
 * @module lib/service-redux-alerts/redux
 * @see Alerts
 * @see AlertDialog
 * @see MessageSnackbars
 */

import * as serviceBrowser from "lib/service-browser";

/*#############################################################
 *  Actions
 *###########################################################*/

const OPEN_SNACKBAR = "@@mui-redux-alerts/OPEN_SNACKBAR";
const OPEN_DIALOG = "@@mui-redux-alerts/OPEN_DIALOG";
const OPEN_NOTICE = "@@mui-redux-alerts/OPEN_NOTICE";
const CLOSE_START = "@@mui-redux-alerts/CLOSE_START";
const CLOSE_SNACKBAR = "@@mui-redux-alerts/CLOSE_SNACKBAR";
const CLOSE_DIALOG = "@@mui-redux-alerts/CLOSE_DIALOG";
const CLOSE_NOTICE = "@@mui-redux-alerts/CLOSE_DIALOG";

/*#############################################################
 *  Reducer
 *###########################################################*/

const initialState = {
  snackbars: {},
  dialogs: {},
  notices: {},
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        snackbars: {
          [action.payload.key]: action.payload.props,
          ...state.snackbars,
        },
        dialogs: state.dialogs,
        notices: state.notices,
      };
    case OPEN_DIALOG:
      return {
        snackbars: state.snackbars,
        dialogs: {
          [action.payload.key]: action.payload.props,
          ...state.dialogs,
        },
        notices: state.notices,
      };
    case OPEN_NOTICE:
      return {
        snackbars: state.snackbars,
        dialogs: state.dialogs,
        notices: {
          [action.payload.key]: action.payload.props,
          ...state.notices,
        },
      };
    case CLOSE_SNACKBAR: {
      const { [action.payload.key]: omit, ...snackbars } = state.snackbars;
      return { snackbars, dialogs: state.dialogs, notices: state.notices };
    }
    case CLOSE_DIALOG: {
      const { [action.payload.key]: omit, ...dialogs } = state.dialogs;
      return { snackbars: state.snackbars, dialogs, notices: state.notices };
    }
    case CLOSE_NOTICE: {
      const { [action.payload.key]: omit, ...notices } = state.notices;
      return { snackbars: state.snackbars, dialogs: state.dialogs, notices };
    }
    default:
      return state;
  }
};

/*#############################################################
 *  Helper Functions
 *###########################################################*/

let count = 0;

// Dialog, Snackbar의 종료 처리 리스너 입니다.
const closeListener = {
  snackbars: {},
  dialogs: {},
  notices: {},
};

/*
 * noop 함수 입니다.<p/>
 * openAlert 호출을 통한 Dialog 실행 시 handleCllick이 전달 되지 않으면,<br/>
 * clickListener에 noop 함수를 설정 합니다.
 */
function noop() {}

/*
 * 종료/실행 시 라우팅 제어 여부가 가능한 타입 인지를 검사하는 메소드 입니다.
 */

function isHandlingBlock(type) {
  switch (type) {
    case "dialogs":
    case "notices":
      return true;
  }
  return false;
}

// Gets keys, props and a close function
const getKeyProps = (
  type,
  first,
  second,
  dispatch,
  closeAction,
  onClickListener,
  onClosedListener
) => {
  let key;
  let propsOrGetProps;
  let clickListener = onClickListener || noop;
  let closedListener = onClosedListener || noop;

  // Define key and pogp
  if (typeof first === "string") {
    key = first;
    propsOrGetProps = second;
  } else {
    count += 1;
    key = `Snackbar_${count}`;
    propsOrGetProps = first;
  }

  let isClosed = false;
  let handleClick = (evt) => {
    !isClosed &&
      !!closeListener[type][key] &&
      clickListener(evt, closeListener[type][key]);
  };

  const props =
    typeof propsOrGetProps === "function"
      ? propsOrGetProps(handleClick, key)
      : propsOrGetProps;

  props.timestamp = Date.now();
  props.open = true;
  props.who = key;

  props.setHandleOnRequestClose = (key, listener) => {
    if (closeListener.hasOwnProperty(type)) {
      closeListener[type][key] = listener;
    }
  };

  props.onOpened = () => {
    if (isHandlingBlock(type)) {
      serviceBrowser.disableScroll();
      serviceBrowser.disableBackNavigation();
    }
  };
  props.onClosed = () => {
    if (!isClosed) {
      isClosed = true;
      closeListener[type][key] = null;
      dispatch(closeAction(key));
      let timer = setTimeout(() => {
        props.setHandleOnRequestClose = props.onOpened = props.onClosed = handleClick = clickListener = null;
        clearTimeout(timer);
        timer = null;
        closedListener();
        closedListener = null;
      }, 0);
    }
  };
  return { key, props };
};

/*#############################################################
 *  Action Creator
 *###########################################################*/

// 토스트 메세지 종료 액션을 생성하는 Action Creator 함수 입니다.
export const closeSnackbar = (key) => {
  closeListener.snackbars[key] && closeListener.snackbars[key]();
  closeListener.snackbars[key] = null;
  return { type: CLOSE_START };
};

const clearSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  payload: { key },
});

// 알람 팝업의 종료 액션을 생성하는 Action Creator 함수 입니다
export const closeDialog = (key) => {
  closeListener.dialogs[key] && closeListener.dialogs[key]();
  closeListener.dialogs[key] = null;
  return { type: CLOSE_START };
};

const clearAlert = (key) => {
  return {
    type: CLOSE_DIALOG,
    payload: { key },
  };
};

// 공지 팝업의 종료 액션을 생성하는 Action Creator 함수 입니다
export const closeNotice = (key) => {
  closeListener.notices[key] && closeListener.notices[key]();
  closeListener.notices[key] = null;
  return { type: CLOSE_START };
};

const clearNotice = (key) => {
  return {
    type: CLOSE_NOTICE,
    payload: { key },
  };
};

/**
 * 토스트 메세지를 실행 시키는 Action Creator 함수 입니다.<p/>
 *
 * 토스트 메세지는 3초 뒤에 자동 종료 됩니다.<p/>
 *
 * props에 대한 자세한 내용은 MessageSnackbars를 참조 합니다.
 *
 * @param {object} props - MessageSnackbars에 주입 될 props
 * @returns {object} OPEN_SNACKBAR 타입을 정의한 객체
 * @see MessageSnackbars
 * @example
 * // Step 1) 토스트 메세지를 실행 할 수 있도록 openSnackbar를 import 합니다.
 * import { openSnackbar } from "lib/service-redux-alerts";
 *
 * // Step 2) openSnackbar를 mapDispatchToProps에 등록 합니다.
 * export default connect(null, dispatch =>
 *   bindActionCreators({ openSnackbar }, dispatch)
 * )(TestAlerts);
 *
 * // Step 3) 컴포넌트 내에서 토스트 메세지 호출이 필요한 경우 아래와 같이 사용 합니다.
 * this.props.openSnackbar({
 *   message: "테스트 메세지"
 * });
 */
export const openSnackbar = (propsOrGetProps) => (dispatch) => {
  const payload = getKeyProps(
    "snackbars",
    "snackbars-" + new Date().getTime(),
    propsOrGetProps,
    dispatch,
    clearSnackbar,
    null,
    null
  );
  dispatch({ type: OPEN_SNACKBAR, payload });
  return payload.key;
};

// 특정 키를 통해 알림팝업을 실행 시키는 Action Creator 함수 입니다.
// openAlert으로 대체 되어 주석 처리 됩니다.
/*
export const openDialog = (
  keyOrPropsOrGetProps,
  propsOrGetProps,
  clickListener
) => dispatch => {
  const payload = getKeyProps(
    "dialogs",
    keyOrPropsOrGetProps,
    propsOrGetProps,
    dispatch,
    clearAlert,
    clickListener
  );
  dispatch({ type: OPEN_DIALOG, payload });
};
*/

/**
 * 알림 팝업을 실행 시키는 Action Creator 함수 입니다.<p/>
 *
 * props에 대한 자세한 내용은 AlertDialog를 참조 합니다.<p/>
 *
 * @param {object|function} propsOrGetProps - AlertDialog에 주입 될 props 혹은 props 제공 함수
 * @param {function} handleClick - 클릭 리스너
 * @returns {object} OPEN_DIALOG 타입을 정의한 객체
 * @see AlertDialog
 * @example
 * // Step 1) 알림 팝업을 실행 할 수 있도록 openAlert를 import 합니다.
 * import { openAlert } from "lib/service-redux-alerts";
 *
 * // Step 2) openAlert를 mapDispatchToProps에 등록 합니다.
 * export default connect(null, dispatch =>
 *   bindActionCreators({ openAlert }, dispatch)
 * )(TestAlerts);
 *
 * // Step 3) 컴포넌트 내에서 토스트 메세지 호출이 필요한 경우 아래와 같이 사용 합니다.
 *
 * this.props.openAlert(
 *  // init
 *  (clickListener) => {
 *    return {
 *      maxWidth: "xs",
 *      title: "Alert",
 *      description: "팝업문구\n팝업문구",
 *      actions: [{
 *        label: "확인",
 *        dataType: "ok",
 *        onClick: clickListener,
 *      }],
 *    };
 *  },
 *  // click listener
 *  (event, close) => {
 *    if (!close) return; // 더블 클릭 시 두번 실행 되는 현상이 있음
 *    close(); // 알림 팝업 종료
 *
 *    let dataType = event.currentTarget.getAttribute("data-type");
 *    switch (dataType) {
 *      case "ok":
 *        // 확인 버튼 클릭 시 실행 될 코드 명시
 *        break;
 *      default:
 *        break;
 *    }
 *  },
 *  // closed listener
 *  () => {
 *    // 팝업 종료 후 실행 될 코드 명시
 *  }
 * );
 */
export const openAlert = (propsOrGetProps, clickListener, closedListener) => (
  dispatch
) => {
  const payload = getKeyProps(
    "dialogs",
    "dialogs-" + new Date().getTime(),
    propsOrGetProps,
    dispatch,
    clearAlert,
    clickListener,
    closedListener
  );
  dispatch({ type: OPEN_DIALOG, payload });
};

export const openNotice = (propsOrGetProps, clickListener, closedListener) => (
  dispatch
) => {
  const payload = getKeyProps(
    "notices",
    "notices-" + new Date().getTime(),
    propsOrGetProps,
    dispatch,
    clearNotice,
    clickListener,
    closedListener
  );
  dispatch({ type: OPEN_NOTICE, payload });
};
