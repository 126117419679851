import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, Button, Divider } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { format } from "date-fns";
import { history } from "lib/history";
import { Link } from "react-router-dom";
import { BoxWrap } from "components";

const styles = (theme) => ({
  noticeFlex: {
    display: "flex",
  },
  noticePrimary: {
    flexGrow: 1,
    cursor: "pointer",
  },
  noticeSecondary: {
    width: "78px",
    color: "#949494",
    textAlign: "right",
    fontStyle: "italic",
    fontSize: "10px",
    lineHeight: "18px",
  },
  noticeDivider: {
    margin: "16px 0",
  },
  linkBtnWrapper: {
    top: "16px",
    right: "16px",
    zIndex: "1",
  },
  textDecoNone: {
    textDecoration: "none",
  },
});

const WhiteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
}))(Button);

/**
 * 공지 사항 Template Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name NoticeTemplate
 * @component
 */
class NoticeTemplate extends React.PureComponent {
  /** 공지사항 게시물 클릭 이벤트 */
  handleClickNotice = (e) => {
    const { id } = e.target;
    history.push({
      pathname: "/notice/detail",
      search: `?num=${id}`,
    });
  };
  render() {
    const { classes, model } = this.props;
    const { noticeData } = model;
    return (
      <BoxWrap title="공지 사항">
        {/* UI 변경 '>' => 버튼 */}
        <Box position="absolute" className={classes.linkBtnWrapper}>
          <Link to={`/notice`} className={classes.textDecoNone}>
            <WhiteButton variant="contained" color="primary" size="small">
              <strong>공지목록</strong>
            </WhiteButton>
          </Link>
        </Box>
        {/* UI 변경 '>' => 버튼 */}

        <Box className={classes.notice}>
          {/* 등록된 공지가 없을경우 */}
          {!noticeData && (
            <Box align="center" py={2}>
              등록된 내용이 없습니다.
            </Box>
          )}

          {/* 등록된 공지가 있을경우 */}
          {noticeData.map((notice, index) => {
            return (
              <React.Fragment key={index}>
                {index > 0 && <Divider className={classes.noticeDivider} />}
                <Box mt={1} className={classes.noticeFlex}>
                  <Box
                    id={notice.key}
                    className={classes.noticePrimary + " text-ellipsis"}
                    onClick={this.handleClickNotice}
                  >
                    {notice.title}
                  </Box>
                  <Box mx={0} px={0} className={classes.noticeSecondary}>
                    {format(new Date().setTime(notice.reg_dt), "yyyy.MM.dd")}
                  </Box>
                </Box>
              </React.Fragment>
            );
          })}
        </Box>
      </BoxWrap>
    );
  }
}

// 초기 props 설정
NoticeTemplate.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default withTheme(withStyles(styles)(NoticeTemplate));
