/**
 * 문자열을 검사하여 제휴점명 형식 여부를 반환 합니다
 * 2022.12.15-양태욱 : 이모지를 제외해달라는 요청이 있어 추가
 * @param {string} str - 문자열
 * @desc 전달받은 문자열에 이모지가 포함되어있는지 여부를 반환합니다.
 * @return {boolean} 제휴점명 형식 여부
 */
export default function isStoreNameFormat(str) {
  const reg = /([\uD800-\uDBFF][\uDC00-\uDFFF])/g;
  return !str.match(reg);
}
