import React from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Paper,
  FormControl,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { BoxWrap } from "components";
import CouponHelperTextTemplate from "./CouponHelperTextTemplate";
import * as serviceStorage from "lib/service-storage";

import {
  COUPON_DISCOUNT_TYPE_LIST,
  COUPON_MIN_MAX_PRICE,
  isEquals,
} from "lib/helper-coupon";
import { objectDeepClone } from "utils/ObjectHelper";
import {
  openConfirmPopupWithPromise,
  openHttpRejectionPopupWithPromise,
  openAlertPopupWithPromise,
} from "lib/helper-popup";
import { CouponSettings } from "../../modal/CouponSettings";
import TableHeader from "../TableHeader";
import NoCouponSlot from "../NoCouponSlot";
import TableContent from "../TableContent";
import CouponTemplateSelectForm from "../CouponTemplateSelectForm";

const styles = (theme) => ({
  table: {
    marginTop: "10px",
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    "& th": {
      background: "#f8f8f9",
      fontWeight: "bold",
    },
    "& td": {
      border: "1px solid #ddd",
    },
  },
  couponSelectWrap: {
    width: "30%",
    lineHeight: "60px",
    background: "#f8f8f9",
    borderRight: "1px solid #ddd",
  },
});

/**
 * 변경 사항
 * 2021.06.10 6월말 항목
 * 1. 기본값 버튼 제거
 * 2. 쿠폰 템플릿 CMS & 서버에서 관리
 * 3. 쿠폰 발행 개수 변경
 */
/**
 * 쿠폰 발행 간편 설정 Template Component 입니다.<p/>
 * @author TaeWook Yang <didxodnr0769@pineone.com>
 * @name CalendarStatusTemplate
 * @component
 * @description  3차 리뉴얼
 */
class CouponEasyTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    if (this.props.model.couponTemplateList.length === 0) return false;
    const identity = serviceStorage.getIdentity();
    this.SPECIAL_CODE_LIST = ["CT021", "CT023", "CT024"]; // 특수 템플릿(ex : 웹마스터 변경가능 템플릿 코드)
    this.MAX_COUNT_PER_SLOT = 999; // 슬롯당 최대 쿠폰 개수 21.06.18 999장으로변경
    this.MOTEL_KEY = serviceStorage.getConnectedMotelKey();
    this.USER_TYPE = serviceStorage.getUserType(identity);
    this.IS_MASTER = serviceStorage.getUserType(identity) === "WEBMASTER";
    this.EASY_COUPON_DAY_TYPE_LIST = [
      { day_type: "ALL", name: "평일/주말", hide: false },
      { day_type: "DAYOFWEEK", name: "평일", hide: false, disabled: true },
      { day_type: "WEEKEND", name: "주말", hide: true },
    ];
    this.COUPON_CONFIRM_STR = "설정한 값으로 적용하시겠습니까?";
    this.CAN_NOT_USE_CONFIRM_STR =
      "신규 탬플릿 변경 시 현재 적용 중인\n기존 탬플릿은 다시 선택할 수 없습니다.\n 설정한 값으로 적용하시겠습니까?";
    // "탬플릿 변경 시 현재 적용 중인 탬플릿은 \n다시 선택할 수 없습니다.\n 설정한 값으로 적용하시겠습니까?";
    this.EQUAL_TAMPLATE = "현재 적용 중인 템플릿입니다. 다시 시도해주세요.";
    // @todo Yang - 문구 확인 필요
    this.ALERT_RULE_VIOLATION = "규칙 위반입니다.";
    this.state = {
      ...this.getDefaultCouponState(),
      modalOpen: false,
      violationIdxList: [], //구분-사용가능일 에러 리스트(22.12.21 기준 미사용 미리만들어놓은 로직)
    };
  }

  /** props로 받아온 쿠폰값 세팅 */
  getDefaultCouponState = () => {
    return {
      applyCouponCode: this.getApplyCouponCode(),
      applyCouponName: this.getApplyCouponName(), //현재 적용된 쿠폰 이름
      easyCouponIndex: this.getCouponIndex(), // 현재 선택한 쿠폰 템플릿 인덱스
      easyCouponTemplate: this.getCouponTemplate(), // 전체 쿠폰 템플릿
      selectCouponArr: this.getHistoryCouponArr(), //현재 선택한 쿠폰배열
      selectCouponCode: this.props.model.couponHistoryObj.coupon_template, //선택한 쿠폰 코드
    };
  };
  /** 현재 적용중인 쿠폰 코드 반환 */
  getApplyCouponCode = () => {
    const { coupon_template } = this.props.model.couponHistoryObj;
    return coupon_template || "";
  };

  /** 현재 적용중인 쿠폰 코드를 이용 쿠폰 이름 반환 */
  getApplyCouponName = () => {
    const { coupon_template } = this.props.model.couponHistoryObj;
    const { couponTemplateList } = this.props.model;
    const applyCoupon = couponTemplateList.find(
        (val) => val.coupon_template === coupon_template
    )
    return applyCoupon ? applyCoupon.coupon_name : "";
  };

  /** 쿠폰 코드를 받아 템플릿에서 해당 쿠폰 정보 반환 */
  getTemplateCoupon = (code) => {
    const { easyCouponTemplate } = this.state;
    const couponInfo = easyCouponTemplate.find(
      (val) => val.coupon_template === code
    );
    return couponInfo || false;
  };

  /** history의 코드를 이용하여  index반환*/
  getCouponIndex = () => {
    const { coupon_template } = this.props.model.couponHistoryObj;
    const { couponTemplateList = [] } = this.props.model;
    for (let i = 0; i < couponTemplateList.length; i++) {
      if (couponTemplateList[i].coupon_template === coupon_template) {
        return i;
      }
    }
    return 0;
  };
  /** couponHistoryObj(현재적용된쿠폰템플릿)의 쿠폰 목록 반환 */
  getHistoryCouponArr = () => {
    const { coupons } = this.props.model.couponHistoryObj;
    return coupons;
  };
  /** 할인 유형에 따른 가격 증가 단위 반환 */
  getUnitInterval = (type) => {
    return COUPON_DISCOUNT_TYPE_LIST.find((val) => val.discount_type === type)
      .interval;
  };
  /** 할인 유형에 따른 가격 max값 반환  */
  getUnitMaxValue = (itemType, discountType) => {
    const { max_price, max_rate } = COUPON_MIN_MAX_PRICE[itemType];
    return discountType === "AMOUNT" ? max_price : max_rate;
  };
  /** 할인 유형에 따른 가격 min값 반환  */
  getUnitMinValue = (itemType, discountType) => {
    const { min_price, min_rate } = COUPON_MIN_MAX_PRICE[itemType];
    return discountType === "AMOUNT" ? min_price : min_rate;
  };
  /** 할인 유형 인덱스 조회 */
  getIndexFromDiscountType = (code) => {
    return COUPON_DISCOUNT_TYPE_LIST.findIndex(
      (val) => val.discount_type === code
    );
  };
  /** 유형 변경에 따른 할인금액 반환 */
  getDiscountPriceFromType = (discount_type, item_type) => {
    if (discount_type === "RATE") {
      return 15;
    } else if (discount_type === "AMOUNT" && item_type === "010102") {
      return 6000;
    } else {
      return 3000;
    }
  };
  /** 초기 쿠폰 템플릿 값 삽입 */
  getCouponTemplate = () => {
    const { couponHistoryObj, couponTemplateList } = this.props.model;
    const { coupon_template, coupons } = couponHistoryObj;
    const copyOriginalTemplate = objectDeepClone(couponTemplateList);
    if (this.getIsSpecialCode(coupon_template)) {
      copyOriginalTemplate.map((val) => {
        if (coupon_template === val.coupon_template) {
          val.coupons = coupons;
        }
      });
    }

    return [...copyOriginalTemplate];
  };
  /** 템플릿 코드를 받아 수정이 가능한 코드인지 반환 */
  getIsSpecialCode = (code) => {
    return this.SPECIAL_CODE_LIST.indexOf(code) !== -1;
  };
  /** 수정 가능한 쿠폰 템플릿인지 반환 */
  getModifyYn = () => {
    const { easyCouponIndex, easyCouponTemplate } = this.state;
    return easyCouponTemplate[easyCouponIndex].modify_yn;
  };
  /** 사용자 권한에 따른 템플릿 표시 여부 */
  getCouponDisplay = (code) => {
    return code === "Y" ? "block" : "none";
  };
  /** 사용자 권한에 따른 '제휴점 맞춤형','임시템플릿'에서 다른 템플릿 변경시  confirm 알림 표시 여부 */
  getCanUseTemplate = (code) => {
    const { applyCouponCode } = this.state;
    const { visible_yn } = this.getTemplateCoupon(applyCouponCode);
    // 조건1 : 새로운 코드와 적용된 코드가 서로 다른 값인가?
    const compareCouponCode = code !== applyCouponCode;
    // 조건2 : 현재 적용된 템플릿의 visible_yn이 N인가
    const applyCodeCondition = visible_yn === "N";
    return compareCouponCode && applyCodeCondition;
  };
  /** 구분-사용가능일 항목 규칙 위반 리스트 필터링 메서드  */
  getViolationCheckList = (coupons) => {
    return coupons
      .map((coupon, idx) => {
        const { day_type, item_type } = coupon;
        // 조건1. 구분-겸용 && 사용가능일-평일/주말이 아님
        return item_type === "010100" && day_type !== "ALL" ? idx : -1;
      })
      .filter((num) => num !== -1);
  };
  setSelectCouponArr = (coupon) => {
    this.setState({
      selectCouponArr: coupon,
    });
  };
  /** 적용 전 쿠폰 템플릿과 수정된 쿠폰 템플릿 비교 */
  matchCouponTemplate = (coupons, code) => {
    const { applyCouponCode } = this.state;
    const past_coupons = this.getHistoryCouponArr();
    if (applyCouponCode === code && isEquals(past_coupons, coupons)) {
      return true;
    } else {
      return false;
    }
  };
  /** 쿠폰 템플릿 변경 이벤트 */
  handleSelectCouponType = (e) => {
    const { value } = e.target;
    const { easyCouponTemplate } = this.state;
    this.setState({
      easyCouponIndex: value,
      selectCouponArr: easyCouponTemplate[value].coupons,
      selectCouponCode: easyCouponTemplate[value].coupon_template,
    });
  };
  /** 적용하기 버튼 클릭 이벤트 */
  handleClickSubmitButton = (coupons = [], code = undefined) => {
    const { selectCouponCode, selectCouponArr, applyCouponCode } = this.state;
    const nextCoupons =
      Array.isArray(coupons) && coupons.length !== 0
        ? coupons
        : selectCouponArr;
    const nextCouponCode = code ? code : selectCouponCode;
    const requestObj = {
      motel_key: this.MOTEL_KEY,
      coupon_template: nextCouponCode,
      past_coupon_template: applyCouponCode,
      past_coupons: this.getHistoryCouponArr(),
      coupon: nextCoupons,
    };
    const isCanUseTemplate = this.getCanUseTemplate(selectCouponCode);

    // CASE 1. 변경 사항 없을 경우 : 현재 적용 중인 템플릿입니다. 다시 시도해주세요.
    if (this.matchCouponTemplate(nextCoupons, nextCouponCode)) {
      openAlertPopupWithPromise(null, this.EQUAL_TAMPLATE);
    }
    // CASE 2. 변경사항 있음
    else {
      const violationIdxList = this.getViolationCheckList(nextCoupons);
      // CASE 2-1. 템플릿 변경 후 해당 템플릿 설정 불가 : 신규 탬플릿 변경 시 현재 적용 중인\n기존 탬플릿은 다시 선택할 수 없습니다.\n 설정한 값으로 적용하시겠습니까?
      if (isCanUseTemplate) {
        openConfirmPopupWithPromise(null, this.CAN_NOT_USE_CONFIRM_STR).then(
          (res) => {
            if (res === "OK") {
              this.onCreateCoupon(requestObj);
              this.couponModalClose();
            }
          }
        );
      }
      // CASE 2-2. '구분'-'사용가능일' 규칙에 맞지 않을 경우 : 문구미정,개발롤백(양-2022.12.22)
      // else if (violationIdxList.length) {
      //   openAlertPopupWithPromise(
      //     null,
      //     `${this.ALERT_RULE_VIOLATION}\n${violationIdxList
      //       .map((idx) => idx + 1 + "번째")
      //       .join(", ")}`
      //   );
      // }
      // CASE 2-3. 설정 가능 : 설정한 값으로 적용하시겠습니까?
      else {
        openConfirmPopupWithPromise(null, this.COUPON_CONFIRM_STR).then(
          (res) => {
            if (res === "OK") {
              this.onCreateCoupon(requestObj);
              this.couponModalClose();
            }
          }
        );
      }
    }
  };
  /** 쿠폰 템플릿 적용 */
  onCreateCoupon = (requestObj) => {
    const { onRequestAjax, onRefresh } = this.props;
    onRequestAjax("createCoupons", requestObj)
      .then((res) => {
        return onRefresh();
      })
      .then((res) => {
        this.setState({
          ...this.getDefaultCouponState(),
        });
      })
      .catch(openHttpRejectionPopupWithPromise);
  };

  /** 선착순 쿠폰 설정 Open*/
  couponModalOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };
  /** 선착순 쿠폰 설정 Close*/
  couponModalClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  setCoupon = (coupon, name) => {
    const copiedCoupon = objectDeepClone(this.state.selectCouponArr);
    copiedCoupon[name] = coupon;
    this.setState({
      selectCouponArr: copiedCoupon,
    });
  };

  render() {
    if (this.props.model.couponTemplateList.length === 0) return false;

    const { classes, model } = this.props;
    const { couponTemplateList = [] } = model;
    const {
      applyCouponName,
      applyCouponCode,
      easyCouponIndex,
      selectCouponArr,
      selectCouponCode,
      violationIdxList,
    } = this.state;

    const buttonDisabled =
      this.USER_TYPE !== "WEBMASTER" && applyCouponCode === "CT024";
    const isNoCoupon = !this.IS_MASTER && selectCouponCode === "CT022";
    const columns = [
      {
        title: "구분",
        align: "center",
        width: "13%",
      },
      {
        title: "할인",
        align: "center",
        width: "13%",
      },
      {
        title: "수량",
        align: "center",
        width: "13%",
      },
      {
        title: "유형",
        align: "center",
        width: "13%",
      },
    ];

    return (
      <BoxWrap
        title={`쿠폰 발행 간편 설정`}
        warningTitle={!applyCouponName ? "미적용중" : null}
        bigSubTitle={`(${applyCouponName} 적용 중)`}
        userType={this.USER_TYPE}
        buttonTitle={"선착순 쿠폰 설정"}
        buttonOnClick={this.couponModalOpen}
      >
        <Box display="flex" style={{ border: "1px solid #ddd" }}>
          <Box className={classes.couponSelectWrap} align="center">
            선착순 쿠폰 탬플릿 선택
          </Box>
          <Box style={{ width: "70%", padding: "10px" }}>
            <CouponTemplateSelectForm
              easyCouponIndex={easyCouponIndex}
              handleSelectCouponType={this.handleSelectCouponType}
              USER_TYPE={this.USER_TYPE}
              applyCouponCode={applyCouponCode}
              couponTemplateList={couponTemplateList}
            />
          </Box>
        </Box>
        <Box>
          <NoCouponSlot display={isNoCoupon ? "block" : "none"} />
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              style={{
                display: !isNoCoupon ? "block" : "none",
                tableLayout: "fixed",
              }}
            >
              <TableHeader columns={columns} />
              <TableBody>
                {selectCouponArr.map((coupon, idx) => {
                  return (
                    <TableContent
                      key={`content_${idx}`}
                      idx={idx}
                      coupon={coupon}
                      setCoupon={this.setCoupon}
                      type={"SIMPLE_COUPON"}
                      selectCouponCode={selectCouponCode}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <CouponHelperTextTemplate code="EASY_COUPON" />
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={this.handleClickSubmitButton}
            disabled={buttonDisabled}
          >
            {buttonDisabled ? (
              <strong>변경은 영업사원에게 문의하세요</strong>
            ) : (
              <strong>적용하기</strong>
            )}
          </Button>
        </Box>

        {/* 쿠폰 발행 Modal */}
        <CouponSettings
          modalOpen={this.state.modalOpen}
          applyCouponName={applyCouponName}
          applyCouponType={applyCouponCode}
          couponModalClose={this.couponModalClose}
          applyCoupons={this.getHistoryCouponArr()}
          handleClickSubmitButton={this.handleClickSubmitButton}
          violationIdxList={violationIdxList}
          selectCouponCode={selectCouponCode}
        />
      </BoxWrap>
    );
  }
}

// 초기 props 설정
CouponEasyTemplate.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default withTheme(withStyles(styles)(CouponEasyTemplate));
