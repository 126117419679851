/**
 * 본 모듈은 값이 비워 있는지 검사하는 모듈 입니다
 *
 * @module utils/isEmpty
 */

/**
 * 전달 된 값을 검사하고, Empty 여부를 true/false로 반환 합니다
 *
 * @param {any} value - 검사 할 값
 * @return {boolean} Empty 여부
 */
export default function isEmpty(value) {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    (value !== null &&
      typeof value === "object" &&
      !Object.keys(value).length) ||
    (typeof value === "string" && value.trim && value.trim() === "")
  ) {
    return true;
  }
  return false;
}
