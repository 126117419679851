import { makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  badgeContainer: {
    width: "90px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
    borderRadius: "20px",
    fontSize: "15px",
  },
  sanhaWings: {
    backgroundColor: "#2155A4",
  },
}));
/**
 * @desc 해당 제휴점이 산하윙스를 이용하는 제휴점일 경우 나타내는 Badge입니다.
 */
const AppSanHaWingsBadge = ({style}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.badgeContainer,
        classes.sanhaWings
      )}
      style={{
        ...style,
      }}
    > <span>산하윙스</span> </div>
  );
};

export default AppSanHaWingsBadge;
