import React, { useState } from "react";

import Snackbar from "@material-ui/core/Snackbar";

const TAG = "[templates-ui/material/alerts/MessageSnackbars.js]";

/**
 * Material-UI의 Snackbar를 랩퍼 한 Functional Component 입니다.<p/>
 *
 * @component
 * @name MessageSnackbars
 * @property {sring} message - 토스트 메세지의 내용
 * @property {object} anchorOrigin - 토스트 메세지의 위치
 * @property {number} autoHideDuration - 토스트 메세지 자동 종료 시간. 기본 3초
 * @see module:lib/service-redux-alerts/redux
 * @see Alerts
 * @author Taesung Park <pts@pineone.com>
 */
const MessageSnackbars = (props) => {
  const {
    who,
    open,
    setHandleOnRequestClose,
    onOpened,
    onClosed,
    message,
    anchorOrigin,
    autoHideDuration,
    classes,
  } = props;

  const [isOpen, setIsOpen] = useState(open);
  const handleOnRequestClose = () => {
    setIsOpen(false);
  };

  setHandleOnRequestClose && setHandleOnRequestClose(who, handleOnRequestClose);

  return (
    <Snackbar
      open={isOpen}
      onClose={handleOnRequestClose}
      onEntered={onOpened}
      onExited={onClosed}
      message={message}
      anchorOrigin={anchorOrigin}
      classes={classes}
      autoHideDuration={autoHideDuration}
      disableWindowBlurListener={true}
    />
  );
};

MessageSnackbars.defaultProps = {
  classes: {},
  /**
   * Material-UI Dialog의 open 속성 입니다.<p/>
   *
   * MessageSnackbars에서 'useState' Hook 함수로 Material-UI Snackbar의 종료를 자체 처리 합니다.
   */
  open: true,
  /**
   * Material-UI Snackbar의 위치 관련 속성 입니다.<p/>
   *
   * <a href="https://material-ui.com/api/snackbar/" target="_blank">참고) Snackbar anchorOrigin</a>
   *
   */
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  /**
   * Material-UI Snackbar의 자동 종료 시간 속성 입니다.<p/>
   *
   * <a href="https://material-ui.com/api/snackbar/" target="_blank">참고) Snackbar autoHideDuration</a>
   *
   */
  autoHideDuration: 3000,
  /**
   * Snackbar 종료 핸들러를 설정하는 메소드 입니다.<p/>
   *
   * lib/service-redux-alerts/redux 모듈에서 주입 합니다.
   */
  setHandleOnRequestClose: function () {
    console.log(TAG, "setHandleOnRequestClose 주입 되지 않았습니다");
  },
  /**
   * Material-UI Snackbar의 onEntered 콜백 메소드 실행 시 호출 되는 핸들러 입니다.<p/>
   *
   * <a href="https://material-ui.com/api/snackbar/" target="_blank">참고) Snackbar onEntered</a>
   *
   * lib/service-redux-alerts/redux 모듈에서 주입 합니다.
   */
  onOpened: function () {
    console.log(TAG, "onOpened가 주입 되지 않았습니다");
  },
  /**
   * Material-UI Snackbar의 onExited 콜백 메소드 실행 시 호출 되는 핸들러 입니다.<p/>
   *
   * <a href="https://material-ui.com/api/snackbar/" target="_blank">참고) Snackbar onExited</a>
   *
   * lib/service-redux-alerts/redux 모듈에서 주입 합니다.
   */
  onClosed: function () {
    console.log(TAG, "onClosed가 주입 되지 않았습니다");
  },
};

export default MessageSnackbars;
