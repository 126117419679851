import React from "react";
import PropTypes from "prop-types";

import { TablePagination, IconButton, Box } from "@material-ui/core";

import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";

const getNumberOfPages = (rowCount, rowsPerPage) =>
  Math.ceil(rowCount / rowsPerPage);

const pageNumberStyle = {
  fontSize: "0.9rem",
  cursor: "pointer",
};

const Page = ({ pageNumber, isActive, onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      aria-label={`page-${pageNumber}`}
      //color={isActive ? "primary" : "default"}
      style={
        isActive
          ? {
              width: "36px",
              height: "36px",
              lineHeight: "10px",
              background: "#ddd",
            }
          : {
              width: "36px",
              height: "36px",
              lineHeight: "10px",
            }
      }
    >
      <label style={pageNumberStyle}>
        {pageNumber}
        {/* {isActive && <strong>{pageNumber}</strong>}
        {!isActive && pageNumber} */}
      </label>
    </IconButton>
  );
};
Page.propTypes = {
  value: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

const calculateRange = (arg) => {
  const { total, current, display } = arg;

  let end = total;
  let start = 1;
  if (display < end) {
    let beforeNumber = Math.floor(display / 2);
    const afterNumber = beforeNumber;
    if (display % 2 === 0) {
      beforeNumber -= 1;
    }

    if (current <= beforeNumber + 1) {
      end = display;
    } else if (current >= total - afterNumber) {
      start = total - display + 1;
    } else {
      start = current - beforeNumber;
      end = current + afterNumber;
    }
  }

  return { end, start };
};

function TablePaginationActions({ count, page, rowsPerPage, onChangePage }) {
  const handleFirstPageButtonClick = () => {
    onChangePage(1);
  };

  const total = getNumberOfPages(count, rowsPerPage);
  const current = page;
  const display = 5;

  const { end, start } = calculateRange({ total, current, display });

  const handleBackButtonClick = () => {
    onChangePage(page);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 2);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(getNumberOfPages(count, rowsPerPage));
  };

  const array = [];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {array.map((pageNum, k) => (
        <Page
          key={k}
          pageNumber={pageNum}
          isActive={page === pageNum - 1}
          onClick={() => onChangePage(pageNum)}
          style={{ verticalAlign: "middle" }}
        />
      ))}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

/**
 * DataTable의 페이지 컴포넌트
 * DataTable의 속성으로 paginationPerPage에 숫자를 입력한 만큼
 *  해당 컴포넌트에 rowsPerPage 속성이 온다(미표기시 Default 10)
 */
const CustomMaterialPagination = (props) => {
  const {
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage,
  } = props;

  return (
    <Box align="center">
      <TablePagination
        component="div"
        //rowsPerPageOptions={[10, 25, 50, 100]}
        //labelRowsPerPage="페이지당"
        rowsPerPageOptions={[]}
        labelRowsPerPage=""
        // labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
        labelDisplayedRows={({ from, to, count }) => ""}
        count={rowCount}
        rowsPerPage={rowsPerPage}
        //rowsPerPage={10}
        page={currentPage - 1}
        onChangePage={onChangePage}
        onChangeRowsPerPage={({ target }) =>
          onChangeRowsPerPage(Number(target.value))
        }
        ActionsComponent={TablePaginationActions}
      />
    </Box>
  );
};

CustomMaterialPagination.propTypes = {
  rowsPerPage: PropTypes.number.isRequired, // calculated rows per page state from DataTable
  rowCount: PropTypes.number.isRequired, // calculated row count from DataTable
  onChangePage: PropTypes.func.isRequired, // you want to "callback" the updated page number to DataTable so it can update its state
  onChangeRowsPerPage: PropTypes.func.isRequired, // you want to "callback" the updated rows per (newRowsPerPage, currentPage) to DataTable so it can update its state
  currentPage: PropTypes.number.isRequired, // the current page state from DataTable
};

export default CustomMaterialPagination;
