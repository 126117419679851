import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

/**
 * EmptyList 컴포넌트 입니다.<p/>
 *
 * @author max5500 <max5500@pineone.com>
 * @name EmptyList
 * @function
 * @component
 */

const useStyles = makeStyles((theme) =>
  createStyles({
    noneContentsWraper: {
      width: "100%",
      position: "absolute",
      // top: "50%",
      top: "40%", //양태욱 전임 수정 : 하단 푸터영역 추가로 인한 조정
      left: 0,
      transform: "translateY(-50%)",
      whiteSpace: "pre-wrap",
      textAlign: "center",
      "& .noneContents": {},
      "& .iconWraper": {
        fontSize: "60px",
      },
      "& .mainDesc": {
        fontSize: "15px",
        fontWeight: "bold",
        marginBottom: "11px",
      },
      "& .subDesc": {
        fontSize: "11px",
      },
    },
  })
);

const ErrorMobile = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.noneContentsWraper}>
      <div className="noneContents">
        <div className="iconWraper">
          <ErrorOutlineIcon fontSize="inherit" />
        </div>
        <div className="mainDesc">{props.mainDesc}</div>
        <div className="subDesc">{props.subDesc}</div>
      </div>
    </Box>
  );
};

export { ErrorMobile };
