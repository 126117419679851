import React, { Component } from "react";

import { Box } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";

const styles = (theme) => ({
  help: {
    border: "1px solid #cccccc",
    borderRadius: "3px",
    padding: "8px 24px",
    "& h4": {
      margin: "4px",
      opacity: 0.8,
    },
    "& h5": {
      margin: "4px",
      opacity: 0.8,
    },
  },
});

/**
 * @name RawHelperTextRecycle
 * @author Yang TaeWook
 * @class
 * @component
 * @description 화면 하단의 도움말 컴포넌트입니다.
 * @see DayChargeHelperText.js 특정일 요금
 * @see SeriallyStayContainer.js 연박설정 화면
 * @see SurtaxPageLayout.js 부가세신고 화면
 */
class RawHelperTextRecycle extends Component {
  render() {
    const { classes, title = "도움말", targetText } = this.props;
    return (
      <Box mt={6} className={classes.help}>
        <h4>{title}</h4>
        {targetText.map((content, idx) => {
          const { desc, color } = content;
          return (
            <h5 key={`helper_${idx}`} style={{ color }}>
              {desc}
            </h5>
          );
        })}
      </Box>
    );
  }
}
const styledHelperTextRecycle = withTheme(
  withStyles(styles)(RawHelperTextRecycle)
);

export { styledHelperTextRecycle as HelperTextRecycle };
