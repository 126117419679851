/* global __APP_VERSION__, __SERVICE_TYPE__, __BASE_NAME__, __ROUTER_TYPE__ */
export const APP_VERSION = `${__APP_VERSION__}`; // 웹앱의 버전 정보를 정의 한 Constant 입니다
export const SERVICE_TYPE = `${__SERVICE_TYPE__}`; // 서비스 타입(PC: pc, 모바일: mobile)
export const BASE_NAME = `${__BASE_NAME__}`; // basename (PC: /pension-biz-pc, 모바일: /pension-biz-mobile, 미정의: /)
export const ROUTER_TYPE = `${__ROUTER_TYPE__}`; // 라우터 타입 (browser: BrowserRouter, hash: HashRouter)

export const ALIAS_SVR = "__CSD__"; // 서버 별칭 정보를 정의 한 Constant 입니다
export const TIMEOUT_FILE_UPLOAD = 1000 * 60 * 3; // 파일 업로드 타이아웃 시간

// WAS URL를 정의 한 Constant 입니다
// export const URL_WAS = "http://dev.server.coolstay.co.kr:9020"; // stg2(서버요청으로 추가 - 2021.11.15 양태욱)
export const URL_WAS = "http://partnergw.stg.coolstay.co.kr"; // coolstay 2.0

export const RUNTIME_WHERE = "stg"; // 웹앱의 실행 정보(LOCAL/DEV/PROD)을 정의 한 Constant 입니다

// Roles (역활)
export const ROLE_OWNER = 1; // 사업주
export const ROLE_FRONT = 10; // 직원
export const ROLE_MANAGER = 100; // 꿀스테이 - 매니저 영업 인력
export const ROLE_ADMIN = 1000; // 꿀스테이 - 관리자

// Accessed Permission (액세스 권한)
export const PERMISSION_OWNER = [ROLE_OWNER, ROLE_MANAGER, ROLE_ADMIN]; // 사업주, 영업인력, 관리자만 접근 가능
export const PERMISSION_MANAGER = [ROLE_MANAGER, ROLE_ADMIN]; // 영업인력, 관리자만 접근 가능

// 앱 타이틀
export const APP_TITLE = "가성비 숙박 꿀스테이 파트너센터";

// 연락처
export const COOLSTAY_TEL = "1833-4123";

// 현재 물리적으로 최상위 경로 반환 메소드
export const getPathRoot = function () {
  if (BASE_NAME.trim() === "/" || BASE_NAME.trim() === "") {
    return "";
  }
  return BASE_NAME;
};

// 베타 서비스 여부
export const IS_BETA = false;

let iconMain;
let iconLogin;
const iconMainWidthBeta = 180;
const iconMainWidth = iconMainWidthBeta - 53;

if (IS_BETA === true) {
  if (SERVICE_TYPE === "pc") {
    iconMain = "/icons/logo_main_beta.svg";
    iconLogin = "/icons/logo_login_pc_beta.svg";
  } else {
    iconMain = "/icons/logo_main.svg"; // 모바일 헤더에 모텔명을 표시하는 기능 적용으로 헤더 공간이 부족하여 베타 제외함
    iconLogin = "/icons/logo_login_mobile_beta.svg";
  }
} else {
  iconMain = "/icons/logo_main.png";
  if (SERVICE_TYPE === "pc") {
    iconLogin = "/icons/logo_login_pc.png";
  } else {
    iconLogin = "/icons/logo_login_mobile.svg";
  }
}

// 로그인, 메인 상단 아이콘 이미지
export const ICON_MAIN = iconMain;
export const ICON_MAIN_WIDTH =
  iconMain === "/icons/logo_main.png" ? iconMainWidth : iconMainWidthBeta;
export const ICON_LOGIN = iconLogin;

// 마감 기준 시간
export const COOLSTAY_CLOSED_HOUR = 3;
