import React, { Component } from "react";
import { history } from "lib/history";

import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Divider,
  // , Typography
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { grey } from "@material-ui/core/colors";

/**
 * ContainerWrap Component를 변경하여 상단 게시글 타이틀을 Fixed한 Component 입니다.<p/>
 *
 * @author TaeWook Yang <didxodnr0769@pineone.com>
 * @name TitleFixedContainerWrap
 * @class
 * @component
 */

const styles = (theme) => ({
  root: {
    // border: "1px solid red",
    background: "#fff",
  },
  title: {
    "& h3": {
      padding: "0",
      margin: "0",
    },
  },
  containerHead: {
    top: 0,
    left: "auto",
    right: 0,
    position: "sticky",
    background: "#fff",
    zIndex: 1,
  },
  contentWrap: {
    top: 0,
    margin: "5%",
    position: "sticky",
    background: "#fff",
    zIndex: 1,
  },
  subTitle: {
    margin: 0,
    fontSize: "11px",
  },
  mainTitle: {
    fontSize: "14px",
    margin: "0",
    wordBreak: "break-word",
    fontWeight: "bold",
  },
});
const WhiteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
}))(Button);
class TitleFixedContainerWrap extends Component {
  render() {
    const {
      classes,
      title,
      onBack,
      children,
      boardTitle,
      boardCategory,
      customButton,
      isWriter,
    } = this.props;

    return (
      <Box
        //py={2}
        px={0}
        className={classes.root}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box id="container-wrap-head" className={classes.containerHead}>
          <Box display="flex" mt={2} mb={1}>
            <Box
              onClick={!!onBack ? onBack : defaultGotoBack}
              style={{ paddingTop: "1px" }}
              ml={1}
            >
              <strong>
                <ArrowBackIcon />
              </strong>
            </Box>
            <Box ml={2} className={classes.title}>
              {/* <h3>{title}</h3> */}{" "}
              <strong style={{ fontSize: "17px", margin: 0 }}>{title}</strong>
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            className={classes.contentWrap}
          >
            <Box>
              <p className={classes.subTitle}>{boardCategory}</p>
              <p variant="p" className={classes.mainTitle}>
                {boardTitle}
              </p>
              {/* <h3 style={{ margin: "0", wordBreak: "break-word" }}>
                {boardTitle}
              </h3> */}
            </Box>
            {customButton !== undefined && (
              <Box>
                <WhiteButton
                  variant="contained"
                  size="small"
                  disabled={!isWriter}
                  onClick={customButton}
                >
                  삭제
                </WhiteButton>
              </Box>
            )}
          </Box>
          <Divider style={{ margin: "10px 5% 3% 5%" }} />
        </Box>
        {!!children && (
          <Box
            id="container-wrap-contents"
            flex={1}
            style={{ margin: "0% 5% 3% 5%" }}
          >
            <p>{children}</p>
          </Box>
        )}
      </Box>
    );
  }
}

function defaultGotoBack() {
  history.goBack(-1);
}

//export default ContainerWrap;
const styledContainerWrap = withStyles(styles)(TitleFixedContainerWrap);

export { styledContainerWrap as TitleFixedContainerWrap };
