import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

import { withStyles } from "@material-ui/core/styles";
// import Box from "@material-ui/core/Box";
import PhoneIcon from "@material-ui/icons/Phone";

import * as configApp from "config/config-app";
import * as serviceStorage from "lib/service-storage";
import { Container, Divider } from "@material-ui/core";
import { BoxWrap } from "./BoxWrap";

let SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";
let CSS_STYLE_ROOT = "Footer-PC";
if (!SERVICE_TYPE_PC) CSS_STYLE_ROOT = "Footer-MOBILE";

const COOLSTAY_TEL = configApp.COOLSTAY_TEL.replace(/-/gi, "");
const COOLSTAY_COPYRIGHT = "Copyright © 2020 PINESTAY, all rights reserved.";
const COOLSTAY_OPERATING_TIME = "오전 10시 ~ 새벽 02시";
const COOLSTAY_LUNCH_TIME = "(점심시간: 오후12시 ~ 오후1시)";

const styles = (theme) => ({
  root: {
    pointerEvents: "all",
    "& h4, & h5, & h6": {
      padding: 0,
      margin: 0,
    },
    "& h5": {
      margin: "4px 0",
    },
    "& h6": {
      opacity: "0.7",
    },
    "& h6.default": {
      margin: "8px 0 0 0",
    },
    "& h6.small": {
      fontSize: "0.5rem",
      letterSpacing: "-0.02857em",
    },
  },
  flexBoxSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    margin: "0px",
    padding: "0px",
  },
  phoneIcon: {
    cursor: "pointer",
    border: "2px solid #cccccc",
    borderRadius: "30px",
    padding: "0.2em",
    // margin: "0.4em 0.1em 0.3em 0.1em",
    margin: "0",
    marginLeft: "1rem",
    backgroundColor: "#fff",
    fontSize: "3.5em",
  },
  noHighlight: {
    "& *,& *:focus": {
      outline: "none",
      tapHighlightColor: "rgba(0,0,0,0)",
      // webkitTapHighlightColor: "rgba(0,0,0,0)",
      webkitTapHighlightColor: "transparent",
    },
  },
});

/**
 * 공용 Footer Component 입니다.<p/>
 *
 * PC/Mobile 로그인 하단, PC 서비스 페이지 하단에 출력 됩니다.
 *
 * @author Taesung Park <pts@pineone.com>
 * @name Footer
 * @class
 * @component
 */
class Footer extends React.PureComponent {
  render() {
    const { classes, isLoggedIn } = this.props;

    console.log(
      "Footer",
      "Called render()",
      "configApp.SERVICE_TYPE : ",
      configApp.SERVICE_TYPE
    );

    return (
      <div id={CSS_STYLE_ROOT} className={classes.root}>
        {SERVICE_TYPE_PC && (
          <RenderInnerOnPC classes={classes} isLoggedIn={isLoggedIn} />
        )}
        {!SERVICE_TYPE_PC && (
          <RenderInnerOnMobile classes={classes} isLoggedIn={isLoggedIn} />
        )}
      </div>
    );
  }
}

const styledFooter = withStyles(styles)(Footer);
export { styledFooter as Footer };

function RenderInnerOnPC({ classes, isLoggedIn }) {
  return (
    <Container id="page_container">
      <BoxWrap style={{ backgroundColor: "#F5F6F8" }}>
        <h5>
          <Links isLoggedIn={isLoggedIn} />
        </h5>
        <h6 className="default">
          {`${configApp.APP_TITLE} ${configApp.COOLSTAY_TEL} / 이용시간 : ${COOLSTAY_OPERATING_TIME} ${COOLSTAY_LUNCH_TIME}`}
        </h6>
        <h6 className="default">{COOLSTAY_COPYRIGHT}</h6>
      </BoxWrap>
    </Container>
  );
}

function RenderInnerOnMobile({ classes, isLoggedIn }) {
  let name = "미지정";
  let callNumber = COOLSTAY_TEL;
  let consultant = serviceStorage.getConsultant();
  if (!!consultant) {
    name = consultant.name || name;
    callNumber = consultant.callNumber || callNumber;
  }
  consultant = null;

  return (
    <>
      {isLoggedIn && (
        <div
          style={
            {
              // 양태욱 주석처리 매니저 정보 상단에도 구분선을 추가하기 위하여 Divider 추가
              // borderBottom: "1px solid #ddd",
              // marginBottom: "1rem",
              // paddingTop: "1rem",
              // paddingBottom: "1rem",
            }
          }
        >
          <Divider style={{ marginBottom: "1rem" }} />
          <div className={classes.flexBoxSpaceBetween}>
            <div>
              <h3 className={classes.title}>담당 매니저</h3>
              <h4>{name}</h4>
            </div>
            <div className={classes.noHighlight}>
              <a href={`tel:${callNumber}`}>
                <PhoneIcon color="primary" className={classes.phoneIcon} />
              </a>
            </div>
          </div>
        </div>
      )}
      <div>
        {/* 20200904 박태성C - 로그인이 안된 상태이면 구분선은 미출력 하도록 처리 */}
        {isLoggedIn && <Divider style={{ margin: "1rem 0" }} />}
        <h4 className={classes.title}>
          {configApp.APP_TITLE.replace("가성비 숙박 ", "")}
        </h4>
        <h5>
          <Links isLoggedIn={isLoggedIn} />
        </h5>
        <div className={classes.flexBoxSpaceBetween}>
          <div>
            <h6 className="default">
              {`${configApp.APP_TITLE} ${configApp.COOLSTAY_TEL}`}
              <br />
              {`파트너센터 이용시간 : ${COOLSTAY_OPERATING_TIME} `}
              {isLoggedIn && <br />}
              {`${COOLSTAY_LUNCH_TIME}`}
            </h6>
            <h6 className="default">{COOLSTAY_COPYRIGHT}</h6>
          </div>
          {isLoggedIn && (
            <div className={classes.noHighlight}>
              <a href={`tel:${COOLSTAY_TEL}`}>
                <PhoneIcon color="primary" className={classes.phoneIcon} />
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function Links({ isLoggedIn }) {
  return (
    <>
      <Link target="_blank" href="http://biz.coolstay.co.kr">
        서비스 소개
      </Link>
      &nbsp;
      {isLoggedIn && (
        <>
          |&nbsp;
          <RouterLink to="/termIntro?tab=1">개인정보 처리방침</RouterLink>&nbsp;
          |&nbsp;
          <RouterLink to="/termIntro?tab=0">이용약관</RouterLink>
        </>
      )}
    </>
  );
}
