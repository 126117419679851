import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { changeRequest } from "system/redux-ducks/breadcrumb";

import MetisMenu from "haofun-react-metismenu";
import RouterLink from "lib/service-react-metismenu-router-link/RouterLink";
import TemplateLayout from "TemplateLayout";

import * as configApp from "config/config-app";
import * as serviceBrowser from "lib/service-browser";
import * as serviceStorage from "lib/service-storage";
import * as serviceNotice from "lib/service-notice";
import * as serviceRefresh from "lib/service-refresh";
import * as helperLayouts from "lib/helper-layouts";
import * as serviceScheduler from "lib/service-scheduler";
import { logoutRequest } from "system/redux-ducks/authentication";

import { POPUP_ACTION_OK, openAlertPopupWithPromise } from "lib/helper-popup";

const TAG = "[system/client/DefaultLayoutContainer.js]";
// const SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";
const COOLSTAY_CLOSED_HOUR = configApp.COOLSTAY_CLOSED_HOUR;
const SCHEDULE_TIME_CLOSING = "SCHEDULE_TIME_CLOSING"; // 마감 시간

// 강제 로그아웃
let FORCE_LOGOUT = false;

/**
 * <h4>DefaultLayoutContainer는</h4>
 * 생성 시 사용자 계정이 접근 할 수 있는 페이지를 구성하며,<br/>
 * URL과 매칭 되는 좌측 메뉴의 포커싱 처리를 제어 합니다.<br/>
 *
 * 접근 권한(ex : 계정, 모텔 정보)이 없을 경우에는 강제 로그아웃 처리를 수행 합니다.
 *
 * ```
 * - 사용자 계정이 접근 할 수 있는 페이지 목록 구성
 * - URL과 매칭 되는 좌측 메뉴의 포커싱 처리
 * - 접근 권한(ex : 계정, 모텔 정보)이 없을 경우에는 강제 로그아웃 처리 수행
 * ```
 *
 * @author Taesung Park <pts@pineone.com>
 * @name DefaultLayoutContainer
 * @class
 * @component
 * @see AppContainer
 * @see Root
 * @see TemplateLayout
 */
class DefaultLayoutContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCatchRefMetisMenu = this.handleCatchRefMetisMenu.bind(this);
    this.menuRef = null;
    this.firstFocusURL = null;

    this.timeScheduleClosing = 0;
    this.isOpenedScheduleClosing = false;

    helperLayouts.clearAll();
    let routes = props.routes;
    // let open = props.open;
    let role = serviceStorage.getUserRole(serviceStorage.getIdentity());

    let menus = this.handleCreateMenu(role, routes);
    let views = this.handleCreateView(role, routes);
    this.state = {
      menus: menus,
      views: views,
    };
    menus = views = routes = null;
    this.loading = this.loading.bind(this);
    this.unlisten = this.props.history.listen(this.onLocationChange.bind(this));
    this.findLocationChange(this.props.location);

    this.timerStartService = null;
    this.addSchedules();
  }

  addSchedules = () => {
    let d = new Date();
    d.setHours(COOLSTAY_CLOSED_HOUR); // 새벽 3시. 0 ~ 23
    serviceScheduler.addRepeatScheduleAtHours(d, SCHEDULE_TIME_CLOSING, false);
    d = null;
  };

  handleCatchRefMetisMenu(ref) {
    this.menuRef = ref;
    if (this.firstFocusURL) {
      this.menuRef.changeActiveLinkTo(this.firstFocusURL);
      this.firstFocusURL = null;
    }
  }

  handleCreateMenu(role, routes) {
    console.log(TAG, "Called handleCreateMenu()");
    return (
      <MetisMenu
        content={helperLayouts.getMenusAtRoutes(role, routes)}
        LinkComponent={RouterLink}
        activeLinkFromLocation={true}
        iconNamePrefix=""
        ref={this.handleCatchRefMetisMenu}
      />
    );
  }

  handleCreateView(role, routes) {
    console.log(TAG, "Called handleCreateView()");
    return helperLayouts.getFilterRoute(role, routes).map((model, idx) => {
      const Component = model.route.component;
      return !!Component ? (
        <Route
          key={model.route.key || idx}
          path={model.route.path}
          exact={model.route.exact}
          render={(props) => <Component {...props} />}
        />
      ) : null;
    });
  }

  loading() {
    return <div>Loading...</div>;
  }

  componentDidUpdate(prevProps, prevState) {
    const { name, timeSchedule } = this.props.schedule;
    const { pathname } = this.props.location;

    console.log(TAG, "componentDidUpdate", pathname);

    if (name === SCHEDULE_TIME_CLOSING) {
      if (this.timeScheduleClosing !== timeSchedule) {
        this.timeScheduleClosing = timeSchedule;
        if (!this.isOpenedScheduleClosing) {
          this.isOpenedScheduleClosing = true;
          openAlertPopupWithPromise(
            "안내",
            `꿀스테이의 오늘은 새벽 ${COOLSTAY_CLOSED_HOUR}시까지 입니다.\n\n화면에서 보시는 '오늘'도\n새벽 ${COOLSTAY_CLOSED_HOUR}시 기점으로 다음날로 전환이\n되오니 서비스 이용에 참고해 주세요.`,
            "완료",
            true
          )
            .then((actionType) => {
              if (actionType !== POPUP_ACTION_OK) return;
              window.location.replace(window.location.pathname);
              // window.location.reload();
            })
            .finally(() => {
              this.isOpenedScheduleClosing = false;
            });
        }
      }
    }
  }

  componentDidMount() {
    console.log(TAG, "componentDidMount");
    serviceBrowser.removeClassNameToBody("logout");

    this.timerStartService = setTimeout(() => {
      const isAccessed =
        !!this.props.isLoggedIn && !!serviceStorage.getConnectedMotelKey();
      if (isAccessed) {
        // PC 공지 팝업 실행
        serviceNotice.startService();
        // 스케쥴러 실행(ex : 마감 기준 시간에 따른 갱신 처리)
        serviceScheduler.startService(true);
        // 갱신
        serviceRefresh.startService();
        serviceRefresh.updateRefreshTimeByMotelKey(
          serviceStorage.getConnectedMotelKey()
        );
      }
    }, 2000);
  }

  componentWillUnmount() {
    console.log(TAG, "componentWillUnmount");

    serviceNotice.stopService();
    serviceScheduler.stopService();
    serviceRefresh.stopService();

    !!this.timerStartService && clearTimeout(this.timerStartService);
    this.timerStartService = null;
    !!this.unlisten && this.unlisten();
    this.unlisten = null;
    this.menuRef = null;
  }

  onLocationChange(e) {
    const isAccessed =
      this.props.isLoggedIn && !!serviceStorage.getConnectedMotelKey();
    console.log(TAG, "onLocationChange isAccessed=", isAccessed);
    isAccessed && this.findLocationChange(e);
  }

  findLocationChange(e) {
    //페이지 바뀌고 난후 스크롤을 제일 위로 이동 시켜줌 처리
    window.scrollTo(0, 0);

    let pathKey = helperLayouts.convertPathKey(e.pathname);
    let isMenuURL = helperLayouts.isMenuURL(pathKey);
    let id = helperLayouts.findParentMenuIdByPath(pathKey);

    // 20200605 박태성C - 통계의 경우 부모가 없기 때문에 현재 URL에서 id를 추출해야 함
    if (!id) id = helperLayouts.findMenuIdByPath(pathKey);

    if (!isMenuURL && !id) {
      console.log(TAG, "URL이 잘못 되었거나, 진짜로 없는 경우");
      let urlList = e.pathname.split("/");
      let i = 1;
      let len = urlList.length;
      let url = "";
      let tmpKey = null;
      for (; i < len; i++) {
        url += "/" + urlList[i];
        tmpKey = helperLayouts.convertPathKey(url);
        if (!helperLayouts.isMenuURL(tmpKey)) {
          break;
        }
        pathKey = tmpKey;
        isMenuURL = helperLayouts.isMenuURL(pathKey);
        id = helperLayouts.findMenuIdByPath(pathKey);
      }
      urlList.splice(0, urlList.length);
      urlList = null;
    }

    console.log(TAG, "pathKey", pathKey, "isMenuURL", isMenuURL, "id", id);

    if (!id) {
      this.menuRef && this.menuRef.changeActiveLinkId("JS-HIDE");
    } else {
      if (this.menuRef) {
        this.menuRef.changeActiveLinkId(isNaN(id) ? id : parseInt(id));
      } else {
        // 아직 MetisMenu의 Ref가 설정 되지 않음
        this.firstFocusURL = helperLayouts.findPathByMenuId(id);
      }
    }

    // let breadcrum = helperLayouts.findBreadcrumByPathKey(pathKey);
    // breadcrum = breadcrum || "";
    // 20200325 박태성C : Breadcrumb 처리
    // console.log(TAG, "Routing이 발생하여 Breadcrumb 이벤트 발송함");
    // this.props.changeRequest({ history: breadcrum });
  }

  render() {
    console.log(TAG, "Called render()");

    const { isLoggedIn, location } = this.props;
    const { menus, views } = this.state;
    // const isPublic = helperLayouts.isPublicURL(location.pathname);
    const motelKey = serviceStorage.getConnectedMotelKey();

    const isAccessed = isLoggedIn && !!motelKey;

    console.log(
      TAG,
      `location.pathname=${location.pathname}, isLoggedIn=${isLoggedIn}, isAccessed=${isAccessed}`
    );

    /* 
    -----------------------------------------------------------------------------------------------
    예외처리 
    -----------------------------------------------------------------------------------------------
    1. 작성자 : 박태성 책임
    2. 작성일 : 2020년 8월 21일
    3. 관련 이슈 : 0006463
    4. 이슈 내용
      4.1 무한 화면 갱신 현상

        4.1.1. 이슈 설명
          로그인 후 다음 화면 이동 시 `계정` 정보는 존재하나 `모텔` 정보가 없을 경우 
          `외부 요인`에 의해 무한 화면 갱신 현상 발생하여 빈 화면이 출력 됨

        4.1.2. 외부 요인

          4.1.2.1. 화면 이동 시 브라우저 Back 선택

            - 로그인을 수행하면 `계정` 정보가 생성 됨.
            - 로그인 후 다음 화면에서 제휴점을 선택하면, `제휴점` 정보가 생성 됨.
            - 동일 브라우저에서 로그인, 로그아웃을 여러 번 수행 할 경우 브라우저 히스토리에 `/`가 적재 됨.
            - `로그인 화면`에서 로그인 후 `제휴점 선택 화면`으로 이동 중에 브라우저 Back 선택하면 `무한 화면 갱신` 현상 발생 됨.

            * 재현 절차
              1) 최초 진입 후 로그인 후 대쉬보드 진입
              2) 다른 화면으로 이동 한 후 로그 아웃
              3) 로그인 진행 후 화면 전환 시 브라우저 상단 Back키 클릭
              4) 바로 재현 되지 않을 경우 약 3~4회 시도

        4.1.3. 예외처리 내용

          - `계정` 및 `모텔` 정보가 있어야 대쉬보드로 이동 되도록 처리 함.
          - 강제 로그 아웃 코드 추가

          * 강제 로그아웃 코드
            1) 판단 기준
              `계정` 정보는 존재하나, `제휴점` 정보가 없을 경우 Location 정보 가 `/`로 들어올 때
              (`/`로 올 경우 `대시보드` 화면으로 이동 처리 되도록 구현 되어 있음.)

            2) 처리 내용
              - 이상 상태라 판단하여 화면은 미출력 처리
              - `무한 화면 갱신` 현상이 발생 되지 않도록, 강제 로그 아웃 처리하여 `로그인 화면`으로 이동 처리 함.
    */

    if (isLoggedIn && !motelKey && location.pathname === "/") {
      if (FORCE_LOGOUT === false) {
        console.log(TAG, `강제 로그 아웃 실행`);
        FORCE_LOGOUT = true;
        this.props.logoutRequest().then(() => {
          FORCE_LOGOUT = false;
          console.log(TAG, `강제 로그 아웃 종료`);
        });
      }

      console.log(TAG, "이상 상태라 판단하여 미출력 처리");
      return null;
    }

    console.log(TAG, "정상 출력 처리");

    return (
      <TemplateLayout menus={menus} isLoggedIn={isLoggedIn}>
        <Suspense fallback={this.loading()}>
          <Switch>
            {!isAccessed && <Redirect to="/login" />}
            {isAccessed && views}
            {isAccessed && <Redirect from="/" to="/dashboard" />}
            {location.pathname !== "/" && <Redirect to="/not-found" />}
          </Switch>
        </Suspense>
      </TemplateLayout>
    );
  }
}

export default connect(
  (state) => ({
    schedule: state.scheduler.schedule,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        changeRequest,
        logoutRequest,
      },
      dispatch
    )
)(DefaultLayoutContainer);
