import React from "react";
import { Select, MenuItem, FormControl, TableCell } from "@material-ui/core";

/** 테이블의 '사용가능일' 항목 컴포넌트입니다. */
function CouponSelect({
  onChange = null,
  discountType = null,
  value,
  index,
  itemList = [],
  disabled = true,
  width = "22%",
  error,
}) {

  return (
    <TableCell align="center" style={{ width: width }}>
      <FormControl variant="outlined" fullWidth size="small" error={error}>
        <Select
          value={value}
          onChange={onChange}
          name={`${index}`}
          disabled={disabled}
        >
          {itemList.map((item, i) => (
            <MenuItem
              value={i}
              key={`day_type_${i}`}
              style={{
                display: `${item.hide === true ? "none" : "block"}`,
              }}
              disabled={item.disabled === true}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  );
}

export default CouponSelect;
