import React from "react";
import { Box, Paper, Tooltip, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

/**
 * 판매 통계 컴포넌트 입니다.<p/>
 *
 * @author max5500 <max5500@pineone.com>
 * @name StatSelling
 * @function
 * @component
 */

const useStyles = makeStyles((theme) =>
  createStyles({
    contents: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        height: theme.spacing(15),
      },
    },
    content: {
      width: theme.spacing(17),
      textAlign: "center",
    },
    title: {},
    subTitle: {},
    up: {
      color: "#f44336",
    },
    down: {
      color: "#2196f3",
    },
  })
);

function StatSelling() {
  const classes = useStyles();
  return (
    <Box m={1} className="test">
      <Paper elevation={0} variant="outlined" square>
        <span className={classes.title}>
          Title
          <Tooltip title="ToolTip">
            <i className="fa fa-fa fa-question-circle"></i>
          </Tooltip>
        </span>
        <div className={classes.contents}>
          <Grid container>
            <Grid
              container
              item
              xs={4}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Paper
                className={classes.content}
                elevation={0}
                variant="outlined"
                square
              >
                <p>title</p>
                <p>text</p>
              </Paper>
            </Grid>
            <Grid
              container
              item
              xs={4}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Paper
                className={classes.content}
                elevation={0}
                variant="outlined"
                square
              >
                <p>title</p>
                <p>text</p>
              </Paper>
            </Grid>
            <Grid
              container
              item
              xs={4}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Paper
                className={classes.content}
                elevation={0}
                variant="outlined"
                square
              >
                <p>title</p>
                <p>text</p>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <div className={classes.subTitle}>
          <span>subTitle</span>
          <span className={classes.down}>⬇</span>
          <span className={classes.up}>⬆</span>
        </div>
      </Paper>
    </Box>
  );
}

export { StatSelling };
