/**
 * 갱신 이벤트를 발생 시키는 모듈 입니다.<p/>
 *
 * @module lib/service-refresh
 */

import { notifyScheduleRequest } from "system/redux-ducks/scheduler";
import * as serviceScheduler from "lib/service-scheduler";
const TAG = "[lib/service-refresh.js]";

export const AUTO_REFRESH = "@service-refresh/AUTO_REFRESH";

const TIME_MINUTE = 1000 * 60;
const AUTO_REFRESH_MINUTE = 30; // 자동 갱신 주기. 30분
const AUTO_REFRESH_TIME = TIME_MINUTE * AUTO_REFRESH_MINUTE;

let __isProvided = false;
let __isStartService = false;
let __store = null;
let __unsubscribe = null;
let __nextRefreshTime = 0;
let __timeAfterEvent = 0;
let __isSending = false;

export function provider(store) {
  if (__isProvided) return;
  __isProvided = true;
  __store = store;
}

export function startService() {
  if (!__isProvided) return;

  stopService();
  __isStartService = true;
  __nextRefreshTime = Date.now() + AUTO_REFRESH_TIME;
  __timeAfterEvent = getRandomIntInclusive(0, 60) * 1000;
  __unsubscribe = __store.subscribe(onSubscribe);

  console.log(
    TAG,
    `Called startService(). [자동 갱신 주기] 초기 설정 (${Utils_getFormatDate(
      new Date(__nextRefreshTime)
    )})`
  );
}

export function stopService() {
  if (!__isProvided) return;
  !!__unsubscribe && __unsubscribe();
  __unsubscribe = null;
  __nextRefreshTime = 0;
  __isStartService = false;
  console.log(TAG, "Called startService()");
}

export function updateRefreshTimeByMotelKey(key) {
  if (!!key) {
    /*
      1. 자동 갱신 주기 : 매번 30분 간격으로 갱신
      2. 업주PC 서버 접속(요청) 분산 정책
        2-1. 업소 고유번호(순번) / 자동 갱신 주기 = 나머지 값(MOD)
              1단계 (분)
                  Ex) 181개 / 30분 = 1
                        24개 / 30분 = 24
              2단계 (초)
                  60초 이내에서 랜덤[Random(60)]
              3단계
                  1단계 예시 1번 기준으로 
                  1번의 값(나머지) - 1~30분 사이에 1회 접속
                                          31~60분 사이에 1회 접속
                  Ex) 나머지 값(1) = 1분 서버 접속 / 31분 서버 접속
                      나머지 값(24) = 24분 서버 접속 / 58분 서버 접속
      3. 분(Min)과 초(Sec) 계산 시점 : 로그인 시
    */

    // 1단계 (분)
    __nextRefreshTime = (29 % AUTO_REFRESH_MINUTE) * 1000 * 60;
    // 2단계 (초)
    __timeAfterEvent = getRandomIntInclusive(0, 60) * 1000;
    __nextRefreshTime = Date.now() + __nextRefreshTime + __timeAfterEvent;



    console.log(
      TAG,
      `제휴점 키 ${key}를 기반으로 [자동 갱신 주기] 업데이트 (${Utils_getFormatDate(
        new Date(__nextRefreshTime)
      )})`
    );
  } else {
    console.log(
      TAG,
      `\n자동 갱신 주기 구성 실패 함.\n현재 제휴점 키는 ${key}.\n제휴점 키는 Number형으로 전달 되어야 합니다.`
    );
  }
}

function onSubscribe() {
  if (!!__store) {
    let state = __store.getState();
    if (
      !!state &&
      state.hasOwnProperty("scheduler") &&
      state.scheduler.hasOwnProperty("schedule")
    ) {
      // 스케쥴러 이벤트는 1분 단위로 발생 되므로,
      let schedule = state.scheduler.schedule;
      if (serviceScheduler.SCHEDULE_TICK === schedule.name) {
        // 자동 갱신 이벤트 발생 시점 이후에 실행 됩니다.
        if (__nextRefreshTime < Date.now()) {
          const timeRefresh = __nextRefreshTime;
          // 브라우저 상태가 비활성이 되면 JS 엔진이 잠시 Pause가 될 수 있습니다.
          // 브라우저 활성화 시 JS 엔진이 구동 되면서, 동시 다발적으로 이벤트가 발생 될 수 있어
          // 본 모듈에서는 `전송 여부`에 따라 이벤트 발송 처리를 하도록 구현 하였습니다.
          if (__isSending === false) {
            __isSending = true;

            console.log(
              TAG,
              `자동 갱신 이벤트는 약 ${Math.floor(
                __timeAfterEvent / 1000
              )} 초 뒤에 발송 됩니다.`
            );

            setTimeout(() => {
              console.log(
                TAG,
                `자동 갱신 이벤트(${AUTO_REFRESH}) 발송. timeRefresh is ${timeRefresh}`
              );

              // document.dispatchEvent(
              //   new CustomEvent(AUTO_REFRESH, {
              //     detail: {
              //       // 실제 발송 된 시간을 보낸다.
              //       timeRefresh: timeRefresh + __timeAfterEvent,
              //     },
              //   })
              // );
              // __isSending = false;

              __store &&
                notifyScheduleRequest({
                  name: AUTO_REFRESH,
                  timeSchedule: timeRefresh + __timeAfterEvent,
                })(__store.dispatch).then(() => {
                  __isSending = false;
                });
            }, __timeAfterEvent);
          }
          __nextRefreshTime += AUTO_REFRESH_TIME;
        }
      }
      schedule = null;
    }
    state = null;
  }
}

// https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Math/random
function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //최댓값도 포함, 최솟값도 포함
}

function Utils_toDigit(num) {
  if (num < 10) return `0${num}`;
  return `${num}`;
}

function Utils_getFormatDate(d) {
  return `${d.getFullYear()}-${Utils_toDigit(1 + d.getMonth())}-${Utils_toDigit(
    d.getDate()
  )} ${Utils_toDigit(d.getHours())}:${Utils_toDigit(
    d.getMinutes()
  )}:${Utils_toDigit(d.getSeconds())}`;
}
