class ApiQueue {

    constructor() {
        this._arr = []
    }

    add(api) {
        this._arr.push(api);
    }

    shift() {
        return this._arr.shift();
    }

    get () {
        return this._arr;
    }

    clear() {
        this._arr = [];
    }
}

export default ApiQueue;