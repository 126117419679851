import React, { memo } from "react";
// import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import commaFormater from "utils/commaFormater";
import { TextFieldLengthNumberOnly } from "components";

const RoomCoastInputComponentRaw = (props) => {
  const useStyles = makeStyles((theme) => ({
    InputGray: {
      display: "inline",
      "& fieldset": {
        border: "1px solid #ccc !important",
      },
      "& input:disabled": {
        color: "#000000",
      },
    },
  }));

  const classes = useStyles();

  const { noMember, dayCode, roomKey, changeFunc, inputRef } = props;

  // const onChangeAction = (e) => {
  //   let targetStr = e.currentTarget.value.replace(/\,/g,'')
  //   e.currentTarget.value = commaFormater(targetStr.substring(0,6))
  //   changeFunc(targetStr, dayCode, roomKey)
  // }

  const onFocusAction = (e) => {
    e.currentTarget.value = e.currentTarget.value.replace(/\,/g, "");
  };

  const onBlurAction = (e) => {
    e.currentTarget.value = commaFormater(e.currentTarget.value);
  };

  // const resetValue = () => {
  //   alert(resetValue);
  // };
  return (
    <Box>
      <TextFieldLengthNumberOnly
        variant="outlined"
        size="small"
        maxLength={6}
        className={classes.InputGray}
        inputProps={{
          style: {
            textAlign: "right",
          },
        }}
        onFocus={onFocusAction}
        onBlur={onBlurAction}
        inputRef={inputRef}
        defaultValue={commaFormater(noMember)}
        onChange={({ currentTarget: { value } }) => {
          changeFunc(value, dayCode, roomKey);
        }}
      />
    </Box>
  );
};

const RoomCoastInputComponent = memo(RoomCoastInputComponentRaw);
export { RoomCoastInputComponent };
