import React from "react";
import { Route, Redirect } from "react-router-dom";

/**
 * 인증 여부에 따라 화면 이동을 처리하기 위한 Component 입니다<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name AuthRoute
 * @component
 * @example
 * const { status } = this.props;
 * const { valid, isLoggedIn } = status;
 * if (valid === undefined) return null;
 *
 * return (
 *  <>
 *    <Switch>
 *      <AuthRoute
 *        exact={true}
 *        path="/"
 *        authenticated={isLoggedIn}
 *        render={props => <MainPage {...props} />}
 *        />
 *    </Switch>
 *  </>
 * );
 */
function AuthRoute({ authenticated, component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          render ? (
            render(props)
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default AuthRoute;
