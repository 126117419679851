import React, { useState, useCallback } from "react";
import commaFormater from "utils/commaFormater";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, IconButton, OutlinedInput, FormControl } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/**
 * 업 다운 버튼이 있는 컨트롤러 Component 입니다.<p/>
 *
 * 해당 컴포넌트는 기본적으로 언컨트롤러 방식으로 동작 처리 합니다
 *
 * @author Taesung Park <pts@pineone.com>
 * @name UpDownControlUC
 * @class
 * @component
 */

const styles = (theme) => ({
  root: {},
  formControl: {
    display: "inline",
  },
  outlinedInput: { display: "block", padding: "0 8px" },
  boxUp: {
    border: "1px solid #ddd",
    borderRadius: "5px 5px 0 0",
    height: "19px",
  },
  boxDown: {
    border: "1px solid #ddd",
    borderRadius: "0 0 5px 5px",
    height: "19px",
  },
  iconButton: {
    padding: "0 0 6px 0",
  },
  expandIcon: {
    fontSize: 14,
  },
});

const outlinedInputProps = {
  style: { padding: "10px 0px", textAlign: "right" },
};

let COUNT_COMPONENT = 0;
const REX_NUM = /[^0-9]/g;

function UpDownControlUC({
  classes,
  type,
  name,
  callback,
  initialVal,
  interval,
  min,
  max,
}) {
  const [state, setState] = useState(() => {
    COUNT_COMPONENT++;
    return {
      unique: `UD_${COUNT_COMPONENT}_` + Date.now(),
    };
  });

  const { unique } = state;

  const increment = useCallback(
    (value) => {
      let newValue = parseInt(value) + interval;
      if (newValue > max) newValue = max;
      return newValue;
    },
    [interval, max]
  );

  const decrement = useCallback(
    (value) => {
      let newValue = parseInt(value) - interval;
      if (newValue < min) newValue = min;
      return newValue;
    },
    [interval, min]
  );

  const handleUp = useCallback(
    (evt) => {
      let elem = evt.currentTarget;
      let target = document.querySelector(`#${unique}`);
      if (!!target) {
        let value = parseInt(target.value.replace(REX_NUM, ""));
        let newValue = increment(value);
        if (value !== newValue) {
          target.value = 1000 < newValue ? commaFormater(newValue) : newValue;
          !!callback && callback(newValue, type, name);
        }
      }
      elem = target = null;
    },
    [callback, type, name]
  );

  const handleDown = useCallback(
    (evt) => {
      let elem = evt.currentTarget;
      let target = document.querySelector(`#${unique}`);
      if (!!target) {
        let value = parseInt(target.value.replace(REX_NUM, ""));
        let newValue = decrement(value);
        if (value !== newValue) {
          target.value = 1000 < newValue ? commaFormater(newValue) : newValue;
          !!callback && callback(newValue, type, name);
        }
      }
      elem = target = null;
    },
    [callback, type, name]
  );

  return (
    <Box display="flex" className={classes.root}>
      <Box>
        <FormControl variant="outlined" className={classes.formControl}>
          <OutlinedInput
            disabled
            value={commaFormater(parseInt(initialVal))}
            id={unique}
            className={classes.outlinedInput}
            inputProps={outlinedInputProps}
          />
        </FormControl>
      </Box>
      <Box ml={0.5}>
        <Box onClick={handleUp} className={classes.boxUp}>
          <IconButton className={classes.iconButton}>
            <ExpandLessIcon className={classes.expandIcon} />
          </IconButton>
        </Box>
        <Box onClick={handleDown} className={classes.boxDown}>
          <IconButton className={classes.iconButton}>
            <ExpandMoreIcon className={classes.expandIcon} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

const styledUpDownControl = withTheme(
  withStyles(styles)(React.memo(UpDownControlUC))
);
export { styledUpDownControl as UpDownControlUC };
