/**
 * 팝업 String
 *
 * @version 0.0.1
 * @author <max5500@pineone.com>
 * @module constant/PopupConsts
 */

import * as configApp from "config/config-app";
import PopupConstsMobile from "./PopupConstsMobile";
import PopupConstsPC from "./PopupConstsPC";

export default configApp.SERVICE_TYPE === "mobile"
  ? PopupConstsMobile
  : PopupConstsPC;
