/**
 * 대시보드 쿠폰 관련 기능의 헬퍼 모듈 입니다<p/>
 *
 * svn revision 8483, 9132
 *
 * @module lib/helper-coupon
 */
import React from "react";
import { deepFreeze, objectDeepClone } from "utils/ObjectHelper";

const EASY_COUPON_TYPES = [
  {
    name: "1단계 기본",
    coupon_template: "CT017",
    coupons: [
      {
        discount_amount: 10000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 10,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 10,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 10,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 10,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 10,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
    ],
    is_region_coupon: true,
  },
  {
    name: "2단계 균형",
    coupon_template: "CT018",
    coupons: [
      {
        discount_amount: 12000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 10000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 8000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 2,
        item_type: "010101",
        day_type: "ALL",
      },
      {
        discount_amount: 10,
        total_amount: 4,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 10,
        total_amount: 3,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
    ],
    is_region_coupon: true,
  },
  {
    name: "3단계 든든",
    coupon_template: "CT019",
    coupons: [
      {
        discount_amount: 12000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 10000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 8000,
        total_amount: 4,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 4,
        item_type: "010101",
        day_type: "ALL",
      },
      {
        discount_amount: 10,
        total_amount: 3,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 10,
        total_amount: 3,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
    ],
    is_region_coupon: true,
  },
  {
    name: "4단계 꿀스클럽",
    coupon_template: "CT020",
    coupons: [
      {
        discount_amount: 12000,
        total_amount: 3,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 10000,
        total_amount: 3,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 8000,
        total_amount: 6,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 6,
        item_type: "010101",
        day_type: "ALL",
      },
      {
        discount_amount: 10,
        total_amount: 3,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 10,
        total_amount: 3,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
    ],
    is_region_coupon: true,
  },
  {
    name: "만능형 1단계",
    coupon_template: "CT007",
    coupons: [
      {
        discount_amount: 10000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 8000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 6000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 1,
        item_type: "010101",
        day_type: "ALL",
      },
    ],
  },
  {
    name: "만능형 2단계",
    coupon_template: "CT008",
    coupons: [
      {
        discount_amount: 10000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 8000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 6000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 2,
        item_type: "010101",
        day_type: "ALL",
      },
    ],
  },
  {
    name: "만능형(할인율) 1단계",
    coupon_template: "CT013",
    coupons: [
      {
        discount_amount: 20,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 20,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 20,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 20,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 20,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 20,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
    ],
  },
  {
    name: "만능형(할인율) 2단계",
    coupon_template: "CT014",
    coupons: [
      {
        discount_amount: 20,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 20,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 20,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 20,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 20,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 20,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
    ],
  },
  {
    name: "숙박 강화형 1단계",
    coupon_template: "CT001",
    coupons: [
      {
        discount_amount: 10000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 8000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 6000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 4000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 1,
        item_type: "010101",
        day_type: "ALL",
      },
    ],
  },
  {
    name: "숙박 강화형 2단계",
    coupon_template: "CT002",
    coupons: [
      {
        discount_amount: 10000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 8000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 6000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 4000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 2,
        item_type: "010101",
        day_type: "ALL",
      },
    ],
  },
  {
    name: "대실 강화형 1단계",
    coupon_template: "CT003",
    coupons: [
      {
        discount_amount: 8000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 5000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 1,
        item_type: "010101",
        day_type: "ALL",
      },
    ],
  },
  {
    name: "대실 강화형 2단계",
    coupon_template: "CT004",
    coupons: [
      {
        discount_amount: 8000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 5000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 2,
        item_type: "010101",
        day_type: "ALL",
      },
    ],
  },
  {
    name: "평일 강화형 1단계",
    coupon_template: "CT005",
    coupons: [
      {
        discount_amount: 10000,
        total_amount: 1,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 8000,
        total_amount: 1,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 6000,
        total_amount: 1,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 2000,
        total_amount: 1,
        item_type: "010101",
        day_type: "DAYOFWEEK",
      },
    ],
    hide: true,
  },
  {
    name: "평일 강화형 2단계",
    coupon_template: "CT006",
    coupons: [
      {
        discount_amount: 10000,
        total_amount: 2,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 8000,
        total_amount: 2,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 6000,
        total_amount: 2,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 3000,
        total_amount: 2,
        item_type: "010100",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 3000,
        total_amount: 2,
        item_type: "010100",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 2000,
        total_amount: 2,
        item_type: "010101",
        day_type: "DAYOFWEEK",
      },
    ],
    hide: true,
  },
  {
    name: "실속형 1단계",
    coupon_template: "CT009",
    coupons: [
      {
        discount_amount: 5000,
        total_amount: 1,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 5000,
        total_amount: 1,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 5000,
        total_amount: 1,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 2000,
        total_amount: 1,
        item_type: "010101",
        day_type: "DAYOFWEEK",
      },
    ],
    hide: true,
  },
  {
    name: "실속형 2단계",
    coupon_template: "CT010",
    coupons: [
      {
        discount_amount: 5000,
        total_amount: 2,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 5000,
        total_amount: 2,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 5000,
        total_amount: 2,
        item_type: "010102",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 3000,
        total_amount: 2,
        item_type: "010100",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 3000,
        total_amount: 2,
        item_type: "010100",
        day_type: "DAYOFWEEK",
      },
      {
        discount_amount: 2000,
        total_amount: 2,
        item_type: "010101",
        day_type: "DAYOFWEEK",
      },
    ],
    hide: true,
  },
  {
    name: "보급형 1단계",
    coupon_template: "CT011",
    coupons: [
      {
        discount_amount: 5000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 5000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 5000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 1,
        item_type: "010101",
        day_type: "ALL",
      },
    ],
  },
  {
    name: "보급형 2단계",
    coupon_template: "CT012",
    coupons: [
      {
        discount_amount: 5000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 5000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 5000,
        total_amount: 2,
        item_type: "010102",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 3000,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
      },
      {
        discount_amount: 2000,
        total_amount: 2,
        item_type: "010101",
        day_type: "ALL",
      },
    ],
  },
  {
    name: "보급형(할인율) 1단계",
    coupon_template: "CT015",
    coupons: [
      {
        discount_amount: 15,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 15,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 15,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 15,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 15,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 15,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
    ],
  },
  {
    name: "보급형(할인율) 2단계",
    coupon_template: "CT016",
    coupons: [
      {
        discount_amount: 15,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 15,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 15,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 15,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 15,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
      {
        discount_amount: 15,
        total_amount: 2,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "RATE",
      },
    ],
  },
  {
    name: "사장님 자유발행형",
    coupon_template: "CT100",
    coupons: [
      {
        discount_amount: 10000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
        discount_type: "AMOUNT",
      },
      {
        discount_amount: 8000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
        discount_type: "AMOUNT",
      },
      {
        discount_amount: 6000,
        total_amount: 1,
        item_type: "010102",
        day_type: "ALL",
        discount_type: "AMOUNT",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "AMOUNT",
      },
      {
        discount_amount: 3000,
        total_amount: 1,
        item_type: "010100",
        day_type: "ALL",
        discount_type: "AMOUNT",
      },
      {
        discount_amount: 2000,
        total_amount: 1,
        item_type: "010101",
        day_type: "ALL",
        discount_type: "AMOUNT",
      },
    ],
  },
];
deepFreeze(EASY_COUPON_TYPES);

const COUPON_COLUMNS = [
  {
    title: "구분",
    align: "center",
    width: "13%",
  },
  {
    title: "할인",
    align: "center",
    width: "15%",
  },
  {
    title: "수량",
    align: "center",
    width: "8%",
  },
  {
    title: "유형",
    align: "center",
    width: "13%",
  },
  {
    title: "사용가능일",
    align: "center",
    width: "15%",
  },
  {
    title: "사용가능 기준금액",
    align: "center",
    width: "14%",
  },
  {
    title: "본사이벤트",
    align: "center",
    width: "14%",
  },
  {
    title: "신규회원 전용",
    align: "center",
    width: "8%",
  },
];
deepFreeze(COUPON_COLUMNS);

const ALERT_MAX_COUNT_COUPON = "더 이상 쿠폰 발급이 불가능합니다.";
const ALERT_CANCEL_FINISH = "오늘 추가 발행된 쿠폰이 취소되었습니다.";
const ALERT_COUPON_CHANGED =
  "사용된 쿠폰 수량이 변경되었습니다.\n쿠폰 취소를 다시 진행해주세요.";
// "쿠폰 사용 내역이 변경되었습니다.\n쿠폰 취소를 다시 진행해주세요.";
const ALERT_EMPTY_COUPON =
  "오늘 추가 발행한 쿠폰이 모두 사용되었습니다.\n 취소할 쿠폰이 없습니다.";
const ALERT_EMPTY_COUPON_MOBILE =
  "오늘 추가 발행한 쿠폰이\n모두 사용되었습니다.\n 취소할 쿠폰이 없습니다.";

const COUPON_SUMMARY_ITEM_TYPE_LIST = [
  { item_type: "010102", name: "숙박 쿠폰" },
  { item_type: "010100", name: "숙박/대실 겸용 쿠폰" },
  { item_type: "010101", name: "대실 쿠폰" },
];
deepFreeze(COUPON_SUMMARY_ITEM_TYPE_LIST);

/** 하루 쿠폰 발행 수 제한  */
// 20200510 김주호, PLATFORM-729 1차 개발 요구 (수량: 21->999)
const COUNT_PUBLISH_COUPON = 999;

const COUPON_ADD_COUNT_OBJ = {
  init_val: 0,
  interval: 1,
  min: 0,
  max: COUNT_PUBLISH_COUPON,
};
deepFreeze(COUPON_ADD_COUNT_OBJ);

const COUPON_ADD_ITEM_TYPE_LIST = [
  {
    item_type: "010102",
    name: "숙박전용",
    price: {
      init_val: 6000,
      interval: 1000,
    },
    count: COUPON_ADD_COUNT_OBJ,
    discount_type: "AMOUNT",
  },
  {
    item_type: "010100",
    name: "숙박/대실 겸용",
    price: {
      init_val: 3000,
      interval: 1000,
    },
    count: COUPON_ADD_COUNT_OBJ,
    discount_type: "AMOUNT",
  },
  {
    item_type: "010101",
    name: "대실전용",
    price: {
      init_val: 3000,
      interval: 1000,
    },
    count: COUPON_ADD_COUNT_OBJ,
    discount_type: "AMOUNT",
  },
];
deepFreeze(COUPON_ADD_ITEM_TYPE_LIST);

/** 최소/최대 할인율 추가  */
const COUPON_MIN_MAX_PRICE = {
  "010102": { min_price: 2000, max_price: 9999000, min_rate: 10, max_rate: 30 },
  "010100": { min_price: 2000, max_price: 9999000, min_rate: 10, max_rate: 30 },
  "010101": { min_price: 1000, max_price: 9999000, min_rate: 10, max_rate: 30 },
};
deepFreeze(COUPON_MIN_MAX_PRICE);

/** 양태욱 추가 : 쿠폰 할인 유형에 따른 정보  */
const COUPON_DISCOUNT_TYPE_LIST = [
  { discount_type: "AMOUNT", name: "할인금액", unitType: "원", interval: 1000 },
  { discount_type: "RATE", name: "할인율", unitType: "%", interval: 1 },
];
deepFreeze(COUPON_DISCOUNT_TYPE_LIST);

/** 쿠폰 발행 간편 설정 - 구분 항목 리스트 */
const EASY_COUPON_ITEM_TYPE_LIST = [
  {
    item_type: "010102",
    name: "숙박",
    defaultPrice: 6000,
    defaultRate: 15,
  },
  {
    item_type: "010100",
    name: "겸용",
    defaultPrice: 3000,
    defaultRate: 15,
  },
  {
    item_type: "010101",
    name: "대실",

    defaultPrice: 3000,
    defaultRate: 15,
  },
];
deepFreeze(EASY_COUPON_ITEM_TYPE_LIST);

/** 본사 이벤트 유형에 따른 정보  */
const COUPON_SUPPORT_EVENT_PRICE = {
  min_price: 0,
  max_price: 100000,
  min_rate: 0,
  max_rate: 70,
};
deepFreeze(COUPON_SUPPORT_EVENT_PRICE);

const AVAILABLE_PRICE = {
  min: 10000,
  max: 9999000,
  interval: 1000,
};
deepFreeze(AVAILABLE_PRICE);

const EASY_COUPON_DAY_TYPE_LIST = [
  { day_type: "ALL", name: "평일/주말", hide: false },
  { day_type: "WEEKDAY", name: "평일", hide: false, disabled: false },
  { day_type: "HOLIDAY", name: "주말", hide: false },
];
const FIRST_COUPON_DAY_TYPE_LIST = [
  { day_type: "ALL", name: "평일/주말", hide: false },
  { day_type: "WEEKDAY", name: "평일", hide: false, disabled: false },
  { day_type: "HOLIDAY", name: "주말", hide: false },
];
const OWNER_COUPON = {
  MANUAL: {
    label: "예약",
    desc: "업주 수동선물",
  },
  AUTO: {
    label: "예약",
    desc: "자동 발행",
  },
  AUTO_LIKE: {
    label: "찜",
    desc: "찜",
  },
  DOWNLOAD: {
    label: "조회",
    desc: "조회",
  },
  MANUAL_CMS: {
    label: "꿀스테이",
    desc: "꿀스테이",
  },
  FIRST_RESERVATION: {
    label: "첫예약",
    desc: "첫예약 쿠폰",
  },
};

/** 쿠폰 발행 유형에 따른 쿠폰 정보 반환(수동,자동,찜,조회) */
function getOwnerCoupon(ownerCouponType) {
  const defaultCoupon = { label: "", desc: "" };
  return OWNER_COUPON[ownerCouponType] ?? defaultCoupon;
}

/** 쿠폰 유형 코드(discount_type)를 받아 인덱스 반환합니다.  */
function getIndexOfDiscountType(item) {
  return COUPON_DISCOUNT_TYPE_LIST.findIndex(
    (itemType) => itemType.discount_type === item.discount_type
  );
}

/** 단위 value를 받아 할인액의 단위를 반환 ("원,%") */
function getUnitType(value) {
  return COUPON_DISCOUNT_TYPE_LIST[value].unitType;
}

/** DayType 코드를 받아 이름을 반환합니다.*/
function getDayTypeNameFromCode(code) {
  if (!code) return "";
  return EASY_COUPON_ITEM_TYPE_LIST.find((val) => val.item_type === code).name;
}

/** 쿠폰 유닛 타입 코드를 받아 금액 및 단위 정보 반환 */
function getUnitTypeFromCode(code) {
  return COUPON_DISCOUNT_TYPE_LIST.find((val) => code === val.discount_type);
}

/** 최소금액과 최대금액 반환 */
function getMinMaxPrice(dayType, discountType) {
  let maxPrice = 0;
  let minPrice = 0;
  if (discountType === "RATE") {
    maxPrice = COUPON_MIN_MAX_PRICE[dayType].max_rate;
    minPrice = COUPON_MIN_MAX_PRICE[dayType].min_rate;
  } else {
    maxPrice = COUPON_MIN_MAX_PRICE[dayType].max_price;
    minPrice = COUPON_MIN_MAX_PRICE[dayType].min_price;
  }
  return { maxPrice, minPrice };
}

/** 쿠폰 추가 발행 Default 금액 반환 */
function getDefaultPrice() {
  return COUPON_ADD_ITEM_TYPE_LIST.map((item) => item.price.init_val);
}
/** 쿠폰 추가 발행 Default 수량 반환 */
function getDefaultCount() {
  return COUPON_ADD_ITEM_TYPE_LIST.map((item) => item.count.init_val);
}
/** 쿠폰 추가 발행 Default 유형 반환 */
function getDefaultDiscountType() {
  return COUPON_ADD_ITEM_TYPE_LIST.map((item) => item.discount_type);
}
/** Object, Array 등의 내용을 비교하여 참, 거짓 반환 */
function isEquals(ObjectA, ObjectB) {
  return JSON.stringify(ObjectA) === JSON.stringify(ObjectB) ? true : false;
}
/** 오늘 쿠폰 추가발행 슬롯 초기화 */
function initCouponInfo() {
  return {
    couponAddPriceList: getDefaultPrice(),
    couponAddCountList: getDefaultCount(),
    couponDiscountTypeList: getDefaultDiscountType(),
    disabled: true,
  };
}
/** 할인 '유형' 변경시 변경 정보 반환 */
function getSelectDiscoutTypeInfo(evt, discoutTypeList, addPriceList) {
  const { value, name } = evt.target;
  const idx = parseInt(name);
  const couponDiscountTypeList = discoutTypeList.concat();
  const couponAddPriceList = addPriceList.concat();
  couponDiscountTypeList[idx] = COUPON_DISCOUNT_TYPE_LIST[value].discount_type;
  couponAddPriceList[idx] = getDefaultPriceRate(parseInt(name), value);

  return { couponDiscountTypeList, couponAddPriceList };
}

/** 숙박타입 , 할인타입(금액,율)을 받아 default 금액(율)을 반환 */
function getDefaultPriceRate(dayType = 0, discountIdx = 0) {
  const discountType = COUPON_DISCOUNT_TYPE_LIST[discountIdx].discount_type;
  const dayInfo = EASY_COUPON_ITEM_TYPE_LIST[dayType];
  if (discountType === "RATE") {
    return dayInfo.defaultRate;
  } else {
    return dayInfo.defaultPrice;
  }
}
/** 쿠폰 숙박 코드를 받아 쿠폰 인덱스 반환*/
function getEasyCouponIndex(code, itemTypeList = EASY_COUPON_ITEM_TYPE_LIST) {
  return itemTypeList.findIndex((val) => val.item_type === code);
}

/** 추가발행 쿠폰 종류별(숙박/대실/겸용) 데이터 합산하여 반환 */
function getCouponCountFromSummary(model) {
  let totalAmount = 0;
  let usedAmount = 0;
  let remainAmount = 0;
  model.map((val) => {
    totalAmount += val.daily_total_amount || 0;
    usedAmount += val.daily_used_amount || 0;
    remainAmount += val.daily_remain_amount || 0;
  });
  return { totalAmount, usedAmount, remainAmount };
}

/** 추가 발행 쿠폰 취소 팝업 문구 */
function getCancelPopupMsg(model) {
  const { totalAmount, usedAmount, remainAmount } =
    getCouponCountFromSummary(model);

  let msg = "";

  msg = `• 오늘 추가 발행한 쿠폰 수 : ${totalAmount}장\n`;
  msg += `• 사용된 쿠폰 수 : ${usedAmount}장\n`;
  msg += `• 취소 가능한 쿠폰 수 : ${remainAmount}장\n\n`;
  msg += `오늘 추가 발행한 쿠폰 ${remainAmount}장을\n취소하시겠습니까?`;

  return { msg, remainAmount };
}

/** 발행하려는 총 쿠폰 개수 */
function getAddCouponCount(countList) {
  const reducer = (acc, value) => acc + value;
  return countList.reduce(reducer);
}
/** 할인 유형에 따른 가격 증가 단위 반환 */
function getUnitInterval(type) {
  return COUPON_DISCOUNT_TYPE_LIST.find((val) => val.discount_type === type)
    .interval;
}
/** 할인 유형 인덱스 조회 */
function getIndexFromDiscountType(code) {
  return COUPON_DISCOUNT_TYPE_LIST.findIndex(
    (val) => val.discount_type === code
  );
}

/** 쿠폰 최소 할인금액/할인률을 반환한다. */
function getUnitMinValueDiscount(coupon, selectCouponCode) {
  const {
    item_type,
    discount_type,
    template_comb_min_amount,
    template_comb_min_rate,
    template_rent_min_amount,
    template_rent_min_rate,
    template_stay_min_amount,
    template_stay_min_rate,
  } = coupon;
  // Default Setting 값
  const { min_price, min_rate } = COUPON_MIN_MAX_PRICE[item_type];

  // CASE 1. 쿠폰유형 = "할인금액"
  if (discount_type === "AMOUNT") {
    let value = min_price;
    // CASE 1.1 대실
    if (item_type === "010101") value = template_rent_min_amount;
    // CASE 1.2 숙박
    else if (item_type === "010102") value = template_stay_min_amount;
    // CASE 1.3 겸용
    else if (item_type === "010100") value = template_comb_min_amount;

    // CASE 1.4 서버에서 내려준 값이 없을 경우 CT025는 1000원, 아닐경우 default
    return value ?? (selectCouponCode === "CT025" ? 1000 : min_price);
  }
  // // CASE2. 쿠폰유형 = "할인율"
  else {
    let value = min_rate;
    // CASE 2.1 대실
    if (item_type === "010101") value = template_rent_min_rate;
    // CASE 2.2 숙박
    else if (item_type === "010102") value = template_stay_min_rate;
    // CASE 2.3 겸용
    else if (item_type === "010100") value = template_comb_min_rate;

    // CASE 2.4 서버에서 내려준 값이 없을 경우 CT025는 1%, 아닐경우 default
    return value ?? (selectCouponCode === "CT025" ? 1 : min_rate);
  }
}
function getUnitMaxValueDiscount(itemType, discountType) {
  const { max_price, max_rate } = COUPON_MIN_MAX_PRICE[itemType];
  return discountType === "AMOUNT" ? max_price : max_rate;
}
function getUnitMinValueEvent(unitType) {
  return unitType === "원"
    ? COUPON_SUPPORT_EVENT_PRICE.min_price
    : COUPON_SUPPORT_EVENT_PRICE.min_rate;
}
function getUnitMaxValueEvent(unitType) {
  return unitType === "원"
    ? COUPON_SUPPORT_EVENT_PRICE.max_price
    : COUPON_SUPPORT_EVENT_PRICE.max_rate;
}
/** 유형 변경에 따른 할인금액 반환 */
function getDiscountPriceFromType(discountType, itemType) {
  if (discountType === "RATE") {
    return 15;
  } else if (discountType === "AMOUNT" && itemType === "010102") {
    return 6000;
  } else {
    return 3000;
  }
}
/** webMaster만 설정할 수 있는 사용가능기준금액, 본사이벤트, 신규회원전용 컬럼 값 초기화 */
function initMasterAttr(coupon) {
  const copiedCoupon = objectDeepClone(coupon);
  copiedCoupon.discount_min_amount = null;
  copiedCoupon.discount_support_amount = 0;
  copiedCoupon.new_member_yn = "N";
  return copiedCoupon;
}
/** 사용 가능일 인덱스 조회 */
const getIndexFromDayType = (code) => {
  return EASY_COUPON_DAY_TYPE_LIST.findIndex((val) => val.day_type === code);
};

export {
  ALERT_MAX_COUNT_COUPON,
  ALERT_CANCEL_FINISH,
  ALERT_COUPON_CHANGED,
  ALERT_EMPTY_COUPON,
  ALERT_EMPTY_COUPON_MOBILE,
  EASY_COUPON_TYPES,
  COUPON_SUMMARY_ITEM_TYPE_LIST,
  COUPON_ADD_COUNT_OBJ,
  COUPON_ADD_ITEM_TYPE_LIST,
  COUPON_MIN_MAX_PRICE,
  COUPON_DISCOUNT_TYPE_LIST,
  COUPON_SUPPORT_EVENT_PRICE,
  COUPON_COLUMNS,
  COUNT_PUBLISH_COUPON,
  EASY_COUPON_ITEM_TYPE_LIST,
  EASY_COUPON_DAY_TYPE_LIST,
  FIRST_COUPON_DAY_TYPE_LIST,
  AVAILABLE_PRICE,
  getOwnerCoupon,
  getIndexOfDiscountType,
  getUnitType,
  getDayTypeNameFromCode,
  getMinMaxPrice,
  getDefaultPrice,
  getDefaultCount,
  getDefaultDiscountType,
  getSelectDiscoutTypeInfo,
  getDefaultPriceRate,
  getEasyCouponIndex,
  getUnitTypeFromCode,
  getCouponCountFromSummary,
  getCancelPopupMsg,
  initCouponInfo,
  getAddCouponCount,
  isEquals,
  getUnitInterval,
  getIndexFromDiscountType,
  getUnitMinValueDiscount,
  getUnitMaxValueDiscount,
  getUnitMinValueEvent,
  getUnitMaxValueEvent,
  getDiscountPriceFromType,
  initMasterAttr,
  getIndexFromDayType,
};
