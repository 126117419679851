import React, { createRef } from "react";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles({
  defaultRoot: {
    width: "100%",
  },
  customRoot: {
    "& svg.MuiSelect-icon": { display: "none" },
    "& .MuiSelect-root": { textAlignLast: "end" },
    width: "100%",
  },
  defaultInput: {
    fontSize: "16px",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
    textAlign: "center", // IE 정렬
    textAlignLast: "center", // Chrome에서 필요함
  },
  customInput: {
    fontSize: "16px",
    width: "80%",
    paddingLeft: "0px",
    paddingRight: "0px",
    textAlign: "right", // IE 정렬
    textAlignLast: "end", // Chrome 정렬
  },
  notchedOutline: {
    backgroundColor: "blue",
    opacity: "0.1",
  },
});

function CustomSelectComponent(props) {
  const classes = useStyles();
  const inputRef = createRef();
  const {
    value,
    initValue,
    index,
    onChange,
    onClickCancel,
    valueArr = [],
    startValue = 0,
    endValue = 0,
    useDisabled = false,
    hideCancelButton = false,
    unitType = "",
    isPound = false,
    hasExceed,
  } = props;
  const diffValue = initValue !== value;
  const copyValueArr = isPound ? ["##", ...valueArr] : [...valueArr];
  const copyValue = isPound ? 0 : value;
  const menuItem = copyValueArr.map((val, idx) => {
    return (
      <MenuItem
        value={idx}
        key={idx}
        style={{
          display: val === "##" ? "none" : "block",
          backgroundColor: hideCancelButton && idx === initValue && "#E7E7B1",
        }}
        disabled={useDisabled && val > endValue - startValue}
      >
        {val}
        {unitType}
      </MenuItem>
    );
  });

    const handleChangeAmount = (e) => {
    const { value } = e.target;
    const copyValue = isPound ? value - 1 : value;
    !!onChange && onChange(index, copyValue);
  };
  const handleClickCancel = () => {
    if (!!onClickCancel) {
      !!onClickCancel && onClickCancel(index, initValue);
    } else {
      !!onChange && onChange(index, initValue);
    }
  };
  return (
    <div id="TinyBtnSelect">
      <TextField
        disabled={hasExceed}
        select
        variant="outlined"
        value={copyValue || 0}
        className={
          !hideCancelButton && diffValue
            ? classes.customRoot
            : classes.defaultRoot
        }
        ref={inputRef}
        inputProps={{
          className:
            !hideCancelButton && diffValue
              ? classes.customInput
              : classes.defaultInput,
        }}
        onChange={handleChangeAmount}
        open={false}
        InputProps={{
          classes: {
            notchedOutline: diffValue && classes.notchedOutline,
          },
          endAdornment: !hideCancelButton && diffValue && (
            <InputAdornment position="end" style={{ width: "20%" }}>
              <IconButton
                size="small"
                onClick={handleClickCancel}
                style={{
                  padding: '0',
                  display: diffValue ? "block" : "none",
                }}
              >
                <CancelIcon size="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      >
        {menuItem}
      </TextField>
    </div>
  );
}

/** 불필요 렌더링 방지 */
function propsCompare(preProps, nextProps) {
  return JSON.stringify(preProps) === JSON.stringify(nextProps);
}
export default React.memo(CustomSelectComponent, propsCompare);
