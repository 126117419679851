import {Workbook} from "exceljs";
import { saveAs } from 'file-saver';
import {flattenObject} from "./ObjectHelper";
const excelDownload = async (headers, data, filename)=> {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    let currentCol = 1;
    let rowIndex = 1;
    let isChildHeaders = false;

    headers.slice(0).forEach((header) => {
        const cell = worksheet.getCell(rowIndex, currentCol);
        cell.value = header.text;
        Object.assign(cell.style, convertToXlsxStyle(header.style));
        worksheet.mergeCells(rowIndex, currentCol, header.mergeDown, currentCol + header.mergeAcross - 1);

        worksheet.getColumn(currentCol).width = header.width;

        if (header.childHeaders) {
            isChildHeaders = true;
            header.childHeaders.forEach((childHeader, index) => {
                const cell = worksheet.getCell(rowIndex + 1, currentCol + index);
                cell.value = childHeader.text;
                Object.assign(cell.style, convertToXlsxStyle(header.style));
                worksheet.getColumn(currentCol + index).width = childHeader.width;
            });
        }

        currentCol += header.mergeAcross;
    });

    data.forEach((rowData, index) => {
        const newRow = flattenObject(rowData);
        const excelRowIndex = index + (isChildHeaders ? rowIndex + 2 : rowIndex + 1);
        const row = worksheet.getRow(excelRowIndex);
        let cellIndex = 0;
        headers.forEach((header) => {
            if (header.childHeaders) {
                header.childHeaders.forEach((childHeader) => {
                    cellIndex += 1;
                    const cell = row.getCell(cellIndex);
                    cell.value = newRow[childHeader.id];
                    Object.assign(cell.style, convertToXlsxStyle({ border: true }));
                });
            } else {
                cellIndex += 1;
                const headerKey = header.key;
                if (header.key === 'index') {
                    row.getCell(cellIndex).value = index + 1;
                } else {
                    row.getCell(cellIndex).value = newRow[headerKey];
                }

                Object.assign(row.getCell(cellIndex), convertToXlsxStyle({ border: true }));
            }
        });

        row.commit();
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename ? `${filename}.xlsx` : 'download.xlsx');




};

const convertToXlsxStyle = (style) => {
    const xlsxStyle = {};

    if (style.bold) {
        xlsxStyle.font = { bold: true };
    }

    if (style.bgColor) {
        const color = style.bgColor.replace('#', '');
        xlsxStyle.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF' + color }
        };
    }

    if (style.border) {
        xlsxStyle.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    }

    xlsxStyle.alignment = { vertical: 'middle', horizontal: 'center' };

    return xlsxStyle;
};

export default excelDownload;