import React from "react";
import { Router, Prompt, BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import reducers from "system/reducers";
import * as providerAPI from "lib/provider-api";
import * as helperPopup from "lib/helper-popup";
import * as serviceScheduler from "lib/service-scheduler";
import * as serviceRefresh from "lib/service-refresh";
import * as configApp from "config/config-app";

import AppContainer from "./AppContainer";

import { history } from "lib/history";

const TAG = "[system/client/Root.js]";

const { URL_WAS, RUNTIME_WHERE, APP_VERSION, SERVICE_TYPE } = configApp;
console.log(`*********************************************************
- 앱 버전 : ${APP_VERSION}
- 서비스 타입 : ${SERVICE_TYPE}
- 실행 환경 : ${RUNTIME_WHERE}
- WAS URL : ${URL_WAS}
*********************************************************`);

if (RUNTIME_WHERE === "production") {
  if (!!console && !!console.log) {
    // 20200918 박태성C - 상용 모드 일 경우 콘솔에 출력 되지 않도록 처리
    console.log = function () {};
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store =
  RUNTIME_WHERE === "local"
    ? createStore(reducers, composeEnhancers(applyMiddleware(thunk)))
    : createStore(reducers, applyMiddleware(thunk));

providerAPI.provider(store);
helperPopup.provider(store);
serviceScheduler.provider(store);
serviceRefresh.provider(store);

/**
 * 웹앱의 초기 설정을 담당하는 최상위 Contaner Component 입니다<p/>
 *
 * ```
 * - 앱의 전역 상태(ex : 로그인) 관리를 위한 Redux Store 생성
 * - 공통 모듈(API 연동, 브라우저 제어)의 Provider 처리 (ex : Redux store 서비스 주입)
 * - 브라우저 Back Navigation 제어를 하기 위해 Prompt 선언
 * - 화면 이동 후 미완료 된 API 일괄 취소 처리
 * ```
 * </pre>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name Root
 * @component
 * @see AppContainer
 * @see DefaultLayoutContainer
 * @see module:system/reducers
 * @see module:lib/provider-api
 * @see module:lib/helper-popup
 * @see module:lib/service-scheduler
 * @see module:lib/history
 */
const Root = () => {
  console.log(TAG, "Called render()");
  return (
    <>
      <Provider store={store}>
        <BrowserRouter basename={configApp.BASE_NAME}>
          <Router history={history}>
            <Prompt message="check browser navigation" />
            <AppContainer />
          </Router>
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default Root;
