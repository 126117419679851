/**
 * http 통신을 취소 시키는 서비스 모듈 입니다<p/>
 *
 * @module lib/service-http-canceler
 */

import axios from "axios";

const TAG = "[lib/service-http-canceler.js]";
// console.log(TAG, "Create");

const REASON_REQUESTED_ALL = "__REASON_REQUESTED_ALL__";

let __source = null;

/**
 * 취소 토큰(axios.CancelToken) 을 반환하는 메소드 입니다
 *
 * @see {@link https://github.com/axios/axios/blob/master/lib/cancel}
 * @returns {axios.CancelToken} 작업 취소를 요청 할 때 사용 되는 객체
 */
export function getCancelToken() {
  if (__source === null) {
    __source = axios.CancelToken.source(); // axios.CancelToken.source() 팩토리를 사용하여 취소 토큰 생성
  }
  return __source.token;
}

/**
 * 본 모듈에서 발행 되는 '취소 사유' 인지를 검사하는 메소드 입니다
 *
 * @param {string} message - 취소 사유 입니다
 * @returns {boolean} 모듈에서 발행 되는 '취소 사유' 여부
 */
export function isCancelReason(message) {
  switch (message) {
    case REASON_REQUESTED_ALL:
      return true;
    default:
  }
  return false;
}

/**
 * Ajax 요청을 해제하는 메소드 입니다
 */
export function cancelAll() {
  if (__source) {
    console.log(TAG, "The successed calling cancelAll().");
    __source.cancel(REASON_REQUESTED_ALL);
    __source = null; // cancel 처리 시 토큰은 더 이상 유효하지 않으므로 폐기
  }
}
