/**
 * 본 모듈은 문자열이 이메일 형식 인지 검사하는 모듈 입니다
 *
 * @module utils/isEmailFormat
 */

/**
 * 문자열을 검사하여 이메일 형식 여부를 반환 합니다
 *
 * @param {string} str - 문자열
 * @return {boolean} 이메일 형식 여부
 */
export default function isEmailFormat(email) {
  // /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  if (
    /^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}$/.test(
      "" + email
    ) === false
  ) {
    return false;
  }
  return true;
}
