import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { BoxWrap, TableStyle } from "components";
import CommonConsts from "constant/CommonConsts";

const styles = (theme) => ({
  linkBtnWrapper: {
    top: "16px",
    right: "16px",
    zIndex: "1",
  },
  textDecoNone: {
    textDecoration: "none",
  },
});

const WhiteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
}))(Button);

/*
 * [수정내역]
 *
 *  2020.01.08 양태욱
 *   1. '예약시간' 시간단위 12시간 -> 24시간 단위 변경
 *   2. '예약자'  꿀스테이 닉네임이 아닌 예약자명으로 변경
 *
 *  2020.05.03 양태욱
 *   1. 기존 입실구분을 book.type에서 book.type_name으로 변경
 */

/**
 * 12시간 내 입실 예정 내역 Template Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name ReservationTemplate
 * @component
 */
class ReservationTemplate extends React.PureComponent {
  columns = [
    {
      name: "입실구분",
      // cell: ({ book: { type } }) => CommonConsts.MOTEL_PROD_TYPE[type],
      cell: ({ book }) => book.type_name || "",
      center: true,
      width: "13%",
    },
    {
      name: "객실명",
      selector: "book.room.name",
      center: true,
      // left: true,
    },
    {
      cell: (row) => {
        return (
          <div>
            {row.book.user.name || ""}
            {/* 채널아웃명 노출 */}
            {row.channel_out_yn === "Y" && (
              <p style={{ margin: 0 }}>({row.channel_name})</p>
            )}
          </div>
        );
      },
      name: "예약자",
      // selector: "user.name", 양태욱 수정 : 2021.01.08
      // selector: "book.user.name" || "",
      center: true,
      width: "12%",
    },
    {
      cell: (row) => {
        return (
          <>
            {format(new Date().setTime(row.book.start_dt), "MM.dd H:mm")}{" "}
            {" ~ "}
            <br /> {format(new Date().setTime(row.book.end_dt), "MM.dd H:mm")}
          </>
        );
      },
      name: "사용시간",
      center: true,
      width: "20%",
    },
    {
      cell: (row) => {
        return (
          // <>{format(new Date().setTime(row.book.reg_dt), "MM.dd hh:mm")}</> 양태욱 수정 : 2021.01.08
          <>{format(new Date().setTime(row.book.reg_dt), "MM.dd HH:mm")}</>
        );
      },
      name: "예약시간",
      center: true,
      width: "17%",
    },
    {
      cell: (row) => (row.book.vehicle_yn === "Y" ? "차량" : "도보"),
      name: "이동수단",
      center: true,
      width: "12%",
    },
  ];

  render() {
    const { classes, model } = this.props;
    const { booksData } = model;

    return (
      <BoxWrap title="12시간 내 입실 예정 내역">
        <Box position="absolute" className={classes.linkBtnWrapper}>
          <Link to={`/reservation`} className={classes.textDecoNone}>
            <WhiteButton variant="contained" color="primary" size="small">
              <strong>예약내역</strong>
            </WhiteButton>
          </Link>
        </Box>

        <DataTable
          noHeader={true}
          highlightOnHover={true}
          columns={this.columns}
          data={booksData}
          customStyles={TableStyle.styleNormal()}
          noDataComponent={
            <span style={{ margin: "50px" }}>
              12시간 이내에 체크인 하실 예약 고객이 없습니다.
            </span>
          }
        />
      </BoxWrap>
    );
  }
}

// 초기 props 설정
ReservationTemplate.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default withTheme(withStyles(styles)(ReservationTemplate));
