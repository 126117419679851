import { history } from "lib/history";
import React from "react";

import MainPageContainer from "./MainPageContainer";
import MainPageLayout from "./MainPageLayout";

/**
 * 메인 페이지의 Container Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name MainPage
 * @class
 * @component
 */
class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mountFlag: true,
    };
  }

  componentDidMount() {
    this.unlisten = history.listen((location) => {
      if (location.pathname === "/dashboard") {
        this.setState(
          {
            mountFlag: false,
          },
          () => {
            this.setState({
              mountFlag: true,
            });
          }
        );
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { mountFlag } = this.state;
    return <>{mountFlag && <MainPageContainer component={MainPageLayout} />}</>;
  }
}

export default MainPage;
