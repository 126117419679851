import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, Button, Divider } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { grey } from "@material-ui/core/colors";
import { format } from "date-fns";

import { Link } from "react-router-dom";
import { BoxWrap } from "components";

const styles = (theme) => ({
  noticeDivider: {
    margin: "16px 0",
  },
  reviewRoot: {
    "& .main-summary-contents": {},
    "& .main-summary-date": {
      fontSize: "12px",
      color: "#949494",
    },
    "& .main-summary-grade": {
      width: "26px",
    },
  },
  linkBtnWrapper: {
    top: "16px",
    right: "16px",
    zIndex: "1",
  },
  textDecoNone: {
    textDecoration: "none",
  },
});

const WhiteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
}))(Button);

/**
 * 최근 후기 Template Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name ReviewTemplate
 * @component
 */
class ReviewTemplate extends React.PureComponent {
  render() {
    const {
      classes,
      // theme,
      model,
      // onRequestAjax
    } = this.props;
    const { reviewCount, reviewData } = model;

    return (
      <BoxWrap title="최근 후기">
        {/* UI 변경 '>' => 버튼 */}
        <Box position="absolute" className={classes.linkBtnWrapper}>
          <Link to={`/reviews`} className={classes.textDecoNone}>
            <WhiteButton variant="contained" color="primary" size="small">
              <strong>후기목록</strong>
            </WhiteButton>
          </Link>
        </Box>
        {/* UI 변경 '>' => 버튼 */}

        {reviewCount > 3 && (
          <Box position="absolute" top={19} left={100} className={"item-badge"}>
            {reviewCount}
          </Box>
        )}

        <Box>
          {/* 등록된 후기가 없을경우 */}
          {!reviewData && (
            <Box align="center" py={2}>
              등록된 내용이 없습니다.
            </Box>
          )}

          {/* 등록된 후기가 있을경우 */}
          {reviewData &&
            reviewData.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {index > 0 && <Divider className={classes.noticeDivider} />}

                  <Box display="flex" className={classes.reviewRoot}>
                    <Box width={"18%"}>
                      <Rating
                        precision={0.1}
                        value={Number(item.score)}
                        size="small"
                        style={{ verticalAlign: "top" }}
                        readOnly
                      />
                    </Box>
                    <Box width={"5%"} ml={0.5} className={"main-summary-grade"}>
                      {item.score}
                    </Box>
                    <Box width={"73%"} ml={1.5}>
                      <Box className={"main-summary-contents text-ellipsis"}>
                        {item.text}
                      </Box>
                      <Box className={"main-summary-date"}>
                        {item.user && item.user.name} |{" "}
                        {format(new Date().setTime(item.reg_dt), "yyyy.MM.dd")}
                      </Box>
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })}
        </Box>
      </BoxWrap>
    );
  }
}

// 초기 props 설정
ReviewTemplate.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default withTheme(withStyles(styles)(ReviewTemplate));
