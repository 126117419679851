/**
 * 본 모듈은 숫자 형식의 문자열을 사업자번호 형식으로 변환하는 모듈 입니다
 *
 * @module utils/bussinessNumFormatter
 */

/**
 * 숫자 형식의 문자열을 사업자번호 형식으로 변환하여 반환 합니다
 *
 * @param {string} num - 숫자 형식의 문자열
 * @return {string} 사업자번호 형식의 문자열
 */
export default function bussinessNumFormatter(num) {
  if (typeof num !== "string") return "";
  return `${num.substr(0, 3)}-${num.substr(3, 2)}-${num.substr(5)}`;
}
