import React from "react";
import { Route, Redirect } from "react-router-dom";

import * as serviceStorage from "lib/service-storage";

/**
 * 상태에 따라 화면 이동을 처리하는 Component 입니다<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name HandlerRouteLogin
 * @component
 */
function HandlerRouteLogin({
  isLoggedIn,
  component: Component,
  render,
  ...rest
}) {
  // 로그인 상태
  if (!isLoggedIn) return <Redirect to="/login" />;

  return (
    <Route
      {...rest}
      render={(props) => (render ? render(props) : <Component {...props} />)}
    />
  );
}

export default HandlerRouteLogin;
