import React from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import { BoxWrap } from "components";
import {
  POPUP_ACTION_OK,
  openAlertPopupWithPromise,
  openConfirmPopupWithPromise,
  openHttpRejectionPopupWithPromise,
} from "lib/helper-popup";
import * as serviceStorage from "lib/service-storage";
import {
  COUPON_ADD_ITEM_TYPE_LIST,
  COUPON_DISCOUNT_TYPE_LIST,
  COUPON_MIN_MAX_PRICE,
  COUNT_PUBLISH_COUPON,
  getIndexOfDiscountType,
  getUnitType,
  getMinMaxPrice,
  getSelectDiscoutTypeInfo,
  getCancelPopupMsg,
  initCouponInfo,
  getUnitTypeFromCode,
  getAddCouponCount,
  ALERT_MAX_COUNT_COUPON,
  ALERT_CANCEL_FINISH,
  getCouponCountFromSummary,
  ALERT_EMPTY_COUPON,
  ALERT_COUPON_CHANGED,
} from "lib/helper-coupon";
import CouponHelperTextTemplate from "./CouponHelperTextTemplate";
import { TodayCouponSettings } from "../../modal/TodayCouponSettings";
import { commaFormater } from "utils";
import CouponSelect from "../CouponSelect";
import CouponUpDownForm from "../CouponUpDownForm";
const styles = (theme) => ({
  table: {
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    "& th": {
      background: "#f8f8f9",
      fontWeight: "bold",
    },
    "& td": {
      border: "1px solid #ddd",
    },
  },
});

// 쿠폰 발행 가능 갯수
/**
 * 오늘 쿠폰 추가 발행 Template Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name CouponAddTemplate
 * @component
 */
class CouponAddTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    const identity = serviceStorage.getIdentity();
    this.USER_TYPE = serviceStorage.getUserType(identity);
    this.state = {
      ...initCouponInfo(),
      modalOpen: false,
    };
  }

  /** 발행하기 버튼 클릭 이벤트 */
  handleClickPublishButton = (evt) => {
    evt.preventDefault();
    const { couponAddPriceList, couponAddCountList, couponDiscountTypeList } =
      this.state;
    let msg = "";

    COUPON_ADD_ITEM_TYPE_LIST.forEach((item, i) => {
      const { unitType } = getUnitTypeFromCode(couponDiscountTypeList[i]);
      const couponPrice = commaFormater(couponAddPriceList[i]);
      const discountMsg = `${couponPrice}${unitType} X `;
      const addMsg = couponAddCountList[i] === 0 ? "" : discountMsg;
      msg += `${item.name} :  ${addMsg}${couponAddCountList[i]}장` + "\n";
    });

    msg = msg + "\n설정한 값으로 적용하시겠습니까?";

    openConfirmPopupWithPromise(null, msg).then((actionType) => {
      if (actionType !== POPUP_ACTION_OK) return;
      this.requestPublish();
    });
  };

  /** 오늘 쿠폰 추가 발행API 요청 */
  requestPublish = () => {
    const { couponAddPriceList, couponAddCountList, couponDiscountTypeList } =
      this.state;
    const { onRequestAjax, onRefresh } = this.props;
    const param = {
      coupon_template: "CT200",
      coupon: COUPON_ADD_ITEM_TYPE_LIST.map((item, i) => {
        return {
          discount_amount: couponAddPriceList[i],
          total_amount: couponAddCountList[i],
          item_type: item.item_type,
          day_type: "ALL",
          discount_type: couponDiscountTypeList[i],
        };
      }),
    };
    // API호출
    onRequestAjax("createCoupons", param)
      .then((res) => {
        return onRefresh();
      })
      .then((res) => {
        this.setState({
          ...this.state,
          ...initCouponInfo(),
        });
      })
      .catch((error) => {
        if (
          !!error &&
          !!error.response &&
          !!error.response.data &&
          !!error.response.data.code
        ) {
          switch (error.response.data.code) {
            case "40015009": // 쿠폰 등록 불가 - 최대발행수 초과
              openAlertPopupWithPromise(null, ALERT_MAX_COUNT_COUPON);
              return;
            default:
              break;
          }
        }
        openHttpRejectionPopupWithPromise(error);
      });
  };

  /** 쿠폰 가격 변경 콜백 함수 */
  handleUpDownOfCouponAddPrice = (value, name, type) => {
    let couponAddPriceList = this.state.couponAddPriceList.concat();
    couponAddPriceList[name] = value;
    this.setState({ couponAddPriceList });
  };

  /** 쿠폰 개수 변경 콜백 함수 */
  handleUpDownOfCouponAddCount = (value, name) => {
    const couponAddCountList = this.state.couponAddCountList.concat();
    couponAddCountList[name] = value;

    if (
      this.getRemainCouponCount() - getAddCouponCount(couponAddCountList) <
      0
    ) {
      openAlertPopupWithPromise(null, ALERT_MAX_COUNT_COUPON);

      return;
    }

    this.setState({
      couponAddCountList,
      disabled: getAddCouponCount(couponAddCountList) === 0,
    });
  };

  getRemainCouponCount = () => {
    const { couponSummaryList } = this.props.model;

    let todayCouponCnt = 0;
    couponSummaryList.forEach((coupon) => {
      // [COOL175-72] 사용수량이 발급수량보다 큰 경우에 대한 예외 처리 추가
      todayCouponCnt += Math.max(
        coupon.total_amount || 0,
        coupon.used_amount || 0
      );
    });

    return Math.max(COUNT_PUBLISH_COUPON - todayCouponCnt, 0);
  };

  /** 유형 변경 이벤트 */
  handleSelectDiscountType = (evt) => {
    const { couponDiscountTypeList, couponAddPriceList } = this.state;
    this.setState(
      getSelectDiscoutTypeInfo(evt, couponDiscountTypeList, couponAddPriceList)
    );
  };

  /** 오늘 추가 발행 쿠폰 취소 버튼 클릭 이벤트 */
  handleClickCouponCancel = () => {
    const { couponSummaryList } = this.props.model;
    const { msg } = getCancelPopupMsg(couponSummaryList);

    openConfirmPopupWithPromise(null, msg)
      .then((action) => {
        if (action !== "OK") return false;
        this.compareCouponData();
      })
      .catch(openHttpRejectionPopupWithPromise);
  };

  /** 현재 화면에 보이는 쿠폰 데이터와 다시 조회한 쿠폰 데이터 비교 */
  compareCouponData = () => {
    const { onRequestAjax, model, onRefresh } = this.props;
    const { couponSummaryList } = model;
    onRequestAjax("REQUEST_modelCoupon", null, false)
      .then((res) => {
        const newSummaryList = res.modelCoupon.couponSummaryList;
        const newCountObj = getCouponCountFromSummary(newSummaryList);
        const newRemainAmount = newCountObj.remainAmount;
        const newUsedAmount = newCountObj.usedAmount;
        const { usedAmount, remainAmount } =
          getCouponCountFromSummary(couponSummaryList);
        // 남은 쿠폰이 없을 경우
        if (!newRemainAmount)
          openAlertPopupWithPromise(null, ALERT_EMPTY_COUPON).then(onRefresh);
        // props로 받은 model과 새로 조회한 데이터가 상이할 경우
        else if (
          newUsedAmount !== usedAmount ||
          newRemainAmount !== remainAmount
        )
          openAlertPopupWithPromise(null, ALERT_COUPON_CHANGED).then(onRefresh);
        // 정상적으로 쿠폰 취소가 가능한 경우
        else this.requestCouponCancel();
      })
      .catch(openHttpRejectionPopupWithPromise);
  };

  /** 추가발행 쿠폰 취소 API 요청 */
  requestCouponCancel = () => {
    const { onRequestAjax, onRefresh, model } = this.props;
    const { couponSummaryList } = model;
    const { totalAmount, remainAmount } =
      getCouponCountFromSummary(couponSummaryList);
    onRequestAjax("createDeleteDailyCoupons", {
      daily_total_amount: totalAmount,
      daily_remain_amount: remainAmount,
    })
      .then((res) => {
        if (res.data.code !== "20000000") return false;
        onRefresh().then((res) => {
          openAlertPopupWithPromise(null, ALERT_CANCEL_FINISH);
        });
      })
      .catch(openHttpRejectionPopupWithPromise);
  };

  /** 사용조건 쿠폰 Open*/
  couponModalOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };
  /** 사용조건 쿠폰 Close*/
  couponModalClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { classes, model, onRefresh, onRequestAjax } = this.props;
    const {
      couponAddPriceList,
      couponAddCountList,
      couponDiscountTypeList,
      disabled,
    } = this.state;
    const { couponSummaryList } = model;
    const cancelDisabled = !couponSummaryList.filter(
      (val) => !!val.daily_remain_amount
    ).length;

    return (
      <BoxWrap
        title={`오늘 쿠폰 추가 발행`}
        userType={this.USER_TYPE}
        buttonTitle={"사용조건 쿠폰"}
        buttonOnClick={this.couponModalOpen}
      >
        <Box mt={1} width={"100%"}>
          <TableContainer component={Paper} style={{ boxShadow: "none" }}>
            <Table style={{ tableLayout: "fixed" }} className={classes.table}>
              <TableBody>
                {COUPON_ADD_ITEM_TYPE_LIST.map((item, i) => {
                  const discountType = getIndexOfDiscountType({
                    discount_type: couponDiscountTypeList[i],
                  });
                  const { maxPrice, minPrice } = getMinMaxPrice(
                    item.item_type,
                    couponDiscountTypeList[i]
                  );

                  return (
                    <TableRow key={`coupon_add_${i}`}>
                      <TableCell
                        align="center"
                        style={{
                          background: "#f8f8f9",
                          width: "25%",
                        }}
                      >
                        {item.name}
                      </TableCell>
                      <CouponUpDownForm
                        onChange={this.handleUpDownOfCouponAddPrice}
                        unitType={getUnitType(discountType)}
                        value={couponAddPriceList[i]}
                        interval={
                          COUPON_DISCOUNT_TYPE_LIST[discountType].interval
                        }
                        min={minPrice}
                        max={maxPrice}
                        name={`${i}`}
                        disabled={false}
                        width={"30%"}
                      />
                      <CouponUpDownForm
                        onChange={this.handleUpDownOfCouponAddCount}
                        value={couponAddCountList[i]}
                        interval={item.count.interval}
                        min={item.count.min}
                        name={`${i}`}
                        // disabled={!useModify || specialDisabled}
                        disabled={false}
                        width={"20%"}
                      />
                      <CouponSelect
                        itemList={COUPON_DISCOUNT_TYPE_LIST}
                        value={discountType}
                        onChange={this.handleSelectDiscountType}
                        index={`${i}`}
                        // disabled={!useModify || specialDisabled}
                        disabled={false}
                        width={"25%"}
                      />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <CouponHelperTextTemplate code="TODAY_COUPON_ADD" />
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={this.handleClickPublishButton}
            disabled={disabled}
          >
            <strong>발행하기</strong>
          </Button>
        </Box>
        <Box mt={1}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={this.handleClickCouponCancel}
            // disabled={cancelDisabled}
          >
            <strong>오늘 추가 발행한 쿠폰 취소</strong>
          </Button>
        </Box>
        <TodayCouponSettings
          modalOpen={this.state.modalOpen}
          couponModalClose={this.couponModalClose}
          onRequestAjax={onRequestAjax}
          onRefresh={onRefresh}
        />
      </BoxWrap>
    );
  }
}

// 초기 props 설정
CouponAddTemplate.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default withTheme(withStyles(styles)(CouponAddTemplate));
