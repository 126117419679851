import { format } from "date-fns";

/**
 *
 * @author TaeWook Yang <didxodnr0769@pineone.com>
 * @description 부가세신고 화면에서 사용할 유틸 파일입니다.
 *
 */

const SURTAX_NO_DATA = "현재 등록된 부가세 신고 기록이 없습니다.";
const STORE_SETTINGS_MESSAGE_NO_DATA = "등록된 휴대폰 번호가 없습니다.";
const SURTAX_HELPER_TEXT = [
  {
    desc: "1. 신고기간에 해당하는 월별 정산금액(신고금액)을 합산하여 신고하세요",
  },
  {
    desc: "2. 세금계산서 확인 방법은 공지사항에서 자세하게 안내되어 있습니다.",
  },
  {
    desc: "3. 월 중에는 말일까지의 정산금액이 완료된 현재 월 다음달 9일 오전 10시(영업일 기준)에 정확한 금액 확인이 가능합니다.",
  },
  {
    desc: "4. 꿀스테이 이용료는 정액 요금제 금액이며 VAT 포함된 금액입니다.",
  },
  {
    desc: "5. 꿀스테이 수수료는 정률 요금제 금액이며 판매가의 10%입니다.",
  },
  {
    desc: "6. 부가세 신고용 매출은 고객이 퇴실까지 마친 판매완료된 금액만 포함합니다. (고객이 사용을 완료하지 않은 예약은 부가세 신고용 매출에 포함되지 않습니다.)",
  },
];
const SURTAX_BLUE_HELPER_TEXT = [
  "※ 찾으시는 월 정산 내역은 현재 월 다음달 9일 오전 10시에 노출이 됩니다.",
  "※ 꿀스테이 부가세 신고 금액에는 현장결제 매출이 포함되어 있지 않습니다.",
  "현장결제 매출은 별도로 부가세 신고를 하셔야 합니다.",
  "※ 꿀스테이 수수료는 PG수수료를 포함하고 있습니다.",
];

/** 시작일과 마지막일을 받아 조회API규격에 맞는 형태로 변환 해줍니다. */
function getDefaultSearchExtra(startDate, endDate) {
  return `${format(startDate, "yyyyMM")}|${format(endDate, "yyyyMM")}`;
}

/** 시작일과 마지막일을 받아 2022년도 이전인지 확인합니다. */
function getIsBefore2022(startDate, endDate) {
  const baselineDate = new Date(2022, 0, 1);
  return startDate < baselineDate || endDate < baselineDate;
}

export {
  SURTAX_NO_DATA,
  SURTAX_HELPER_TEXT,
  SURTAX_BLUE_HELPER_TEXT,
  STORE_SETTINGS_MESSAGE_NO_DATA,
  getDefaultSearchExtra,
  getIsBefore2022,
};
