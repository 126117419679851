import React from "react";
import { isIE11 } from "lib/service-browser";
import { Box } from "@material-ui/core";

//  2021.06.17[PLATFORM-728]기획 요청으로 해당 컴포넌트 사용하지 않게됨
//  2021.06.21 기획 요청으로 다시 사용
/** 제휴점 맞춤형 템플릿 사용& 업주,직원계정 & 발행하는 슬롯이 없을경우 컴포넌트 */
function NoCouponSlot(props) {
  const { display } = props;
  const showStr = "발행된 쿠폰이 없습니다.\n담당 매니저에게 문의해주세요.";
  return (
    <Box
      style={{
        display: display,
      }}
    >
      <Box
        style={{
          display: isIE11() ? "-ms-grid" : "grid", //ie11css 처리
          msGridRows: "1fr 1fr 1fr", //ie11css 처리
          msGridColumns: "1fr 2fr 1fr", //ie11css 처리
          gridTemplateRows: "repeat(3,1fr)",
          minHeight: "400px",
        }}
      >
        <Box
          style={{
            msGridRow: 2, //ie11css 처리
            msGridColumn: 2, //ie11css 처리
            gridRowStart: 2,
            gridRowEnd: 3,
            textAlign: "center",
            alignSelf: "center",
            whiteSpace: "pre-wrap",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          {showStr}
        </Box>
      </Box>
    </Box>
  );
}

export default NoCouponSlot;
