function encrypt(public_key, password) {
  const forge = require('node-forge');

  // 서버에서 받은 공개 키
  const publicKeyPEM = `-----BEGIN PUBLIC KEY-----\n${public_key}\n-----END PUBLIC KEY-----`;

  // 사용자의 패스워드
  const userPassword = password;

  // 공개 키 파싱
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPEM);

  // 패스워드를 바이트로 변환
  const passwordBytes = forge.util.encodeUtf8(userPassword);

  //공개 키를 사용하여 패스워드 암호화
  const encryptedPassword = publicKey.encrypt(passwordBytes);

  // 암호화된 패스워드를 Base64 문자열로 변환하여 출력
  const encodedPW = forge.util.encode64(encryptedPassword);

  return encodedPW;
}

export default encrypt;
