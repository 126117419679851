// utils/ObjectHelper
import { isDate } from "date-fns";

/*
  Object Array => Object로 변경
  ex)
  [{code: "OPT_001", name: "요일 선택", values: "1,2,3,0"}], 'code' 요청시

  ===>
  {
    OPT_001: {code: "OPT_001", name: "요일 선택", values: "1,2,3,0", idx: 0}
  }
  리턴
*/
const arrayToObject = (targetArray, targetKey) => {
  if (!targetKey) {
    throw Error("targetKey is nessesary");
  }
  let returnObject = {};
  targetArray.forEach((value, idx) => {
    returnObject[value[targetKey]] = {
      ...value,
      idx,
    };
  });
  return returnObject;
};

/*
  Object => Object Array로 변경
  ex)
  {
    OPT_001: {code: "OPT_001", name: "요일 선택", values: "1,2,3,0", idx: 0}
  } , 'idx' (리턴 array index 참고 필드명)
  요청시
  ===>

  [{code: "OPT_001", name: "요일 선택", values: "1,2,3,0", idx: 0}], 
  
  리턴
*/
const objectToArray = (targetObject, idxName) => {
  let returnArray = new Array(Object.keys(targetObject).length);

  Object.keys(targetObject).forEach((value, idx) => {
    returnArray[idxName ? parseInt(value[idxName]) : idx] = targetObject[value];
  });
  return returnArray;
};

//객체 깊은복사
const objectDeepClone = (obj) => {
  if (obj === null || typeof obj !== "object" || isDate(obj)) {
    return obj;
  }

  const result = Array.isArray(obj) ? [] : {};

  for (let key of Object.keys(obj)) {
    result[key] = objectDeepClone(obj[key]);
  }

  return result;
};

// 불변(immutable) 객체
function deepFreeze(obj) {
  const props = Object.getOwnPropertyNames(obj);

  props.forEach((name) => {
    const prop = obj[name];
    if (typeof prop === "object" && prop !== null) {
      deepFreeze(prop);
    }
  });
  return Object.freeze(obj);
}

function flattenObject(obj, parentKey = '') {
  return Object.keys(obj).reduce((result, key) => {
    const newKey = parentKey ? `${parentKey}_${key}` : key;
    const value = obj[key]?? '';

    if (typeof value === 'object' && !Array.isArray(value)) {
      Object.assign(result, flattenObject(value, newKey));
    } else {
      result[newKey] = value;
    }

    return result;
  }, {});
}

function deepCopy (obj) {
  if ( obj === null || typeof obj !== 'object' ) {
    return obj;
  }

  if(Array.isArray(obj)) {
   const copy = [];
    for(let i = 0; i < obj.length; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  if(typeof obj === 'object') {
    const copy = {};
    for(let key in obj) {
      if(obj.hasOwnProperty(key)) {
        copy[key] = deepCopy(obj[key]);
      }
    }
    return copy;
  }

}



export { arrayToObject, objectToArray, objectDeepClone, deepFreeze, flattenObject, deepCopy };
