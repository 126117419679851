// action
import * as serviceAPI from "../../../lib/service-api";

export const GET_TEMP_TOKEN_REQUEST = 'GET_TEMP_TOKEN_REQUEST';
export const GET_TEMP_TOKEN_SUCCESS = 'GET_TEMP_TOKEN_SUCCESS';
export const GET_TEMP_TOKEN_FAILURE = 'GET_TEMP_TOKEN_FAILURE';

export const getTempTokenRequest = () => ({
  type: GET_TEMP_TOKEN_REQUEST,
});
export const getTempTokenSuccess = (tempToken) => ({
    type: GET_TEMP_TOKEN_SUCCESS,
    tempToken,
});

export const getTempTokenFailure = (error) => ({
    type: GET_TEMP_TOKEN_FAILURE, error
});

export const postTempToken = (params) => {
    return (dispatch) => {
        dispatch(getTempTokenRequest());

        return serviceAPI.postTempToken(params)
            .then(response => {
                return response?.data?.result;
            })
            .then(data => dispatch(getTempTokenSuccess(data?.token?.token)))
            .catch(error => dispatch(getTempTokenFailure(error)));
    };
};
