/**
 * 본 모듈은 문자열이 휴대폰 번호 (앞자리 번호 검증 포함) 형식 인지 검사하는 모듈 입니다
 *
 * @module utils/isHandPhoneNumberFormat
 */

/**
 * 문자열을 검사하여 휴대폰 번호 (앞자리 번호 검증 포함) 형식 여부를 반환 합니다
 *
 * @param {string} str - 문자열
 * @return {boolean} 휴대폰 번호 (앞자리 번호 검증 포함) 형식 여부
 */
export default function isHandPhoneNumberFormat(str) {
  if (
    /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/.test("" + str) === false
  ) {
    return false;
  }
  return true;
}
