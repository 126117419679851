import React, {useEffect, useState} from "react";
import {FormControl, MenuItem, Select, TableCell, TableRow, OutlinedInput} from "@material-ui/core";
import {COUPON_DISCOUNT_TYPE_LIST, FIRST_COUPON_DAY_TYPE_LIST, getIndexFromDiscountType} from "../../lib/helper-coupon";
import {UpDownControl} from "../../components";
import TextFieldForCharge from "../../components/TextFieldForCharge";
import commaFormater from "../../utils/commaFormater";
import CouponSelect from "./components/CouponSelect";


// 숙박/대실 타입과 선착순 설정 데이터가 넘어오면 해당하는 탭에 맞는 데이터를 출력
export default function FirstComeCouponTable({data = []}) {

    const getBody = () => {
        let body = [];

        data.map((item, index) => {
            body.push(<TableRow key={`firstComeCouponBody_${index}`}>
                <TableCell align="center" style={{width: "22%"}}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <OutlinedInput
                            disabled
                            value={FIRST_COUPON_DAY_TYPE_LIST.find((value) => value.day_type === item.weekType).name}
                            autoComplete="off"
                            style={{
                                display: "block",
                                padding: "0 8px",
                                color: "#00000061",
                            }}
                            inputProps={{
                                style: {padding: "10px 0px", textAlign: "center"},
                            }}
                        />
                    </FormControl>
                </TableCell>
                <TableCell
                    align="center"
                    style={{
                        width: '22%',
                        maxWidth: "130px",
                    }}
                >
                    <FormControl variant="outlined" style={{display: "inline"}}>
                        <OutlinedInput
                            disabled
                            value={`${commaFormater(item.discountPrice)}${item.discountType === 'AMOUNT' ? '원':'%'}`}
                            autoComplete="off"
                            style={{
                                display: "block",
                                padding: "0 8px",
                                color: "#00000061",
                            }}
                            inputProps={{
                                style: {padding: "10px 0px", textAlign: "center"},
                            }}
                        />
                    </FormControl>
                </TableCell>

                <TableCell
                    align="center"
                    style={{
                        width: '22%',
                        maxWidth: "130px",
                    }}
                >
                    <FormControl variant="outlined" style={{display: "inline"}}>
                        <OutlinedInput
                            disabled
                            value={`${commaFormater(item.totalCount)}`}
                            autoComplete="off"
                            style={{
                                display: "block",
                                padding: "0 8px",
                                color: "#00000061",
                            }}
                            inputProps={{
                                style: {padding: "10px 0px", textAlign: "center"},
                            }}
                        />
                    </FormControl>
                </TableCell>

                <TableCell
                    align="center"
                    style={{
                        width: '22%',
                        maxWidth: "130px",
                    }}
                >
                    <FormControl variant="outlined" style={{display: "inline"}}>
                        <OutlinedInput
                            disabled
                            value={COUPON_DISCOUNT_TYPE_LIST.find((value) => value.discount_type === item.discountType).name}
                            autoComplete="off"
                            style={{
                                display: "block",
                                padding: "0 8px",
                                color: "#00000061",
                            }}
                            inputProps={{
                                style: {padding: "10px 0px", textAlign: "center"},
                            }}
                        />
                    </FormControl>
                </TableCell>
            </TableRow>)
        });

        return body;
    }

    return getBody()

}
