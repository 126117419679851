import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4B93FF",
    },
    secondary: {
      main: "#c50e29",
    },
    black: {
      main: "#000000",
    },
  },
});

theme = {
  ...theme,
  metismenuLink: {
    active: {
      background: "red",
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        // backgroundColor: '#18202c',
        background: "#F6F7F9",
      },
    },
  },
};

export default theme;
