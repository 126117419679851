// 리듀서 스토어, 액션 함수 생성 예시
const initialState = {
  dayStandardData: [], // 숙박 데이터
  changeDayStandardData: [],  // 숙박 불러오기 데이터(저장 전)
  hourStandardData: [], // 대실 데이터
  changeHourStandardData: [], // 대실 불러오기 데이터(저장 전)
};

export const DAY = 'ROOMS/DAY';
export const HOUR = 'ROOMS/HOUR';
const rooms = (state = initialState, action = {}) => {
  switch (action.type) {
    case DAY:
      return {
        ...state,
        dayStandardData: action.payload?.dayStandardData,
        changeDayStandardData: action.payload?.changeDayStandardData,
      };
    case HOUR:
      return {
        ...state,
        hourStandardData: action.payload?.hourStandardData,
        changeHourStandardData: action.payload?.changeHourStandardData,
      };

    default:
      return state;
  }
};

export const setDayRoomsList = (rooms) => ({type: DAY, payload: rooms});
export const setHourRoomsList = (rooms) => ({type: HOUR, payload: rooms});
export default rooms;