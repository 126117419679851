import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/locale";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import * as serviceBrowser from "lib/service-browser";
import * as helperDatePicker from "lib/helper-react-datepicker";
import { format } from "date-fns";

/**
 * [변경 내역]
 * 2020.11.26 양태욱
 * 1. 선택 된 날짜 색상 변경 (common-mobile.css)
 * 2. 토,일요일 색상 적용 (handleDayClassName 메서드)
 * 참고 : https://jaimemin.tistory.com/1432
 */
const styles = {
  calendarIcon: {
    width: "32px",
    height: "32px",
    position: "absolute",
    top: "5px",
    right: "2px",
  },
};
/**
 * (Mobile용) DatePicker를 커스터마이징하여 변경 사항이 있을 경우 일괄적으로 변경하는 DatePicker입니다. - 양태욱 전임
 *
 * @author TaeWook Yang<didxodnr0769@pineone.com>
 * @name CustomMobileDatePicker
 */
class CustomMobileDatePicker extends Component {
  onCalendarOpen = () => {
    // handleOnCalendarOpen을 props로 받지 않으면 해당 메서드 실행
    serviceBrowser.disableScroll();
  };
  onCalendarClose = () => {
    // handleOnCalendarClose을 props로 받지 않으면 해당 메서드 실행
    serviceBrowser.enableScroll();
  };

  /** 날짜에 class를 부여하여 css 적용 */
  handleDayClassName = (date) => {
    const dateStr = format(date, "iii");
    const CONST_CLASS_SATURDAY = "react-datepicker__day--saturday";
    const CONST_CLASS_SUNDAY = "react-datepicker__day--sunday";
    if (dateStr === "Sat") return CONST_CLASS_SATURDAY;
    else if (dateStr === "Sun") return CONST_CLASS_SUNDAY;
  };

  render() {
    const {
      selected,
      startDate,
      endDate,
      minDate,
      maxDate,
      dateFormat = "yyyy-MM-dd",
      id = "",
      selectsStart = false,
      selectsEnd = false,
      disabled = false,
      showMonthYearPicker = false,
      calendarClassName,
      className,
      customInput,
      placeholderText,
    } = this.props;
    return (
      <Box
        position="relative"
        style={{
          border: "none",
          padding: "0",
        }}
      >
        <DatePicker
          selected={selected}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={this.props.onChange}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          showPopperArrow={false}
          dateFormat={dateFormat}
          locale={ko}
          ref={helperDatePicker.handleCalendarClickOutside}
          popperPlacement="end"
          onFocus={(e) => e.target.blur()}
          id={id}
          disabled={disabled}
          showMonthYearPicker={showMonthYearPicker}
          calendarClassName={calendarClassName}
          calssName={className}
          customInput={customInput}
          withPortal
          onCalendarOpen={
            this.props.onCalendarOpen === undefined
              ? this.onCalendarOpen
              : this.props.onCalendarOpen
          }
          onCalendarClose={
            this.props.onCalendarClose === undefined
              ? this.onCalendarClose
              : this.props.onCalendarClose
          }
          dayClassName={this.handleDayClassName}
          placeholderText={placeholderText}
        />
      </Box>
    );
  }
}
const styledDatePicker = withStyles(styles)(CustomMobileDatePicker);
export { styledDatePicker as CustomMobileDatePicker };
