/**
 * 본 모듈은 예금주 이름의 형식이 유효한지 체크 하는 모듈 입니다<p/>
 *
 * checkUserName와 처리 플로우는 동일하나<br/>
 * 문구 관리를 InputFieldGuide에서 처리 하기 위해 별도 모듈로 운용 합니다.
 *
 * @module utils/checkAccountName
 * @see module:utils/checkUserName
 * @see module:constants/InputFieldGuide
 */

import InputFieldGuide from "constant/InputFieldGuide";
import isEmpty from "./isEmpty";
import isKoreanFormat from "./isKoreanFormat";

/**
 * 예금주 이름을 검사하여, 유효하지 않을 경우 에러 메세지를 반환 합니다
 *
 * @param {string} accountName - 예금주 이름
 * @return {string} 에러 메세지. 유효 할 경우 빈문자열을 반환 함
 */

export default function checkAccountName(accountName, isRequired = true) {
  let errorMessage = "";
  let strAccountName = accountName?.trim();

  // 이름 공백 검사
  if (isRequired && !errorMessage && isEmpty(strAccountName) === true) {
    errorMessage = InputFieldGuide.accountName.required;
  }

  // 이름 길이 검사 (최소 2글자 이상)
  if (isRequired && !errorMessage && 2 > strAccountName.length) {
    // errorMessage = InputFieldGuide.accountName.minLength;
    // 20201230 박태성C : C202101-7 관련 수정
    errorMessage = InputFieldGuide.accountName.invalid;
  }

  // 예금주 입력은 한글, 영문, 콤마, 빈칸만을 허용 하므로,
  // 영문, 한글, 콤마, 빈칸을 없애고 공백이 아닐 경우 에러 처리 함
  if (!errorMessage) {
    if (!!strAccountName?.replace(/[a-zA-Z가-힣()\[\]*\-_&,]/gi, "").trim()) {
      errorMessage = InputFieldGuide.accountName.invalid;
    }
  }

  return errorMessage;
}
