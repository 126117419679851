import React from "react";

import { SimpleMileageSetting } from "components";

import {
  POPUP_ACTION_OK,
  // openAlertPopupWithPromise,
  openConfirmPopupWithPromise,
  openHttpRejectionPopupWithPromise,
} from "lib/helper-popup";

/**
 * 마일리지 간편 설정 Template Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name MileageTemplate
 * @component
 */
class MileageTemplate extends React.PureComponent {
  changeMileage = (mileageAmount) => {
    openConfirmPopupWithPromise(null, "설정한 값으로 적용하시겠습니까?")
      .then((res) => {
        if (res === POPUP_ACTION_OK) {
          return this.props
            .onRequestAjax("createModifySettingsMotels", {
              point_rate: mileageAmount, //마일리지 적립률%
            })
            .then((res) => {
              return this.props.onRefresh();
            });
        }
      })
      .catch(openHttpRejectionPopupWithPromise);
  };

  render() {
    const {
      model,
      // onRequestAjax
    } = this.props;
    const { settingData } = model;
    console.log("PTS", JSON.stringify(settingData, null, 4));
    if (!!settingData) {
      return (
        <SimpleMileageSetting
          settingData={settingData}
          changeMileage={this.changeMileage}
        />
      );
    }

    return <></>;
  }
}

// 초기 props 설정
MileageTemplate.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default MileageTemplate;
