import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ContainerSinglePage from "components/ContainerSinglePage";
import ButtonSubmit from "components/ButtonSubmit";

import * as configApp from "config/config-app";
import * as serviceBrowser from "lib/service-browser";

let SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";

// 16px 기준으로 rem 테이블 정리 사이트 : http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
// https://nekocalc.com/px-to-rem-converter

const styles = (theme) => {
  if (!SERVICE_TYPE_PC) {
    // 모바일
    return {
      root: {
        "& .csp .csp-box-inner": {
          width: "90%",
        },
        "& .not-found": {
          display: "block !important",
          "& p.guide": {
            margin: 0,
          },
        },
        // AppOnMobile에서 body에 landscape를 등록 할 경우 하단 스타일이 적용 되도록 수정
        "body.landscape & .main": {
          paddingTop: "5% !important",
        },
        "& .main": {
          paddingTop: "28% !important",
        },
        "& .main img": {
          width: "auto",
          height: "50px",
        },
      },
    };
  }

  // PC
  return {
    root: {
      "& .not-found": {
        height: "11rem", // 176px,
      },
    },
  };
};

/**
 * Chunk 파일 로딩 시 네트워크 단절로 인해 에러가 발생했을 때<br/>
 * Fallback 화면을 출력 시키는 Error Boundary Component입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name ChunkLoadErrorBoundary
 * @class
 * @component
 */
class ChunkLoadErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasChunkError: false };
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(evt) {
    !!evt && !!evt.preventDefault && evt.preventDefault();
    window.location.reload();
  }

  componentDidMount() {
    serviceBrowser.addClassNameToBody("logout");
  }
  static getDerivedStateFromError(error) {
    if(error.name === 'ChunkLoadError') {
     return {
       hasChunkError: true
     }
    }
  }
  componentDidCatch(error, errorInfo) {
  }

  render() {
    const { classes } = this.props;

    if (this.state.hasChunkError) {
      return (
        <div className={classes.root}>
          <ContainerSinglePage
            title={`${configApp.APP_TITLE}`}
            isHideBack={true}
          >
            {SERVICE_TYPE_PC && <InnerOnPC onSubmit={this.handleOnClick} />}
            {!SERVICE_TYPE_PC && (
              <InnerOnMobile onSubmit={this.handleOnClick} />
            )}
          </ContainerSinglePage>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withTheme(withStyles(styles)(ChunkLoadErrorBoundary));

function InnerOnPC({ onSubmit }) {
  return (
    <>
      <Box className="description not-found">
        <p className="bold font-size-16">연결 상태가 원활하지 않습니다.</p>
        <p className="bold">
          네트워크 상태를 확인하시고 연결을 시도 해 주세요.
        </p>
      </Box>
      <ButtonSubmit lblSubmit={"재연결"} onSubmitByClick={onSubmit} />
    </>
  );
}

function InnerOnMobile({ onSubmit }) {
  return (
    <>
      <Box justifyContent="center">
        <div className="main">
          <Box className="description not-found" align="center">
            <p className="bold font-size-16">연결 상태가 원활하지 않습니다.</p>
            <p className="bold guide">
              네트워크 상태를 확인하시고
              <br />
              연결을 시도 해 주세요.
            </p>
          </Box>
          <ButtonSubmit lblSubmit={"재연결"} onSubmitByClick={onSubmit} />
        </div>
      </Box>
    </>
  );
}
