import React, { useEffect, useState, useCallback } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Paper, Table, TableContainer, TableBody } from "@material-ui/core";
import { ModalWrap } from "../../../components";
import { COUPON_COLUMNS } from "lib/helper-coupon";
import CouponSettingHelp from "../components/CouponSettingHelp";
import TableHeader from "../components/TableHeader";
// import TableContents from "../components/TableContents";
import TableContent from "../components/TableContent";
import { objectDeepClone } from "utils/ObjectHelper";

const styles = (theme) => ({
  table: {
    marginTop: "10px",
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    "& th": {
      background: "#f8f8f9",
      fontWeight: "bold",
    },
    "& td": {
      border: "1px solid #ddd",
    },
  },
  couponSelectWrap: {
    width: "30%",
    lineHeight: "60px",
    background: "#f8f8f9",
    borderRight: "1px solid #ddd",
  },
});

export const CouponSettings = (props) => {
  const {
    modalOpen,
    applyCouponName,
    applyCouponType,
    couponModalClose,
    applyCoupons,
    handleClickSubmitButton,
    violationIdxList,
    selectCouponCode,
  } = props;
  const [coupons, setCoupons] = useState(applyCoupons);

  const setCoupon = (coupon, idx) => {
    const copiedCoupons = objectDeepClone(coupons);
    copiedCoupons[idx] = coupon;
    setCoupons(copiedCoupons);
  };

  useEffect(() => {
    setCoupons(applyCoupons);
  }, [applyCoupons]);

  const handleSubmit = () => {
    handleClickSubmitButton(coupons, applyCouponType);
  };

  const handlemodalClose = () => {
    setCoupons(applyCoupons);
    couponModalClose();
  };

  return (
    <ModalWrap
      title={"선착순 쿠폰설정"}
      subTitle={`(${applyCouponName} 적용중)`}
      onClose={handlemodalClose}
      onButtons={[
        {
          title: "확인",
          callback: handleSubmit,
        },
        {
          title: "취소",
          callback: handlemodalClose,
        },
      ]}
      open={modalOpen}
      PaperProps={{
        style: {
          width: "1100px",
          minHeight: "600px",
        },
      }}
    >
      {coupons.length === 1 ? (
        <Paper
          elevation={0}
          variant="outlined"
          style={{ margin: "1em 0", padding: "10px 20px" }}
        >
          <h5
            style={{
              color: "#383838",
              textAlign: "center",
              fontSize: "1.2rem",
              margin: "50px 0px",
            }}
          >
            미발행 템플릿 적용 중입니다. <br />
            다른 템플릿 적용 후 다시 선택해주세요.
          </h5>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table
            // className={classes.table}
            style={{
              tableLayout: "fixed",
            }}
          >
            <TableHeader columns={COUPON_COLUMNS} />
            <TableBody>
              {coupons.map((coupon, idx) => {
                return (
                  <TableContent
                    key={`coupon_${idx}`}
                    idx={idx}
                    coupon={coupon}
                    couponType={applyCouponType}
                    setCoupon={setCoupon}
                    type={"SIMPLE_COUPON"}
                    isDetail
                    isViolation={violationIdxList.includes(idx)}
                    selectCouponCode={applyCouponType}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <CouponSettingHelp />
    </ModalWrap>
  );
};

export default withTheme(withStyles(styles)(CouponSettings));
