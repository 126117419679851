/**
 * 본 모듈은 userId 형식이 유효한지 검사하는 모듈 입니다
 *
 * @module utils/isUserIdFormat
 */

/**
 * userId를 검사하여 형식의 유효 여부를 반환 합니다
 *
 * @param {string} userId - 인증번호
 * @return {boolean} userId 형식 유효 여부
 */
export default function isUserIdFormat(userId) {
  let strUserId = "" + userId;
  if (/^[A-Za-z0-9]+$/.test(strUserId) === false) {
    return false;
  }
  return true;
}
