import React, { useState, useCallback } from "react";
import commaFormater from "utils/commaFormater";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, IconButton, OutlinedInput, FormControl } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

/**
 * 모바일 - 업 다운 버튼이 있는 컨트롤러 Component 입니다.<p/>
 *
 * 해당 컴포넌트는 기본적으로 언컨트롤러 방식으로 동작 처리 합니다
 *
 * @author Taesung Park <pts@pineone.com>
 * @name UpDownControlMobileUC
 * @class
 * @component
 */

const styles = (theme) => ({
  root: {},
  formControl: {
    display: "inline",
  },
  outlinedInput: {
    width: "100%",
    margin: "0",
    border: "none",
    height: "100%",
    borderRadius: "0px",
  },
  button: {
    background: "#dedede",
    "& button": {
      height: "36px",
      marginTop: "2px !important",
      padding: "6px 12px",
    },
  },
  boxInput: {
    width: "calc(100% - 96px)",
  },
});

const outlinedInputProps = {
  style: {
    fontSize: "12px",
    paddingTop: "10.5px",
    paddingBottom: "10.5px",
    textAlign: "center",
    border: "none",
  },
};

let COUNT_COMPONENT = 0;
const REX_NUM = /[^0-9]/g;

function UpDownControlMobileUC({
  classes,
  name,
  callback,
  initialVal,
  interval,
  min,
  max,
}) {
  const [state, setState] = useState(() => {
    COUNT_COMPONENT++;
    return {
      unique: `UD_${COUNT_COMPONENT}_` + Date.now(),
    };
  });

  const { unique } = state;

  const increment = useCallback(
    (value) => {
      let newValue = parseInt(value) + interval;
      if (newValue > max) newValue = max;
      return newValue;
    },
    [interval, max]
  );

  const decrement = useCallback(
    (value) => {
      let newValue = parseInt(value) - interval;
      if (newValue < min) newValue = min;
      return newValue;
    },
    [interval, min]
  );

  const handleUp = useCallback(
    (evt) => {
      let elem = evt.currentTarget;
      let target = document.querySelector(`#${unique}`);
      if (!!target) {
        let value = parseInt(target.value.replace(REX_NUM, ""));
        let newValue = increment(value);
        if (value !== newValue) {
          target.value = 1000 < newValue ? commaFormater(newValue) : newValue;
          !!callback && callback(newValue, name);
        }
      }
      elem = target = null;
    },
    [callback, name]
  );

  const handleDown = useCallback(
    (evt) => {
      let elem = evt.currentTarget;
      let target = document.querySelector(`#${unique}`);
      if (!!target) {
        let value = parseInt(target.value.replace(REX_NUM, ""));
        let newValue = decrement(value);
        if (value !== newValue) {
          target.value = 1000 < newValue ? commaFormater(newValue) : newValue;
          !!callback && callback(newValue, name);
        }
      }
      elem = target = null;
    },
    [callback, name]
  );

  return (
    <Box display="flex" className={classes.root}>
      <Box className={classes.button} onClick={handleDown}>
        <IconButton>
          <RemoveIcon />
        </IconButton>
      </Box>
      <Box align="center" className={classes.boxInput}>
        <FormControl
          variant="outlined"
          margin="normal"
          className={classes.formControl}
        >
          <OutlinedInput
            disabled
            value={commaFormater(parseInt(initialVal))}
            id={unique}
            size="small"
            className={classes.outlinedInput}
            inputProps={outlinedInputProps}
          />
        </FormControl>
      </Box>
      <Box className={classes.button} onClick={handleUp}>
        <IconButton>
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

const styledUpDownControl = withTheme(
  withStyles(styles)(React.memo(UpDownControlMobileUC))
);
export { styledUpDownControl as UpDownControlMobileUC };
