/**
 * Redux 기반으로 `스케쥴 전파` 기능을 제공하는 모듈 입니다
 *
 * @author Taesung Park <pts@pineone.com>
 * @module system/redux-ducks/scheduler
 */

// const TAG = "[system/redux-ducks/scheduler.js]";
// console.log(TAG, "Create");

/*#############################################################
 *  Actions
 *###########################################################*/

/** 스케쥴을 전파 할 때 발생 되는 Action Type 입니다 */
export const SCHEDULE_NOTIFYED = "@scheduler/SCHEDULE_NOTIFYED";

/*#############################################################
 *  Action Creator
 *###########################################################*/

/**
 * Breadcrumb 상태 업데이트 요청을 수행하는 Action 함수 입니다
 *
 * @param {object} schedule - 스케쥴 정보
 * @returns {Promise} Promise 객체
 */
export function notifyScheduleRequest(schedule) {
  return (dispatch) =>
    Promise.resolve().then(() => dispatch(notifySchedule(schedule)));
}

/**
 * SCHEDULE_NOTIFYED 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @param {object} schedule - 스케쥴 정보
 * @returns {object} SCHEDULE_NOTIFYED 타입을 정의한 객체
 */
export function notifySchedule(schedule) {
  return {
    type: SCHEDULE_NOTIFYED,
    name: schedule.name,
    timeSchedule: schedule.timeSchedule,
  };
}

/*#############################################################
 *  Reducer
 *###########################################################*/

const initialState = {
  schedule: {
    name: "",
    timeSchedule: 0,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SCHEDULE_NOTIFYED:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          name: action.name,
          timeSchedule: action.timeSchedule,
        },
      };
    default:
      return state;
  }
}
