import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

/**
 * 오늘 숙박/대실 판매 현황에서 사용하는 판매/마감 switch Component 입니다.<p/>
 */

const styles = (theme) => ({
  container: {
    position: "relative",
    width: "60px",
    left: "10px",
    cursor: "pointer",
  },
  track: {
    position: "absolute",
    top: "3px",
    width: "50px",
    height: "15px",
    background: "#9F9F9F",
    borderRadius: "8px",
  },
  thumb: {
    position: "absolute",
    transition: "left 0.1s linear",
    left: "-10px",
    fontSize: "12px",
    fontWeight: "100",
    padding: "0 5px 2px 5px",
    borderRadius: "3px",
    float: "left",
    marginLeft: "8px",
  },
  checked: {
    transition: "left 0.1s linear",
    left: "10px",
  },
});

class SalesSwitch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saleYn: props.saleYn,
    };
  }

  handleClick = () => {
    const saleYn = this.state.saleYn === "Y" ? "N" : "Y";
    this.setState({ saleYn });
    this.props.callback(saleYn, this.props.type, this.props.name);
  };

  render() {
    const { classes } = this.props;
    const { saleYn } = this.state;

    return (
      <div className={classes.container} onClick={this.handleClick}>
        <div className={classes.track}></div>
        <span
          className={`${
            saleYn === "N" ? `bg-room-close ${classes.checked}` : "bg-room-open"
          } ${classes.thumb}`}
        >
          {saleYn === "N" ? "마감" : "판매"}
        </span>
      </div>
    );
  }
}

// props의 초깃값을 정의합니다.
SalesSwitch.defaultProps = {};

const styledSalesSwitch = withStyles(styles)(SalesSwitch);

export { styledSalesSwitch as SalesSwitch };
