import React, { Component } from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import * as configApp from "config/config-app";
// import { objectDeepClone } from "utils/ObjectHelper";
const BASE_NAME = configApp.getPathRoot();

/**
 * 이미지 확대 모달 Component 입니다.<p/>
 *
 * @author Drst3in <drst3in9196@pineone.com>
 * @name ModalWrapForImage
 * @class
 * @component
 */

/**
 * [수정사항]
 *
 * 2021.05.04 양태욱
 * Close 버튼 position : fixed로 변경
 */

const styles = (theme) => ({
  root: {
    // border: "1px solid red",
    margin: 0,
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  pcClosedBtnPos: {
    right: "20px",
  },
  mobileClosedBtnPos: {
    left: 0,
  },
  arrowLeft: {
    left: "0px",
    color: "white",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    position: "fixed",
    fontSize: "55px",
    top: "46%",
    width: "34px",
    fontWeight: "bold",
  },
  arrowLeftPC: {
    left: "20px",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    color: "white",
    position: "fixed",
    fontSize: "55px",
    top: "46%",
    width: "34px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  arrowLeftHide: {
    display: "none",
  },
  arrowRight: {
    right: "20px",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    color: "white",
    position: "fixed",
    fontSize: "55px",
    top: "46%",
    width: "34px",
    fontWeight: "bold",
  },
  arrowRightPC: {
    right: "40px",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    color: "white",
    position: "fixed",
    fontSize: "55px",
    top: "46%",
    width: "34px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  arrowRightHide: {
    display: "none",
  },
  indicator: {
    color: "#fff",
    fontSize: "1.5em",
    position: "fixed",
    bottom: "10%",
    width: "100%",
    textAlign: "center",
  },
  indicatorInner: {
    width: "100px",
    margin: "0 auto",
    background: "#5a5a5a",
    borderRadius: "5px",
  },
});

class ModalWrapForImage extends Component {
  constructor(props) {
    super(props);
    console.log("ModalWrapForImage props ; ", props);

    this.state = {
      viewImgUrl: "",
      viewNowIndex: 1,
      imgCnt: 0,
      arrowLeftVisible: false,
      arrowRightVisible: false,
      PaperProps: {
        style: {
          background: "#000",
        },
      },
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.imgUrl !== prevProps.imgUrl) {
      this.setState({ viewImgUrl: this.props.imgUrl });
      var nowIndex = this.getImgIndex(this.props.imgUrl);

      this.arrowVisibleCheck(nowIndex);

      //전체 이미지 갯수
      this.setState({ imgCnt: this.props.imgUrlList.length });
    }
  }

  //이미지 화살표 상태 검사
  arrowVisibleCheck = (nowIndex) => {
    if (nowIndex > 0 && nowIndex < this.props.imgUrlList.length - 1) {
      this.setState({ arrowLeftVisible: true });
      this.setState({ arrowRightVisible: true });
    } else {
      if (nowIndex === 0) {
        this.setState({ arrowLeftVisible: false });
        this.setState({ arrowRightVisible: true });
      } else if (nowIndex === this.props.imgUrlList.length - 1) {
        this.setState({ arrowLeftVisible: true });
        this.setState({ arrowRightVisible: false });
      }
    }

    //이미지 총 갯수가 1이면 화살표 숨김
    if (this.props.imgUrlList.length === 1) {
      this.setState({ arrowLeftVisible: false });
      this.setState({ arrowRightVisible: false });
    }

    //현재 보는 이미지의 순번
    this.setState({ viewNowIndex: nowIndex + 1 });
  };

  //이미지 url로 해당 이미지의 index를 추출
  getImgIndex = (imgUrl) => {
    var nowIndex = 0;
    var checkImgUrl = this.state.viewImgUrl;
    if (imgUrl) {
      checkImgUrl = imgUrl;
    }

    this.props.imgUrlList.forEach((obj, index) => {
      //console.log(obj);
      if (obj.url === checkImgUrl) {
        nowIndex = index;
      }
    });

    return nowIndex;
  };

  //이전 화살표
  prevImg = () => {
    console.log("이전");
    var nowIndex = this.getImgIndex();
    console.log("현재 이미지 index : ", nowIndex);
    if (nowIndex > 0) {
      nowIndex--;
    }

    var ImgUrl = this.props.imgUrlList[nowIndex].url;
    this.setState({ viewImgUrl: ImgUrl });
    this.arrowVisibleCheck(nowIndex);
  };

  //다음 화살표
  nextImg = () => {
    console.log("다음");
    var nowIndex = this.getImgIndex();
    console.log("현재 이미지 index : ", nowIndex);
    console.log(
      "this.props.imgUrlList.length : ",
      this.props.imgUrlList.length
    );

    if (nowIndex < this.props.imgUrlList.length - 1) {
      nowIndex++;
    }

    var ImgUrl = this.props.imgUrlList[nowIndex].url;
    this.setState({ viewImgUrl: ImgUrl });
    this.arrowVisibleCheck(nowIndex);
  };

  render() {
    const {
      classes,
      // , theme
      onClose,
      // , imgUrl, imgUrlList
    } = this.props;

    let imgWidth = "100%";
    let closeIconsSize = "1.2em";

    //mobile 환경일때 화살표 css classes
    var arrowLeftClass = classes.arrowLeft;
    var arrowRightClass = classes.arrowRight;

    if (configApp.SERVICE_TYPE === "pc") {
      imgWidth = "50%";
      closeIconsSize = "2.2em";

      //pc 환경일때 화살표 css classes
      arrowLeftClass = classes.arrowLeftPC;
      arrowRightClass = classes.arrowRightPC;
    }

    return (
      <Dialog
        disableBackdropClick={true}
        onClose={onClose}
        open={this.props.open}
        className={classes.root}
        fullScreen
        PaperProps={this.state.PaperProps}
      >
        <DialogContent
          style={{
            padding: "0",
            display: "table",
            height: "100%",
            width: "100%",
          }}
        >
          {/* 이전 이미지 버튼 */}
          <Box
            title="이전"
            className={
              this.state.arrowLeftVisible
                ? arrowLeftClass
                : classes.arrowLeftHide
            }
            onClick={this.prevImg}
          >
            〈
          </Box>
          {/* 다음 이미지 버튼 */}
          <Box
            title="다음"
            className={
              this.state.arrowRightVisible
                ? arrowRightClass
                : classes.arrowRightHide
            }
            onClick={this.nextImg}
          >
            〉
          </Box>
          <Box className={classes.indicator}>
            <Box className={classes.indicatorInner}>
              {this.state.viewNowIndex} / {this.state.imgCnt}
            </Box>
          </Box>
          <Box
            // position="absolute"
            position="fixed"
            top={0}
            className={
              configApp.SERVICE_TYPE === "pc"
                ? classes.pcClosedBtnPos
                : classes.mobileClosedBtnPos
            }
          >
            {/* 닫기 버튼 */}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon style={{ fontSize: closeIconsSize }} />
            </IconButton>
          </Box>
          <Box
            display="table-cell"
            style={{ verticalAlign: "middle" }}
            align="center"
          >
            <img
              width={imgWidth}
              src={this.state.viewImgUrl}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = BASE_NAME + "/no-image1.gif";
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

//export default ModalWrap;
const styledModalWrapForImage = withTheme(
  withStyles(styles)(ModalWrapForImage)
);

export { styledModalWrapForImage as ModalWrapForImage };
