import React, { useEffect, useState } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { TableRow, TableCell } from "@material-ui/core";
import {
  COUPON_DISCOUNT_TYPE_LIST,
  EASY_COUPON_ITEM_TYPE_LIST,
  EASY_COUPON_DAY_TYPE_LIST,
  COUPON_ADD_ITEM_TYPE_LIST,
  AVAILABLE_PRICE,
  getEasyCouponIndex,
  getUnitTypeFromCode,
  getUnitInterval,
  getIndexFromDiscountType,
  getUnitMinValueDiscount,
  getUnitMaxValueDiscount,
  getUnitMinValueEvent,
  getUnitMaxValueEvent,
  getDiscountPriceFromType,
  initMasterAttr,
  getIndexFromDayType,
} from "lib/helper-coupon";
import { objectDeepClone } from "utils/ObjectHelper";
import CouponSelect from "./CouponSelect";
import CouponUpDownForm from "./CouponUpDownForm";

const styles = (theme) => ({
  table: {
    marginTop: "10px",
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    "& th": {
      background: "#f8f8f9",
      fontWeight: "bold",
    },
    "& td": {
      border: "1px solid #ddd",
    },
  },
  couponSelectWrap: {
    width: "30%",
    lineHeight: "60px",
    background: "#f8f8f9",
    borderRight: "1px solid #ddd",
  },
});
const ITEM_LIST = [
  { name: "Y", hide: false },
  { name: "N", hide: false },
];
/**
 * coupons의 row를 하나씩 가져와 보여주는 컴포넌트
 * props
 *  - idx(Number): row의 인덱스 값
 *  - coupon(Array): row
 *  - setCoupon(f): row의 set state 함수
 *  - couponType(string): coupon template code
 *  - type(string): "SIMPLE_COUPON"(쿠폰발행간편설정) | "TODAY_COUPON"(오늘쿠폰추가발행)
 *  - isDetail(boolean): 사용조건 쿠폰, 선착순 쿠폰 설정에서 보여지는 세부 설정 여부
 */
export const TableContent = (props) => {
  const { selectCouponCode } = props;
  const [coupon, setCoupon] = useState(props.coupon);
  const [timer, setTimer] = useState(null);
  const { idx, couponType, type, isDetail, isViolation } = props;
  const unitType = getUnitTypeFromCode(coupon.discount_type).unitType;
  const MAX_COUNT_PER_SLOT = 999;
  /**
   * 쿠폰 템플릿 : 스마트형(고정)일 때 선착순 쿠폰 설정에서
   * 구분, 할인, 수량, 유형 컬럼 수정 가능하도록 조치하였습니다. - 태민
   */
  let disabled = false;

  /** @todo Yang 추후 로직 개선  */
  if (type === "TODAY_COUPON") {
    disabled = false;
  } else if (type === "SIMPLE_COUPON") {
    if (isDetail) {
      disabled = !["CT024", "CT025"].includes(couponType);
    } else {
      disabled = coupon.fix_yn === "Y";
    }
  }

  useEffect(() => {
    setCoupon(props.coupon);
  }, [props.coupon]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const updateCoupon = (copiedCoupon, idx) => {
    setCoupon(copiedCoupon);

    timer && clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        props.setCoupon(copiedCoupon, idx);
      }, 200)
    );
  };

  const copyCoupon = (coupon) => {
    return isDetail ? objectDeepClone(coupon) : initMasterAttr(coupon);
  };

  const handle = {
    /** 구분 이벤트 함수 */
    selectItemType: (e) => {
      const { value, name } = e.target;
      const copiedCoupon = copyCoupon(coupon);

      copiedCoupon.item_type = itemTypeList[value].item_type;
      const { item_type, discount_type, discount_amount } = copiedCoupon;
      const maxPrice = getUnitMaxValueDiscount(item_type, discount_type);
      const minPrice = getUnitMinValueDiscount(copiedCoupon, selectCouponCode);

      if (discount_amount > maxPrice) {
        // 최대 최소 금액이 적합하지 않을 경우의 처리
        copiedCoupon.discount_amount = maxPrice;
      } else if (discount_amount < minPrice) {
        copiedCoupon.discount_amount = minPrice;
      }

      props.setCoupon(copiedCoupon, parseInt(name));
    },
    /** 할인 이벤트 함수 */
    changeCouponPrice: (price, name, type) => {
      const copiedCoupon = copyCoupon(coupon);
      copiedCoupon.discount_amount = price;
      updateCoupon(copiedCoupon, parseInt(name));
    },
    /** 수량 이벤트 함수 */
    changeCouponCount: (count, name) => {
      const copiedCoupon = copyCoupon(coupon);
      copiedCoupon.total_amount = count;
      updateCoupon(copiedCoupon, parseInt(name));
    },
    /** 유형 이벤트 함수 */
    selectDiscountType: (e) => {
      const { value, name } = e.target;
      const copiedCoupon = copyCoupon(coupon);
      const type = COUPON_DISCOUNT_TYPE_LIST[value].discount_type;
      const price = getDiscountPriceFromType(type, coupon.item_type);
      copiedCoupon.discount_type = type;
      copiedCoupon.discount_amount = price;
      copiedCoupon.discount_support_amount = 0;
      props.setCoupon(copiedCoupon, parseInt(name));
    },
    /** 사용가능일 이벤트 함수 */
    selectDayType: (e) => {
      const { value, name } = e.target;
      const copiedCoupon = objectDeepClone(coupon);
      copiedCoupon.day_type = EASY_COUPON_DAY_TYPE_LIST[value].day_type;
      props.setCoupon(copiedCoupon, parseInt(name));
    },
    /** 사용가능 기준금액 이벤트 함수 */
    changeCouponLimitPrice: (price, name, type) => {
      const copiedCoupon = objectDeepClone(coupon);

      if (type === "down" && !copiedCoupon.discount_min_amount) return;
      const nextPrice =
        type === "down" &&
        copiedCoupon.discount_min_amount === AVAILABLE_PRICE.min
          ? null
          : price;

      copiedCoupon.discount_min_amount = nextPrice;
      updateCoupon(copiedCoupon, parseInt(name));
    },
    /** 본사이벤트 이벤트 함수 */
    changeCouponSupportPrice: (price, name) => {
      const copiedCoupon = objectDeepClone(coupon);
      copiedCoupon.discount_support_amount = price;
      updateCoupon(copiedCoupon, parseInt(name));
    },
    /** 신규회원 전용 이벤트 함수 */
    selectNewMember: (e) => {
      const { value, name } = e.target;
      const copiedCoupon = objectDeepClone(coupon);
      copiedCoupon.new_member_yn = value === 0 ? "Y" : "N";
      props.setCoupon(copiedCoupon, parseInt(name));
    },
  };

  const itemTypeList = EASY_COUPON_ITEM_TYPE_LIST;

  return (
    <TableRow>
      {/* 구분 */}
      {type === "TODAY_COUPON" ? (
        <TableCell align="center" style={{ background: "#f8f8f9" }}>
          {COUPON_ADD_ITEM_TYPE_LIST[idx].name}
        </TableCell>
      ) : type === "SIMPLE_COUPON" ? (
        <CouponSelect
          index={idx}
          value={getEasyCouponIndex(coupon.item_type, itemTypeList) || 0}
          itemList={itemTypeList}
          disabled={disabled}
          onChange={handle.selectItemType}
        />
      ) : null}
      {/* 할인 */}
      <CouponUpDownForm
        value={coupon.discount_amount}
        unitType={unitType}
        interval={getUnitInterval(coupon.discount_type)}
        min={getUnitMinValueDiscount(coupon, selectCouponCode)}
        max={getUnitMaxValueDiscount(coupon.item_type, coupon.discount_type)}
        name={`${idx}`}
        disabled={disabled}
        onChange={handle.changeCouponPrice}
      />
      {/* 수량 */}
      <CouponUpDownForm
        value={coupon.total_amount || 0}
        unitType={""}
        interval={1}
        min={0}
        max={MAX_COUNT_PER_SLOT}
        name={`${idx}`}
        disabled={disabled}
        onChange={handle.changeCouponCount}
      />
      {/* 유형 */}
      <CouponSelect
        index={idx}
        value={getIndexFromDiscountType(coupon.discount_type)}
        itemList={COUPON_DISCOUNT_TYPE_LIST}
        name={`${idx}`}
        disabled={disabled}
        onChange={handle.selectDiscountType}
      />
      {isDetail ? (
        <>
          {/* 사용가능일 */}
          <CouponSelect
            value={getIndexFromDayType(coupon.day_type)}
            index={idx}
            itemList={EASY_COUPON_DAY_TYPE_LIST}
            disabled={type === "TODAY_COUPON"}
            onChange={handle.selectDayType}
            error={isViolation}
          />
          {/* 사용가능 기준금액 */}
          <CouponUpDownForm
            value={coupon.discount_min_amount || "제한 없음"}
            unitType={coupon.discount_min_amount ? "원" : ""}
            interval={AVAILABLE_PRICE.interval}
            min={AVAILABLE_PRICE.min}
            max={AVAILABLE_PRICE.max}
            name={`${idx}`}
            disabled={false}
            onChange={handle.changeCouponLimitPrice}
          />
          {/* 본사이벤트 */}
          <CouponUpDownForm
            value={coupon.discount_support_amount || 0}
            unitType={unitType}
            interval={getUnitInterval(coupon.discount_type)}
            min={getUnitMinValueEvent(unitType)}
            max={getUnitMaxValueEvent(unitType)}
            name={`${idx}`}
            disabled={false}
            onChange={handle.changeCouponSupportPrice}
          />
          {/* 신규회원 전용 */}
          <CouponSelect
            value={coupon.new_member_yn === "Y" ? 0 : 1}
            index={idx}
            itemList={ITEM_LIST}
            disabled={false}
            onChange={handle.selectNewMember}
          />
        </>
      ) : null}
    </TableRow>
  );
};

export default React.memo(withTheme(withStyles(styles)(TableContent)));
