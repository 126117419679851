import React, { useState, useRef, useEffect } from "react";
import {
  OutlinedInput,
  // InputAdornment,
  // IconButton,
  Box,
} from "@material-ui/core";
// import CancelIcon from "@material-ui/icons/Cancel";
// import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { openSnackbar } from "lib/service-redux-alerts";
import { bindActionCreators } from "redux";

const useStyles = makeStyles({
  inputClass: {
    position: "relative",
  },
  adornedEndCss: {
    padding: "0",
  },
  lengthInfo: {
    position: "absolute",
    bottom: "6px",
    right: "8px",
    opacity: "0.5",
    fontSize: "11px",
  },
});

const CommonMobileTextArea = ({
  // classes,
  autoComplete,
  autoFocus,
  color,
  defaultValue,
  disabled,
  inputComponent,
  error,
  FormHelperTextProps,
  fullWidth,
  helperText,
  id,
  InputLabelProps,
  inputProps,
  InputProps,
  inputRef,
  label,
  margin,
  multiline,
  name,
  onChange,
  placeholder,
  required,
  rows,
  rowsMax,
  select,
  SelectProps,
  size,
  type,
  value,
  variant,
  style,
  maxLength,
  desc,
  errorDesc,
  openSnackbar,
}) => {
  const textRef = useRef();
  const [textLength, setTextLength] = useState(0);

  const onInputChange = (event) => {
    //길이제한
    if (maxLength && event.currentTarget.value.length > maxLength) {
      event.currentTarget.value = event.currentTarget.value.substring(
        0,
        maxLength
      );
      event.currentTarget.blur();
      openSnackbar({
        message: `최대 ${maxLength}자까지 입력 가능합니다.`,
      });
    }

    setTextLength(
      event.currentTarget.value ? event.currentTarget.value.length : 0
    );

    onChange && onChange(event);
  };

  useEffect(() => {
    if (defaultValue) {
      setTextLength(defaultValue.length);
    }
  }, []);

  const classes = useStyles();

  return (
    <>
      <Box className={classes.inputClass}>
        <OutlinedInput
          onChange={onInputChange}
          fullWidth={fullWidth ? fullWidth : true}
          placeholder={placeholder && placeholder}
          size={size && size}
          variant={variant && variant}
          classes={{ adornedEnd: classes.adornedEndCss }}
          id={id && id}
          color={color && color}
          inputRef={inputRef ? inputRef : textRef}
          rows={rows ? rows : 7}
          multiline={multiline ? multiline : true}
          inputProps={inputProps && inputProps}
          defaultValue={defaultValue && defaultValue}
          value={value && value}
          style={style && style}
        />
        <span className={classes.lengthInfo}>
          {textLength}/{maxLength}
        </span>
      </Box>
    </>
  );
};
CommonMobileTextArea.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  // classes:	PropTypes.object,
  color: PropTypes.oneOf(["primary", "secondary"]),
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  FormHelperTextProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  label: PropTypes.node,
  margin: PropTypes.oneOf(["dense", "none"]),
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowsMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  select: PropTypes.bool,
  SelectProps: PropTypes.object,
  size: PropTypes.oneOf(["medium", "small"]),
  type: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  style: PropTypes.object,
  maxLength: PropTypes.number.isRequired,
  filterRegExp: PropTypes.object,
  desc: PropTypes.string,
  errorDesc: PropTypes.string,
};

export default connect(null, (dispatch) =>
  bindActionCreators({ openSnackbar }, dispatch)
)(CommonMobileTextArea);
