// 4.1 약관
const Terms = {
    "서비스 이용약관 동의": "PARTNER_SERVICE_USAGE",
    "개인정보 수집 및 이용 동의": "PARTNER_PRIVACY_POLICY",
    "제3자 정보 제공 동의(업주)": "PARTNER_THIRD_PERSON_INFO_OWNER",
    "제3자 정보 제공 동의(직원)": "PARTNER_THIRD_PERSON_INFO_MANAGER",
};
export function getServiceCodeTerms(str) {
    const key = str.trim();
    if (!!Terms[key]) 
        return Terms[key];
    return null;
}

// 4.2 공통코드
const CommonCode = {
    "사용자 설정 코드 - 알림 수신": "US001",
    "사용자 설정 코드 - 예약 알림 수신": "US002",
    "사용자 설정 코드 - 혜택 알림 수신": "US003",
    "사용자 설정 코드 - 방해금지시간": "US004"
};
export function getServiceCodeCommonCode(str) {
    const key = str.trim();
    if (!!CommonCode[key]) 
        return CommonCode[key];
    return null;
}

// 4.3 사용자 유형
const UserType = {
    "일반 가입 사용자": "U",
    "SNS 로그인 사용자(카카오)": "SK",
    "SNS 로그인 사용자(네이버)": "SN",
    "사장님 Web - 사장님": "PARTNER_OWNER",
    "사장님 Web - 직원": "PARTNER_EMPLOYEE",
    "사장님 Web - master": "WEBMASTER",
    "관리자": "A",
    "비회원 사용자(Guest)": "G"
};
export function getServiceCodeUserType(str) {
    const key = str.trim();
    if (!!UserType[key]) 
        return UserType[key];
    return null;
}

export function getUserTypeAtServiceCode(code) {
    switch (code) {
        case "O":
            return "사장님";
        case "OE":
            return "직원";
        case "WEBMASTER":
            return "매니저";
        case "A":
            return "관리자";
    }
    return code;
}

// 4.4 사용자 등급
const UserRating = {
    "VVIP": "VV",
    "VIP": "V",
    "일반": "N"
};
export function getServiceCodeUserRating(str) {
    const key = str.trim();
    if (!!UserRating[key]) 
        return UserRating[key];
    return null;
}

// 4.5 사용자 상태
const UserStatus = {
    "정상": "STABLE",
    "정상 - 사용자정보변경": "UNAUTHORIZED",
    "탈퇴": "DELETED",
    "차단 - 관리자 차단": "BLOCKED",
    "차단 - 비밀번호 입력횟수 초과": "PASSWORD_ATTEMPT_EXCEED",
    // 20200528 박태성C - 박용성 선임과 논의하여 문구 변경 "대기 - 필수정보 미입력(첫 로그인)": "R2",
    "대기 - 필수정보 미입력": "R1",
    "대기 - 승인대기": "R2",
    "첫 로그인": "R3"
};
export function getServiceCodeUserStatus(str) {
    const key = str.trim();
    if (!!UserStatus[key]) 
        return UserStatus[key];
    return null;
}

// 4.6. 검색조건유형
const SearchConditionType = {
    "모텔검색 - 내 주변": "ST001",
    "모텔검색 - 내 주변 + 땡처리": "ST002",
    "모텔검색 - 최저가": "ST003",
    "모텔검색 - 평점순": "ST004",
    "모텔검색 - 방문했던 모텔": "ST005",
    "모텔검색 - 즉시할인": "ST006",
    "모텔검색 - 쿠폰혜택": "ST007",
    "모텔검색 - 지역": "ST008",
    "지역검색 - 지역별": "ST101",
    "지역검색 - 역주변": "ST102",
    "예약 가능여부 검색 - 일별 검색": "ST201",
    "예약 가능여부 검색 - 월별 검색": "ST202",
    "고객후기 - 기간": "ST301",
    "고객후기 - 검색어 - 내용": "ST302",
    "고객후기 - 검색어 - 작성자": "ST303",
    "고객후기 - 포토/베스트 후기": "ST304",
    "고객후기 - 미답변": "ST305",
    "고객후기 - 닉네임 검색": "ST306",
    "고객후기 - 객실명 검색": "ST307",
    "고객후기 - 리뷰키 검색": "ST308",
    "이미지 검색 - 전체 검색": "ST401",
    "이미지 검색 - 분류별 검색": "ST402",
    "이미지 검색 - 룸 유니크 키": "ST403",
    "이미지 검색 - 이미지 키": "ST404",
    "아이디 검색 - 이메일": "ST501",
    "아이디 검색 - 휴대폰 번호": "ST502",
    "예약 검색 - 최근 12시간 이내 예약 검색": "ST601",
    "예약내역 - 기간": "ST602",
    "예약내역 - 키워드": "ST603",
    "예약내역 - 예약상태": "ST604",
    "예약내역 - 입실상태": "ST605",
    "예약내역 - 객실별": "ST606",
    "예약내역 - 대실/숙박코드": "ST607",
    // "예약 캘린더 - 대실/숙박 검색": "ST701", 삭제 - 쿨스테이_사장님Web_API_연동규격서_v0.4_20200522.docx
    "정산내역 - 기간": "ST801",
    "정산내역 - 키워드": "ST802",
    "정산내역 - 예약상태": "ST803",
    "정산내역 - 정산 상태": "ST803",
    "정산내역 - 정산일": "ST804",
    "정산내역 - 객실별": "ST805",
    "정산내역 - 대실/숙박 코드": "ST806",
    "정산내역 - 정산상태": "ST807", // 추가 - 쿨스테이_사장님Web_API_연동규격서_v0.6_20200707.docx
    "요금표 - 기준요금, 특정일요금": "ST905",
    "요금표 - 특정일 기간 검색": "ST906",
    "요금표 - 특정일 유니크 키 검색": "ST907",
    "쿠폰, 게시판 조회 - 진행중": "ST1001",
    "쿠폰, 게시판 조회 - 진행완료": "ST1002",
    "쿠폰 - 기간검색": "ST1003",
    "쿠폰 - 게시판 구분": "ST1004",
    "쿠폰 - 분류": "ST1005",
    "쿠폰 - 유형": "ST1006",
    "쿠폰 - 사용자 이름": "ST1007",
    "매물거래 - 매물 검색조건": "ST1101",
    "매물거래 - 기타옵션": "ST1102",
    "매물거래 - 가격대(매매)": "ST1103",
    "매물거래 - 가격대(임대/보증금)": "ST1104",
    "매물거래 - 가격대(임대/월세)": "ST1105",
    "매물거래 - 게시물 유니크 키": "ST1106", // 추가 - 쿨스테이_사장님Web_API_연동규격서_v0.6_20200707.docx
    "게시판 - 전체": "ST1201",
    "게시판 - 분류 값": "ST1202",
    "게시판 - 키워드": "ST1203",
    "게시판 - 게시물 유니크 키": "ST1204",
    "게시판 - 게시물 상태": "ST1205",
    "게시판 - 게시중인 게시물 조회": "ST1206", // 추가 - 20201028 공지팝업 관련 되어 조건 추가 
    "사용자 마일리지 목록 - 최근 방문 기간": "ST1301",
    "사용자 마일리지 목록 - 방문 수": "ST1302", // 수정 - 쿨스테이_사장님Web_API_연동규격서_v0.6_20200707.docx
    "사용자 마일리지 목록 - 이용자 명": "ST1303",
    "부가세 내역/다운로드 - 기간": "ST1401",
    "통계 조회 일": "ST1501",
    "룸 판매 설정 목록 월별 조회": "ST1601",
    "룸 판매 설정 목록 특정일 검색": "ST1602",
    "룸 판매 설정 목록 기간 검색": "ST1603",
    "모텔 쿠폰 발행(STEP3) - 기간": "ST1701",
    "모텔 쿠폰 발행(STEP3) - 사용 여부": "ST1702",
    "모텔 쿠폰 발행(STEP3) - 사용자 이름": "ST1703",
    "공휴일 목록 조회 - 월 검색": "ST1801",
    "룸 목록 조회 - 모텔 상품 타입": "ST1901"
};
export function getServiceCodeSearchConditionType(str) {
    const key = str.trim();
    if (!!SearchConditionType[key]) 
        return SearchConditionType[key];
    return null;
}

// 4.7 게시물 상태
const PostStatus = {
    /*
    // 삭제 - 쿨스테이_사장님Web_API_연동규격서_v0.5_20200608.docx
    "대기": "S1",
    "진행중": "S1",
    "진행완료": "S2"
    */
    "게시물 등록": "BI001",
    "게시물 차단": "BI002",
    "답변 대기": "BI003",
    "답변 완료": "BI004",
    "일반이벤트": "BI005",
    "쿠폰이벤트": "BI006"
};
export function getServiceCodePostStatus(str) {
    const key = str.trim();
    if (!!PostStatus[key]) 
        return PostStatus[key];
    return null;
}

// 4.8 지역 유형
const RegionType = {
    "지역": "R001",
    "지하철역": "R002"
};
export function getServiceCodeRegionType(str) {
    const key = str.trim();
    if (!!RegionType[key]) 
        return RegionType[key];
    return null;
}

// 4.9. 모텔상품유형
const MotelProductType = {
    "룸": "010100",
    "룸 - 대실": "010101",
    "룸 - 숙박": "010102"
};

// 4.9. 숙박 대실 여부 코드
export const MotelProductTypeConstant = {
    '010100': '',
    '010101': "DAYUSE",
    "010102": "STANDALONE",
};
export function getServiceCodeMotelProductType(str) {
    const key = str.trim();
    if (!!MotelProductType[key]) 
        return MotelProductType[key];
    return null;
}

// 4.10. 모텔편의시설
const MotelConvenience = {
    "커플PC": "C01", // 20210225 김주호, 이태호 팀장 요청으로 커플룸/PC -> 커플PC 변경
    "넷플릭스": "C02",
    "조식": "C03",
    "노래방": "C04",
    "스파/월풀": "C05",
    "스타일러": "C06",
    "트윈베드": "C07",
    "찜질방": "C08",
    "테마욕조": "C09",
    "파티룸": "C10",
    "욕실TV": "C11",
    "헬스장": "C12",
    "주차장": "C13",
    "미니바": "C14",
    "테라스": "C15",
    "거울룸": "C16",
    "발렛파킹": "C17",
    "무인텔": "C18",
    "수영장": "C19",
    "세탁서비스": "C20",
    "물침대": "C21",
    "안마의자": "C22",
    "배틀그라운드": "C23",
    "셀프세탁실": "C24",
    "금연방": "C25"
};
export function getServiceCodeMotelConvenience(str) {
    const key = str.trim();
    if (!!MotelConvenience[key]) 
        return MotelConvenience[key];
    return null;
}

// 4.11. 모텔상품상태
const MotelStatus = {
    "판매대기": "MS001",
    "판매중 (판매)": "MS002",
    "판매완료 (마감)": "MS003"
};
export function getServiceCodeMotelStatus(str) {
    const key = str.trim();
    if (!!MotelStatus[key]) 
        return MotelStatus[key];
    return null;
}

// 4.12. 할인유형
const DiscountType = {
    "쿠폰": "C",
    "마일리지": "M"
};
export function getServiceCodeDiscountType(str) {
    const key = str.trim();
    if (!!DiscountType[key]) 
        return DiscountType[key];
    return null;
}

// 4.13. 예약상태
const ReservationStatus = {
    "예약 완료 - 입실전": "BS001",
    "예약 완료 - 입실완료": "BS002",
    "예약 취소": "BS003",
    /*
    1. 삭제 및 수정 - 쿨스테이_사장님Web_API_연동규격서_v0.4_20200522.docx

    "결제 완료": "BS004",
    "예약 처리중": "BS005",
    "사용 완료(입실)": "BS006",
  */
    "예약 처리중": "BS004",
    "판매 취소 (업주 noshow 설정)": "BS005"
};
export function getServiceCodeReservationStatus(str) {
    const key = str.trim();
    if (!!ReservationStatus[key]) 
        return ReservationStatus[key];
    return null;
}

// 4.14. 결제수단
const PaymentMethod = {
    "삼성페이": "SAMSUNG",
    "신용카드": "CARD",
    "실시간 계좌이체": "TRANS",
    "가상계좌": "VBANK",
    "핸드폰 소액결제": "PHONE",
    "현장결제": "SITE"
};
export function getServiceCodePaymentMethod(str) {
    const key = str.trim();
    if (!!PaymentMethod[key]) 
        return PaymentMethod[key];
    return null;
}

// 4.15. 결제PG
const PaymentPG = {
    "카카오페이(kakao)": "KAKAO",
    "이니시스-웹 표준결제(html5_inicis)": "INICIS",
    "나이스페이(nice)": "NICE",
    "제이티넷(jtnet)": "JTNET",
    "유플러스(uplus)": "UPLUS",
    "다날(danal)": "DANAL",
    "페이코(payco)": "PAYCO",
    "시럽페이(syrup)": "SYRUP",
    "페이팔(paypal)": "PAYPAL"
};
export function getServiceCodePaymentPG(str) {
    const key = str.trim();
    if (!!PaymentPG[key]) 
        return PaymentPG[key];
    return null;
}

// 4.16. 결제상태
const PaymentStatus = {
    "대기": "P001",
    "성공": "P002",
    "취소": "P003",
    "실패1 - 검증 실패": "PS100",
    "실패2 - 한도 초과": "PS101"
};
export function getServiceCodePaymentStatus(str) {
    const key = str.trim();
    if (!!PaymentStatus[key]) 
        return PaymentStatus[key];
    return null;
}

// 4.17. 은행코드(계좌이체환불시사용)
const BankCode = {
    "KB국민은행": "KOOKMIN",
    "SC제일은행": "SC",
    "경남은행": "KYONGNAMBANK",
    "광주은행": "GWANGJUBANK",
    "기업은행": "IBK",
    "농협": "NONGHYEOP",
    "대구은행": "DAEGUBANK",
    "부산은행": "BUSANBANK",
    "산업은행": "KDBBANK",
    "새마을금고": "SAEMAUL",
    "수협": "SUHYEOP",
    "신한은행": "SHINHAN",
    "신협": "SHINHYEOP",
    "외환은행": "HANA",
    "우리은행": "WOORI",
    "우체국": "POST",
    "전북은행": "JEONBUKBANK",
    "축협": "LOCALNONGHYEOP",
    "카카오뱅크": "KAKAOBANK",
    "케이뱅크": "KBANK",
    "하나은행(서울은행)": "SEOUL",
    "한국씨티은행(한미은행)": "CITI",
    "제주은행": "JEJUBANK",
    "토스": "TOSSBANK"
};
export function getServiceCodeBankCode(str) {
    const key = str.trim();
    if (!!BankCode[key]) 
        return BankCode[key];
    return null;
}

export function getBankNameAtServiceCode(code) {
    switch (code) {
        case "KOOKMIN":
            return "KB국민은행";
        case "SC":
            return "SC제일은행";
        case "KYONGNAMBANK":
            return "경남은행";
        case "GWANGJUBANK":
            return "광주은행";
        case "IBK":
            return "기업은행";
        case "NONGHYEOP":
            return "농협";
        case "DAEGUBANK":
            return "대구은행";
        case "BUSANBANK":
            return "부산은행";
        case "KDBBANK":
            return "산업은행";
        case "SAEMAUL":
            return "새마을금고";
        case "SUHYEOP":
            return "수협";
        case "SHINHAN":
            return "신한은행";
        case "SHINHYEOP":
            return "신협";
        case "HANA":
            return "외환은행";
        case "WOORI":
            return "우리은행";
        case "POST":
            return "우체국";
        case "JEONBUKBANK":
            return "전북은행";
        case "LOCALNONGHYEOP":
            return "축협";
        case "KAKAOBANK":
            return "카카오뱅크";
        case "KBANK":
            return "케이뱅크";
        case "SEOUL":
            return "하나은행(서울은행)";
        case "CITI":
            return "한국씨티은행(한미은행)";
        case "JEJUBANK":
            return "제주은행";
        case "TOSSBANK":
            return "토스";
    }
    return code;
}

// 4.18. 알람유형
const AlarmType = {
    "예약 완료": "AR101",
    "예약 취소 완료": "AR102",
    "후기 답변 등록": "AR201",
    "후기 작성 요청": "AR202",
    "이벤트 알림 - 쿠폰 이벤트": "AR301"
};
export function getServiceCodeAlarmType(str) {
    const key = str.trim();
    if (!!AlarmType[key]) 
        return AlarmType[key];
    return null;
}

// 4.19. 알람부가정보
const AlarmAdditionalInfo = {
    "모텔명": "ARE01",
    "객실명": "ARE02",
    "예약번호": "ARE03"
};
export function getServiceCodeAlarmAdditionalInfo(str) {
    const key = str.trim();
    if (!!AlarmAdditionalInfo[key]) 
        return AlarmAdditionalInfo[key];
    return null;
}

// 4.20. 게시판코드
const BoardCode = {
    "공지사항": "NOTICE_O",
    "이벤트": "EVENT_O",
    "FAQ": "FAQ_O",
    "1:1 문의하기": "ASK_O",
    "매물거래 게시판": "SALE_BOARD",
    "매물거래 게시판 공지사항": "NOTICE_SALE",
    /*
    // 삭제 - 쿨스테이_사장님Web_API_연동규격서_v0.5_20200608.docx
    "모텔 공지사항":"MOTEL_NOTICE",
    */
    "모텔 이벤트": "EVENT_M"
};
export function getServiceCodeBoardCode(str) {
    const key = str.trim();
    if (!!BoardCode[key]) 
        return BoardCode[key];
    return null;
}

// 4.21. 요일코드
const DayCode = {
    "일": "0",
    "월": "1",
    "화": "2",
    "수": "3",
    "목": "4",
    "금": "5",
    "토": "6",
    "공휴일": "7",
    "공휴일 전날": "8",
    /*
    1. 삭제 - 쿨스테이_사장님Web_API_연동규격서_v0.6_20200617.docx
    "요일 선택 없음": "9"
    */
};
export function getServiceCodeDayCode(str) {
    const key = str.trim();
    if (!!DayCode[key]) 
        return DayCode[key];
    return null;
}

// 4.22. 정산상태
const AdjustmentStatus = {
    "정산대기": "READY",
    "정산완료": "FINISH"
};
export function getServiceCodeAdjustmentStatus(str) {
    const key = str.trim();
    if (!!AdjustmentStatus[key]) 
        return AdjustmentStatus[key];
    return null;
}

// 4.23. 정렬조건
const SortCondition = {
    "이름순": "NAME",
    "최신순": "LATEST",
    "입실일": "ENTER",
    "결제일": "PAYMENT",
    "가격순": "PRICE",
    "댓글순": "COMMENT",
    "조회수": "VIEW",
    "정산일": "ADJUSTMENT",
    "예약일": "BOOK",
    "방문일": "VISIT",
    "방문수": "VISIT_COUNT", // 추가 - 쿨스테이_사장님Web_API_연동규격서_v0.6_20200707.docx
    "마일리지순(누적)": "POINT_ACC",
    "마일리지순(잔여)": "POINT_REM",
    "가입일": "REG_DATE"
};
export function getServiceCodeSortCondition(str) {
    const key = str.trim();
    if (!!SortCondition[key]) 
        return SortCondition[key];
    return null;
}

// 4.24. 이미지카테고리
const ImageCategory = {
    "대표 이미지": "IC003",
    "내부": "IC004",
    "외관": "IC005",
    "비품": "IC006",
    "편의시설": "IC007"
};
export function getServiceCodeImageCategory(str) {
    const key = str.trim();
    if (!!ImageCategory[key]) 
        return ImageCategory[key];
    return null;
}

// 4.25. 숙소 유형
const MotelType = {
    /*
    1. 삭제 - 쿨스테이_사장님Web_API_연동규격서_v0.4_20200522.docx

    "모텔": "MT001"
    */
};
export function getServiceCodeMotelType(str) {
    const key = str.trim();
    if (!!MotelType[key]) 
        return MotelType[key];
    return null;
}

// 4.26. 제휴 상품 코드
const AffiliateProductCode = {
    /*
     1. 삭제 - 쿨스테이_사장님Web_API_연동규격서_v0.4_20200522.docx

     "예약 50건 / 월정액 10만원": "BC50",
     "예약 100건 / 월정액 20만원": "BC100",
     "예약 150건 / 월정액 30만원": "BC150",
     "예약 무한건 / 월정액 50만원": "BCIFT"
    */
};
export function getServiceCodeAffiliateProductCode(str) {
    const key = str.trim();
    if (!!AffiliateProductCode[key]) 
        return AffiliateProductCode[key];
    return null;
}

// 4.27. 쿠폰 카테고리
const CouponCategory = {
    /*
     1. 삭제 - 쿨스테이_사장님Web_API_연동규격서_v0.4_20200522.docx

     "기본할인 쿠폰 - 본사/업소 발행 쿠폰": "STEP1",
    "내 쿠폰함 - 제휴사 발행 쿠폰": "STEP2"
    */

    /*
    1. 삭제 및 추가 - 쿨스테이_사장님Web_API_연동규격서_v0.5_20200608.docx

    "업주 발행 (템플릿)": "STEPO201",
    "업주 발행 (오늘쿠폰)": "STEPO202",
    "본사 발행 (스토어 대상 없음)": "STEPP301",
    "본사 발행 (특정 스토어)": "STEPP302",
    "업주 발행 (특정 사용자)": "STEPO303"
    */

    "업소 발행 선착순 쿠폰": "STEP2",
    "업소 발행 쿠폰": "STEP3_O",
    "본사 발행 쿠폰": "STEP3_S"
};
export function getServiceCodeCouponCategory(str) {
    const key = str.trim();
    if (!!CouponCategory[key]) 
        return CouponCategory[key];
    return null;
}

// 4.28. 쿠폰 할인 유형
const CouponType = {
    "정률": "RATE",
    "정액": "AMOUNT"
};
export function getServiceCodeCouponType(str) {
    const key = str.trim();
    if (!!CouponType[key]) 
        return CouponType[key];
    return null;
}

// 4.29. 쿠폰 상태
const CouponStatus = {
    "사용가능": "C001",
    "발급가능": "C002",
    "사용불가 - 선착순 마감": "C003",
    "사용불가 - 기한만료": "C004"
};
export function getServiceCodeCouponStatus(str) {
    const key = str.trim();
    if (!!CouponStatus[key]) 
        return CouponStatus[key];
    return null;
}

// 4.30. 쿠폰 제약조건
const CouponConstraint = {
    "최소사용가격": "CC001",
    "상품 조건": "CC002",
    "최소예약 일수": "CC003",
    /*
    // 삭제 - 쿨스테이_사장님Web_API_연동규격서_v0.5_20200608.docx
    "예약 가능(요일)": "CC004"
    */
    "적용 업소": "CC004",
    "사용 요일(평일, 주말, 겸용)": "CC005"
};
export function getServiceCodeCouponConstraint(str) {
    const key = str.trim();
    if (!!CouponConstraint[key]) 
        return CouponConstraint[key];
    return null;
}

// 4.31. 리뷰상태
const ReviewStatus = {
    "등록": "R",
    "삭제요청": "B"
};
export function getServiceCodeReviewStatus(str) {
    const key = str.trim();
    if (!!ReviewStatus[key]) 
        return ReviewStatus[key];
    return null;
}

// 4.32. 쿠폰 템플릿 유형
const CouponTempleateType = {
    /*
    1. 변경 - 쿨스테이_사장님Web_API_연동규격서_v0.6_20200617.docx
    */
    "숙박 강화형 1": "CT001",
    "숙박 강화형 2": "CT002",
    "대실 깅화형 1": "CT003",
    "대실 강화형 2": "CT004",
    "평일 강화형 1": "CT005",
    "평일 강화형 2": "CT006",
    "만능 강화형 1": "CT007",
    "만능 강화형 2": "CT008",
    /*
    1. 삭제 및 추가 - 쿨스테이_사장님Web_API_연동규격서_v0.6_20200617.docx
    "직접 발행": "CT100"
    */
    "사장님 자유 발행": "CT100",
    "오늘 추가 발급": "CT200"
};
export function getServiceCodeCouponTempleateType(str) {
    const key = str.trim();
    if (!!CouponTempleateType[key]) 
        return CouponTempleateType[key];
    return null;
}

// 4.33. 룸 설정 부가 코드
const RoomAdditionalCode = {
    /*
    1. 삭제 및 변경 - 쿨스테이_사장님Web_API_연동규격서_v0.6_20200617.docx
    "룸 개수": "R001",
    */
    "룸 최소 인원": "MIN",
    "룸 최대 인원": "MAX"
};
export function getServiceCodeRoomAdditionalCode(str) {
    const key = str.trim();
    if (!!RoomAdditionalCode[key]) 
        return RoomAdditionalCode[key];
    return null;
}

// 4.33. 게시판 옵션 코드
const BoardOptionCode = {
    "1:1 문의 게시판": "OPT_001",
    "이벤트 게시판 - 이벤트 노출 순위": "OPT_001",
    "이벤트 게시판 - 이벤트 적용 요일 목록": "OPT_002",
    "이벤트 게시판 - 이벤트 분류 값": "OPT_003"
};
export function getServiceCodeBoardOptionCode(str) {
    const key = str.trim();
    if (!!BoardOptionCode[key]) 
        return BoardOptionCode[key];
    return null;
}

const userType = {
    GENERAL : 'GENERAL',
    SNS_KAKAO : 'SNS_KAKAO',
    SNS_NAVER : 'SNS_NAVER',
    SNS_APPLE : 'SNS_APPLE',
    OWNER : 'OWNER',
    CMS_ADMIN : 'CMS_ADMIN',
    ETC : 'ETC'


};
export function getUserType(str) {
    const key = str.trim();
    if (!!userType[key])
        return userType[key];
    return null;
}

const imageType = {
    IMAGE:'IMAGE',
    VIDEO:'VIDEO',
    ETC:'ETC'
};
export function getImageType(str) {
    const key = str.trim();
    if (!!imageType[key])
        return imageType[key];
    return null;
}

const uploadType = {
    TRANSFER : 'TRANSFER',
    EXTERNAL : 'EXTERNAL'
};
export function getUploadType(str) {
    const key = str.trim();
    if (!!uploadType[key])
        return uploadType[key];
    return null;
}



const affiliateImageType = {
    TRANS_AGREE: 'TRANS_AGREE',
    BUSI_CERT: 'BUSI_CERT',
    TRANS_COPY: 'TRANS_COPY',
    ADJUSTMENT_COPY: 'ADJUSTMENT_COPY'

};
export function getAffiliateImageType(str) {
    const key = str.trim();
    if (!!affiliateImageType[key])
        return affiliateImageType[key];
    return null;
}

const boardImageCategory = {
    "THUMBNAIL": "THUMBNAIL",
    "DEFAULT": "DEFAULT",
    "BANNER": "BANNER",
    "BANNER_DETAIL": "BANNER_DETAIL",
    "BADGE": "BADGE",
    "BOTTOM": "BOTTOM",
    "WORD" : "WORD",
    "EXCEL" : "EXCEL",
    "PDF" : "PDF"
};
export function getBoardImageCategory(str) {
    const key = str.trim();
    if (!!boardImageCategory[key])
        return boardImageCategory[key];
    return null;
}

// 기간 검색 유형 (2024.7월 개선-예약내역, 정산관리 추가)
export const periodType = {
    TODAY: 'TODAY',
    DURATION: 'DURATION'
}
// 쿠폰 유형 (2024.7월 개선-예약내역, 정산관리 추가)
export const couponTypeBySettle = {
    STORE: 'STORE',
    GIFT: 'GIFT',
    COOLSTAY: 'COOLSTAY',
}
// 쿠폰 유형 (2024.7월 개선-예약내역, 정산관리 추가)
export const couponSubTypeBySettle = {
    STORE_FIRST: 'STORE_FIRST',
    STORE_NEW_VISIT: 'STORE_NEW_VISIT',
    STORE_UNLIMITED: 'STORE_UNLIMITED',
    STORE_LOWEST: 'STORE_LOWEST',
    STORE_TODAY_ADDITIONAL: 'STORE_TODAY_ADDITIONAL',
    STORE_CMS: 'STORE_CMS',
    GIFT_MANUAL: 'GIFT_MANUAL',
    GIFT_AUTO: 'GIFT_AUTO',
    GIFT_AUTO_LIKE: 'GIFT_AUTO_LIKE',
    GIFT_DOWNLOAD: 'GIFT_DOWNLOAD',
    COOLSTAY_SALE_PESTA: 'COOLSTAY_SALE_PESTA',
    COOLSTAY_FIRST: 'COOLSTAY_FIRST',
    COOLSTAY_CS: 'COOLSTAY_CS',
    COOLSTAY_CMS: 'COOLSTAY_CMS',
}