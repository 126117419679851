import React from "react";
import { TableCell, TableHead, TableRow } from "@material-ui/core";

/** 테이블의 컬럼 컴포넌트입니다. */
function TableHeader({ columns }) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column, idx) => {
          return (
            <TableCell key={idx} width={column.width} align="center" style={column.style}>
              <strong>{column.title}</strong>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default React.memo(TableHeader);
