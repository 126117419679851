import React, { useState } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";

import * as serviceBrowser from "lib/service-browser";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";

// import Typography from "@material-ui/core/Typography";

import * as configApp from "config/config-app";

const TAG = "[templates-ui/material/alerts/AlertDialog.js]";

const styles = (theme) => ({
  root: {
    // border: "1px solid red",
    margin: 0,
    padding: theme.spacing(2),
  },
  modalTitle: {
    //border: "1px solid red",
    height: "50px",
    //background: "#404149",
    background: "#fff",
    "& h3": {
      //color: "#fff",
      margin: "0",
    },
  },
  modalTail: {
    //border: "1px solid red",
    textAlign: "center",
    justifyContent: "center",
    "& .MuiButton-containedPrimary": {
      background: "#424242",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const noop = function () {};

/**
 * Material-UI의 Dialog를 랩퍼 한 Functional Component 입니다.<p/>
 *
 * @component
 * @name AlertDialog
 * @property {sring} title - 알림 팝업의 제목
 * @property {sring} description - 알림 팝업의 내용
 * @property {sring} maxWidth - 알림 팝업의 넓이. 기본은 sm으로 정의 되어 있음. (xs, sm, md, lg, xl 지원)
 * @property {array} actions - DialogActions에 주입 되는 React.Component 집합체.
 * @see module:lib/service-redux-alerts/redux
 * @see Alerts
 * @author Taesung Park <pts@pineone.com>
 */
const AlertDialog = (props) => {
  const {
    classes,
    who,
    open,
    setHandleOnRequestClose,
    onOpened,
    onClosed,
    title,
    maxWidth,
    description,
    isHiddenClose,
    actions,
  } = props;

  const [isOpen, setIsOpen] = useState(open);
  const handleOnRequestClose = () => {
    serviceBrowser.enableScroll();
    serviceBrowser.enableBackNavigation();
    setIsOpen(false);
  };

  setHandleOnRequestClose && setHandleOnRequestClose(who, handleOnRequestClose);

  const isMobile = serviceBrowser.isMobile();
  console.log("isHiddenClose", isHiddenClose, !!isHiddenClose);
  return (
    <Dialog
      open={isOpen}
      onClose={handleOnRequestClose}
      disableBackdropClick={true}
      onEntered={onOpened}
      onExited={onClosed}
      maxWidth={maxWidth}
      fullWidth={true}
      className={classes.root}
    >
      {configApp.SERVICE_TYPE === "pc" && (
        <React.Fragment>
          <DialogTitle
            disableTypography
            className={classes.root}
            className={classes.modalTitle}
          >
            <h3>{title}</h3>
            {!isHiddenClose && (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleOnRequestClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
          <Box px={3}>
            <Divider />
          </Box>
        </React.Fragment>
      )}

      <DialogContent
        dividers
        style={{ display: "table", width: "100%", border: "none" }}
      >
        <div
          style={{
            whiteSpace: "pre-line",
            verticalAlign: "middle",
            display: "table-cell",
            height: "100px",
            textAlign: "center",
          }}
        >
          {isMobile ? (
            // 양태욱 추가 (폰트 사이즈 조정)
            <b style={{ fontSize: "13px" }}  dangerouslySetInnerHTML={{ __html: description }}></b>
          ) : (
            <h3 dangerouslySetInnerHTML={{ __html: description }}></h3>
          )}
          {/* {isMobile ? <h4>{description}</h4> : <h3>{description}</h3>} */}
        </div>
      </DialogContent>
      {actions && 0 < actions.length && (
        <DialogActions className={classes.modalTail}>
          {actions.map((action, index) => {
            let label = "";
            let callback = noop;
            let dataType = "";

            let { props } = action;
            if (!!props) {
              let { children, onClick } = props;
              label = children;
              if (!!onClick) callback = onClick;
              dataType = props["data-type"] || props.dataType || "";
            } else {
              if (action.hasOwnProperty("label") && !!action.label)
                label = action.label;
              if (action.hasOwnProperty("onClick") && !!action.onClick)
                callback = action.onClick;
              if (action.hasOwnProperty("dataType") && !!action.dataType)
                dataType = action.dataType;
            }
            props = null;

            if (!label) {
              return null;
            }

            return (
              <Box my={1} mx={2} key={index}>
                <Button
                  data-type={dataType}
                  onClick={callback}
                  color="primary"
                  type="button"
                  className={
                    "MuiButton-contained MuiButton-containedPrimary custom-modal-button"
                  }
                  style={isMobile ? { width: "80px" } : { width: "160px" }}
                >
                  {/* <strong>{label}</strong> */}
                  {/* 양태욱 변경 */}
                  <strong style={{ fontSize: "12px" }}>{label}</strong>
                </Button>
              </Box>
            );
          })}
        </DialogActions>
      )}
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  /**
   * Material-UI Dialog의 open 속성 입니다.<p/>
   *
   * AlertDialog에서 'useState' Hook 함수로 Material-UI Dialog의 종료를 자체 처리 합니다.
   */
  open: true,
  maxWidth: "xs",
  isHiddenClose: false,
  /**
   * Dialog 종료 핸들러를 설정하는 메소드 입니다.<p/>
   *
   * lib/service-redux-alerts/redux 모듈에서 주입 합니다.
   */

  setHandleOnRequestClose: function () {
    console.log(TAG, "setHandleOnRequestClose 주입 되지 않았습니다");
  },
  /**
   * Material-UI Dialog의 onEntered 콜백 메소드 실행 시 호출 되는 핸들러 입니다.<p/>
   *
   * <a href="https://material-ui.com/api/dialog/" target="_blank">참고) onEntered</a>
   *
   * lib/service-redux-alerts/redux 모듈에서 주입 합니다.
   */

  onOpened: function () {
    console.log(TAG, "onOpened가 주입 되지 않았습니다");
  },
  /**
   * Material-UI Dialog의 onExited 콜백 메소드 실행 시 호출 되는 핸들러 입니다.<p/>
   *
   * <a href="https://material-ui.com/api/dialog/" target="_blank">참고) onExited</a>
   *
   * lib/service-redux-alerts/redux 모듈에서 주입 합니다.
   */

  onClosed: function () {
    console.log(TAG, "onClosed가 주입 되지 않았습니다");
  },
};

// export default AlertDialog;
export default withTheme(withStyles(styles)(AlertDialog));
