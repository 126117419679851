import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  textStyle: {
    margin: "0px",
    fontWeight: "bold",
    color: "#008aff",
  },
});

/**
 * @param {Array} content 표시해줄 도움말 배열
 * @param {String} fontSize 폰트 사이즈 default 12px
 * @author TaeWook Yang <didxodnr0769@pineone.com>
 * @description 전체 파란색 도움말 컴포넌트입니다.
 * @see SettlePageLayout
 */
function HelperTextBlue(props) {
  const { content = [], fontSize = "12px", style } = props;
  const classes = useStyles();
  return (
    <div>
      {content.map((val, idx) => (
        <p
          key={idx}
          className={classes.textStyle}
          style={{
            fontSize: fontSize,
            ...style,
          }}
        >
          {val}
        </p>
      ))}
    </div>
  );
}

export { HelperTextBlue };
