import React from "react";
import { Switch, withRouter, Route } from "react-router-dom";

// 라우터 핸들러 - 앱 상태(ex: 로그인, 약관 미동의)에 따라 라우팅을 제어하는 Component
import HandlerRoute from "components/HandlerRoute";
import HandlerRouteLogin from "components/HandlerRouteLogin";

// 공용 Containers
import DefaultLayoutContainer from "system/client/DefaultLayoutContainer";

// 공용 css
import "system/client/App.css";
// PC 버젼용 css
import "./common-PC.css";
// PC 버젼용 라우터 정보
import {
  // PC 서비스 페이지
  routesOnPC,
  // 404 Not Found Page
  NotFoundPage,
  // 로그인 관련 페이지
  LoginPage,
  SignInSelectMotelPage,
  SignInNotFoundPage,
  // 회원 가입 관련 페이지
  SignUpSelectUserPage,
  RegisterPage,
  TermsPage,
  AlliancePage,
  // 아이디 및 비밀번호 변경/찾기
  FindIdAndPasswordPage,
} from "./routesOnPC";

const TAG = "[system/shared/pc/AppOnPC.js]";

/**
 * PC 환경 브라우저에 서비스 되는 화면 페이지들의 라우팅 내역 정의 된 Contaner Component 입니다<p/>
 *
 * config-overrides.js 파일에서 서비스 타입에 따라 앱 구동 및 빌드 시<br/>
 * "App"이라는 별칭 으로 물리적 파일을 연결 합니다.<p/>
 *
 * ```
 *  - 서비스 타입이 'pc'이면, src/system/shared/pc/AppOnPC.js가 'App' 별칭으로 연결
 *  - 서비스 타입이 'mobile'이면, src/system/shared/mobile/AppOnMobile.js가 'App' 별칭으로 연결
 * ```
 *
 * AppContainer는 다음과 같이 'App' 별칭으로 모듈을 import 하여 사용 합니다.<p/>
 *
 * ```
 * import App from "App"; // 서비스 타입(PC/모바일)에 따라 config-overrides.js에서 주입
 *
 * ... 중간 생략 ...
 *
 * return (
 *    <>
 *      <ThemeProvider theme={theme}>
 *        <App isLoggedIn={isLoggedIn} {...props} />
 *        <Alerts alerts={alerts} />
 *      </ThemeProvider>
 *    </>
 *   );
 *
 * ```
 *
 * @version 0.0.1
 * @author Taesung Park <pts@pineone.com>
 * @name AppOnPC
 * @class
 * @component
 * @see Root
 * @see AppContainer
 * @see DefaultLayoutContainer
 * @see AuthRoute
 * @see AppOnMobile
 * @see module:system/shared/pc/routesOnPC
 */
class AppOnPC extends React.Component {
  constructor(props) {
    super(props);
    this.loading = this.loading.bind(this);
    console.log(TAG, "\n", props);
  }

  loading() {
    return <div>Loading...</div>;
  }

  render() {
    console.log(TAG, "Called render()");
    const { props } = this;
    const { isLoggedIn } = props;

    return (
      <>
        <Switch>
          <HandlerRoute
            path="/login"
            name="로그인 페이지"
            isLoggedIn={isLoggedIn}
            render={(props) => <LoginPage {...props} />}
            exact={true}
          />
          <HandlerRouteLogin
            path="/login-motel"
            name="숙소 선택"
            isLoggedIn={isLoggedIn}
            render={(props) => <SignInSelectMotelPage {...props} />}
            exact={true}
          />
          <Route
            path="/login-404"
            name="로그인 계정 404"
            render={(props) => <SignInNotFoundPage {...props} />}
            exact={true}
          />
          <HandlerRoute
            path="/signup/terms"
            name="회원가입 약관"
            isLoggedIn={isLoggedIn}
            render={(props) => <TermsPage {...props} />}
          />
          <HandlerRoute
            path="/signup/register"
            name="회원 가입 페이지"
            isLoggedIn={isLoggedIn}
            checkAcceptTerms={true}
            render={(props) => <RegisterPage {...props} />}
          />
          <HandlerRoute
            path="/signup/alliance"
            name="제휴 신청 - 회원 가입 직후 제휴 신청"
            isLoggedIn={isLoggedIn}
            checkingTmpToken={true}
            render={(props) => <AlliancePage {...props} />}
            exact={true}
          />
          <HandlerRouteLogin
            path="/signup/alliance/add"
            name="제휴 신청 - 로그인 후 업소 추가하기"
            isLoggedIn={isLoggedIn}
            render={(props) => <AlliancePage {...props} />}
            exact={true}
          />
          <HandlerRouteLogin
            path="/signup/alliance/none"
            name="제휴 신청 - 로그인은 처리 되었으나, 필수 정보 누락"
            isLoggedIn={isLoggedIn}
            render={(props) => <AlliancePage {...props} />}
            exact={true}
          />
          <HandlerRoute
            path="/signup"
            name="회원가입 선택화면"
            isLoggedIn={isLoggedIn}
            render={(props) => <SignUpSelectUserPage {...props} />}
            exact={true}
          />
          <Route
            path="/not-found"
            name="페이지 없음"
            render={(props) => <NotFoundPage {...props} />}
            exact={true}
          />
          <HandlerRoute
            path="/findid"
            name="아이디 찾기"
            isLoggedIn={isLoggedIn}
            render={(props) => <FindIdAndPasswordPage {...props} />}
            exact={true}
          />
          <HandlerRoute
            path="/findpass"
            name="비밀번호 찾기"
            isLoggedIn={isLoggedIn}
            render={(props) => <FindIdAndPasswordPage {...props} />}
            exact={true}
          />
          <Route
            path="/"
            name="서비스 페이지"
            render={(props) => (
              <DefaultLayoutContainer
                isLoggedIn={isLoggedIn}
                routes={routesOnPC}
                {...props}
              />
            )}
          />
        </Switch>
      </>
    );
  }
}

export default withRouter(AppOnPC);
