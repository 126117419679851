import { differenceInDays, isBefore } from "date-fns";
import format from "date-fns/format";

/**
 * 월정액, 월정률 관련 한 기능 및 데이터를 관리합니다.
 */
const RATE_CODE = "RATE";
const AMOUNT_CODE = "AMOUNT";
const FREE_CODE = "FREE";
const FEE_SERVICE_TYPE = [
  {
    code: RATE_CODE,
    name: "정률 요금제",
    chargeName: "꿀스테이 수수료",
  },
  {
    code: AMOUNT_CODE,
    name: "정액 요금제",
    chargeName: "꿀스테이 이용료",
    monthlyBookName: "월 누적 판매수",
  },
  {
    code: FREE_CODE,
    name: "무료 사용기간 이용중",
    chargeName: "꿀스테이 이용료",
  },
];

/* 서비스 타입의 코드를 받아 해당 서비스 타입 객체 반환 */
const getServiceType = (code) => {
  return FEE_SERVICE_TYPE.find((service) => service.code === code);
};

/** 이용중인 서비스 이름 */
const getUseServiceName = (code) => {
  if (!code) return "";
  const service = getServiceType(code);
  return service.name;
};

/** 무료 사용 기간 계산 */
const getFreeUseTerm = (startDt, endDt, isMobile) => {
  if (!startDt || !endDt) return "";
  const startDate = new Date(startDt);
  const endDate = new Date(endDt);
  const formatStartDt = format(startDate, "yyyy.MM.dd");
  const formatEndDt = format(endDate, "yyyy.MM.dd");
  const today = new Date();
  endDate.setDate(endDate.getDate() + 1);
  endDate.setHours(0);
  endDate.setMinutes(0);
  endDate.setSeconds(0);

  const differenceDay = differenceInDays(endDate, today);

  const freeUserTerm = `${formatStartDt} ~ ${formatEndDt} ${
    isMobile ? "\n" : ""
  }`;

  const modDay = isBefore(today, endDate)
    ? `${differenceDay}일 남음`
    : `무료 기간 종료`;
  return `${freeUserTerm} (${modDay})`;
};

export {
  RATE_CODE,
  AMOUNT_CODE,
  FREE_CODE,
  FEE_SERVICE_TYPE,
  getUseServiceName,
  getServiceType,
  getFreeUseTerm,
};
