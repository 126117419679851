import { Select, FormControl, MenuItem } from "@material-ui/core";
import React from "react";

const CouponTemplateSelectForm = ({
  easyCouponIndex,
  handleSelectCouponType,
  USER_TYPE,
  applyCouponCode,
  couponTemplateList,
  style,
}) => {
  /** 사용자 권한에 따른 템플릿 표시 여부 */
  const getCouponDisplay = (code) => {
    return code === "Y" ? "block" : "none";
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      size="small"
      // className={classes.name}
    >
      <Select
        style={style}
        value={easyCouponIndex}
        onChange={handleSelectCouponType}
        disabled={USER_TYPE !== "WEBMASTER" && applyCouponCode === "CT024"}
      >
        {couponTemplateList.map((e, idx) => {
          const { visible_yn, coupon_name } = e;
          const couponDisplay = getCouponDisplay(visible_yn);
          return (
            <MenuItem
              value={idx}
              key={`select_${idx}`}
              style={{
                display: couponDisplay,
                ...style,
              }}
            >
              {coupon_name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CouponTemplateSelectForm;
