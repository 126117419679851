import React from "react";
import PropTypes from "prop-types";

import MessageSnackbars from "./alerts/MessageSnackbars";
import AlertDialog from "./alerts/AlertDialog";
import NoticeDialog from "./alerts/NoticeDialog";

/**
 * Alerts (알림 팝업, 토스트 메세지)의 Container Component 입니다.<p/>
 * 
 * Alerts (알림 팝업, 토스트 메세지)의 갱신 처리 및 props 주입을 담당 합니다.<p/>
 * 
 * Alerts에서는 다음의 props를 컴포넌트에 주입 합니다.<p/>
 * 
 * ```
 * - AlertDialog : title, description, maxWidth, actions
 * - MessageSnackbars : message, autoHideDuration, anchorOrigin
 * ```
 * @component
 * @name Alerts
 * @property {object} alerts - dialogs, snackbars 속성이 정의 된 객체
 * @see module:lib/service-redux-alerts/redux
 * @see AlertDialog
 * @see MessageSnackbars 
 * @see NoticeDialog
 * @see AppContainer 
 * @author Taesung Park <pts@pineone.com>
 * @example
  
  // Step 1) Alerts 컴포넌트를 import 합니다
  import Alerts from "lib/service-redux-alerts/Alerts";

  // Step 2) 상태를 구독(subscibe) 할 수 있도록 mapStateToProps에 'alerts'을 선언 합니다
  export default connect(
    state => ({ status: state.authentication.status, alerts: state.alerts }),
    dispatch => bindActionCreators({ getStatusRequest }, dispatch)
  )(AppContainer);  

  // Step 3) 구독(subscribe) 된 'alerts'을 Alerts 컴포넌트에 주입 합니다
  render() {
    console.log(TAG, "Called render()");
    const { props } = this;
    const { status, alerts } = props;
    const { valid, isLoggedIn } = status;
    if (valid === undefined) return null;

    return (
      <>
        <ThemeProvider theme={theme}>
          <App isLoggedIn={isLoggedIn} {...props} />    
          <Alerts alerts={alerts} />
        </ThemeProvider>
      </>
    );
  }

 */
const cleanAndOrder = (obj) => {
  return Object.keys(obj)
    .map((key) => ({ open: true, key, ...obj[key] }))
    .sort((a, b) => a.timestamp - b.timestamp)
    .map((_p) => {
      const p = _p;
      delete p.timestamp;
      return p;
    });
};

const Alerts = (props) => {
  const { alerts } = props;
  return (
    <>
      {cleanAndOrder(alerts.snackbars).map((props) => (
        <MessageSnackbars {...props} />
      ))}
      {cleanAndOrder(alerts.dialogs).map((props) => (
        <AlertDialog {...props} />
      ))}
      {cleanAndOrder(alerts.notices).map((props) => (
        <NoticeDialog {...props} />
      ))}
    </>
  );
};

Alerts.propTypes = {
  alerts: PropTypes.shape({
    notices: PropTypes.object.isRequired,
    dialogs: PropTypes.object.isRequired,
    snackbars: PropTypes.object.isRequired,
  }).isRequired,
};

export default Alerts;
