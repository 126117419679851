// action
import * as serviceAPI from "../../../lib/service-api";

export const GET_RSA_REQUEST = 'GET_RSA_REQUEST';
export const GET_RSA_SUCCESS = 'GET_RSA_SUCCESS';
export const GET_RSA_FAILURE = 'GET_RSA_FAILURE';
export const SET_RSA_INITIAL = 'SET_RSA_INITIAL';

export const getRsaRequest = () => ({
    type: GET_RSA_REQUEST,
});
export const getRsaSuccess = (rsa) => ({
    type: GET_RSA_SUCCESS,
    ...rsa,
});

export const getRsaFailure = (error) => ({
    type: GET_RSA_FAILURE, error
});

export const setRsaInitial = () =>({
    type: SET_RSA_INITIAL
})
export const postRSA = (params) => {
    return (dispatch) => {
        dispatch(getRsaRequest());
        return serviceAPI.postRSA(params)
            .then(response => {
                return response?.data?.result;
            })
            .then(data => dispatch(getRsaSuccess(data)))
            .catch(error => dispatch(getRsaFailure(error)));
    };
};
