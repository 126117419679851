/**
 * 본 모듈은 문자열이 숫자 형식 인지 검사하는 모듈 입니다
 *
 * @module utils/isNumberFormat
 */

/**
 * 문자열을 검사하여 숫자 형식 여부를 반환 합니다
 *
 * @param {string} str - 문자열
 * @return {boolean} 숫자 형식 여부
 */
export default function isNumberFormat(str) {
  if (/^[0-9]+$/.test("" + str) === false) {
    return false;
  }
  return true;
}
