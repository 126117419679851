import React from "react";
import { Paper } from "@material-ui/core";

function CouponSettingHelp() {
  return (
    <Paper
      elevation={0}
      variant="outlined"
      style={{ margin: "1em 0", padding: "10px 20px" }}
    >
      <b>도움말</b>
      <h5 style={{ margin: "0", color: "#383838" }}>
        <br />
        1. 사용가능 기준금액 : 판매가를 기준으로 쿠폰이 적용되며, 설정한 판매가
        이상인 경우 쿠폰 적용이 가능합니다.
        <br />
        2. 사용가능 기준금액 : 제한 없음으로 설정하는 경우, 모든 객실에서
        선착순쿠폰이 적용됩니다.
        <br />
        3. 본사이벤트 : 앱에서 본사이벤트 할인이 합산된 쿠폰으로 노출됩니다.
        <br />
        4. 신규회원 전용 : 앱에서 예약내역이 없는 신규회원에게만 쿠폰이
        노출됩니다.
        <br />
        5. 선착순 쿠폰 템플릿을 변경하는 경우, 설정이 초기화될 수 있으니
        유의해주세요.
      </h5>
    </Paper>
  );
  // }
}

export default React.memo(CouponSettingHelp);
