import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, Dialog, Button } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";

import * as configApp from "config/config-app";
import * as serviceBrowser from "lib/service-browser";

// const DEFAULT_POPUP_TITLE = "[파트너센터 안내]";
const DEFAULT_POPUP_TITLE = "파트너센터 안내";

/**
 * 모달 블럭 Component 입니다.<p/>
 *
 * @author Drst3in <drst3in9196@pineone.com>
 * @name ModalWrap
 * @class
 * @component
 */

const styles = (theme) => ({
  root: {
    // border: "1px solid red",
    margin: 0,
    padding: theme.spacing(2),
  },
  modalTitle: {
    //border: "1px solid red",
    height: "50px",
    //background: "#404149",
    background: "#fff",
    "& h3": {
      // color: "#fff",
      margin: "0",
    },
  },
  modalTitleMobile: {
    //border: "1px solid red",
    //background: "#404149",
    background: "#fff",
    "& h3": {
      // color: "#fff",
      margin: "0",
    },
    "& h5": {
      // color: "#fff",
      margin: "4px 0 0 0",
    },
  },
  modalTail: {
    //border: "1px solid red",
    textAlign: "center",
    justifyContent: "center",
    "& .MuiButton-containedPrimary": {
      background: "#424242",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

class ModalWrap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PaperProps: {
        style: {
          minWidth: "95vw",
          minHeight: "95vh",
          width: "95vw",
          height: "95vh",
        },
      }
    };

    this.isDisableBack = false;
    this.clickedTime = 0;
  }

  onEnteredDialog = () => {
    if (this.isDisableBack === false) {
      this.isDisableBack = true;
      serviceBrowser.disableScroll();
      serviceBrowser.disableBackNavigation();
    }
  };

  onExitedDialog = () => {
    if (this.isDisableBack) {
      this.isDisableBack = false;
      serviceBrowser.enableScroll();
      serviceBrowser.enableBackNavigation();
    }
  };

  componentWillUnmount() {
    this.onExitedDialog();
  }

  componentDidMount() {
    if (this.props.PaperProps) {
      const _PaperProps = {
        style: {
          minWidth: this.props.PaperProps.style.width,
          minHeight: this.props.PaperProps.style.height,
          width: this.props.PaperProps.style.width,
          height: this.props.PaperProps.style.height,
        },
      };
      this.setState({ PaperProps: _PaperProps });
    }
  }

  render() {
    const {
      classes,
      open,
      // theme,
      onClose,
      onButtons,
      title,
      titleHide = false,
      subTitle,
      link,
      isNotUsePaperProps = false,
      maxWidth = "sm",
      buttonStyles,
    } = this.props;

    // let btnWidth = "160px";
    // if (configApp.SERVICE_TYPE == "mobile") {
    //   btnWidth = "100px";
    // }

    // 20201112 박태성C - 실제 브라우저 모바일 환경 여부 체크
    const isMobile = serviceBrowser.isMobile();

    // 20201013 박태성C - Material-Ui Dialog의 onExited가 호출 되지 않는 현상이 있어 추가 함
    !!open ? this.onEnteredDialog() : this.onExitedDialog();

    return (
      <Dialog
        disableBackdropClick={true}
        onClose={onClose}
        open={open}
        className={classes.root}
        fullWidth={true}
        scroll={"paper"}
        onEntered={this.onEnteredDialog}
        onExited={this.onExitedDialog}
        PaperProps={!!isNotUsePaperProps ? {} : this.state.PaperProps}
        maxWidth={maxWidth}
      >
        {configApp.SERVICE_TYPE === "pc" && !titleHide && (
          <React.Fragment>
            <DialogTitle
              disableTypography
              // className={classes.root}
              className={classes.modalTitle}
            >
              <h3>
                {title || DEFAULT_POPUP_TITLE}{" "}
                {subTitle && <span stlye={{ opacity: "0.5" }}>{subTitle}</span>}
              </h3>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Box px={3}>
              <Divider />
            </Box>
          </React.Fragment>
        )}

        {configApp.SERVICE_TYPE === "mobile" && !titleHide && (
          <React.Fragment>
            <DialogTitle
              disableTypography
              className={`${classes.root} ${classes.modalTitleMobile}`}
            >
              <Box align="center">
                {/* <h3>{title || DEFAULT_POPUP_TITLE}</h3> */}
                {/* 양태욱 수정 */}
                <strong style={{ fontSize: "16px" }}>
                  {title || DEFAULT_POPUP_TITLE}
                </strong>
                {subTitle && <h5 style={{ opacity: "0.5" }}>{subTitle}</h5>}
              </Box>
            </DialogTitle>
            <Box>
              <Divider />
            </Box>
          </React.Fragment>
        )}
        <DialogContent>
          {this.props.children}
        </DialogContent>
        {onButtons && (
          <DialogActions className={classes.modalTail}>
            {onButtons.map((button, index) => {
              return (
                <Box my={1} mx={2} key={index}>
                  <Button
                    onClick={(evt) => {
                      let nowTime = Date.now();
                      if (nowTime - this.clickedTime < 600) {
                        return;
                      }
                      this.clickedTime = nowTime;
                      button.callback(evt);
                    }}
                    color="primary"
                    type="button"
                    className={
                      "MuiButton-contained MuiButton-containedPrimary custom-modal-button"
                    }
                    // style={{ width: btnWidth }}
                    // 20201112 박태성C - [COOL-7] iPhone SE5에서 버튼이 짤리는 현상이 있어 공통 팝업과 동일하게 수치 조정함
                    style={
                      buttonStyles
                        ? buttonStyles
                        : isMobile
                        ? { width: "100px" }
                        : { minWidth: "130px" }
                    }
                    disabled={button.disabled}
                    focusRipple={false}
                  >
                    {/* {link && (
                      <Link to={link} style={{ textDecoration: "none" }}>
                        <strong>{button.title}</strong>
                      </Link>
                    )}
                    {!link && <strong>{button.title}</strong>} */}
                    {/* 2020.11.27 양태욱 버튼에 스타일 추가 (폰트 사이즈) */}
                    {link && (
                      <Link to={link} style={{ textDecoration: "none" }}>
                        <strong
                          style={
                            isMobile
                              ? { fontSize: "12px" }
                              : { fontSize: "14px" }
                          }
                        >
                          {button.title}
                        </strong>
                      </Link>
                    )}
                    {!link && (
                      <strong
                        style={
                          isMobile ? { fontSize: "12px" } : { fontSize: "14px" }
                        }
                      >
                        {button.title}
                      </strong>
                    )}
                  </Button>
                </Box>
              );
            })}
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

//export default ModalWrap;
const styledModalWrap = withTheme(withStyles(styles)(ModalWrap));

export { styledModalWrap as ModalWrap };
