import React from "react";

import SignInContainer from "../SignInContainer";
import LoginPageLayout from "./LoginPageLayout";

/**
 * 로그인 페이지의 Container Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name LoginPage
 * @class
 * @component
 */
class LoginPage extends React.Component {
  render() {
    return (
      <>
        <SignInContainer component={LoginPageLayout} />
      </>
    );
  }
}

export default LoginPage;
