import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

import { commaFormater, commaRemover } from "utils";

const styles = (theme) => ({});

class PriceTextField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { inputValue: commaFormater(props.number) };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.number !== prevProps.number) {
      this.setState({ inputValue: commaFormater(this.props.number) });
    }
  };

  handleBlur = (e) => {
    const value = e.target.value;
    const zeroValue = value === "" ? 0 : value;
    const commaValue = commaFormater(zeroValue);

    this.setState({
      inputValue: commaValue,
    });

    this.props.onChange(e, Number(zeroValue));
  };

  handleFocus = (e) => {
    const value = e.target.value;
    const nonZeroValue = value === "0" ? "" : value;
    const nonCommaValue = commaRemover(nonZeroValue);

    this.setState({
      inputValue: nonCommaValue,
    });
  };

  handleChange = (e) => {
    const value = e.target.value;

    if (!/^[0-9]*$/.test(value)) return;

    const result = value.replace(/(^0+)/g, "");

    this.setState({
      inputValue: result,
    });
  };

  render() {
    const { classes, theme, ...other } = this.props;

    return (
      <TextField
        {...other}
        value={this.state.inputValue}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        variant="outlined"
        size="small"
      />
    );
  }
}

// 초기 props 설정
PriceTextField.defaultProps = {};

const styledInputPrice = withStyles(styles)(PriceTextField);

export { styledInputPrice as PriceTextField };
