/**
 * 레이아웃 (메뉴와 Routing) 관련 헬퍼 모듈 입니다<p/>
 *
 * @module lib/helper-layouts
 */

import * as configApp from "config/config-app";

const isServiceTypeMobile = configApp.SERVICE_TYPE === "mobile";

// const TAG = "[lib/helper-layouts.js]";
// console.log(TAG, "Create");

let __publicURL = [];
let __hideHeaderURL = [];
let __menuURL = [];
let __treeBreadcrumb = {};
let __models = {};

/*
 * path를 변환하여 pathKey를 반환 하는 메소드 입니다.<p/>
 *
 * /dashboard      -> /dashboard
 * /dashboard/:id  -> /dashboard/
 *
 * @param {String} path
 * @return {String} pathKey
 */
function getPathKey(path) {
  let pathKey = path;
  if (!!pathKey && -1 < pathKey.lastIndexOf("/:")) {
    pathKey = pathKey.substr(0, pathKey.lastIndexOf("/:") + 1);
  }
  return pathKey;
}

/*
 * url를 변환하여 pathKey를 반환 하는 메소드 입니다.<p/>
 *
 * /dashboard      -> /dashboard
 * /dashboard/10   -> /dashboard/
 *
 * @param {String} url
 * @return {String} pathKey
 */
export function convertPathKey(url) {
  if (__models.hasOwnProperty(url)) {
    // /dashboard 체크
    return url;
  }
  if (!!url) {
    // /dashboard/10, /dashboard/path/10를 체크하기 위해 /dashboard/, /dashboard/path/ 로 변환
    const tmp = url.substr(0, url.lastIndexOf("/") + 1);
    if (__models.hasOwnProperty(tmp)) {
      return tmp;
    }
  }
  return null;
}

/*
 * url를 기준으로 모델을 반환하는 메소드 입니다.<p/>
 *
 * @param {String} url
 * @return {Object|Null} model
 */
function getModel(url) {
  if (__models.hasOwnProperty(url)) {
    // /dashboard 체크
    return __models[url];
  }
  if (!!url) {
    // /dashboard/10, /dashboard/path/10를 체크하기 위해 /dashboard/, /dashboard/path/ 로 변환
    const tmp = url.substr(0, url.lastIndexOf("/") + 1);
    if (__models.hasOwnProperty(tmp)) {
      return __models[tmp];
    }
  }
  return null;
}

/*
 * Public URL를 등록하는 메소드 입니다
 *
 * @param {String} url Public URL
 */
function addPublicURL(url) {
  !!url && __publicURL.push(url);
}

/*
 * Header를 숨기는 URL를 등록하는 메소드 입니다
 *
 * @param {String} url Hide Header URL
 */
function addHideHeaderURL(url) {
  !!url && __hideHeaderURL.push(url);
}

/*
 * Menu URL를 등록하는 메소드 입니다
 *
 * @param {String} url Menu URL
 */
function addMenuURL(url) {
  !!url && __menuURL.push(url);
}

/*
 * 모델을 등록하는 메소드 입니다
 *
 * @param {String} pathKey 키
 * @param {String} model 모델(Menu, Route)
 */
function addModel(pathKey, model) {
  __models[pathKey] = model;
}

/**
 * 인자로 전달 된 URL의 Public 여부를 반환하는 메소드 입니다
 *
 * @param {String} url URL
 * @returns {Boolean} URL의 Public 여부
 */
export function isPublicURL(url) {
  // /dashboard 체크
  let ret = -1 < __publicURL.indexOf(url);
  if (!ret) {
    // /dashboard/10, /dashboard/path/10를 체크하기 위해 /dashboard/, /dashboard/path/ 로 변환
    let tmp = url.substr(0, url.lastIndexOf("/") + 1);
    if (tmp !== "/") ret = -1 < __publicURL.indexOf(tmp);
  }
  return ret;
}

/**
 * 인자로 전달 된 URL의 헤더 숨김 여부를 반환하는 메소드 입니다
 *
 * @param {String} url URL
 * @returns {Boolean} 헤더 숨김 여부
 */
export function isHideHeaderByURL(url) {
  // /dashboard 체크
  let ret = -1 < __hideHeaderURL.indexOf(url);
  if (!ret) {
    // /dashboard/10, /dashboard/path/10를 체크하기 위해 /dashboard/, /dashboard/path/ 로 변환
    let tmp = url.substr(0, url.lastIndexOf("/") + 1);
    if (tmp !== "/") ret = -1 < __hideHeaderURL.indexOf(tmp);
  }
  // console.log(url, ret);
  return ret;
}

/**
 * 인자로 전달 된 URL의 Menu 등록 여부를 반환하는 메소드 입니다
 *
 * @param {String} url URL
 * @returns {Boolean} URL의 Menu 등록 여부
 */
export function isMenuURL(url) {
  // /dashboard 체크
  let ret = -1 < __menuURL.indexOf(url);
  if (!ret && !!url) {
    // /dashboard/10, /dashboard/path/10를 체크하기 위해 /dashboard/, /dashboard/path/ 로 변환
    let tmp = url.substr(0, url.lastIndexOf("/") + 1);
    if (tmp !== "/") ret = -1 < __menuURL.indexOf(tmp);
  }
  return ret;
}

/**
 * Route의 path를 반환하는 메소드 입니다<p/>
 *
 * /user/1이 전달 되면, Route 컴포넌트에 설정 된 /user/:id가 반환 합니다<br/>
 *
 * @param {String} url URL
 * @returns {String} 매칭 되는 Route의 path
 */
export function findPathByPathname(url) {
  let path = null;
  let model = getModel(url);
  if (!!model && model.hasOwnProperty("route")) {
    path = model.route.path || null;
  }
  model = null;
  return path;
}

/**
 * Menu의 parentId를 반환하는 메소드 입니다<p/>
 *
 * @param {String} url URL
 * @returns {String|Number} parentId
 */
export function findParentMenuIdByPath(url) {
  let parentId = null;
  let model = getModel(url);
  if (!!model && model.hasOwnProperty("menu")) {
    parentId = model.menu.parentId || null;
  }
  model = null;
  return parentId;
}

/**
 * Menu의 id를 반환하는 메소드 입니다<p/>
 *
 * @param {String} url URL
 * @returns {String|Number} id
 */
export function findMenuIdByPath(url) {
  let id = null;
  let model = getModel(url);
  if (!!model && model.hasOwnProperty("menu")) {
    id = model.menu.id || null;
  }
  model = null;
  return id;
}

/**
 * Menu의 id로 매칭 되는 path를 반환하는 메소드 입니다<p/>
 *
 * @param {String} id URL
 * @returns {String|Number} parentId
 */
export function findPathByMenuId(id) {
  const key = "k" + id;
  if (__treeBreadcrumb.hasOwnProperty(key)) {
    return __treeBreadcrumb[key].to || null;
  }
  return null;
}

/**
 * 인자로 전달 된 Menu 메타를 분석하여<br/>
 * 이진 트리 형태의 '브레드크럼 구조체'를 생성 합니다
 *
 * @param {Object} menu Menu 메타 정보
 */
export function addTreeBreadcrumbWithMenu(menu) {
  const { parentId, id, to, label } = menu;
  const isDefineParentId = !!parentId;
  const isDefineId = !!id;
  const keyParent = isDefineParentId ? "k" + parentId : null;
  const key = isDefineId ? "k" + id : null;

  if (isDefineParentId && !__treeBreadcrumb.hasOwnProperty(keyParent)) {
    __treeBreadcrumb[keyParent] = {};
  }
  if (isDefineId && !!key) {
    __treeBreadcrumb[key] = { to, label };
    if (isDefineParentId) __treeBreadcrumb[key].keyParent = keyParent;
    if (!!to) __treeBreadcrumb[getPathKey(to)] = key;
  }
  if (isDefineParentId && isDefineId) {
    __treeBreadcrumb[keyParent][key] = __treeBreadcrumb[key];
  }
}

/*
 * Menu의 식별값으로 이진 트리 형태의 '브레드크럼 구조체'를<br/>
 * 재귀적인 방법으로 탐색하여 '브레드크럼 네비게이션' 정보를 반환하는 메소드 입니다
 *
 * @param {String|Number} id Menu의 식별값
 * @param {String} navigation 초기 브레드크럼 네비게이션 정보
 * @returns {String} 탐색 된 '브레드크럼 네비게이션' 정보
 */
function findBreadcrumById(id, navigation) {
  if (__treeBreadcrumb.hasOwnProperty(id)) {
    if (!!__treeBreadcrumb[id].keyParent && !!__treeBreadcrumb[id].label) {
      return findBreadcrumById(
        __treeBreadcrumb[id].keyParent,
        !!navigation
          ? __treeBreadcrumb[id].label + " > " + navigation
          : __treeBreadcrumb[id].label
      );
    }
    return !!navigation
      ? __treeBreadcrumb[id].label + " > " + navigation
      : __treeBreadcrumb[id].label;
  }
  return "";
}

/**
 * pathKey로 '브레드크럼 네비게이션' 정보를<br/>
 * 탐색하여 반환하는 메소드 입니다
 *
 * @param {String} pathKey path를 변환 한 식별키
 * @returns {String} 탐색 된 '브레드크럼 네비게이션' 정보
 */
export function findBreadcrumByPathKey(pathKey) {
  if (pathKey && __treeBreadcrumb.hasOwnProperty(pathKey)) {
    return findBreadcrumById(__treeBreadcrumb[pathKey], "");
  }
  return null;
}

/**
 * Routing 설정 정보에서 Route 관련 메타 만 추출하여<br/>
 * 반환하는 메소드 입니다
 *
 * @param {Array} role 현재 사용자 계정의 Role
 * @param {Array} routes Routing 설정 정보
 * @returns {Array} Route 메타 정보
 */
export function clearAll() {
  __publicURL.splice(0, __publicURL.length);
  __hideHeaderURL.splice(0, __hideHeaderURL.length);
  __menuURL.splice(0, __menuURL.length);
  __treeBreadcrumb = {};
  __models = {};
}

/**
 * Routing 설정 정보에서 Menu 관련 메타 만 추출하여<br/>
 * 반환하는 메소드 입니다
 *
 * @param {Array} role 현재 사용자 계정의 Role
 * @param {Array} routes Routing 설정 정보
 * @returns {Array} Menu 메타 정보
 */
export function getMenusAtRoutes(role, routes) {
  return routes
    .filter((model) => {
      if (!model.menu) {
        return false;
      }
      if (model.hasOwnProperty("permission")) {
        if (0 > model.permission.indexOf(role)) {
          return false;
        }
      }

      if (isServiceTypeMobile) {
        if (model.menu.parentId) {
          const pathKey = getPathKey(model.menu.to);
          !!pathKey && addMenuURL(pathKey); // 빠른 처리를 위해 별도 등록
          addTreeBreadcrumbWithMenu(model.menu);
          return false;
        }
      }

      if (model.menu.isFilterMenu) {
        const pathKey = getPathKey(model.menu.to);
        !!pathKey && addMenuURL(pathKey); // 빠른 처리를 위해 별도 등록
        addTreeBreadcrumbWithMenu(model.menu);
        return false;
      }
      return true;
    })
    .map((model) => {
      const pathKey = getPathKey(model.menu.to);
      !!pathKey && addMenuURL(pathKey); // 빠른 처리를 위해 별도 등록
      addTreeBreadcrumbWithMenu(model.menu);
      return model.menu;
    });
}

/**
 * Routing 설정 정보에서 Route 관련 메타 만 추출하여<br/>
 * 반환하는 메소드 입니다
 *
 * @param {Array} role 현재 사용자 계정의 Role
 * @param {Array} routes Routing 설정 정보
 * @returns {Array} Route 메타 정보
 */
export function getFilterRoute(role, routes) {
  return routes.filter((model) => {
    if (!model.route) {
      return false;
    }
    if (model.hasOwnProperty("permission")) {
      if (0 > model.permission.indexOf(role)) {
        return false;
      }
    }

    const pathKey = getPathKey(model.route.path);
    if (!!pathKey) {
      !!model.route.public && addPublicURL(pathKey); // 빠른 처리를 위해 별도 등록
      addModel(pathKey, model);
    }

    // 헤더 감추기
    !!model.route.isHideHeader && addHideHeaderURL(pathKey);

    return true;
  });
}
