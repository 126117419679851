import React, { useEffect, useState } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Paper, Table, TableBody, TableContainer } from "@material-ui/core";
import { ModalWrap } from "../../../components";
import {
  POPUP_ACTION_OK,
  openAlertPopupWithPromise,
  openConfirmPopupWithPromise,
  openHttpRejectionPopupWithPromise,
} from "lib/helper-popup";
import {
  COUPON_ADD_ITEM_TYPE_LIST,
  COUPON_COLUMNS,
  ALERT_MAX_COUNT_COUPON,
  getUnitTypeFromCode,
} from "lib/helper-coupon";
import TodayCouponSettingHelp from "../components/TodayCouponSettingHelp";
import { commaFormater } from "utils";
import TableHeader from "../components/TableHeader";
import TableContent from "../components/TableContent";
import { objectDeepClone } from "utils/ObjectHelper";

const styles = (theme) => ({
  table: {
    marginTop: "10px",
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    "& th": {
      background: "#f8f8f9",
      fontWeight: "bold",
    },
    "& td": {
      border: "1px solid #ddd",
    },
  },
  couponSelectWrap: {
    width: "30%",
    lineHeight: "60px",
    background: "#f8f8f9",
    borderRight: "1px solid #ddd",
  },
});

export const TodayCouponSettings = ({
  classes,
  modalOpen,
  couponModalClose,
  onRequestAjax,
  onRefresh,
}) => {
  const [coupons, setCoupons] = useState([]);
  const [active, setActive] = useState(false);

  // state 초기화
  const initState = () => {
    setCoupons(() => {
      return COUPON_ADD_ITEM_TYPE_LIST.map((item) => {
        return {
          discount_amount: item.price.init_val,
          total_amount: item.count.init_val,
          item_type: item.item_type,
          day_type: "ALL",
          discount_type: item.discount_type,
          discount_min_amount: null,
          discount_support_amount: 0,
          new_member_yn: "N",
        };
      });
    });
  };

  useEffect(() => {
    initState();
  }, []);

  useEffect(() => {
    if (coupons.reduce((prev, curr) => prev + curr.total_amount, 0) > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [coupons]);

  /** 오늘 쿠폰 추가 발행API 요청 */
  const requestPublish = () => {
    const param = {
      coupon_template: "CT200",
      coupon: coupons,
    };
    // API호출
    onRequestAjax("createCoupons", param)
      .then((res) => {
        return onRefresh();
      })
      // .then((res) => {
      //   this.setState({
      //     ...this.state,
      //     ...initCouponInfo(),
      //   });
      // })
      .catch((error) => {
        if (
          !!error &&
          !!error.response &&
          !!error.response.data &&
          !!error.response.data.code
        ) {
          switch (error.response.data.code) {
            case "40015009": // 쿠폰 등록 불가 - 최대발행수 초과
              openAlertPopupWithPromise(null, ALERT_MAX_COUNT_COUPON);
              return;
            default:
              break;
          }
        }
        openHttpRejectionPopupWithPromise(error);
      });
  };
  /** 발행하기 버튼 클릭 이벤트 */
  const handleClickPublishButton = (evt) => {
    evt.preventDefault();
    let msg = "";

    COUPON_ADD_ITEM_TYPE_LIST.forEach((item, i) => {
      const { unitType } = getUnitTypeFromCode(coupons[i].discount_type);
      const couponPrice = commaFormater(coupons[i].discount_amount);
      const discountMsg = `${couponPrice}${unitType} X `;
      const addMsg = coupons[i].total_amount === 0 ? "" : discountMsg;
      msg += `${item.name} :  ${addMsg}${coupons[i].total_amount}장` + "\n";
    });

    msg = msg + "\n설정한 값으로 적용하시겠습니까?";

    openConfirmPopupWithPromise(null, msg).then((actionType) => {
      if (actionType !== POPUP_ACTION_OK) {
        return;
      } else {
        handlemodalClose();
      }
      requestPublish();
    });
  };
  const handleSubmit = (e) => {
    handleClickPublishButton(e);
  };
  const handlemodalClose = () => {
    initState();
    couponModalClose();
  };

  const setCoupon = (coupon, name) => {
    const copiedCoupon = objectDeepClone(coupons);
    copiedCoupon[name] = coupon;
    setCoupons(copiedCoupon);
  };

  return (
    <ModalWrap
      title={"사용조건 쿠폰 발행"}
      onClose={handlemodalClose}
      onButtons={[
        {
          title: "발행하기",
          callback: handleSubmit,
          disabled: !active,
        },
        {
          title: "취소",
          callback: handlemodalClose,
        },
      ]}
      open={modalOpen}
      PaperProps={{
        style: {
          width: "1100px",
          minHeight: "600px",
        },
      }}
    >
      <TableContainer component={Paper}>
        <Table
          // className={classes.table}
          style={{
            tableLayout: "fixed",
          }}
        >
          <TableHeader columns={COUPON_COLUMNS} />
          <TableBody>
            {coupons.map((coupon, idx) => {
              return (
                <TableContent
                  key={`content_${idx}`}
                  idx={idx}
                  coupon={coupon}
                  setCoupon={setCoupon}
                  type={"TODAY_COUPON"}
                  isDetail
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TodayCouponSettingHelp />
    </ModalWrap>
  );
};

// 초기 props 설정
TodayCouponSettings.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default withTheme(withStyles(styles)(TodayCouponSettings));
