import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "pages/theme";

import { openHttpRejectionPopupWithPromise } from "lib/helper-popup";
import { getStatusRequest } from "system/redux-ducks/authentication";

// Containers
import Alerts from "lib/service-redux-alerts/Alerts";

// Components
import { Loading } from "components";
import ChunkLoadErrorBoundary from "components/ChunkLoadErrorBoundary";

// 서비스 타입(PC/모바일)에 따라 config-overrides.js에서 주입
import App from "App";

import * as serviceStorage from "lib/service-storage";

const TAG = "[system/shared/AppContainer.js]";

/**
 * <h4>AppContainer에서 주요 처리 항목은</h4>
 * Redux를 기반으로 한 상태 변화(로그인 여부, 공통 팝업 실행, 로딩 실행)를 감지하여,<br/>
 * 관련 된 하위 컴포넌트에 전파 하는 역활을 담당 합니다.<p/>
 *
 * ```
 * - 로그인 상태(로그인/로그아웃) 변화를 감지하여 하위 컴포넌트에 전파
 * - 공통 팝업 실행 시 Alerts 컴포넌트에 해당 Alert 전달
 * - Material-UI ThemeProvider에 theme 정보 전달
 * ```
 *
 * 그리고, PC/모바일에서 공통으로 처리 될 기능을 담당 합니다.<p/>
 *
 * ```
 * - 알림톡을 통한 진입 여부 체크
 * - 브라우저 재갱신(ex : 키보드 F5 입력 시 ) 후 사용자 유효 여부 체크
 * - PC/Mobile 환경에 따른 컴포넌트(AppOnPC, AppOnMobile)의 로딩 및 랜더링 처리
 * - 네트워크 단절에 의한 chunk 파일 로딩 에러 처리
 * ```
 *
 * @author Taesung Park <pts@pineone.com>
 * @name AppContainer
 * @class
 * @component
 * @see Root
 * @see DefaultLayoutContainer
 * @see AuthRoute
 * @see module:system/redux-ducks/authentication
 * @see module:lib/service-redux-alerts/Alerts
 */
class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    console.log(TAG, "\n", props);
    serviceStorage.findLandingPageFromLocation();
  }

  componentDidMount() {
    this.props
      .getStatusRequest()
      .then(() => {
        serviceStorage.clearLandingPage();
      })
      .catch(openHttpRejectionPopupWithPromise);
  }
  render() {
    console.log(TAG, "Called render()");
    const { props } = this;
    const { status, alerts, isOpenLoading } = props;
    const { valid, isLoggedIn } = status;
    if (valid === undefined) return null;

    /* 
    -----------------------------------------------------------------------------------------------
    예외처리 
    -----------------------------------------------------------------------------------------------
    1. 작성자 : 박태성 책임
    2. 작성일 : 2020년 8월 21일
    3. 이슈 내용
      4.1 네트워크 장애 처리

        4.1.1. 이슈 설명
          네트워크 단절 상태에서 다른 화면으로 이동 시 `빈 화면` 출력 현상

        4.1.2. 외부 요인

          4.1.2.1. 신규 버전 배포

            - 브라우저에 웹리소스(ex: js, css) 캐시 되어 있을 경우 
              배포 된 파일이 정상 반영 되지 않을 수 있어, 빌드 시 변경된 소스 파일은 다른 이름으로 생성 함.
            - 브라우저에서 진입 한 상태에서 배포 후 서버를 재기동 하면, 
              브라우저에 캐싱 된 `asset-manifest.json`과 서버에 적재 된 리소스가 불일치하여
              화면 이동 시 다음 화면의 웹리소스(ex: js, css)를 가져오지 못하여 `빈 화면` 출력 됨.

          4.1.2.2. 기기의 네트워크 상태 불안

            - 휴대폰에서 서비스 이용 시 일시적으로 네트워크 상태가 불안 할 때
            - PC에서 서비스 이용 시 Wifi 상태가 불안하거나, 공유기 상태가 불안할 때 

        4.1.3. 예외처리 내용
          - 해당 현상이 `네트워크 단절` 혹은 `신규 버전 배포`에 의해 발생하였는지 판단 할 수 없으므로,
            범용적인 문구를 사용자에게 제공하고 [재연결] 버튼을 클릭하도록 유도 하여
            숙박앱 서비스를 복구 시키는 방향으로 처리 함.
          - React에서 제공하는 Error Boundary(에러 경계선) 기능을 기반으로
            화면 이동 시 내려 받는 파일(Chunk Files)의 로딩 상태를 감지하여
            에러 발생 시 화면 출력 처리 
    */

    return (
      <>
        <ThemeProvider theme={theme}>
          <ChunkLoadErrorBoundary>
            <App isLoggedIn={isLoggedIn} {...props} />
          </ChunkLoadErrorBoundary>
          {/* <App isLoggedIn={isLoggedIn} {...props} /> */}
          <Alerts alerts={alerts} />
          <Loading open={isOpenLoading} />
        </ThemeProvider>
      </>
    );
  }
}

export default connect(
  (state) => ({
    status: state.authentication.status,
    alerts: state.alerts,
    isOpenLoading: state.loading.status.open,
  }),
  (dispatch) => bindActionCreators({ getStatusRequest }, dispatch)
)(AppContainer);
