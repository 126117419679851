import styled from "styled-components";
import {Table } from "@material-ui/core";


export const StyledTable = styled(Table)(() => ({
    width: '100%',
    marginTop: '10px',
    border: '1px solid #ddd',
    borderCollapse: 'collapse',
    '& th': {
        background: '#f8f8f9',
        fontWeight: 'bold'
    },
    '& td' : {
        border: '1px solid #ddd'
    }
}));

export const StyledTableBody = styled('tbody')(() => ({
    // 테이블 셀 테두리 없애기
    '& td': {
        border: 'none !important',
    }


}));


