import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
// import IconButton from "@material-ui/core/IconButton";
// import Container from "@material-ui/core/Container";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// import Typography from "@material-ui/core/Typography";
// import Box from "@material-ui/core/Box";

import Header from "./Header";
// import Copyright from "./Copyright";

import * as helperLayouts from "lib/helper-layouts";
import { history } from "lib/history";

// import { FooterPC } from "components";
import { Footer } from "components";

// const $ = window.$; //jquery 선언

const drawerWidth = 256;

// 참고 : https://jongbeom-dev.tistory.com/32

/*
* 2020.11.18 수정 양태욱 [COOL-6]
 1. 브라우저 창을 위아래 작게 했을시 스크롤 바 표시 문제( 하단의 화살표가 표시되지 않았음 )
     => 좌측 Drawer( Navigation ) 을 Header 높이만큼 4.6em marginTop을 줬었기 때문에
          Drawer의 Height를 계산하여 부여해줌 ( 100vh - 4.6em )
 */
const styles = (theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    background: "#000",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    // position: "relative",
    position: "fixed", // 양태욱 수정
    marginTop: "4.6em",
    whiteSpace: "nowrap",
    width: drawerWidth,
    height: `calc(100vh - 4.6em)`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    // overflowX: "hidden",
    overflow: "hidden", // 20201104 박태성C : COOL-6 [개선요청][업주PC]브라우저 창을 위아래 작게 했을시 스크롤 바 표시 문제
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  appBarSpacer: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
    color: "#fff",
    textAlign: "center",
    display: "block",
    fontSize: "24px",
    fontWeight: "bold",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  main: {
    flex: "auto",
    padding: theme.spacing(12, 2, 4),
    display: "flex"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  footer: {},
});

function TemplateLayout(props) {
  const { classes, menus, isLoggedIn } = props;
  const [open, setOpen] = React.useState(true);
  const [mouseHandle, setMouseHandle] = React.useState("leave");

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  /* 화면사이즈가 1500 이하면 네이게이션 hide, 이상이면 show */
  const resizeHook = () => {
    // console.log("window innerWidth : ", window.innerWidth);
    if (window.innerWidth < 1500) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  React.useEffect(() => {
    if (mouseHandle === "over") {
      window.removeEventListener("resize", resizeHook);
      handleDrawerOpen();
    } else {
      window.addEventListener("resize", resizeHook);
      resizeHook();
    }

    return () => {
      window.removeEventListener("resize", resizeHook);
    };
  });
  /* 화면사이즈가 1500 이하면 네이게이션 hide, 이상이면 show */

  // console.log("TemplateLayout.js props : ", props);

  const handleMouseEnter = () => {
    //console.log("마우스 오버!!!");
    setMouseHandle("over");
  };

  const handleMouseLeave = () => {
    //console.log("마우스 아웃!!!");
    setMouseHandle("leave");
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {!helperLayouts.isHideHeaderByURL(history.location.pathname) && (
        <Header
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
      )}
      {/* <Header
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      /> */}
      <Drawer
        variant="permanent"
        style={{ marginTop: "64px" }}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* <div className={classes.toolbarIcon}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            CoolStay
          </Typography>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            <ChevronLeftIcon />
          </IconButton>
        </div> */}
        {/*<Divider />*/}
        <div id={"open-" + open}>{menus}</div>
        {/* <div style={{ marginTop: "4.6em" }}></div> */}
      </Drawer>
      {/* <div
        style={{ minHeight: '100vh', overflow: 'hidden', overflowX: 'auto' }}
        className={classes.app}
      > */}
      <div
        style={{ minHeight: "100vh", overflow: "hidden", overflowX: "auto" }}
        className={classes.app}
      >
        <main
          id="main"
          className={classes.main}
          style={{ marginLeft: open ? "256px" : "100px" }}
        >
          {props.children}
        </main>

        <footer
          className={classes.footer}
          style={{ marginLeft: open ? "256px" : "100px" }}
        >
          {/* <Copyright /> */}
          {/* <FooterPC /> */}
          <Footer isLoggedIn={isLoggedIn} />
        </footer>
        {/* </div> */}
      </div>
    </div>
  );
}

TemplateLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(TemplateLayout);
