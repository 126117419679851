/**
 * react-router-dom History에 관련 기능을 제공하는 모듈 입니다<p/>
 *
 * @module lib/provider-history
 * @see module:lib/service-browser
 * @see module:lib/service-http-canceler
 * @see module:lib/lib/service-notice
 */
import * as serviceBrowser from "lib/service-browser";
import * as serviceHttpCanceler from "lib/service-http-canceler";
import * as serviceNotice from "lib/service-notice";

serviceBrowser.provider();

let __timer = null;
export function getUserConfirmation(message, callback) {
  if (serviceBrowser.isDisableBackNavigation()) return callback(false);
  callback(true);
  serviceBrowser.resetBlockingScroll();
  serviceHttpCanceler.cancelAll();

  !!__timer && clearTimeout(__timer);
  __timer = setTimeout(doRunRequestShowNotice, 2000);
}

function doRunRequestShowNotice() {
  !!__timer && clearTimeout(__timer);
  __timer = null;
  serviceNotice.requestShowNotice();
}
