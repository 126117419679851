// constant/CommonConsts

const COMMON_CONSTS = {
  BOARD_ITEM_STATUS: {
    // BI001:	'게시물 등록',
    BI001: "적용완료",
    BI002: "게시물 차단",
    BI003: "답변 대기",
    BI004: "답변 완료",
    BI005: "일반이벤트",
    BI006: "쿠폰이벤트",
  },

  SERVER_API_CODE: {
    SUCCESS: "20000000",
  },

  MOTEL_PROD_CODE: {
    ROOM: "010100",
    SHORT_RENT: "010101",
    DAY_RENT: "010102",
  },
  MOTEL_EVENT_TYPE: {
    NONE: "선택",
    UPGRADE: "객실업그레이드",
    FOOD: "음식제공",
    ANNIVERSARY: "기념일축하",
    REVIEW: "우수후기 선물",
    DISCOUNT: "요금할인",
  },
  USER_TYPE: {
    U: "일반 가입 사용자",
    SK: "SNS 로그인 사용자(카카오)",
    SN: "SNS 로그인 사용자(네이버)",
    PARTNER_OWNER: "사장님",
    PARTNER_EMPLOYEE: "직원",
    WEBMASTER: "master",
    A: "관리자",
    G: "비회원 사용자(Guest)",
  },
  SALE_TYPE: {
    SELL: "매매",
    RENT: "임대",
  },
  SALE_OPTIONS: {
    C01: "커플PC", // 20210225 김주호, 이태호 팀장 요청으로 커플룸/PC -> 커플PC 변경
    C09: "테마욕조",
    C17: "발렛파킹",
    C02: "넷플릭스",
    C10: "파티룸",
    C18: "무인텔",
    C03: "조식",
    C11: "욕실TV",
    C19: "수영장",
    C04: "노래방",
    C12: "헬스장",
    C20: "세탁서비스",
    C05: "스파/월풀",
    C13: "주차장",
    C21: "물침대",
    C06: "스타일러",
    C14: "미니바",
    C22: "안마의자",
    C07: "트윈베드",
    C15: "테라스",
    C23: "배틀그라운드",
    C08: "찜질방",
    C16: "거울룸",
    C24: "셀프세탁실",
  },
  MOTEL_PROD_TYPE: {
    "010101": "대실",
    "010102": "숙박",
  },
  ALLIANCE: {
    // 20210208 박태성C - API에 owner_type 추가 되어 수정함
    SOLE_PROPRIETORSHIP: "0",
    CORPORATION: "1",
  },
  CUSTOM_EVENT: {
    HEADER: {
      // 20201120 박태성C - Mobile Header에서 [만실 설정] 변경 시 발송 되는 CustomEvent 타입
      REFRESH_SETTING_MOTELS: "REFRESH_SETTING_MOTELS@HEADER",
    },
  },
  FIND_AUTH_TYPE: {
    PHONE_CHANGE: "PHONE_CHANGE",
    FIND_ID: "ID",
    FIND_PW: "PW",
    REGISTER: "REGISTER",
  },
  SERVER_BUSINESS_TYPE: {
    MOTEL : "MOTEL",
    HOTEL : "HOTEL",
    PENSION : "PENSION",
  },
};

export default COMMON_CONSTS;
