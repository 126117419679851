/**
 * react-datepicker의 헬퍼 모듈 입니다<p/>
 *
 * @module lib/helper-react-datepicker
 */

import * as configApp from "config/config-app";

const SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";

const TAG = "[lib/helper-react-datepicker]";
// console.log(TAG, "Create");

// https://github.com/Hacker0x01/react-datepicker/blob/master/src/index.jsx
// https://snoopy81.tistory.com/124
// http://sculove.github.io/blog/2016/12/29/addEventListener-passive/
export function handleCalendarClickOutside(elem) {
  if (
    !!elem &&
    !!elem.handleCalendarClickOutside &&
    !elem.__handleCalendarClickOutside__
  ) {
    console.log(TAG, "Change handleCalendarClickOutside", elem);
    elem.__handleCalendarClickOutside__ = elem.handleCalendarClickOutside;
    elem.handleCalendarClickOutside = function (evt) {
      if (!SERVICE_TYPE_PC) {
        if (evt.cancelable) {
          evt.stopPropagation();
          // evt.preventDefault();
        }
        // setTimeout(() => {
        //   if (!this.props.inline) {
        //     this.setOpen(false);
        //   }
        // }, 200);

        if (!this.props.inline) {
          setTimeout(() => {
            try {
              this.setOpen(false);
              this.props.onClickOutside(evt);
            } catch (e) {}
          }, 200);
        } else {
          this.props.onClickOutside(evt);
        }

        return;
      }
      this.__handleCalendarClickOutside__(evt);
    }.bind(elem);
  }
}
