import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, Tabs, Tab, Button } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import { BoxWrap, TabPanel } from "components";

import {
  POPUP_ACTION_OK,
  openAlertPopupWithPromise,
  openConfirmPopupWithPromise,
  openHttpRejectionPopupWithPromise,
} from "lib/helper-popup";

import * as serviceStorage from "lib/service-storage";
import { getSalesRoomsMotels } from "lib/service-api";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { openSnackbar, closeSnackbar } from "lib/service-redux-alerts";
import TabPanelSalesRoom from "../organisms/TabPanelSalesRoomOrganismV2";

import { format } from "date-fns";

const SALE_DAY = "saleday"; // 숙박
const SALE_HOUR = "salehour"; // 대실
const DELAY_NEXT_ACTION = 500; // 1000m/s

const styles = (theme) => ({
  linkBtnWrapper: {
    top: "16px",
    right: "16px",
    zIndex: "1",
  },
  textDecoNone: {
    textDecoration: "none",
  },
  skinbarRoot: {
    "& .MuiSnackbarContent-message": {
      padding: "20px",
    },
  },
});

const WhiteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
}))(Button);

/**
 * 판매캘린더 Template Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name RoomSalesTemplateV3
 * @component
 */
class RoomSalesTemplateV3 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      // 원본 데이타에 속성값을 변경(잔여 객실수, 체크 상태 )하므로 복사본 사용
      dataSalesRoom: [],
      dataSalesRoomRental: [],
      dataSalesRoomRecived: [],
      dataSalesRoomRentalRecived: [],
    };
    this.timer = null;
    this.isActive = true;
    this.selectedRows = [];
    this.changedRooms = {}; // 변경 발생한 객실의 키를 Object의 key로 저장
    this.keyToast = null;
    this.isOpenConfirm = false;

    this.roomSales = [];
    this.prevRemainNum = null;
    this.remainNum = null;
    this.saleYn = null;
    this.hasMismatchData = false;
  }

  handleChange = (event, newValue) => {
    this.setState({ tabIndex: newValue });
  };

  notifyChangedStateSalesMotels = (message) => {
    const { classes } = this.props;
    if (this.isOpenConfirm !== true) {
      this.keyToast = this.props.openSnackbar({
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        classes: {
          root: classes.skinbarRoot,
        },
        autoHideDuration: 5000,
        message: message,
      });
    }
  };

  handelOnCallbackSaleYn = (saleYn, type, roomKey) => {
    this.saleYn = saleYn;
    // let { type, roomKey } = this.queryName(name);

    this.closeSnackbar();

    this.preUpdateSaleYn(type, roomKey, saleYn);

    this.changeData({
      rentType: type,
      changedType: "saleYn",
    });
  };

  /** 잔여객실 변경 이벤트 */
  handelOnCallbackUpDown = (remainNum, type, roomKey) => {
    this.changedType = "number";
    this.remainNum = remainNum;
    // let { type, roomKey } = this.queryName(name);

    this.closeSnackbar();

    /*
    20201126 박태성
    
    1. [잔여 객실 수]를 변경하면 서버 연동 처리 됨
    -> PLATFORM-397 메인 페이지 오늘판매설정 카드 개선
    2. 사용자가 [잔여 객실] 변경 시 클릭이 많이 발생 될 것으로 예상 되어
    사용자 액션 이후 N초(DELAY_NEXT_ACTION 참고) 이내 추가적인 액션이 없을 경우 서버 연동 처리 함
    */
    // 동시에 여러 룸의 [잔여 객실 수]를 변경 할 수 있어 데이타는 미리 업데이트 처리 함
    this.preUpdateRemainNum(type, roomKey, remainNum);

    this.clearTimer();
    this.timer = setTimeout(this.changeData, DELAY_NEXT_ACTION, {
      rentType: type,
      changedType: "remainNum",
    });
  };

  changeData = ({ rentType, changedType }) => {
    if (!this.isActive) return;

    // 대실/숙박 중 하나의 정보만 가져옴
    getSalesRoomsMotels(
      {
        motel_key: serviceStorage.getConnectedMotelKey(),
        search_type: "ST1602",
        search_extra: format(
          new Date(Date.now() - 3600 * 3 * 1000),
          "yyyyMMdd"
        ), //룸 판매 설정 목록 특정일 검색	검색일 (yyyyMMdd)
        item_type: rentType === SALE_DAY ? "010102" : "010101", //룸:010100, 대실: 010101, 숙박: 010102
      },
      true
    ).then((result) => {
      this.checkMismatchData(rentType, result);
      this.notifyChangedState(changedType);
      this.createSalesRoomsMotelsWithPopup(rentType, true, changedType)
        .then((ret) => {
          const isOnlyUpdate = !!ret && ret.isOnlyUpdate === true;
          !!this.props.onRefresh && this.props.onRefresh(!isOnlyUpdate);
        })
        .catch(openHttpRejectionPopupWithPromise)
        .finally(() => {
          this.roomSales = [];
        });
    });
  };

  createSalesRoomsMotelsWithPopup = (rentType, isHideLoading, changedType) => {
    if (this.hasMismatchData) {
      return openAlertPopupWithPromise(
        null,
        "객실 판매 현황에 새로운 변경사항이 발생하였습니다. 잔여 객실 수 또는 객실 상태를 다시 조정하시기 바랍니다."
      ).then((actionType) => {
        return Promise.resolve({ isOnlyUpdate: true });
      });
    }

    const roomSale =
      changedType === "saleYn"
        ? this.roomSales.map((roomSale) => {
          return { ...roomSale, isChangeStatus: true, status: 'STABLE'}
          })
        : this.roomSales.map((roomSale) => {
            return { ...roomSale, pre_setting_yn: "Y", isChangeStatus: false, status: 'STABLE' };
          });
    console.log("ytw roomSales", changedType, roomSale);
    return this.createSalesRoomsMotels(rentType, roomSale, isHideLoading);
  };

  // render 실행 시 하위 버튼 컴포넌트가 갱신 되지 않도록 핸들러를 랩퍼 처리 함
  handleOnClickOpenSaleDay = () => {
    this.handleChangeSaleStatus(SALE_DAY, "open");
  };

  handleOnClickCloseSaleDay = () => {
    this.handleChangeSaleStatus(SALE_DAY, "close");
  };

  handleOnClickOpenSaleHour = () => {
    this.handleChangeSaleStatus(SALE_HOUR, "open");
  };

  handleOnClickCloseSaleHour = () => {
    this.handleChangeSaleStatus(SALE_HOUR, "close");
  };

  handleOnChangeCheckBox = ({ selectedRows }) => {
    // 이전에 설정 된 데이타가 반환 되기 때문에 체크 여부만 확인 할 때 사용해야 함
    this.selectedRows = selectedRows;
  };

  /** 판매/마감 처리 */
  handleChangeSaleStatus = (type, action) => {

    if (!!this.keyToast) {
      this.props.closeSnackbar(this.keyToast);
      this.keyToast = null;
    }

    this.isOpenConfirm = true;

    const isSaleYN = action === "open";
    const txtSaleStatus = isSaleYN ? "판매" : "마감";
    if (1 > this.selectedRows.length) {
      return openAlertPopupWithPromise(
        null,
        `${txtSaleStatus}할 객실 선택 후 ${txtSaleStatus} 설정해 주세요.`
      ).finally(() => {
        this.isOpenConfirm = false;
      });
    }

    openConfirmPopupWithPromise(
      null,
      `선택한 객실을 모두\n${txtSaleStatus} 상태로 적용하시겠습니까?`
    ).then((actionType) => {
      if (actionType !== POPUP_ACTION_OK) {
        this.isOpenConfirm = false;
        return;
      }

      // (START) 선택 된 객실 추출
      let selectedRooms = {};
      this.selectedRows.forEach((rows) => {
        selectedRooms[`${rows.room_key}`] = 1;
      });
      let roomSales = this.getSalesRoomsMotels(type)
        .filter((roomSale) => {
          const roomKey = `${roomSale.room_key}`;
          const isPass = selectedRooms.hasOwnProperty(roomKey) === true;
          selectedRooms[roomKey] = null; //초기화
          return isPass;
        })
        .map((roomSale) => {
          let item = this.convertRoomSale(roomSale);
          item.sale_yn = isSaleYN ? "Y" : "N";
          item.isChangeStatus = true;
          item.status = 'STABLE';
          return item;
        });
      selectedRooms = null;
      // (END) 선택 된 객실 추출

      // 20210106 박태성C
      // [C202101-110][업주PC] 오늘판매설정 잔여 객실 수 변경 후 마감 처리 빠르게 진행 시 서버 에러 팝업 제공되는 문제
      if (1 > roomSales.length) {
        roomSales = null;
        this.isOpenConfirm = false;
        return;
      }

      return this.createSalesRoomsMotels(type, roomSales)
        .then(() => {
          if (!this.isActive) return;
          // 서버 통신이 완료 되고 현재 실행 중일 경우 초기화 하도록 함
          this.selectedRows = [];
          !!this.props.onRefresh &&
            this.props.onRefresh().then(() => {
              if (!!this.isActive) {
                this.isOpenConfirm = false;
                this.notifyChangedStateSalesMotels(
                  `선택하신 객실의 상태가 ${
                    isSaleYN ? "판매로" : "마감으로"
                  } 변경되었습니다.`
                );
              }
            });
        })
        .catch(openHttpRejectionPopupWithPromise)
        .finally(() => {
          this.isOpenConfirm = false;
          roomSales.splice(0, roomSales.length);
          roomSales = null;
        });
    });
  };

  convertRoomSale = (roomSale) => {
    // 서버에서 null이 전달 됨
    const discount_price = Number(roomSale.discount_price);
    const total_amount = Number(roomSale.total_amount);
    const sales_amount = Number(roomSale.sales_amount);
    const special_price = Number(roomSale.special_price);

    if (roomSale.discount_price === null)
      roomSale.discount_price = discount_price;
    if (roomSale.total_amount === null) roomSale.total_amount = total_amount;
    if (roomSale.sales_amount === null) roomSale.sales_amount = sales_amount;
    if (roomSale.special_price === null) roomSale.special_price = special_price;

    return {
      room_key: roomSale.room_key,
      room_name: roomSale.room_name,
      item_type: roomSale.item_type,
      start_time: roomSale.start_time,
      end_time: roomSale.end_time,
      stay_time: roomSale.stay_time,
      infinite_yn: roomSale.infinite_yn,
      infinite_start_time: roomSale.infinite_start_time,
      infinite_stay_time: roomSale.infinite_stay_time,
      sale_yn: roomSale.sale_yn,
      pre_setting_yn: roomSale.pre_setting_yn,
      discount_price,
      total_amount,
      sales_amount,
      special_price,
    };
  };

  // 판매 설정 등록
  createSalesRoomsMotels = (type, roomSales, hideLoading) => {
    const { todaySalesDate, todaySalesPrice, todaySalesPriceRental } =
      this.props.model;

    return this.props.onRequestAjax(
      "createSalesRoomsMotels",
      {
        daily_room_sales: [
          {
            date: todaySalesDate,
            room_sales: roomSales,
            sales_price:
              type === SALE_DAY ? todaySalesPrice : todaySalesPriceRental,
          },
        ],
      },
      hideLoading
    );
  };

  getSalesRoomsMotels = (type) => {
    return type === SALE_DAY
      ? this.state.dataSalesRoom
      : this.state.dataSalesRoomRental;
  };

  clearTimer = () => {
    !!this.timer && clearTimeout(this.timer);
    this.timer = null;
  };

  updateByModel = () => {
    if (!this.isActive) return;
    let { model } = this.props;

    // 원본 데이타에 속성값을 변경(잔여 객실수, 체크 상태 )하므로 복사본 사용
    let dataSalesRoom = JSON.parse(JSON.stringify(model.dataSalesRoom));
    let dataSalesRoomRental = JSON.parse(
      JSON.stringify(model.dataSalesRoomRental)
    );

    // 원본 데이타에 속성값을 변경(잔여 객실수, 체크 상태 )하므로 받은 시점의 데이타 사용
    let dataSalesRoomRecived = JSON.parse(JSON.stringify(model.dataSalesRoom));
    let dataSalesRoomRentalRecived = JSON.parse(
      JSON.stringify(model.dataSalesRoomRental)
    );

    this.setState(
      {
        ...this.state,
        dataSalesRoom,
        dataSalesRoomRental,
        dataSalesRoomRecived,
        dataSalesRoomRentalRecived,
      },
      () => {
        model = null;
        dataSalesRoom = null;
        dataSalesRoomRental = null;
        dataSalesRoomRecived = null;
        dataSalesRoomRentalRecived = null;
      }
    );
  };

  checkMismatchData = (type, result) => {
    // 화면 갱신 시 서버에서 조회 된 객실
    const { hmRecivedRoomSales, hmNowRoomSales } = this.getReceivedAndNowData(
      type,
      result
    );

    this.hasMismatchData = false;
    this.roomSales = this.roomSales.filter((roomSale) => {
      const roomKey = `${roomSale.room_key}`;
      const isPass = this.changedRooms.hasOwnProperty(roomKey) === true;

      if (isPass && !this.hasMismatchData) {
        // 화면 갱신 시 존재 하였으나 현재 서버에는 없는 객실
        this.hasMismatchData =
          hmRecivedRoomSales.hasOwnProperty(roomKey) === true &&
          hmNowRoomSales.hasOwnProperty(roomKey) === false;

        if (
          !this.hasMismatchData &&
          hmRecivedRoomSales.hasOwnProperty(roomKey) &&
          hmNowRoomSales.hasOwnProperty(roomKey)
        ) {
          const sales_amount_now = parseInt(
            hmNowRoomSales[roomKey].sales_amount
          );
          const total_amount_now = parseInt(
            hmNowRoomSales[roomKey].total_amount
          );
          const sale_yn_now = hmNowRoomSales[roomKey].sale_yn;

          const sales_amount_recived = parseInt(
            hmRecivedRoomSales[roomKey].sales_amount
          );
          const total_amount_recived = parseInt(
            hmRecivedRoomSales[roomKey].total_amount
          );
          const sale_yn_recived = hmRecivedRoomSales[roomKey].sale_yn;

          this.hasMismatchData =
            sales_amount_now !== sales_amount_recived ||
            total_amount_now !== total_amount_recived ||
            sale_yn_now !== sale_yn_recived;
        }
      }

      return isPass;
    });

    this.changedRooms = {};
  };

  getReceivedAndNowData = (type, result) => {
    // 화면 결과
    const hmRecivedRoomSales = {};
    const { dataSalesRoomRecived, dataSalesRoomRentalRecived } = this.state;
    let recivedRoomSales =
      type === SALE_DAY ? dataSalesRoomRecived : dataSalesRoomRentalRecived;
    recivedRoomSales.forEach((roomSale) => {
      const roomKey = `${roomSale.room_key}`;
      if (this.changedRooms.hasOwnProperty(roomKey) === true) {
        hmRecivedRoomSales[roomKey] = roomSale;
      }
    });

    // 서버 결과
    const hmNowRoomSales = {};
    if (
      result.data &&
      !!result.data.result &&
      !!result.data.result.daily_room_sales &&
      !!result.data.result.daily_room_sales[0].room_sales
    ) {
      this.nowRoomSales = result.data.result.daily_room_sales[0].room_sales;
      this.nowRoomSales.forEach((roomSale) => {
        const roomKey = `${roomSale.room_key}`;
        if (this.changedRooms.hasOwnProperty(roomKey) === true) {
          hmNowRoomSales[roomKey] = roomSale;
        }
      });
    }

    return { hmRecivedRoomSales, hmNowRoomSales };
  };

  closeSnackbar = () => {
    if (!!this.keyToast) {
      this.props.closeSnackbar(this.keyToast);
      this.keyToast = null;
    }
  };

  queryName = (name) => {
    let uri = name.split("_");
    let type = uri[0];
    let roomKey = parseInt(uri[1]);
    uri.splice(0, uri.length);
    uri = null;
    return { type, roomKey };
  };

  notifyChangedState = (changedType) => {
    if (!this.hasMismatchData) {
      // 서버 연동 전에 메세지 띄우기
      if (changedType === "remainNum") {
        switch (this.remainNum) {
          // case 0:
          // this.notifyChangedStateSalesMotels(
          //   "선택하신 객실의 상태가 판매에서 완료로 변경되었습니다."
          // );
          // this.notifyChangedStateSalesMotels(
          //   "선택하신 객실의 상태가 완료로 변경되었습니다."
          // );
          // break;
          default:
            if (this.prevRemainNum === 0) {
              // this.notifyChangedStateSalesMotels(
              //   "선택하신 객실의 상태가 완료에서 판매로 변경되었습니다."
              // );
              this.notifyChangedStateSalesMotels(
                "선택하신 객실의 상태가 판매로 변경되었습니다."
              );
            } else {
              this.notifyChangedStateSalesMotels(
                `선택하신 객실의 잔여 객실수가 ${this.remainNum}개로 변경되었습니다.`
              );
            }
            break;
        }
      } else if (changedType === "saleYn") {
        this.notifyChangedStateSalesMotels(
          `선택하신 객실의 상태가 ${
            this.saleYn === "Y" ? "판매로" : "마감으로"
          } 변경되었습니다.`
        );
      }
    }
  };

  preUpdateSaleYn = (type, roomKey, saleYn) => {
    this.roomSales = this.getSalesRoomsMotels(type).map((roomSale) => {
      let item = this.convertRoomSale(roomSale);
      if (roomSale.room_key === roomKey) {
        this.prevRemainNum = item.total_amount - item.sales_amount;
        this.changedRooms[`${roomKey}`] = 1;
      }

      item.sale_yn = roomSale.room_key === roomKey ? saleYn : item.sale_yn;

      // [잔여 객실 수] 변경 후 별도 갱신 처리가 없으므로, props 데이타도 업데이트 처리 함
      roomSale.total_amount = item.total_amount;
      return item;
    });
  };

  preUpdateRemainNum = (type, roomKey, remainNum) => {
    this.roomSales = this.getSalesRoomsMotels(type).map((roomSale) => {
      let item = this.convertRoomSale(roomSale);
      if (roomSale.room_key === roomKey) {
        this.prevRemainNum = item.total_amount - item.sales_amount;
        this.changedRooms[`${roomKey}`] = 1;
      }

      item.total_amount =
        roomSale.room_key === roomKey
          ? remainNum + item.sales_amount
          : item.total_amount;

      // [잔여 객실 수] 변경 후 별도 갱신 처리가 없으므로, props 데이타도 업데이트 처리 함
      roomSale.total_amount = item.total_amount;
      return item;
    });
  };

  componentDidMount() {
    this.updateByModel();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!!prevProps && !!this.props) {
      if (prevProps.model !== this.props.model) {
        this.updateByModel();
      }
    }
  }

  componentWillUnmount() {
    this.isActive = false;
    this.clearTimer();
  }

  render() {
    const { classes, theme, typeSale = "SALE_ALL" } = this.props;
    const { tabIndex, dataSalesRoom, dataSalesRoomRental } = this.state;
    const isActiveSalesRoom = 0 < dataSalesRoom.length;
    const isActiveSalesRoomRental = 0 < dataSalesRoomRental.length;

    let isShowSaleAll = typeSale === "SALE_ALL";
    let isShowSaleDay = typeSale === "SALE_DAY";
    let isShowSaleHour = typeSale === "SALE_HOUR";

    return (
      <BoxWrap>
        <Box position="absolute" className={classes.linkBtnWrapper}>
          <Link
            to={`/calendar?tabIndex=${
              isShowSaleAll
                ? tabIndex
                : isShowSaleDay
                ? 0
                : isShowSaleHour
                ? 1
                : 0
            }`}
            className={classes.textDecoNone}
          >
            <WhiteButton variant="contained" color="primary" size="small">
              <strong>판매캘린더</strong>
            </WhiteButton>
          </Link>
        </Box>
        <Box mt={1}>
          <div className={"tabroot"}>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              onChange={this.handleChange}
            >
              {isShowSaleDay && (
                <Tab
                  value={0}
                  className={"tab color-saleday"}
                  label="오늘 숙박"
                />
              )}
              {isShowSaleHour && (
                <Tab
                  value={0}
                  className={"tab color-salehour"}
                  label="오늘 대실"
                />
              )}
              {isShowSaleAll && (
                <Tab
                  value={0}
                  className={"tab color-saleday"}
                  label="오늘 숙박"
                />
              )}
              {isShowSaleAll && (
                <Tab
                  value={1}
                  className={"tab color-salehour"}
                  label="오늘 대실"
                />
              )}
            </Tabs>
          </div>

          {isShowSaleDay && (
            <TabPanel value={tabIndex} index={0} dir={theme.direction}>
              {tabIndex === 0 && (
                <TabPanelSalesRoom
                  tag={"숙박"}
                  uniqueTable={SALE_DAY}
                  data={dataSalesRoom}
                  isActive={isActiveSalesRoom}
                  onChangeCheckBox={this.handleOnChangeCheckBox}
                  onCallbackSaleYn={this.handelOnCallbackSaleYn}
                  onCallbackUpDown={this.handelOnCallbackUpDown}
                  onCallbackOpen={this.handleOnClickOpenSaleDay}
                  onCallbackClose={this.handleOnClickCloseSaleDay}
                />
              )}
            </TabPanel>
          )}

          {isShowSaleHour && (
            <TabPanel value={tabIndex} index={0} dir={theme.direction}>
              {tabIndex === 0 && (
                <TabPanelSalesRoom
                  tag={"대실"}
                  uniqueTable={SALE_HOUR}
                  data={dataSalesRoomRental}
                  isActive={isActiveSalesRoomRental}
                  onChangeCheckBox={this.handleOnChangeCheckBox}
                  onCallbackSaleYn={this.handelOnCallbackSaleYn}
                  onCallbackUpDown={this.handelOnCallbackUpDown}
                  onCallbackOpen={this.handleOnClickOpenSaleHour}
                  onCallbackClose={this.handleOnClickCloseSaleHour}
                />
              )}
            </TabPanel>
          )}

          {isShowSaleAll && (
            <>
              <TabPanel value={tabIndex} index={0} dir={theme.direction}>
                {tabIndex === 0 && (
                  <TabPanelSalesRoom
                    tag={"숙박"}
                    uniqueTable={SALE_DAY}
                    data={dataSalesRoom}
                    isActive={isActiveSalesRoom}
                    onChangeCheckBox={this.handleOnChangeCheckBox}
                    onCallbackSaleYn={this.handelOnCallbackSaleYn}
                    onCallbackUpDown={this.handelOnCallbackUpDown}
                    onCallbackOpen={this.handleOnClickOpenSaleDay}
                    onCallbackClose={this.handleOnClickCloseSaleDay}
                  />
                )}
              </TabPanel>
              <TabPanel value={tabIndex} index={1} dir={theme.direction}>
                {tabIndex === 1 && (
                  <TabPanelSalesRoom
                    tag={"대실"}
                    uniqueTable={SALE_HOUR}
                    data={dataSalesRoomRental}
                    isActive={isActiveSalesRoomRental}
                    onChangeCheckBox={this.handleOnChangeCheckBox}
                    onCallbackSaleYn={this.handelOnCallbackSaleYn}
                    onCallbackUpDown={this.handelOnCallbackUpDown}
                    onCallbackOpen={this.handleOnClickOpenSaleHour}
                    onCallbackClose={this.handleOnClickCloseSaleHour}
                  />
                )}
              </TabPanel>
            </>
          )}
        </Box>
      </BoxWrap>
    );
  }
}

// 초기 props 설정
RoomSalesTemplateV3.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default connect(null, (dispatch) =>
  bindActionCreators({ openSnackbar, closeSnackbar }, dispatch)
)(withTheme(withStyles(styles)(RoomSalesTemplateV3)));
// function noop(err) {}
