import React, { Component } from "react";
import { Link } from "react-router-dom";

import { makeStyles, withStyles, withTheme } from "@material-ui/core/styles";
import { Box, IconButton } from "@material-ui/core";

import * as configApp from "config/config-app";
let SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";

/**
 * PC Footer Component 입니다.<p/>
 *
 * @author Drst3in <drst3in9196@gmail.com>
 * @name FooterPC
 * @class
 * @component
 */

const styles = (theme) => ({});

class FooterPC extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Box id="Footer-PC">
        <Box>
          <h4 style={{ margin: "4px 0" }}>
            <Link to="/serviceIntro">서비스 소개</Link>
            &nbsp;|&nbsp;<Link to="/serviceIntro">파트너센터 사용 안내</Link>
            &nbsp;|&nbsp; <Link to="/termIntro?tab=1">개인정보 처리방침</Link>
            &nbsp;|&nbsp; <Link to="/termIntro?tab=0">이용약관</Link>
            &nbsp;|&nbsp;
            <Link to="/question">1:1 문의</Link>
          </h4>
        </Box>
        <Box>
          <h5 style={{ margin: "8px 0 0 0", opacity: "0.7" }}>
            가성비 숙박 꿀스테이 고객센터 053-215-7123 / 이용시간 오전 9시 ~
            오후 6시(점심시간: 오후12시 ~ 오후1시)
            <br /> Copyright © 2020 PINESTAY, all rights reserved.
          </h5>
        </Box>
      </Box>
    );
  }
}

const styledFooterPC = withStyles(styles)(FooterPC);

export { styledFooterPC as FooterPC };
