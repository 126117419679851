import React, { useState, useEffect, useRef, useCallback } from "react";

import { ModalWrap } from "components";

import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { makeStyles } from "@material-ui/core/styles";

import * as configApp from "config/config-app";
import * as serviceBrowser from "lib/service-browser";
import * as serviceStorage from "lib/service-storage";
import {
  // POPUP_ACTION_OK,
  openHttpRejectionPopupWithPromise,
  // openAlertPopupWithPromise,
  // openConfirmPopupWithPromise,
} from "lib/helper-popup";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { openRequest } from "system/redux-ducks/loading";

let SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";

const useStyles = makeStyles({
  root: {
    padding: "0",
    "& .pc-motels": {
      overflow: "hidden",
      overflowY: "auto",
      display: "block",
      height: SERVICE_TYPE_PC ? "300px" : "325px",
      marginTop: SERVICE_TYPE_PC ? "10px" : "0px",
      overflowScrolling: "touch",
      WebkitOverflowScrolling: "touch",
      padding: "0.25rem",
    },
    "& .pc-motels .pc-motels-item": {
      position: "relative",
      width: "100%",
      marginTop: "0.25rem",
      marginBottom: "0.25rem",
      "& .MuiTypography-body1": {
        fontSize: "0.83rem",
      },
    },
    "& .pc-motels .formControl": {
      marginTop: "0px !important",
      display: "block",
      width: "100%",
    },
    "& .pc-motels .formControlLabel": {
      borderBottom: "1px solid #f5f6f8",
      fontWeight: "bold",
      width: "100%",
    },
    "& .description": {
      "& p": {
        marginTop: "10px",
        marginBottom: "10px",
        fontWeight: "bold",
      },
    },
  },
});

/**
 * 숙소 변경 팝업 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name PopupMotelSelect
 * @component
 */
function PopupMotelSelect({ openRequest, open, onClose }) {
  const [state, setState] = useState({
    selectedKey: "",
    compMotels: [],
  });

  const { selectedKey } = state;

  const refScrollTarget = useRef();
  const isAllowInnserScroll = useRef(false);

  const handleOnClose = useCallback(() => {
    !!onClose && onClose();
  }, []);

  const handleTouchStart = useCallback((evt) => {
    if (!!refScrollTarget.current) {
      // https://d2.naver.com/helloworld/80243
      let rect = refScrollTarget.current.getBoundingClientRect();
      const top = window.pageYOffset + rect.top;
      const bottom = window.pageYOffset + rect.bottom;
      const left = rect.left;
      const right = rect.right;

      const pageX = evt.pageX || evt.touches[0].pageX;
      const pageY = evt.pageY || evt.touches[0].pageY;

      if (left <= pageX && pageX < right && top <= pageY && pageY < bottom) {
        isAllowInnserScroll.current = true;
        serviceBrowser.pauseDisableScroll();
      }

      rect = null;
    }
  }, []);

  const handleTouchEnd = useCallback(() => {
    if (!!isAllowInnserScroll.current) {
      isAllowInnserScroll.current = false;
      serviceBrowser.resumeDisableScroll();
    }
  }, []);

  // 생성, 소멸
  const stateActive = useRef(true);
  useEffect(() => {
    document.body.addEventListener(
      "touchstart",
      handleTouchStart,
      serviceBrowser.isSupportedPassive() ? { passive: true } : true
    );
    document.body.addEventListener(
      "touchend",
      handleTouchEnd,
      serviceBrowser.isSupportedPassive() ? { passive: true } : true
    );

    return () => {
      document.body.removeEventListener("touchstart", handleTouchStart);
      document.body.removeEventListener("touchend", handleTouchEnd);
      stateActive.current = false;
    };
  }, []);

  useEffect(() => {
    if (!!open) {
      setState((state) => {
        return {
          ...state,
          selectedKey: serviceStorage.getConnectedMotelKey(),
          compMotels: makeCompMotels(
            JSON.parse(
              JSON.stringify(
                serviceStorage.getMotels(serviceStorage.getIdentity())
              )
            ) || []
          ),
        };
      });
    }
  }, [open]);

  const handleSelectModel = () => {
    if (!!selectedKey && !!serviceStorage.getConnectedMotelKey()) {
      if (serviceStorage.getConnectedMotelKey() !== selectedKey) {
        serviceStorage
          .connectMotelWithPromise(selectedKey)
          .then(() => {
            if (!stateActive.current) return;
            openRequest();
            window.location.replace(window.location.pathname);
            // window.location.reload();
            // 2020.11.05 양태욱 변경
            // reload() -> replace(window.loaction.pathname)
            // 기존 숙소 변경 후 리로드 할 때 url의 param값을 모두 가져오게됨.
            // url에 룸키, 게시물키가 있을 경우 정상 동작 하지 않기 때문에
            // param값을 가져오지 않고 replace 처리
          })
          .catch(openHttpRejectionPopupWithPromise);
        return;
      }
    }

    handleOnClose();
  };

  const handleOnChange = (event) => {
    !!event.preventDefault && event.preventDefault();
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const classes = useStyles();

  // 20201125 박태성C - [업주][PC][M] 제휴점 변경 팝업 내 [취소] 버튼 노출되지 않는 문제
  // PC/모바일의 버튼 위치가 다름 => 동일하게 수정
  /*
   * 2021.05.06 양태욱
   * [COOL2105-10] 모바일에서 제휴점 변경시 확인,닫기 버튼 순서 변경
   */
  const buttons = SERVICE_TYPE_PC
    ? [
        {
          title: "확인",
          callback: handleSelectModel,
        },
        {
          title: "취소",
          callback: handleOnClose,
        },
      ]
    : [
        {
          title: "확인",
          callback: handleSelectModel,
        },
        {
          title: "닫기",
          callback: handleOnClose,
        },
        // {
        //   title: "닫기",
        //   callback: handleOnClose,
        // },
        // {
        //   title: "확인",
        //   callback: handleSelectModel,
        // },
      ];

  return (
    <ModalWrap
      // title="숙소변경"
      title="제휴점변경"
      open={open}
      onClose={handleOnClose}
      onButtons={buttons}
      PaperProps={
        SERVICE_TYPE_PC
          ? {
              style: {
                width: `${500 + 80}px`,
                height: `${400 + 90}px`,
              },
            }
          : {
              style: {
                width: "90%",
                height: `${380 + 90}px`,
              },
            }
      }
    >
      <Box className={classes.root}>
        {SERVICE_TYPE_PC && (
          <Box className="description">
            {/* <p>선택하신 숙소로 로그인합니다.</p> */}
            <p>선택하신 제휴점으로 로그인합니다.</p>
          </Box>
        )}
        <Box className="pc-motels" ref={refScrollTarget}>
          <FormControl component="fieldset" className="formControl">
            <RadioGroup
              name="selectedKey"
              value={state.selectedKey}
              onChange={handleOnChange}
              className="pc-motels-item"
            >
              {state.compMotels}
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </ModalWrap>
  );
}

export default connect(null, (dispatch) =>
  bindActionCreators({ openRequest }, dispatch)
)(PopupMotelSelect);

function makeCompMotels(motels) {
  return motels.map((motel, index) => {
    return (
      <FormControlLabel
        key={index}
        control={<Radio color="primary" />}
        value={"" + motel.key}
        label={motel.name}
        className="formControlLabel"
      />
    );
  });
}
