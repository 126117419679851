import React from "react";

import { Box } from "@material-ui/core";
import { BoxWrap } from "components";

import { commaFormater } from "utils";
import {
  getConsultantInfo,
} from "../dashboardUtils.js/serviceTemplateUtil";
import {  getFreeUseTerm, FREE_CODE } from "lib/helper-fee";

/*
 * [변동사항]
 * 21.04.15 양태욱
 * 수수료 & 이용료 등 금액에 대한 내용 삭제
 * 요금제에 따른 하단 도움말 문구 변경 & 무료이용기간중엔 표출하지않음
 * 정률 요금제 : 월 누적 판매수 항목 추가
 */

/**
 * Template Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @author TaeWook Yang <didxodnr0769@pineone.com> 2차수정
 * @name ServiceTemplate
 * @component
 */
class ServiceTemplate extends React.PureComponent {
  render() {
    const { model } = this.props;
    const { serviceData } = model;
    const {
      fee_method = "AMOUNT",
      monthly_book_count,
      free_start_dt,
      free_end_dt,
    } = serviceData;
    const freeTerm = getFreeUseTerm(free_start_dt, free_end_dt);
    const consultantInfo = getConsultantInfo(serviceData);
    return (
      <BoxWrap title="서비스 이용 현황">
        <Box id="CommonFormList-PC">
          {/* 210415 양태욱 : 요금 표출 부정적 영향으로 요금 내용 제거 */}

          <Box>
            <TitleComponent title={"이번달 예약수"} />
            <ContentComponent
              content={commaFormater(monthly_book_count) || 0}
            />
          </Box>
          {/*  무료 사용기간 이용중(FREE) 일때만 사용기간 표출 -211005 양태욱*/}
          {fee_method === FREE_CODE && (
            <Box>
              <TitleComponent title={"무료 사용기간"} />
              <ContentComponent content={freeTerm} />
            </Box>
          )}
          <Box style={{ borderBottom: "1px solid #ddd" }}>
            <TitleComponent title={"담당 컨설턴트"} />
            <ContentComponent content={consultantInfo} />
          </Box>
        </Box>
      </BoxWrap>
    );
  }
}
// 항목명 컴포넌트
function TitleComponent(props) {
  const { title } = props;
  return (
    <div className={"title"} style={{ width: "40%" }}>
      {title}
    </div>
  );
}
// 항목 내용 컴포넌트
function ContentComponent(props) {
  const { content } = props;
  return (
    <div className={"contentBody"} style={{ width: "60%" }}>
      {content}
    </div>
  );
}
// 초기 props 설정
ServiceTemplate.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default ServiceTemplate;
