import { withStyles } from "@material-ui/core";
import React from "react";

const styles = (theme) => ({
  textStyle: {
    fontSize: "14px",
    fontWeight: 600,
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 다음 렌더링에서 폴백 UI가 보이도록 상태를 업데이트 합니다.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // openAlertPopupWithPromise(
    //   null,
    //   "서비스가 원활하지 않습니다.\n지속적으로 반복될 시 고객센터로 연락 주세요"
    // );
    // 에러 리포팅 서비스에 에러를 기록할 수도 있습니다.
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { classes } = this.props;

    if (this.state.hasError) {
      // 폴백 UI를 커스텀하여 렌더링할 수 있습니다.
      return (
        <div>
          <p className={classes.textStyle}>서비스가 원활하지 않습니다.</p>
          <p className={classes.textStyle}>
            지속적으로 반복될 시 고객센터로 연락 주세요.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
