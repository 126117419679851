/**
 * 본 모듈은 DataTable을 사용하는 게시판에서 queryString의 정보를 가져오는 모듈입니다.
 *
 * @module utils/boardQueryData
 * @author TaeWook Yang <didxodnr0769@pineone.com>
 *
 * @see EventPageLayout
 * @see NoticePageLayout
 * @see FaqPageLayout
 * @see QuestionPageLayout
 * @see CustomerEventPageLayout
 * @see SurtaxPageLayout
 *
 * @description DataTable을 사용하는 모든 페이지가 아닌 비교적 간단한 QueryString을 사용하는 페이지에서만 적용 가능합니다. urlParser를 이용합니다.
 *
 * @param {Object} location
 * @returns {String} searchExtra
 * @returns {String} searchType
 * @returns {Number} page
 *
 */

import { urlParser } from "./urlParser";

export default function boardQueryData(location) {
  const queryString = urlParser.getQueryString(location);
  const query = Object.fromEntries(queryString);
  const searchExtra = query.searchExtra || "";
  const searchType = query.searchType || "";
  const page = parseInt(query.page) || 1;
  return { searchExtra, searchType, page };
}
