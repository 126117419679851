/**
 * 본 모듈은 3시간1초를 뺀 값을 반환해주는 모듈입니다.
 *
 * @module utils/motelTodayFormat
 */

/**
 *
 *
 * @return {Date} 3시간 1초를 뺀 Date객체를 반환
 */
export default function motelTodayFormat(date = new Date()) {
  const today = new Date(date.getTime() - 3600 * 3 * 1000 - 1);
  return today;
}
