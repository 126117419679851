import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// import { FooterContainer, Footer } from "components";
import ContainerSinglePage from "components/ContainerSinglePage";
import ButtonSubmit from "components/ButtonSubmit";

import { urlParser } from "utils";

import * as configApp from "config/config-app";
import * as serviceBrowser from "lib/service-browser";

let SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";

// 16px 기준으로 rem 테이블 정리 사이트 : http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
// https://nekocalc.com/px-to-rem-converter

const styles = (theme) => {
  if (!SERVICE_TYPE_PC) {
    // 모바일
    return {
      root: {
        "& .csp .csp-box-inner": {
          width: "90%",
        },
        "& .not-found": {
          display: "block !important",
          "& p.guide": {
            margin: 0,
          },
        },
        // AppOnMobile에서 body에 landscape를 등록 할 경우 하단 스타일이 적용 되도록 수정
        "body.landscape & .main": {
          paddingTop: "5% !important",
        },
        "& .main": {
          paddingTop: "28% !important",
        },
        "& .main img": {
          width: "auto",
          height: "50px",
        },
      },
    };
  }

  // PC
  return {
    root: {
      "& .not-found": {
        height: "11rem", // 176px,
      },
    },
  };
};

/**
 * 404 Not Found 및 네트워크 장애 페이지의 Container Component 입니다.<p/>f
 *
 * @author Taesung Park <pts@pineone.com>
 * @name NotFoundPage
 * @class
 * @component
 */
class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    let query = urlParser.getQueryString(window.location);
    let reason = query.get("reason") || "404";
    query = null;

    this.state = {
      reason: reason,
    };

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    this.props.history.replace({ pathname: "/login" });
  }

  componentDidMount() {
    serviceBrowser.addClassNameToBody("logout");
  }

  render() {
    const { classes } = this.props;
    const { reason } = this.state;

    return (
      <div className={classes.root}>
        {/* {!SERVICE_TYPE_PC && (
          <FooterContainer>
            <Footer />
          </FooterContainer>
        )} */}
        <ContainerSinglePage title={`${configApp.APP_TITLE}`}>
          {SERVICE_TYPE_PC && (
            <InnerOnPC onSubmit={this.handleOnClick} reason={reason} />
          )}
          {!SERVICE_TYPE_PC && (
            <InnerOnMobile onSubmit={this.handleOnClick} reason={reason} />
          )}
        </ContainerSinglePage>
      </div>
    );
  }
}

export default withTheme(withStyles(styles)(NotFoundPage));

function InnerOnPC({ onSubmit, reason }) {
  return (
    <>
      <Box className="description not-found">
        {reason === "404" && (
          <>
            <p className="bold font-size-16">404 Not Found</p>
            <p className="bold">요청하신 페이지를 찾을 수 없습니다.</p>
          </>
        )}
      </Box>

      <ButtonSubmit lblSubmit={"확인"} onSubmitByClick={onSubmit} />
    </>
  );
}

function InnerOnMobile({ onSubmit, reason }) {
  return (
    <>
      <Box justifyContent="center">
        <div className="main">
          <Box className="description not-found" align="center">
            <img
              alt="NotFoundPage"
              src={`${configApp.getPathRoot()}/icons/ic_noti.svg`}
            />

            {reason === "404" && (
              <>
                <p className="bold font-size-16">404 Not Found</p>
                <p className="bold guide">
                  요청하신 페이지를 찾을 수 없습니다.
                </p>
              </>
            )}
          </Box>
          <ButtonSubmit lblSubmit={"확인"} onSubmitByClick={onSubmit} />
        </div>
      </Box>
    </>
  );
}
