/**
 * 등록 된 스케쥴에 따라 브로드캐스트 이벤트를 발생 시키는 모듈 입니다.<p/>
 *
 * @module lib/service-scheduler
 */

import { notifyScheduleRequest } from "system/redux-ducks/scheduler";

const TAG = "[lib/service-scheduler.js]";
// const TIME_TICK = 1000 * 60 * 5; // 5분
const TIME_TICK = 1000 * 60 * 1; // 1분
const TIME_NEXT = 1000 * 60 * 60 * 24; // 하루
export const SCHEDULE_TICK = "@service-scheduler/SCHEDULE_TICK";

const __date = new Date();
let __isProvided = false;
let __isAfterStartService = false;
let __store = null;
let __timer = null;
let __timeList = [];
let __schedule = {};
let __scheduleState = {};
let __baseTime = 0;
let __nowTime = 0;

let __timesRuningAfterStart = [];
let __isNotifyTick = false;

export function provider(store) {
  if (__isProvided) return;
  __isProvided = true;
  // console.log(TAG, "Provider");
  __store = store;
}

export function startService(isNotifyTick) {
  doUpdateBaseTime();
  doCheckSchedule(
    0 < __timesRuningAfterStart.length ? __timesRuningAfterStart : null
  );
  __timesRuningAfterStart.splice(0, __timesRuningAfterStart.length);
  console.log(TAG, "Called startService()");
  __isAfterStartService = true;
  __isNotifyTick = isNotifyTick || false;

  console.log(
    TAG,
    __isNotifyTick ? `Enabled ${SCHEDULE_TICK}.` : `Disabled ${SCHEDULE_TICK}.`
  );
}

export function stopService() {
  clearTimer();
  __timeList.splice(0, __timeList.length);
  __timesRuningAfterStart.splice(0, __timesRuningAfterStart.length);
  __schedule = {};
  __scheduleState = {};
  __isAfterStartService = false;
  __isNotifyTick = false;
  console.log(TAG, "Called stopService()");
}

export function addRepeatScheduleAtHours(d, scheduleName, isCheckAfterStart) {
  if (
    !!d &&
    d instanceof Date &&
    typeof scheduleName === "string" &&
    !!scheduleName.trim()
  ) {
    // `스케쥴 시간`을 `시간 기준`으로 보정 한다
    // ex) 현재 시간이 03:20 이면 03:00으로 보정
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);

    // 보정 된 `스케쥴 시간`에서 Time 정보 추출
    const timeRepeat = d.getTime();

    // `금일 시작 시간`(00:00)의 Time 정보 추출
    const timeTodayStart = getTimeTodayStart(timeRepeat);

    // Date 객체는 1970년 1월 1일 0시 0분 0초(UTC+0)을 기준으로 시간을 표현 함
    // 그래서 `스케쥴 시간`에서 `금일 시작 시간`의 Time을 차감 하면
    // 00:00를 기준으로 `스케쥴 시간`의 Time을 구할 수 있음
    let timeFirst = timeRepeat - timeTodayStart;
    let isRunNextDay = false;
    if (!isCheckAfterStart) {
      // `스케쥴 시간`이 이미 지난 상태이면
      if (timeRepeat < Date.now()) {
        // 24 시간 뒤에 동작 되도록 처리 함
        isRunNextDay = true;
        timeFirst = timeFirst + TIME_NEXT;
      }
    }

    if (isRunNextDay === true) {
      console.log(
        TAG,
        `Called addRepeatScheduleAtHours(${scheduleName}). The schedule will run tomorrow`
      );
    } else {
      console.log(
        TAG,
        `Called addRepeatScheduleAtHours(${scheduleName}). The schedule will run today`
      );
    }

    addRepeatSchedule(timeFirst, scheduleName, isCheckAfterStart);
  }
}

function clearTimer() {
  !!__timer && clearTimeout(__timer);
  __timer = null;
}

function addRepeatSchedule(time, scheduleName, isCheckAfterStart) {
  if (0 > __timeList.indexOf(time)) {
    __timeList.push(time);
    __schedule[`${time}`] = scheduleName;
    if (!!isCheckAfterStart) {
      __timesRuningAfterStart.push(time);
    }
    __scheduleState[scheduleName] = false;
    console.log(TAG, `Called addRepeatSchedule(${time}, ${scheduleName})`);
  }
}

export function disableScheduleByName(scheduleName) {
  if (__scheduleState.hasOwnProperty(scheduleName)) {
    __scheduleState[scheduleName] = false;
    console.log(TAG, `Disabled ${scheduleName}`);
  }
}

export function enableScheduleByName(scheduleName) {
  if (__scheduleState.hasOwnProperty(scheduleName)) {
    __scheduleState[scheduleName] = true;
    console.log(TAG, `Enabled ${scheduleName}`);
  }
}

function doCheckSchedule(list) {
  console.log(TAG, "Called doCheckSchedule()");
  clearTimer();

  let targetList = !!list ? list : __timeList;
  if (0 < targetList.length) {
    __nowTime = Date.now();
    let schedulers = targetList.filter(filterSchedule);
    if (0 < schedulers.length) {
      schedulers.forEach(doNotifySchedule);
    }
    schedulers.splice(0, schedulers.length);
    schedulers = null;
  }

  if (__isNotifyTick) {
    if (__isAfterStartService) {
      console.log(TAG, `Dispatch the schedule (${SCHEDULE_TICK}).`);
      notifyScheduleRequest({
        name: SCHEDULE_TICK,
        timeSchedule: Date.now(),
      })(__store.dispatch).then(() => {
        __timer = setTimeout(doCheckSchedule, getTimeNextTick());
      });
      return;
    }
  }

  __timer = setTimeout(doCheckSchedule, getTimeNextTick());
}

function doNotifySchedule(time) {
  let scheduleName; // 스케쥴 이름

  // 해당 시간 정보를 삭제
  const findIdx = __timeList.indexOf(time);
  if (-1 < findIdx) __timeList.splice(findIdx, 1);

  // 시간을 기준으로 `스케쥴 이름` 조회
  const sTime = `${time}`;
  if (__schedule.hasOwnProperty(sTime)) {
    scheduleName = __schedule[sTime];
    __schedule[sTime] = null;
    delete __schedule[sTime];
  }

  // `스케쥴 이름`이 존재 할 경우 통지하고 다음 스케쥴을 등록 한다
  if (!!scheduleName && !!__store) {
    if (!!__scheduleState[scheduleName]) {
      console.log(TAG, `Dispatch the schedule (${scheduleName}).`);
      notifyScheduleRequest({
        name: scheduleName,
        timeSchedule: time,
      })(__store.dispatch).then(() => {
        addRepeatSchedule(time + TIME_NEXT, scheduleName);
      });
    } else {
      console.log(
        TAG,
        `Not dispatch the schedule (${scheduleName}). Because state is disable.`
      );
      addRepeatSchedule(time + TIME_NEXT, scheduleName);
    }
  }
}

function filterSchedule(time) {
  return __nowTime > __baseTime + time;
}

function doUpdateBaseTime() {
  const timeNow = Date.now();
  // 기준시 = 현재 시간 - (현재 시간 - 금일 시작 시간)
  __baseTime = timeNow - (timeNow - getTimeTodayStart(timeNow));
}

function getTimeTodayStart(time) {
  !!time ? __date.setTime(time) : __date.setTime(Date.now());

  __date.setHours(0);
  __date.setMinutes(0);
  __date.setSeconds(0);
  __date.setMilliseconds(0);

  return __date.getTime();
}

function getTimeNextTick() {
  const timeNow = Date.now();
  const timeTodayStart = getTimeTodayStart(timeNow);
  const diff = timeNow - timeTodayStart;
  let timeNextTick = Math.ceil(diff / TIME_TICK) * TIME_TICK;
  timeNextTick = timeNextTick - diff;
  console.log(
    TAG,
    `다음 스케쥴러 체크 시간 ${Utils_getFormatDate(
      new Date(timeNow + timeNextTick)
    )}`
  );
  return timeNextTick;
}

function Utils_toDigit(num) {
  if (num < 10) return `0${num}`;
  return `${num}`;
}

function Utils_getFormatDate(d) {
  return `${d.getFullYear()}-${Utils_toDigit(1 + d.getMonth())}-${Utils_toDigit(
    d.getDate()
  )} ${Utils_toDigit(d.getHours())}:${Utils_toDigit(
    d.getMinutes()
  )}:${Utils_toDigit(d.getSeconds())}`;
}
