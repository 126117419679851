/**
 * 판매캘린더 판매 정보 불러오기 기능 제공
 *
 * */

const TAG = "[selling/redux-ducks/headerBadge.js]";

// Actions

export const CHEAPEST_PRICE = "@badge/CHEAPEST_PRICE";
export const ULIMITED_COUPON = "@badge/ULIMITED_COUPON";

const initialState = {
    isCheapest: false,
    isUnlimitedCoupon : false,
    isSanHaWings: false
}

// action creators
export function changeIsCheapest(isCheapest) {
    console.log(TAG, `Called checkSalesDate()`);
    return (dispatch) => Promise.resolve().then(() => dispatch({
        type: CHEAPEST_PRICE,
        isCheapest
    }));
}

export function changeIsUnlimitedCoupon(isUnlimitedCoupon) {
    console.log(TAG, `Called changeSalesData()`);
    return (dispatch) => Promise.resolve().then(() => dispatch({
        type: ULIMITED_COUPON,
        isUnlimitedCoupon
    }));
}


// reducer
export default function reducer(state = initialState, action = {}) {

    switch (action.type) {
        case CHEAPEST_PRICE:
            return {
                ...state,
                isCheapest: action.isCheapest
            }
        case ULIMITED_COUPON:
            return {
                ...state,
                isUnlimitedCoupon: action.isUnlimitedCoupon
            }
        default:
            return state;
    }
}


