import React, { Component } from "react";
import { history } from "lib/history";

import { withStyles } from "@material-ui/core/styles";
import { Box, Divider, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

/**
 * 모바일 페이지의 헤더가 없는 형태의 페이지 블럭 Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name ContainerWrap
 * @class
 * @component
 */

const styles = (theme) => ({
  root: {
    // border: "1px solid red",
    background: "#fff",
  },
  title: {
    "& h3": {
      padding: "0",
      margin: "0",
    },
  },
  btnWrapper: {
    position: "absolute",
    right: "0",
  },
  titleBtn: {
    display: "inline-block",
    fontSize: "14px",
    padding: 0,
  },
});

class ContainerWrap extends Component {
  render() {
    const { classes, title, onBack, isHideBack, children } = this.props;
    return (
      <Box
        //py={2}
        px={0}
        className={classes.root}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          id="container-wrap-head"
          style={{
            top: 0,
            left: "auto",
            right: 0,
            position: "sticky",
            background: "#fff",
            zIndex: 1,
          }}
        >
          <Box display="flex" mt={2} mb={1}>
            {!isHideBack && (
              <Box
                onClick={!!onBack ? onBack : defaultGotoBack}
                style={{ paddingTop: "1px" }}
                ml={1}
              >
                <strong>
                  <ArrowBackIcon />
                </strong>
              </Box>
            )}
            {!!isHideBack && (
              <Box style={{ paddingTop: "1px", height: "29px" }}></Box>
            )}
            <Box ml={2} className={classes.title}>
              {/* <h3>{title}</h3> */}
              {/* 양태욱 폰트 사이즈 수정 */}
              <strong
                  style={{ fontSize: "17px", margin: 0, wordBreak:"keep-all" }}>
                {title}</strong>
            </Box>
            {this.props.btnTitle && this.props.btnClick && (
              <Box className={classes.btnWrapper}>
                {this.props.btnTitle.map((info, idx) => (
                  <Button
                    key={`title_btn_${idx}`}
                    size="small"
                    onClick={this.props.btnClick[idx]}
                    className={classes.titleBtn}
                  >
                    <strong>{info}</strong>
                  </Button>
                ))}
              </Box>
            )}
          </Box>
          <Divider />
        </Box>

        {/* <Box p={2}>{this.props.children}</Box> */}
        {/* 20200622 박태성 - 모바일 아이디/패스워드 찾기에서 패딩이 발생하여 수정*/}
        {!!children && (
          <Box id="container-wrap-contents" flex={1} p={2}>
            {children}
          </Box>
        )}
      </Box>
    );
  }
}

function defaultGotoBack() {
  history.goBack(-1);
}

//export default ContainerWrap;
const styledContainerWrap = withStyles(styles)(ContainerWrap);

export { styledContainerWrap as ContainerWrap };
