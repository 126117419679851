/**
 * 본 모듈은 DOM의 이벤트 관련 메소드를 래퍼하여,<br/>
 * 브라우저에 따른 파편화 처리를 최소화 하기 위해 구현 되었습니다.<p/>
 *
 * IE 계열의 브라우저는 11이하의 경우 DOM의 이벤트를 지원하는 메소드가 다릅니다.<p/>
 *
 * DOM의 기본 이벤트 (click, keydown, .. )에 대해서만 사용 하는 것을 권장합니다.<br/>
 * IE 11 이하의 브라우저는 커스텀 이벤트를 지원하지 않습니다.<p/>
 *
 * @module utils/event-dom
 */

/**
 * DOM 객체에 리스너를 등록하는 메소드 입니다.
 *
 * @param {HTMLElement} element - Document Object Model(DOM) 객체
 * @param {string} eventType - 이벤트 타입
 * @param {Function} handler - 등록된 메소드
 * @param {boolean} bubbling - 이벤트 버블링 여부. IE는 미지원
 */
export function addEventListener(element, eventType, handler, bubbling) {
  if (!eventType || !element) return;

  if (element.attachEvent) {
    element.attachEvent("on" + eventType, handler);
  } else {
    if (bubbling === undefined) bubbling = false;

    element.addEventListener(eventType, handler, bubbling);
  }
}

/**
 * DOM 객체에 리스너를 삭제하는 메소드 입니다.
 *
 * @param {HTMLElement} element - Document Object Model(DOM) 객체
 * @param {string} eventType - 이벤트 타입
 * @param {Function} handler - 등록된 메소드
 * @param {boolean} bubbling - 이벤트 버블링 여부. IE는 미지원
 */
export function removeEventListener(element, eventType, handler, bubbling) {
  if (!eventType || !element) return;

  if (element.detachEvent) {
    element.detachEvent("on" + eventType, handler);
  } else {
    if (bubbling === undefined) {
      bubbling = false;
    }
    element.removeEventListener(eventType, handler, bubbling);
  }
}

/**
 * 엘리먼트의 이벤트 전파를 중단시키는 메소드 입니다.
 *
 * @param {Event} evt - 브라우저 Event 객체
 */
export function stopPropagation(evt) {
  // IE6.x나 그 이전 버전은 리스너의 인자로 event가 전달되지 않는다.
  // 따라서 전역객체 window로 부터 받아올 필요가 있다.
  if (!evt) evt = window.event;

  if (evt) {
    if (!evt.stopPropagation) {
      // IE
      evt.cancelBubble = true;
    } else {
      // DOM2.x
      evt.stopPropagation();
    }
  }
}

/**
 * 브라우저의 이벤트 전파를 중단시키는 메소드 입니다.
 *
 * @param {Event} evt - 브라우저 Event 객체
 */
export function preventDefault(evt) {
  // IE6.x나 그 이전 버전은 리스너의 인자로 event가 전달되지 않는다.
  // 따라서 전역객체 window로 부터 받아올 필요가 있다.
  if (!evt) evt = window.event;

  if (evt) {
    if (!evt.preventDefault) {
      // IE
      evt.returnValue = true;
    } else {
      // DOM2.x
      evt.preventDefault();
    }
  }
}
