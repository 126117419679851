import React from "react";
import { Paper } from "@material-ui/core";

function TodayCouponSettingHelp() {
  return (
    <Paper
      elevation={0}
      variant="outlined"
      style={{ margin: "1em 0", padding: "10px 20px" }}
    >
      <b>도움말</b>
      <h5 style={{ margin: "0", color: "#383838" }}>
        <br />
        1. 사용가능 기준금액, 본사이벤트, 신규회원 전용 설정 시 팝업 내 설정한
        쿠폰에만 적용됩니다.
        <br />
        2. 당일 사용 가능 쿠폰이 즉시 발행되며 취소 시 미사용 쿠폰은 모두
        취소됩니다.
        <br />
        3. 사용가능 기준금액 : 판매가를 기준으로 쿠폰이 적용되며, 설정한 판매가
        이상인 경우 쿠폰 적용이 가능합니다.
        <br />
        4. 사용가능 기준금액 : 제한 없음으로 설정하는 경우, 모든 객실에서 선착순
        쿠폰이 적용됩니다.
        <br />
        5. 본사이벤트 : 앱에서 본사이벤트 할인이 합산된 쿠폰으로 노출됩니다.
        <br />
        6. 신규회원 전용 : 앱에서 예약내역이 없는 신규회원에게만 쿠폰이
        노출됩니다.
      </h5>
    </Paper>
  );
  // }
}

export default React.memo(TodayCouponSettingHelp);
