/**
 * Loading Redux 기능을 제공하는 모듈 입니다
 *
 * @author Taesung Park <pts@pineone.com>
 * @module system/redux-ducks/loading
 */

const TAG = "[system/redux-ducks/loading.js]";
// console.log(TAG, "Create");

/*#############################################################
 *  Actions
 *###########################################################*/

/** 로딩이 시작 될 때 발생 되는 Action Type 입니다 */
export const LOADING_OPEN = "@loading/LOADING_OPEN";

/** 로딩이 종료 될 때 발생 되는 Action Type 입니다 */
export const LOADING_CLOSE = "@loading/LOADING_CLOSE";

/*#############################################################
 *  Action Creator
 *###########################################################*/

/**
 * 로딩의 시작 요청을 수행하는 Action 함수 입니다
 *
 * @returns {Promise} Promise 객체
 */
export function openRequest() {
  console.log(TAG, `Called openRequest()`);

  return (dispatch) => Promise.resolve().then(() => dispatch(openLoading()));
}

/**
 * 로딩의 종료 요청을 수행하는 Action 함수 입니다
 *
 * @returns {Promise} Promise 객체
 */
export function closeRequest() {
  console.log(TAG, `Called closeRequest()`);

  return (dispatch) => Promise.resolve().then(() => dispatch(closeLoading()));
}

/**
 * LOADING_OPEN 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @returns {object} LOADING_OPEN 타입을 정의한 객체
 */
export function openLoading() {
  console.log(TAG, `Called openLoading()`);

  return { type: LOADING_OPEN };
}

/**
 * LOADING_CLOSE 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @returns {object} LOADING_CLOSE 타입을 정의한 객체
 */
export function closeLoading() {
  console.log(TAG, `Called closeLoading()`);

  return { type: LOADING_CLOSE };
}

/*#############################################################
 *  Reducer
 *###########################################################*/

const initialState = {
  status: {
    open: false,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADING_OPEN:
      return {
        ...state,
        status: {
          ...state.staus,
          open: true,
        },
      };
    case LOADING_CLOSE:
      return {
        ...state,
        status: {
          ...state.staus,
          open: false,
        },
      };
    default:
      return state;
  }
}
