import React, {useCallback, useEffect, useMemo, useState} from "react";
import {BoxWrap, UpDownControl,} from "components";
import {Box, Button} from "@material-ui/core";
import commaFormater from "utils/commaFormater";

/*
 * [변경사항]
 * 2021.05.28 양태욱
 * 마일리지 최대 한도 10% -> 15% 변경
 */

const SimpleMileageSetting = ({ settingData, changeMileage }) => {
  // const numReg = useMemo(() => /\d+/g, []);
  const [mileageAmount, setMileageAmount] = useState(settingData?.point_rate);
  const [isChange, setIsChange] = useState(false);
  const onChangeMileage = useCallback((value) => {
    setIsChange(true);
    setMileageAmount(value);
  }, []);

  const initMileage = useMemo(() => { if(!!settingData) return settingData.point_rate },[settingData])

  useEffect(() => {
    if(settingData?.point_rate === mileageAmount) {
      setIsChange(false);
    }
  }, [settingData?.point_rate]);

  const handleChangeMileage = useCallback(() => {
    if((mileageAmount === initMileage) || !isChange) return true;
  },[mileageAmount,initMileage])



  return (
    <BoxWrap title={"마일리지 간편 설정"}>
      <Box display="flex">
        <Box style={{ lineHeight: "38px" }}>
          <h4 style={{ margin: "0" }}>결제 금액의</h4>
        </Box>
        <Box mx={1} width="15%">
          <UpDownControl
              callback={(value) =>  {
                onChangeMileage(value)
              }}
              unitType={"%"}
              initialVal={mileageAmount}
              interval={1}
              min={0}
              max={15}
              textAlign="center"
          />
        </Box>
        <Box style={{ lineHeight: "38px" }}>
          <h4 style={{ margin: "0" }}>를 마일리지로 설정합니다.</h4>
        </Box>
      </Box>
      <Box mt={2}>
        <h5 style={{ margin: "0", color: "#008aff" }}>
          ※ 결제금액의 15%까지 자유롭게 설정 가능합니다.
        </h5>
        <h5 style={{ margin: "0", color: "#f34788" }}>
          ※ 설정한 즉시 적용됩니다.
        </h5>
        <h5 style={{ margin: "0", color: "#008aff" }}>
          ※ 단골 손님이 내 사업장을 자주 방문하도록 마일리지 서비스를
          제공합니다.
          <br />
          {`※ 최소 ${
            settingData.min_use_point
              ? commaFormater(settingData.min_use_point)
              : "3,000"
          }원부터 
          ${
            settingData.min_use_point
              ? commaFormater(settingData.min_use_point_unit)
              : "1,000"
          }원 단위로 사용 가능합니다.`}
        </h5>
      </Box>
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            changeMileage(mileageAmount);
          }}
          disabled={
              // mileageAmount < 3 || mileageAmount > 15 || !isChange
          handleChangeMileage()}
        >
          <strong>적용하기</strong>
        </Button>
      </Box>
    </BoxWrap>
  );
};

export { SimpleMileageSetting };
