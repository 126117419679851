import React, { useState, useRef, useEffect } from "react";
import { OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { openSnackbar } from "lib/service-redux-alerts";
import { bindActionCreators } from "redux";

const useStyles = makeStyles({
  adornedEndCss: {
    padding: "0",
  },
  desc: {
    margin: "5.5px 0px 0px 0px",
    fontSize: "14px",
  },
  errorDesc: {
    margin: "5.5px 0px 0px 0px",
    color: "#f34788",
    fontSize: "14px",
  },
});

const CommonMobileTextField = ({
  autoComplete,
  autoFocus,
  color,
  defaultValue,
  disabled,
  inputComponent,
  error,
  // classes,
  FormHelperTextProps,
  fullWidth,
  helperText,
  id,
  InputLabelProps,
  inputProps,
  InputProps,
  inputRef,
  label,
  margin,
  multiline,
  name,
  onChange,
  placeholder,
  required,
  rows,
  rowsMax,
  select,
  SelectProps,
  size,
  type,
  value,
  variant,
  style,
  maxLength,
  filterRegExp,
  onSubmit,
  desc,
  errorDesc,
  openSnackbar,
  onKeyUp,
  hideSearch = false,
}) => {
  const [showError, setShowError] = useState(false);
  const [showDesc, setShowDesc] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const textRef = useRef();
  // const [showLimit, setShowLimit] = useState(false)

  const clearInput = () => {
    if (inputRef) {
      inputRef.current.value = "";
    } else {
      textRef.current.value = "";
    }
    setShowDelete(false);
  };

  const onInputChange = (event) => {
    setShowDelete(
      event.currentTarget.value && event.currentTarget.value.length
    );
    //길이제한
    if (maxLength && event.currentTarget.value.length > maxLength) {
      event.currentTarget.value = event.currentTarget.value.substring(
        0,
        maxLength
      );
      event.currentTarget.blur();
      openSnackbar({
        message: `최대 ${maxLength}자까지 입력 가능합니다.`,
      });
    }

    if (
      filterRegExp &&
      event.currentTarget.value &&
      !filterRegExp.test(event.currentTarget.value)
    ) {
      setShowError(true);
      setShowDesc(false);
    } else {
      setShowError(false);
      setShowDesc(true);
    }
    onChange && onChange(event);
  };

  useEffect(() => {
    const initValue = defaultValue || value;
    setShowDelete(initValue && initValue.length > 0);
  }, []);

  const classes = useStyles();

  return (
    <>
      <OutlinedInput
        onChange={onInputChange}
        fullWidth={fullWidth ? fullWidth : true}
        placeholder={placeholder && placeholder}
        size={size && size}
        variant={variant && variant}
        value={value && value}
        defaultValue={defaultValue && defaultValue}
        classes={{ adornedEnd: classes.adornedEndCss }}
        id={id && id}
        style={style && style}
        color={showError ? "secondary" : color}
        inputRef={inputRef ? inputRef : textRef}
        onKeyUp={onKeyUp && onKeyUp}
        endAdornment={
          <InputAdornment>
            {showDelete && (
              <IconButton onClick={clearInput}>
                <CancelIcon />
              </IconButton>
            )}
            {!hideSearch && (
              <IconButton onClick={onSubmit}>
                <SearchIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
        inputProps={
          inputProps
            ? {
                ...inputProps,
                style: { padding: "14px" },
              }
            : {
                style: { padding: "14px" },
              }
        }
      />
      {desc && showDesc && <p className={classes.desc}>{desc}</p>}
      {errorDesc && showError && (
        <p className={classes.errorDesc}>{errorDesc}</p>
      )}
    </>
  );
};
CommonMobileTextField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  // classes:	PropTypes.object,
  color: PropTypes.oneOf(["primary", "secondary"]),
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  FormHelperTextProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  label: PropTypes.node,
  margin: PropTypes.oneOf(["dense", "none"]),
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowsMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  select: PropTypes.bool,
  SelectProps: PropTypes.object,
  size: PropTypes.oneOf(["medium", "small"]),
  type: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  style: PropTypes.object,
  maxLength: PropTypes.number,
  filterRegExp: PropTypes.object,
  onSubmit: PropTypes.func,
  desc: PropTypes.string,
  errorDesc: PropTypes.string,
  hideSearch: PropTypes.bool,
  onKeyUp: PropTypes.func,
};

export default connect(null, (dispatch) =>
  bindActionCreators({ openSnackbar }, dispatch)
)(CommonMobileTextField);
