import React from "react";
import { Box, Paper, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

/**
 * 리뷰 통계 컴포넌트 입니다.<p/>
 *
 * @author max5500 <max5500@pineone.com>
 * @name StatReview
 * @function
 * @component
 */

const useStyles = makeStyles((theme) =>
  createStyles({
    contents: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.3),
      },
    },
    title: {},
    content: {
      fontSize: "400%",
      fontWeight: "bold",
    },
  })
);

function StatReview() {
  const classes = useStyles();
  return (
    <Box m={1} className="test">
      <Paper elevation={0} variant="outlined" square>
        <div className={classes.contents}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={6}
              container
              justify="flex-start"
              alignItems="flex-start"
            >
              <Paper elevation={0} square>
                <span className={classes.title}>전체 평점</span>
              </Paper>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justify="flex-end"
              alignItems="flex-end"
            >
              <Paper elevation={0} square>
                <span className={classes.content}>4.8</span>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Box>
  );
}

export { StatReview };
