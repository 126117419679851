import React from "react";
import { TableCell } from "@material-ui/core";
import { UpDownControl } from "components";

/** 테이블의 Select로 처리되는 항목 컴포넌트입니다. */
function CouponUpDownForm({
  onChange = null,
  value,
  unitType = "",
  name = "",
  disabled = true,
  interval = 0,
  min = 0,
  max = 999,
  width = "22%",
}) {
  return (
    <TableCell
      align="center"
      style={{
        width: width,
        maxWidth: "130px",
      }}
    >
      <UpDownControl
        callback={onChange}
        initialVal={value}
        unitType={unitType || ""}
        interval={interval}
        min={min}
        max={max}
        name={name}
        // disabled={!useModify || specialDisabled}
        disabled={disabled}
      />
    </TableCell>
  );
}

export default CouponUpDownForm;
