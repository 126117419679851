import React, { useState, useEffect, useRef, useCallback } from "react";

import { ModalWrap } from "components";

import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";

import { makeStyles } from "@material-ui/core/styles";

import * as configApp from "config/config-app";
import * as serviceBrowser from "lib/service-browser";
import * as serviceStorage from "lib/service-storage";
import {
  // POPUP_ACTION_OK,
  openHttpRejectionPopupWithPromise,
  openAlertPopupWithPromise,
  // openConfirmPopupWithPromise,
} from "lib/helper-popup";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { openRequest } from "system/redux-ducks/loading";

import { getRegions, getMotelsRegions } from "lib/service-api";
import InputLength from "constant/InputLength";
import { makeCompMotels } from "../templates/MotelSelectMasterTemplate";

const GUIDE_NONE_MOTELS = "찾으시는 검색 결과가 없습니다.";
const TIME_INPUT = 500;
let SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";

const useStyles = makeStyles(() => {
  if (!SERVICE_TYPE_PC) {
    // 모바일
    return {
      root: {
        padding: "0",
        "& .pc-motels": {
          overflow: "hidden",
          overflowY: "auto",
          display: "block",
          height: "185px",
          marginTop: "5px",
          overflowScrolling: "touch",
          WebkitOverflowScrolling: "touch",
          padding: "0.25rem",
        },
        "& .pc-motels.none": {
          overflowY: "hidden",
          display: "table",
          width: "100%",
          border: "none",
        },
        "& .pc-motels .pc-motels-item": {
          position: "relative",
          marginTop: "0.25rem",
          marginBottom: "0.25rem",
          "& .MuiTypography-body1": {
            fontSize: "0.83rem",
            width: "calc(100% - 42px)",
          },
        },
        "& .pc-motels .formControl": {
          marginTop: "0px !important",
          display: "block",
          width: "100%",
        },
        "& .pc-motels .formControlLabel": {
          borderBottom: "1px solid #f5f6f8",
          fontWeight: "bold",
          width: "100%",
          "& .label": {
            maxWidth: "calc(100vw - 155px)",
          },
        },
        "& .description": {
          "& p": {
            marginTop: "10px",
            marginBottom: "10px",
            fontWeight: "bold",
          },
        },
      },
      adornedEndCss: {
        padding: "0",
      },
    };
  }

  // PC
  return {
    root: {
      padding: "0",
      "& .pc-motels": {
        overflow: "hidden",
        overflowY: "auto",
        display: "block",
        height: "250px",
        marginTop: "10px",
        // overflowScrolling: "touch",
        // WebkitOverflowScrolling: "touch",
        padding: "0.25rem",
      },
      "& .pc-motels.none": {
        overflowY: "hidden",
        display: "table",
        width: "100%",
        border: "none",
      },
      "& .pc-motels .pc-motels-item": {
        position: "relative",
        width: "532px",
        display: "block",
        marginTop: "0.25rem",
        marginBottom: "0.25rem",
        "& .MuiTypography-body1": {
          fontSize: "0.83rem",
          width: "100%",
        },
      },
      "& .pc-motels .formControl": {
        marginTop: "0px !important",
        display: "block",
        width: "100%",
      },
      "& .pc-motels .formControlLabel": {
        borderBottom: "1px solid #f5f6f8",
        fontWeight: "bold",
        minWidth: "47%",
        maxWidth: "47%",
        "& .label": {
          maxWidth: "200px",
        },
      },
      "& .description": {
        "& p": {
          marginTop: "10px",
          marginBottom: "10px",
          fontWeight: "bold",
        },
      },
    },
    adornedEndCss: {
      padding: "0",
    },
  };
});

const INIT_FIELD_STATE = {
  currentRegions: "ALL",
  currentMotelsRegions: "NONE",
  motelsRegions: [],
  selectedKey: "",
  isShowSearchCancel: false,
  compMotels: [],
};

/**
 * 영업용 웹마스터 전용 숙소 변경 팝업 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name PopupMotelSelectMaster
 * @component
 */
function PopupMotelSelectMaster({ openRequest, open, onClose }) {
  const [state, setState] = useState({
    motels: [],
    regions: [],
    // isFocus: false,
    isHiddenGuideText: true,
    ...INIT_FIELD_STATE,
  });

  const {
    compMotels,
    selectedKey,
    currentRegions,
    regions,
    currentMotelsRegions,
    motelsRegions,
    isShowSearchCancel,
    isHiddenGuideText,
  } = state;

  const isDisableRegions = 1 > regions.length;
  const isDisableMotelsRegions = 1 > motelsRegions.length;

  const stateActive = useRef(true);
  const refScrollTarget = useRef();
  const refTextField = useRef();
  const timerInput = useRef();
  // const validInput = useRef(false);

  const isAllowInnserScroll = useRef(false);
  const isOpenSelect = useRef(false);

  const handleOnClose = useCallback(() => {
    !!onClose && onClose();
  }, []);

  const handleTouchStart = useCallback((evt) => {
    if (!!isOpenSelect.current) return;
    if (!!refScrollTarget.current) {
      // https://d2.naver.com/helloworld/80243
      let rect = refScrollTarget.current.getBoundingClientRect();
      const top = window.pageYOffset + rect.top;
      const bottom = window.pageYOffset + rect.bottom;
      const left = rect.left;
      const right = rect.right;

      const pageX = evt.pageX || evt.touches[0].pageX;
      const pageY = evt.pageY || evt.touches[0].pageY;

      if (left <= pageX && pageX < right && top <= pageY && pageY < bottom) {
        isAllowInnserScroll.current = true;
        serviceBrowser.pauseDisableScroll();
      }

      rect = null;
    }
  }, []);

  const handleTouchEnd = useCallback(() => {
    if (!!isAllowInnserScroll.current) {
      isAllowInnserScroll.current = false;
      serviceBrowser.resumeDisableScroll();
    }
  }, []);

  const handleOnOpenSelect = useCallback(() => {
    isOpenSelect.current = true;
    serviceBrowser.pauseDisableScroll();
  }, []);

  const handleOnCloseSelect = useCallback(() => {
    isOpenSelect.current = false;
    serviceBrowser.resumeDisableScroll();
  }, []);

  const doClearTimerInput = useCallback(() => {
    !!timerInput.current && clearTimeout(timerInput.current);
    timerInput.current = null;
  }, []);

  const isActiveTimerInput = useCallback(() => {
    return !!timerInput.current;
  }, []);

  // 생성, 소멸
  useEffect(() => {
    document.body.addEventListener(
      "touchstart",
      handleTouchStart,
      serviceBrowser.isSupportedPassive() ? { passive: true } : true
    );
    document.body.addEventListener(
      "touchend",
      handleTouchEnd,
      serviceBrowser.isSupportedPassive() ? { passive: true } : true
    );

    return () => {
      stateActive.current = false;
      document.body.removeEventListener("touchstart", handleTouchStart);
      document.body.removeEventListener("touchend", handleTouchEnd);
      doClearTimerInput();
    };
  }, []);

  useEffect(() => {
    if (!!open) {
      // if (!!validInput.curren) {
      //   validInput.current = false;
      // }
      let regions = [];
      getRegions()
        .then((response) => {
          if (!stateActive.current) return;
          regions = response.data.result.regions || [];
        })
        .finally(() => {
          if (!stateActive.current) return;

          setState((state) => {
            let motels =
              JSON.parse(
                JSON.stringify(
                  serviceStorage.getMotels(serviceStorage.getIdentity())
                )
              ) || [];

            return {
              ...state,
              ...INIT_FIELD_STATE,
              motels,
              regions,
              // isFocus: false,
              isHiddenGuideText: true,
            };
          });
        });
    } else {
      doClearTimerInput();
      setState((state) => {
        return {
          ...state,
          ...INIT_FIELD_STATE,
          isHiddenGuideText: true,
        };
      });
    }
  }, [open]);

  const handleSelectModel = () => {
    if (!!selectedKey) {
      if (serviceStorage.getConnectedMotelKey() != selectedKey) {
        serviceStorage
          .connectMotelWithPromise(selectedKey)
          .then(() => {
            if (!stateActive.current) return;
            openRequest();
            window.location.replace(window.location.pathname);
          })
          .catch(openHttpRejectionPopupWithPromise);
        return;
      } else {
        handleOnClose();
        return;
      }
    }

    openAlertPopupWithPromise(null, "로그인하실 제휴점을 선택해주세요.");
  };

  const handleOnChange = (event) => {
    !!event.preventDefault && event.preventDefault();
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleOnChangeRegions = useCallback((event) => {
    !!event.preventDefault && event.preventDefault();
    const { name, value } = event.target;

    if (value === "ALL") {
      setState((state) => {
        return {
          ...state,
          ...INIT_FIELD_STATE,
        };
      });
      return;
    }

    setState((state) => {
      let findRegions = state.regions.filter((region) => {
        return region.code === value;
      });

      if (0 < findRegions.length) {
        return {
          ...state,
          motelsRegions: findRegions[0].sub_regions,
          currentMotelsRegions: findRegions[0].sub_regions[0].code,
          [name]: value,
        };
      }

      return state;
    });
  }, []);

  const handleOnChangeMotelsRegions = useCallback((event) => {
    !!event.preventDefault && event.preventDefault();
    const { value } = event.target;
    setState((state) => {
      return {
        ...state,
        currentMotelsRegions: value,
      };
    });
  }, []);

  const doRenderMotelsRegions = useCallback((value) => {
    getMotelsRegions({
      region_code: value,
    })
      .then((response) => {
        if (!stateActive.current) return;
        let motels = (response.data.result.motels || []).map((motel) => {
          return {
            key: motel.key,
            name: motel.motel_extra.motel_name,
            isAppVisible: motel.isAppVisible,
          };
        });
        return Promise.resolve(motels);
      })
      .catch(() => {
        if (!stateActive.current) return;
        return Promise.resolve([]);
      })
      .then((motels) => {
        if (!!refTextField.current) refTextField.current.value = "";
        if (!!refScrollTarget.current) refScrollTarget.current.scrollTop = 0;
        setState((state) => {
          return {
            ...state,
            selectedKey: "",
            isShowSearchCancel: false,
            isHiddenGuideText: false,
            compMotels: makeCompMotels(
              motels,
              SERVICE_TYPE_PC ? "210px" : "100%",
              true
            ),
          };
        });
      });
  }, []);

  useEffect(() => {
    if (currentMotelsRegions !== "NONE") {
      doRenderMotelsRegions(currentMotelsRegions);
    } else {
      setState((state) => {
        return {
          ...state,
          isHiddenGuideText: 1 > state.compMotels.length,
        };
      });
    }
  }, [currentMotelsRegions]);

  const getDisabled = useCallback((val) => {
    if (val) return { disabled: true };
    return {};
  }, []);

  const handleOnChangeInputKeyword = useCallback((event) => {
    !!event.preventDefault && event.preventDefault();
    doClearTimerInput();
    const value = event.target.value || "";
    setState((state) => {
      return {
        ...state,
        isShowSearchCancel: 0 < value.length,
      };
    });
  }, []);

  // const handleOnKeyDownKeyword = useCallback((event) => {
  //   validInput.current = true;
  // }, []);

  // const handleOnKeyUpKeyword = useCallback((event) => {
  //   validInput.current = false;
  // }, []);

  const handleOnChangeKeyword = useCallback((event) => {
    !!event.preventDefault && event.preventDefault();
    doClearTimerInput();

    // 20210226 박태성C
    // 한글 입력 중 [삭제] 버튼을 누르면 handleOnChangeKeyword 이벤트가 중복 호출 됨
    // onKeyDown, onKeyUp 사이에 발생 되는 onChange 이벤트만 유효하다고 판단하여 처리함
    /**
     * finn ( 20240522 )
     * 일단 [삭제] 버튼을 누를 때 handleOnChangeKeyword 이벤트가 호출 되지 않음
     * 그래서 keyDown, keyUp 이벤트를 사용하여 validInput.current = true, false 처리를 하지 않아도 됨
     * 주석 처리
     * */
    // if (!validInput.current) return;

    const value = event.target.value || "";

    if (!!value) {
      timerInput.current = setTimeout(() => {
        doClearTimerInput();

        if (!stateActive.current) return;

        setState((state) => {
          // if (state.isFocus === true) {
          return {
            ...state,
            ...INIT_FIELD_STATE,
            isShowSearchCancel: 0 < value.length,
            compMotels: makeCompMotels(
              state.motels.filter((motel) => {
                const lowerName = motel.name.toLowerCase();
                const lowerValue = value.toLowerCase();
                return lowerName.includes(lowerValue);
              }),
              SERVICE_TYPE_PC ? "210px" : "100%",
              true
            ),
            isHiddenGuideText: false,
          };
          // }
          // return state;
        });
      }, TIME_INPUT);
    } else {
      setState((state) => {
        // if (state.isFocus === true) {
        if (0 < state.compMotels.length || !!state.isShowSearchCancel) {
          return {
            ...state,
            ...INIT_FIELD_STATE,
            isHiddenGuideText: true,
          };
        }
        // }
        return state;
      });
    }
  }, []);

  const handleOnClickSearchCancel = (event) => {
    !!event.preventDefault && event.preventDefault();
    doClearTimerInput();

    if (!!refTextField.current && !!refTextField.current.value.trim()) {
      refTextField.current.value = "";

      setState({
        ...state,
        ...INIT_FIELD_STATE,
        isHiddenGuideText: true,
      });
    }

    if (
      document &&
      document.activeElement &&
      document.activeElement.tagName !== "BODY"
    ) {
      document.activeElement.blur();
    }
  };

  const handleOnClickSearch = (event) => {
    !!event && !!event.preventDefault && event.preventDefault();
    const value = refTextField.current.value || "";
    setState({
      ...state,
      ...INIT_FIELD_STATE,
      isShowSearchCancel: 0 < value.length,
      isHiddenGuideText: 1 > value.length,
      compMotels: !!value.trim()
        ? makeCompMotels(
            state.motels.filter((motel) => {
              const lowerName = motel.name.toLowerCase();
              const lowerValue = value.toLowerCase();
              return lowerName.includes(lowerValue);
            }),
            SERVICE_TYPE_PC ? "210px" : "100%",
            true
          )
        : [],
    });

    if (
      document &&
      document.activeElement &&
      document.activeElement.tagName !== "BODY"
    ) {
      document.activeElement.blur();
    }
  };

  const handleOnFocusKeyword = (event) => {
    doClearTimerInput();
    // setState({ ...state, isFocus: true });
  };

  const handleOnBlurKeyword = (event) => {
    const isActvieTimer = isActiveTimerInput();
    doClearTimerInput();
    // setState({ ...state, isFocus: false });
    !!isActvieTimer && handleOnClickSearch(null);
  };

  const classes = useStyles();
  /*
   * 2021.05.06 양태욱
   * [COOL2105-10] 모바일에서 제휴점 변경시 확인,닫기 버튼 순서 변경
   */
  const buttons = SERVICE_TYPE_PC
    ? [
        {
          title: "확인",
          callback: handleSelectModel,
        },
        {
          title: "취소",
          callback: handleOnClose,
        },
      ]
    : [
        {
          title: "확인",
          callback: handleSelectModel,
        },
        {
          title: "닫기",
          callback: handleOnClose,
        },
        // {
        //   title: "닫기",
        //   callback: handleOnClose,
        // },
        // {
        //   title: "확인",
        //   callback: handleSelectModel,
        // },
      ];

  return (
    <ModalWrap
      title="제휴점변경"
      open={open}
      onClose={handleOnClose}
      onButtons={buttons}
      PaperProps={
        SERVICE_TYPE_PC
          ? {
              style: {
                width: `${500 + 80}px`,
                height: `${450 + 90}px`,
              },
            }
          : {
              style: {
                width: "90%",
                height: `${380 + 90}px`,
              },
            }
      }
    >
      <Box className={classes.root}>
        {SERVICE_TYPE_PC && (
          <>
            <Box className="description">
              <p>선택하신 제휴점으로 로그인합니다.</p>
            </Box>
            <Box>
              <OutlinedInput
                placeholder="숙소명으로 찾아보세요."
                fullWidth
                variant="outlined"
                size="small"
                autoComplete="off"
                inputProps={{
                  style: { padding: "14px" },
                  maxLength: InputLength.stayName.max,
                  autoCapitalize: "off",
                  autoCorrect: "off",
                }}
                endAdornment={
                  !!isShowSearchCancel ? (
                    <InputAdornment>
                      <IconButton onClick={handleOnClickSearchCancel}>
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  )
                }
                classes={{ adornedEnd: classes.adornedEndCss }}
                inputRef={refTextField}
                onFocus={handleOnFocusKeyword}
                onBlur={handleOnBlurKeyword}
                // onKeyDown={handleOnKeyDownKeyword}
                // onKeyUp={handleOnKeyUpKeyword}
                onChange={handleOnChangeKeyword}
              />
            </Box>
            <Box mt={1}>
              <FormControl
                variant="outlined"
                size="small"
                style={{ minWidth: "49%" }}
              >
                <Select
                  value={currentRegions}
                  onChange={handleOnChangeRegions}
                  name="currentRegions"
                  {...getDisabled(isDisableRegions)}
                >
                  <MenuItem value={"ALL"}>선택</MenuItem>
                  {!!regions &&
                    regions
                      .filter((region) => {
                        return (
                          region.has_motel_yn === "Y" &&
                          !!region.sub_regions &&
                          0 < region.sub_regions.length
                        );
                      })
                      .map((region, index) => (
                        <MenuItem key={index} value={region.code}>
                          {region.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                size="small"
                style={{ marginLeft: "2%", minWidth: "49%", maxWidth: "260px" }}
              >
                <Select
                  value={currentMotelsRegions}
                  onChange={handleOnChangeMotelsRegions}
                  name="currentMotelsRegions"
                  {...getDisabled(isDisableMotelsRegions)}
                >
                  {isDisableMotelsRegions && (
                    <MenuItem value={"NONE"}></MenuItem>
                  )}
                  {!isDisableMotelsRegions &&
                    motelsRegions.map((motel, index) => (
                      <MenuItem key={index} value={motel.code}>
                        {motel.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </>
        )}
        {!SERVICE_TYPE_PC && (
          <>
            <Box>
              <OutlinedInput
                placeholder="숙소명으로 찾아보세요."
                fullWidth
                variant="outlined"
                size="small"
                autoComplete="off"
                inputProps={{
                  style: { padding: "14px" },
                  maxLength: InputLength.stayName.max,
                  autoCapitalize: "off",
                  autoCorrect: "off",
                }}
                endAdornment={
                  <InputAdornment>
                    {!!isShowSearchCancel && (
                      <IconButton onClick={handleOnClickSearchCancel}>
                        <CancelIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={handleOnClickSearch} position="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                classes={{ adornedEnd: classes.adornedEndCss }}
                inputRef={refTextField}
                onChange={handleOnChangeInputKeyword}
              />
            </Box>
            <Box mt={1}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select
                  value={currentRegions}
                  onChange={handleOnChangeRegions}
                  onOpen={handleOnOpenSelect}
                  onClose={handleOnCloseSelect}
                  name="currentRegions"
                  {...getDisabled(isDisableRegions)}
                >
                  <MenuItem value={"ALL"}>선택</MenuItem>
                  {!!regions &&
                    regions
                      .filter((region) => {
                        return (
                          region.has_motel_yn === "Y" &&
                          !!region.sub_regions &&
                          0 < region.sub_regions.length
                        );
                      })
                      .map((region, index) => (
                        <MenuItem key={index} value={region.code}>
                          {region.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Box>
            <Box mt={1}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select
                  value={currentMotelsRegions}
                  onChange={handleOnChangeMotelsRegions}
                  onOpen={handleOnOpenSelect}
                  onClose={handleOnCloseSelect}
                  name="currentMotelsRegions"
                  {...getDisabled(isDisableMotelsRegions)}
                >
                  {isDisableMotelsRegions && (
                    <MenuItem value={"NONE"}></MenuItem>
                  )}
                  {!isDisableMotelsRegions &&
                    motelsRegions.map((motel, index) => (
                      <MenuItem key={index} value={motel.code}>
                        {motel.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </>
        )}
        <Box
          mt={1}
          className={`pc-motels ${1 > compMotels.length ? "none" : ""}`}
          ref={refScrollTarget}
        >
          {0 < compMotels.length && (
            <FormControl component="fieldset" className="formControl">
              <RadioGroup
                name="selectedKey"
                value={selectedKey}
                onChange={handleOnChange}
                className="pc-motels-item"
              >
                {compMotels}
              </RadioGroup>
            </FormControl>
          )}
          {!isHiddenGuideText && 1 > compMotels.length && (
            <GuideText
              isMobile={!SERVICE_TYPE_PC}
              description={GUIDE_NONE_MOTELS}
            />
          )}
        </Box>
      </Box>
    </ModalWrap>
  );
}

export default connect(null, (dispatch) =>
  bindActionCreators({ openRequest }, dispatch)
)(PopupMotelSelectMaster);

function GuideText({ isMobile = false, description = "" }) {
  return (
    <div
      style={{
        whiteSpace: "pre-line",
        verticalAlign: "middle",
        display: "table-cell",
        textAlign: "center",
      }}
    >
      {isMobile ? (
        <p style={{ fontSize: "13px", fontWeight: "bold" }}>{description}</p>
      ) : (
        <p style={{ fontWeight: "bold" }}>{description}</p>
      )}
    </div>
  );
}
