/**
 * 본 모듈은 숫자를 입력받아 콤마를 삽입하여 반환해주는 모듈 입니다
 *
 * @module utils/commaFormater
 */

/**
 * 문자열을 검사하여 한국어 여부를 반환 합니다
 *
 * @param {Number} num - 문자열
 * @return {String} 콤마를 포함한 문자열
 */
export default function commaFormater(num) {
  if (!!num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return num;
  }
}
