import React from "react";
import { Route, Redirect } from "react-router-dom";

import * as serviceStorage from "lib/service-storage";

/**
 * 상태에 따라 화면 이동을 처리하는 Component 입니다<p/>
 *
 * <ul>
 * <li>로그인 여부</li>
 * <li>약관 동의 여부</li>
 * </ul>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name HandlerRoute
 * @component
 */
function HandlerRoute({
  isLoggedIn,
  checkAcceptTerms,
  checkingTmpToken,
  component: Component,
  render,
  ...rest
}) {
  // 로그인 상태
  if (isLoggedIn) return <Redirect to="/" />;

  ////////////////////////////////////////////////////////////////////////////////////
  if (!!checkAcceptTerms) {
    let isAccepted = false;
    let acceptTerms = serviceStorage.getAcceptTerms();
    if (!!acceptTerms) {
      isAccepted = !!acceptTerms.isAccepted;
    }
    acceptTerms = null;
    // 약관 미동의 상태
    if (!isAccepted) return <Redirect to="/terms" />;
  }
  //////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////
  if (!!checkingTmpToken) {
    let hasTmpToken = false;
    let tempData = serviceStorage.getTempData();
    if (!!tempData) {
      hasTmpToken = !!tempData.tmpAccessToken;
    }
    tempData = null;
    if (!hasTmpToken) return <Redirect to="/login" />;
  }
  //////////////////////////////////////////////////////////////////////////////////

  return (
    <Route
      {...rest}
      render={(props) => (render ? render(props) : <Component {...props} />)}
    />
  );
}

export default HandlerRoute;
