// actions
import * as serviceAPI from "../../../lib/service-api";
import * as serviceStorage from "lib/service-storage";
import * as serviceBrowser from "lib/service-browser";
import {login, loginFailure, loginSuccess} from "../authentication";
const TAG = "[system/redux-ducks/actions/authenticationAction.js]";

export const GET_AUTHENTICATION_REQUEST = 'GET_AUTHENTICATION_REQUEST';
export const GET_AUTHENTICATION_SUCCESS = 'GET_AUTHENTICATION_SUCCESS';
export const GET_AUTHENTICATION_FAILURE = 'GET_AUTHENTICATION_FAILURE';

export const GET_LOGOUT_REQUEST = 'GET_LOGOUT_REQUEST';
export const GET_LOGOUT_SUCCESS = 'GET_LOGOUT_SUCCESS';
export const GET_LOGOUT_FAILURE = 'GET_LOGOUT_FAILURE';

export const getAuthenticationRequest = () => ({ type: GET_AUTHENTICATION_REQUEST });
export const getAuthenticationSuccess = () => ({ type: GET_AUTHENTICATION_SUCCESS });
export const getAuthenticationFailure = (error) => ({ type: GET_AUTHENTICATION_FAILURE, error });

export const getLogoutRequest = () => ({ type: GET_LOGOUT_REQUEST });
export const getLogoutSuccess = () => ({ type: GET_LOGOUT_SUCCESS });
export const getLogoutFailure = (error) => ({ type: GET_LOGOUT_FAILURE, error });

export const postLogin = (params) => {
    console.log(TAG, `Called postLogin(isAutoLogin=${params.isAutoLogin}) | isSaveID =${params.isSaveID}`);

    serviceBrowser.disableBackNavigation();
    return (dispatch) => {
        dispatch(getAuthenticationRequest());

        return serviceAPI.postLogin(params)
            .then((response) => {
                const {isSaveID, isAutoLogin} = params;
                dispatch(getAuthenticationRequest());
                isSaveID && serviceStorage.setTempUserId(params.data.id);
                !isSaveID && serviceStorage.removeTempUserId();

                return serviceStorage.setIdentity(response?.data, isAutoLogin);

            }).then((data) => {
                dispatch(getAuthenticationSuccess());
                return Promise.resolve();
            })
            .catch((error) => {
                dispatch(getAuthenticationFailure());
                return Promise.reject(error);
            })
            .finally(() => {
                serviceBrowser.enableBackNavigation();
            });
    };
};
